import {ConditionCode} from '../models/ConditionCode';
import {JobCode} from '../models/JobCode';
import {OfflineEstimateLine} from '../models/OfflineEstimateLine';
import {UnitLocationCode} from '../models/UnitLocationCode';
import {WhyMadeCode} from '../models/WhyMadeCode';
import {OfflineEstimateLineDataGridDto} from '../models/dtos/offline-estimate-line-data-grid-dto';

export const convertOfflineEstimateLineToOfflineEstimateLineDataGridDto = (
    offlineEstimateLine: OfflineEstimateLine,
    jobCodeNumber?: string,
    jobCodeDescription?: string,
    whyMadeCodeDescription?: string,
    conditionCodeDescription?: string,
    unitLocationCodeDescription?: string,
): OfflineEstimateLineDataGridDto => {
    return {
        ...offlineEstimateLine,
        ConditionCodeDescription: conditionCodeDescription,
        JobCodeNumber: jobCodeNumber,
        JobCodeDescription: jobCodeDescription,
        UnitLocationCodeDescription: unitLocationCodeDescription,
        Defect: whyMadeCodeDescription,
        DetailsField: offlineEstimateLine.ID,
        CommentsCount: offlineEstimateLine.Comments?.length ?? 0,
        PartsCount: offlineEstimateLine.Parts?.length ?? 0,
        FilesCount: offlineEstimateLine.Files?.length ?? 0,
    };
};

export const convertOfflineEstimateLinesToOfflineEstimateLineDataGridDtos = (
    offlineEstimateLines: OfflineEstimateLine[],
    jobCodes: JobCode[],
    whyMadeCodes: WhyMadeCode[],
    conditionCodes: ConditionCode[],
    unitLocationCodes: UnitLocationCode[],
): OfflineEstimateLineDataGridDto[] =>
    offlineEstimateLines?.map((item) => {
        const jobCode = jobCodes.find((jobCode) => jobCode.ID === item.JobCodeId);
        const whyMadeCode = whyMadeCodes.find((whyMadeCode) => whyMadeCode.ID === item.WhyMadeCodeId);
        const conditionCode = conditionCodes.find((conditionCode) => conditionCode.ID === item.ConditionCodeId);
        const unitLocationCode = unitLocationCodes.find((unitLocationCode) => unitLocationCode.ID === item.UnitLocationCodeId);

        return convertOfflineEstimateLineToOfflineEstimateLineDataGridDto(
            item,
            jobCode?.JobCodeNumber ?? '',
            jobCode?.Description ?? '',
            whyMadeCode?.Description ?? '',
            conditionCode?.Description ?? '',
            unitLocationCode?.UnitLocation ?? '',
        );
    });
