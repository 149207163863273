import {registerLicense} from '@syncfusion/ej2-base';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';

import {App} from './App';
import {Fallback} from './components/fallback/fallback.component';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './i18n';
import './index.css';
import './utils/floor';

registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Xd0FjUH5ccHNcRmdU');

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<Fallback />}>
            <App />
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
