import {createSelector} from 'reselect';

import {State} from '../root-reducer';

import {ShopState} from './shop.reducer';

export const selectShopState = (state: State): ShopState => state.shopReducer;

export const selectShops = createSelector(selectShopState, ({shops}) => shops);

export const selectSelectedShop = createSelector(selectShopState, ({selectedShop}) => selectedShop);
