import {differenceInMilliseconds} from 'date-fns';
import {unionBy} from 'lodash';
import {takeLeading, call, put} from 'redux-saga/effects';

import config from '../../config';
import {ReduxAction} from '../../models/ReduxAction';
import {Station} from '../../models/Station';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {formatDate} from '../../utils/formatDate';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';

import {stationsError, stationsRequest, stationsResponse} from './station.actions';
import {FETCH_STATIONS} from './station.types';

const {resourceCacheFallback} = config;

export function* fetchStations() {
    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_STATIONS_REFRESH_TIME, LocalStorageKeys.LAST_STATIONS_UPDATE_TIME);

    let cachedStations: Station[] = [];

    if (lastUpdateTime) {
        cachedStations = yield call(IndexedDB.getMany, IndexedDBKeys.STATIONS);

        if (differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
            yield put(stationsResponse(cachedStations));

            return;
        }
    }

    yield put(stationsRequest());

    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_STATIONS_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<Station[]> = yield call(request, {
        url: '/stations',
        method: 'get',
        params: lastUpdateTime
            ? {
                  includeDeleted: true,
                  lastUpdateTime: formatDate({
                      date: lastUpdateTime,
                      ISOFormat: true,
                  }),
              }
            : undefined,
    });

    if (error) {
        yield put(stationsError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedStations) {
            yield put(stationsResponse(cachedStations));
        }
    }

    if (data) {
        const combinedStations = unionBy(data, cachedStations, 'ID').filter((station) => !station.IsDeleted);

        yield put(stationsResponse(combinedStations));

        yield call(IndexedDB.createMany, IndexedDBKeys.STATIONS, combinedStations);
        LocalStorage.set(LocalStorageKeys.LAST_STATIONS_UPDATE_TIME, requestTime);
    }
}

export default function* stationsRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_STATIONS, fetchStations);
}
