import {differenceInMilliseconds} from 'date-fns';
import {t} from 'i18next';
import {takeLatest, call, put, select} from 'redux-saga/effects';

import config from '../../config';
import {Pricing} from '../../models/Pricing';
import {ReduxAction} from '../../models/ReduxAction';
import {ShopPricing} from '../../models/ShopPricing';
import {StationCompanyRelation} from '../../models/StationCompanyRelation';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import localStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {selectIsOfflineStatus} from '../application/application.selectors';
import {selectSelectedShop} from '../shop/shop.selectors';

import {
    clearRepairLinePricing,
    repairLinePricingResponse,
    pricingError,
    pricingRequest,
    shopPricingResponse,
    shopPricingError,
} from './pricing.actions';
import {FETCH_REPAIR_LINE_PRICING, FETCH_SHOP_PRICING, FetchRepairLinePricing} from './pricing.types';

const {resourceCacheFallback} = config;

export function* fetchRepairLinePricing({payload}: ReduxAction<FetchRepairLinePricing>) {
    if (!payload) {
        yield put(clearRepairLinePricing());
        return;
    }

    const isOfflineMode: boolean = yield select(selectIsOfflineStatus);

    if (isOfflineMode) {
        const shopPricing: ShopPricing[] = yield call(IndexedDB.getMany, IndexedDBKeys.PRICING);
        // There should only ever be 1 shop pricing stored.
        if (shopPricing?.length === 1 && shopPricing[0].CompanyID === payload.companyID && shopPricing[0].StationID === payload.stationID) {
            const matches = shopPricing[0].Pricing.filter(
                (p) =>
                    p.JobCodeID === payload.jobCodeID &&
                    (p.ConditionCodeID === payload.conditionCodeID || p.ConditionCodeID < 1) &&
                    (payload.equipmentGroupID == null || p.EquipmentGroupIDs.includes(payload.equipmentGroupID)),
            );

            if (matches?.length > 0) {
                // Multiple matches might be found if the unit wasn't found in the unit group so the equipment group is not known.
                // Take the largest match so it will either be correct or cause an error.
                yield put(
                    repairLinePricingResponse({
                        ID: 0,
                        IsDeleted: false,
                        MaxHoursPerRepairLine: Math.max(...matches.map((m) => m.MaxHoursPerRepairLine)),
                        CompanyID: payload.companyID,
                        StationID: payload.stationID,
                        JobCodeID: payload.jobCodeID,
                        ConditionCodeID: payload.conditionCodeID,
                    }),
                );
            } else {
                yield put(pricingError(true));
                if (!payload.suppressError) {
                    yield showErrorMessages([t('no_pricing_found')]);
                }
            }
        }
    } else {
        yield put(pricingRequest());

        const {data, error}: ApiResponse<Pricing> = yield call(request, {
            url: '/pricing',
            method: 'get',
            params: {
                companyID: payload.companyID,
                stationID: payload.stationID,
                jobCodeID: payload.jobCodeID,
                conditionCodeID: payload.conditionCodeID,
                repairSizeID: payload.repairSizeID,
                equipmentGroupID: payload.equipmentGroupID,
                repairDate: payload.repairDate,
            },
        });

        if (error) {
            yield put(pricingError(true));
            yield showErrorMessages(error.messages);
        }

        if (data) {
            yield put(repairLinePricingResponse(data));
        } else {
            yield put(pricingError(true));
            if (!payload.suppressError) {
                yield showErrorMessages([t('no_pricing_found')]);
            }
        }
    }
}

export function* fetchShopPricing() {
    yield put(pricingRequest());
    const shop: StationCompanyRelation = yield select(selectSelectedShop);

    const lastUpdateTime = localStorage.get(LocalStorageKeys.LAST_PRICING_REFRESH_TIME);

    if (lastUpdateTime) {
        const cachedShopPricing: ShopPricing[] = yield call(IndexedDB.getMany, IndexedDBKeys.PRICING);

        if (
            cachedShopPricing?.length === 1 &&
            cachedShopPricing[0].CompanyID === shop.CompanyID &&
            cachedShopPricing[0].StationID === shop.StationID &&
            differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback
        ) {
            yield put(shopPricingResponse(cachedShopPricing[0]));

            return;
        }
    }

    const requestTime = new Date();
    const {data, error}: ApiResponse<ShopPricing> = yield call(request, {
        url: '/shoppricing',
        method: 'get',
        params: {
            companyID: shop.CompanyID,
            stationID: shop.StationID,
        },
    });

    if (error) {
        yield put(shopPricingError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield put(shopPricingResponse(data));
        yield call(IndexedDB.createMany, IndexedDBKeys.PRICING, [data]);
        localStorage.set(LocalStorageKeys.LAST_PRICING_REFRESH_TIME, requestTime);
    }
}

export default function* pricingRootSaga() {
    yield takeLatest<ReduxAction>(FETCH_REPAIR_LINE_PRICING, fetchRepairLinePricing);
    yield takeLatest<ReduxAction>(FETCH_SHOP_PRICING, fetchShopPricing);
}
