import {UserManager as OidcUserManager, WebStorageStateStore} from 'oidc-client-ts';

import config from '../config';

import {AuthService, IUser, IUserProfile} from './auth-service';
import LocalStorage, {LocalStorageKeys} from './local-storage';

export {Log as OidcLog} from 'cas-client-ts';

/**
 * Class representing authentication services using OIDC.
 *
 * @export
 * @class OidcAuthService
 * @typedef {OidcAuthService}
 */
export default class OidcAuthService extends AuthService {
    /** Official identifier for this service. */
    static readonly AuthServiceName = 'oidc';

    /**
     * Get the official identifier for this authentication service.
     *
     * @readonly
     * @type {"oidc"}
     */
    readonly authServiceName = OidcAuthService.AuthServiceName;

    /**
     * Get or set the UserManager object.
     *
     * @type {*}
     */
    UserManager: OidcUserManager;

    /**
     * Create an instance of OidcAuthService.
     *
     * @constructor
     */
    constructor() {
        super();
        // Destructure authentication properties into new variable names.
        const {authUrl: authority, clientId: client_id, metadata: metadataSeed, metadataUrl, ...rest} = config.oidcAuthConfig;
        this.UserManager = new OidcUserManager({
            authority: authority,
            client_id: client_id,
            redirect_uri: `${location.origin}/signin-auth`,
            post_logout_redirect_uri: `${location.origin}/signout-auth`,
            filterProtocolClaims: true,
            loadUserInfo: true,
            metadataSeed: metadataSeed, // Metadata values to replace or initialize.
            metadataUrl: metadataUrl, // URL of metadata service; undefined for default.
            response_type: 'code',
            scope: 'openid api2',
            userStore: new WebStorageStateStore({store: sessionStorage}),
            ...rest,
        });
    }

    /**
     * Determine if an authenticated session has been established.
     *
     * @returns {Promise<boolean>}
     */
    isAuthenticated = (): Promise<boolean> => {
        // User information contains an access_token if user was authenticated.
        return this.getUser()
            .then((user) => Boolean(user?.access_token))
            .catch((reason) => false);
    };

    /**
     * Get the authentication claims object associated with the current user.
     *
     * @returns {Promise<IUserProfile | null>}
     */
    getClaims = (): Promise<IUserProfile | null> => {
        // Return user claims; null if not authenticated.
        return this.getUser()
            .then((user) => user?.profile || null)
            .catch((reason) => null);
    };

    /**
     * Get a string containing the Authorization header value for the current user.
     *
     * @returns {Promise<string | null>}
     */
    getAuthorizationHeaderValue = (): Promise<string | null> => {
        // Return user authorization header; null if not authenticated.
        return this.getUser()
            .then((user) => user && `${user.token_type} ${user.access_token}`)
            .catch((reason) => null);
    };

    /**
     * Submit a sign-out request for the current user.
     */
    logout = (): void => {
        this.UserManager.signoutRedirect();

        this.UserManager.clearStaleState();
    };

    /**
     * Start the sign-in sequence for the current user.
     *
     * @returns {Promise<void>}
     */
    startAuthentication = (): Promise<void> => {
        LocalStorage.set(LocalStorageKeys.REDIRECT_URI, window.location.pathname);

        return this.UserManager.signinRedirect();
    };

    /**
     * Get the session information for the current user and return it in a fulfilled promise.
     *
     * @returns {(Promise<IUser | null>)} The result of the promise is the authenticated `User`.
     */
    getUser = async (): Promise<IUser | null> => {
        // Return promise with user session information after it has been fulfilled.
        return (await this.UserManager.getUser()) as unknown as IUser;
    };

    /**
     * Complete the sign-in sequence.
     *
     * @returns {Promise<void>}
     */
    completeAuthentication = (): Promise<void> => {
        return this.UserManager.signinRedirectCallback().then(() => {
            const redirectUri = LocalStorage.get(LocalStorageKeys.REDIRECT_URI);

            window.location.pathname = redirectUri ?? location.origin;
        });
    };

    /**
     * Remove the session information for the current user in case authentication sequence fails.
     *
     * @async
     * @returns {(Promise<void>)}
     */
    removeUser = (): Promise<void> => {
        return this.UserManager.removeUser();
    };

    /**
     * Get the username from the session information for the current user.
     *
     * @async
     * @returns {(Promise<string | null>)}
     */
    getUsername = (): Promise<string | null> => {
        return this.getUser().then((user) => user?.profile.sub ?? null);
    };
}
