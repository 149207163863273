import {Button} from '@mui/material';
import styled from 'styled-components';

export const DialogWrap = styled.div`
    margin: 20px;

    .MuiTypography-root {
        padding: 16px 0;
    }

    .MuiInput-root {
        width: 100%;
        margin: 35px 0;
    }
`;

export const ButtonContainer = styled.div`
    width: 100%;
    margin-top: 20px;

    .MuiDialogActions-root {
        width: 100%;
        padding: 0;
        justify-content: space-between;
    }
`;

export const AutoCompleteWrap = styled.div`
    margin-bottom: 10px;
    display: flex;
    align-items: center;
`;

export const AddExceptionButton = styled(Button)`
    margin-left: 10px !important;
`;

export const DeclinationReasonsTitle = styled.h4`
    font-size: 15px;
    color: red;
    margin-bottom: 3px;
`;
