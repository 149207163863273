export enum DocumentTypeCodes {
    None,
    InGate,
    OutGate,
    PhotoOfDamage,
    PhotoOfRepair,
    WorkOrder,
    Other,
    DeclinationLetter,
    ApplicationVersion,
    ConfigurationFile,
    InvoiceReport,
    SpendByMonthReport,
    RebillInvoiceReport,
    Citation,
    FormAnswer,
    CitationRebillDocument,
    RampSpendByMonthReports,
    J2ReviewComplete,
    AGSImage,
}
