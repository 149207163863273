import {t} from 'i18next';

import {ElectiveInspectionTypes} from '../models/enumerations/ElectiveInspectionTypes';
import {EquipmentGroups} from '../models/enumerations/EquipmentGroups';
import {WorkOrderStatus} from '../models/enumerations/WorkOrderStatus';
import {InitialValues} from '../pages/work-order-header/work-order-header.component';

const PRIORITY_FIELD_STATUSES = [
    WorkOrderStatus.Created.Status,
    WorkOrderStatus.Open.Status,
    WorkOrderStatus.SubmittedForApproval.Status,
    WorkOrderStatus.PendingEscalation.Status,
    WorkOrderStatus.WorkApproved.Status,
];

export const fixSchema = (
    schema: Record<string, any>,
    uiSchema: Record<string, any>,
    workOrderStatus: number,
    initialValues: Partial<InitialValues>,
    isOfflineMode: boolean,
) => {
    if (schema && Object.keys(schema).length) {
        schema.title = undefined;
        schema.description = undefined;
    }

    if (schema && schema.properties) {
        if (schema.properties.BuiltDate) {
            delete schema.properties.BuiltDate.format;
        }
        if (schema.properties.BITDueDate) {
            schema.properties.BITDueDate.format = 'date';
        }
        if (schema.properties.FHWADueDate) {
            schema.properties.FHWADueDate.format = 'date';
        }
        if (schema.properties.RepairDate) {
            delete schema.properties.RepairDate.format;
        }
        if (schema.definitions && schema.definitions.ElectiveInspectionTypes) {
            schema.definitions.ElectiveInspectionTypes.enumNames = [...Object.values(ElectiveInspectionTypes).map((type) => t(type.Description))];
        }
        if (schema.definitions && schema.definitions.EquipmentGroups) {
            schema.definitions.EquipmentGroups.enumNames = [...Object.values(EquipmentGroups).map((type) => t(type.Description))];
        }

        // Conditionally swap purchase order for generate PO.
        if (workOrderStatus <= 20 && schema.properties.PurchaseOrderNumber) {
            delete schema.properties.PurchaseOrderNumber;
            delete initialValues.PurchaseOrderNumber;
            schema.properties.GeneratePO = {title: t('generate_po'), type: 'boolean'};
            uiSchema.GeneratePO = {'ui:widget': 'generate-purchase-order'};
        }

        // Conditionally remove Priority field if WO status is not Open, Pending Approval, Pending Escalation, Work Approved
        if (!PRIORITY_FIELD_STATUSES.includes(workOrderStatus) && schema.properties.Priority) {
            delete schema.properties.Priority;
            delete initialValues.Priority;
        }

        if (isOfflineMode) {
            delete schema.properties.BuiltDate;
            delete schema.properties.BITDueDate;
            delete schema.properties.FHWADueDate;
            delete schema.properties.RepairDate;
            delete schema.properties.RefurbishedDate;
            delete schema.properties.IsHardwireLights;
            delete schema.properties.IsRadialTire;
            delete schema.properties.TireSize;
            delete schema.properties.RemanufactureDate;
            delete schema.properties.EquipmentGroups;
            delete schema.properties.UnitTypeDescription;

            // Re-add equipment groups but use a string instead.
            schema.properties.EquipmentGroups = {title: t('equipment_group'), type: 'string'};

            schema.properties.UnitINO = {title: t('unit'), type: 'string'};
            uiSchema['ui:order'] = ['UnitINO'].concat(uiSchema['ui:order']);
        }
    }

    return {schema, uiSchema};
};

export const validate = (formData: any, errors: any) => {
    // Offline mode allows unit INO to be edited.
    if (formData.hasOwnProperty('UnitINO')) {
        const unitINORegex = new RegExp('^([A-Z]{1,4})([0-9]{1,6})$');
        if (formData?.UnitINO == null) {
            errors.UnitINO.addError(t('unit_required'));
        } else if (unitINORegex.exec(formData.UnitINO.toUpperCase())?.length !== 3) {
            // 3 matches should be found if it is correct: the full string, the alpha group, and the number group.
            errors.UnitINO.addError(t('unit_pattern'));
        }
    }

    return errors;
};
