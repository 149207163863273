import {push} from 'connected-react-router';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import WorkOrdersGrid from '../../components/work-orders-grid/work-orders-grid.component';
import {useHeader} from '../../hooks/use-header';
import {WorkOrder} from '../../models/WorkOrder';
import {WorkOrderStatus} from '../../models/enumerations/WorkOrderStatus';
import {selectSelectedShop} from '../../redux/shop/shop.selectors';
import {selectEstimates} from '../../redux/work-order/work-order.selectors';

import {HeaderTitle, Results} from './approval.styles';

const Approval = () => {
    const {t} = useTranslation();
    const location = useLocation<{menuPrevPath?: string}>();
    const dispatch = useDispatch();
    const shop = useSelector(selectSelectedShop);
    const allEstimates = useSelector(selectEstimates(shop?.StationID ?? 0, shop?.CompanyID ?? 0));
    const estimates = allEstimates.filter(({Status}) => Status === WorkOrderStatus.SubmittedForApproval.Status);

    const renderMiddleElement = useCallback(() => <HeaderTitle>{t('approval')}</HeaderTitle>, [t]);

    useHeader({
        backToPath: location.state?.menuPrevPath || '/',
        middleElement: renderMiddleElement,
    });

    const onRowClick = useCallback(
        ({data}: {data: WorkOrder}) => {
            dispatch(push(`/approval/${data.ID}`, {menuPrevPath: location.pathname}));
        },
        [dispatch, location.pathname],
    );

    return (
        <section>
            <Results>
                {t('total_results')}: {estimates.length}
            </Results>
            <WorkOrdersGrid data={estimates} onRowClick={onRowClick} />
        </section>
    );
};

export default Approval;
