import Dialog from '@mui/material/Dialog';
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Filter,
    Inject,
    Sort,
    GridModel,
    Toolbar,
    DetailRow,
    Edit,
    InfiniteScroll,
    Grid,
} from '@syncfusion/ej2-react-grids';
import {t} from 'i18next';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Part} from '../../models/Part';
import {WorkOrderRepairLine} from '../../models/WorkOrderRepairLine';
import {WorkOrderRepairLineStatus} from '../../models/enumerations/WorkOrderRepairLineStatus';
import {HeaderProvider} from '../../providers/header-provider';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {enqueueSnackbar} from '../../redux/notistack/notistack.actions';
import {createRepairLinePart, deleteRepairLinePart, fetchParts, updateRepairLinePart} from '../../redux/part/part.actions';
import {selectPartState} from '../../redux/part/part.selectors';
import {BarcodeScanner} from '../barcode-scanner/barcode-scanner.container';
import {Fallback} from '../fallback/fallback.component';
import PartFormContainer from '../part-form/part-form.container';

import {Title, GridWrapper} from './parts-grid.styles';

type PartsGridProps = {
    repairLines: (WorkOrderRepairLine & {repairLineID: number})[];
    parts: (Part & {repairLineID: number})[];
};

const PartsGrid = ({repairLines = [], parts = []}: PartsGridProps) => {
    const dispatch = useDispatch();
    const {workOrder} = useContext(WorkOrderDataContext);
    const [barcodeLoading, setBarcodeLoading] = useState(false);
    const [barcodeScannerRepairLineID, setBarcodeScannerRepairLineID] = useState<number | null>(null);
    const {parts: partsRegistry, loading: partLoading} = useSelector(selectPartState);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedRepairLineId, setSelectedRepairLineId] = useState<number>();
    const [selectedPartNumber, setSelectedPartNumber] = useState();
    const {readOnly} = useContext(WorkOrderDataContext);

    useEffect(() => {
        dispatch(fetchParts());
    }, [dispatch]);

    const onPartFormClose = useCallback(() => {
        setIsFormOpen(false);
        setSelectedRepairLineId(undefined);
        setSelectedPartNumber(undefined);
    }, []);

    const toolbarOptions = ['Add', {prefixIcon: 'e-custom-icons e-barcode', id: 'Scanner'}, 'Edit', 'Delete'];

    const childGridOptions: GridModel = useMemo(
        () => ({
            created: function (this: Grid) {
                if (this.parentDetails) {
                    const repairLineID = Number(this.parentDetails.parentKeyFieldValue);
                    const repairLine = repairLines.find((item) => item.ID === repairLineID);
                    const isStatusRejected = repairLine?.Status === WorkOrderRepairLineStatus.Rejected;
                    const noParts = !partsRegistry.some(
                        ({AllowablePartCombinations, EquipmentGroupIDPriced}) =>
                            AllowablePartCombinations.some(
                                ({JobCodeID, ConditionCodeID}) =>
                                    repairLine?.JobCodeId === JobCodeID && repairLine?.ConditionCodeId === ConditionCodeID,
                            ) && EquipmentGroupIDPriced[String(workOrder.EquipmentGroups)],
                    );

                    if (isStatusRejected || noParts) {
                        // Double check that no parts were added, possibly before pricing expired or the part was removed from the job code.
                        if (repairLine?.Parts.length === 0) {
                            this.height = 0;
                        }
                        setTimeout(() => {
                            if (noParts) {
                                for (let i = this.toolbarModule.toolbar.items?.length - 1; i >= 0; i--) {
                                    // Don't remove the delete button if there is a part.
                                    if (repairLine?.Parts.length && (this.toolbarModule.toolbar.items[i] as any).properties.id.includes('delete')) {
                                        continue;
                                    } else {
                                        this.toolbarModule.toolbar.removeItems(i);
                                    }
                                }

                                this.toolbarModule.toolbar.addItems([
                                    {
                                        text: 'No Associated Parts',
                                        disabled: true,
                                        template: '', // Bug workaround?
                                    },
                                ]);
                            } else if (isStatusRejected) {
                                const gridId = this.toolbarModule['gridID'];
                                this.toolbarModule.enableItems([`${gridId}_add`, `${gridId}_edit`, `Scanner`, `${gridId}_delete`], false);
                                this.toolbarModule.toolbar.addItems(
                                    [
                                        {
                                            text: 'Repair Line Rejected',
                                            disabled: true,
                                            template: '', // Bug workaround?
                                        },
                                    ],
                                    this.toolbarModule.toolbar.items.length + 0,
                                );
                            }
                        }, 0);
                    }
                }
            },
            columns: [
                {
                    field: 'PartNumber',
                    headerText: 'Part Number',
                    validationRules: {required: true},
                },
                {
                    field: 'PartDescription',
                    headerText: 'Description',
                    validationRules: {required: false},
                },
                {field: 'Quantity', headerText: 'Quantity', validationRules: {required: true, number: true}},
            ],
            dataSource: parts,
            enableAdaptiveUI: true,
            rowRenderingMode: 'Vertical',
            queryString: 'repairLineID',
            allowTextWrap: true,
            editSettings: {
                mode: 'Dialog',
                allowEditing: true,
                allowAdding: true,
                allowDeleting: true,
            },
            toolbar: !readOnly ? toolbarOptions : [],
            actionBegin: function (this: Grid, state) {
                if (state.name === 'actionBegin' && state.requestType === 'beginEdit') {
                    state.cancel = true;
                    setIsFormOpen(true);
                    setSelectedRepairLineId(state.rowData.repairLineID);
                    setSelectedPartNumber(state.rowData.PartNumber);
                }

                if (state.requestType === 'delete' && this.parentDetails) {
                    const part: Part = state.data[0];
                    const repairLineID = Number(this.parentDetails.parentKeyFieldValue);

                    state.cancel = true;
                    dispatch(deleteRepairLinePart({workOrderID: workOrder.ID, repairLineID, partID: part.RepairLinePartID}));
                }

                if (state.requestType === 'add' && this.parentDetails && !Number.isNaN(Number(this.parentDetails.parentKeyFieldValue))) {
                    const repairLineID = Number(this.parentDetails.parentKeyFieldValue);
                    state.cancel = true;
                    setIsFormOpen(true);
                    setSelectedRepairLineId(repairLineID);
                }
            },
            toolbarClick: function (this: Grid, args) {
                const repairLineID = this.toolbarModule['parent'].properties.parentDetails.parentKeyFieldValue;

                if (args.item.properties.id === 'Scanner' && !partLoading) {
                    setBarcodeScannerRepairLineID(repairLineID);
                }
            },
        }),
        [parts, workOrder, partLoading],
    );

    const dataBound = function (this: GridComponent) {
        // This try/catch need to avoid Syncfusion error
        try {
            this.detailRowModule.expandAll();
        } catch {}
    };

    const onScanned = useCallback(
        (unpause: () => void, code: string) => {
            setBarcodeLoading(true);

            const repairLine = repairLines.find(({ID}) => ID === barcodeScannerRepairLineID);
            const allowableParts = partsRegistry.filter(
                ({AllowablePartCombinations, EquipmentGroupIDPriced}) =>
                    AllowablePartCombinations.some(
                        ({JobCodeID, ConditionCodeID}) => repairLine?.JobCodeId === JobCodeID && repairLine?.ConditionCodeId === ConditionCodeID,
                    ) && EquipmentGroupIDPriced[String(workOrder.EquipmentGroups)],
            );

            // allowable parts list should be searched for a matching part number
            let foundPart = allowableParts.find(({PartNumber}) => PartNumber === code);

            // if none are found, the allowable parts' alternative part numbers should be searched for a match
            if (!foundPart) {
                foundPart = allowableParts.find(({AlternativePartNumbers}) => AlternativePartNumbers.includes(code));
            }

            const onFinish = () => {
                // the delay for the next scan
                setBarcodeLoading(false);
                setTimeout(() => {
                    unpause();
                }, 3000);
            };

            if (foundPart) {
                const partInRepairLine = repairLine?.Parts.find(({PartNumber}) => PartNumber === code);
                // If the same part is scanned multiple times, the quantity of this part should be incremented by 1
                if (partInRepairLine) {
                    dispatch(
                        updateRepairLinePart({
                            workOrderID: workOrder.ID,
                            repairLinePartID: partInRepairLine.RepairLinePartID,
                            repairLineID: barcodeScannerRepairLineID!,
                            part: {
                                ID: foundPart.ID,
                                PartNumber: code,
                                PartDescription: foundPart.PartDescription,
                                Quantity: partInRepairLine.Quantity + 1,
                            },
                            onSuccess: onFinish,
                            onFailure: onFinish,
                        }),
                    );
                } else {
                    dispatch(
                        createRepairLinePart({
                            workOrderID: workOrder.ID,
                            repairLineID: barcodeScannerRepairLineID!,
                            part: {ID: foundPart.ID, PartNumber: code, PartDescription: foundPart.PartDescription, Quantity: 1},
                            onSuccess: onFinish,
                            onFailure: onFinish,
                        }),
                    );
                }
            } else {
                const errorMessage = `${code} is not allowed on ${t('job_code')} ${repairLine?.JobCodeNumber}`;

                dispatch(
                    enqueueSnackbar(errorMessage, {
                        autoHideDuration: 3000,
                        variant: 'error',
                    }),
                );
                onFinish();
            }
        },
        [barcodeScannerRepairLineID, dispatch, partsRegistry, repairLines, workOrder],
    );

    const onBarcodeScannerClose = useCallback(() => {
        setBarcodeScannerRepairLineID(null);
    }, []);

    return (
        <>
            <BarcodeScanner
                isLoading={barcodeLoading}
                isOpen={Boolean(barcodeScannerRepairLineID)}
                onClose={onBarcodeScannerClose}
                onScanned={onScanned}
            />
            {isFormOpen && selectedRepairLineId && (
                <Dialog fullScreen open={true}>
                    <HeaderProvider>
                        {partLoading ? <Fallback /> : null}
                        <PartFormContainer repairLineID={selectedRepairLineId} partNumber={selectedPartNumber} onClose={onPartFormClose} />
                    </HeaderProvider>
                </Dialog>
            )}
            <GridWrapper hidden={isFormOpen} className="e-bigger parts-container">
                <Title>Parts</Title>
                <GridComponent
                    dataBound={dataBound}
                    childGrid={childGridOptions}
                    allowPaging
                    allowSorting
                    allowFiltering
                    enableAdaptiveUI
                    dataSource={repairLines}
                    enableInfiniteScrolling
                    rowRenderingMode="Vertical"
                    filterSettings={{type: 'Menu'}}>
                    <ColumnsDirective>
                        <ColumnDirective field="JobCodeNumber" headerText="Code" />
                        <ColumnDirective field="JobCodeDescription" headerText="Desc" />
                        <ColumnDirective field="ConditionCodeDescription" headerText="Repair" />
                        <ColumnDirective field="UnitLocationCodeDescription" headerText="Loc" />
                        <ColumnDirective field="RepairQuantity" headerText="Qty" />
                    </ColumnsDirective>
                    <Inject services={[InfiniteScroll, Filter, Sort, DetailRow, Toolbar, Edit]} />
                </GridComponent>
            </GridWrapper>
        </>
    );
};

export default PartsGrid;
