export const FETCH_REPAIR_LINES = 'FETCH_REPAIR_LINES';
export const UPDATE_REPAIR_LINE = 'UPDATE_REPAIR_LINE';
export const DELETE_REPAIR_LINE = 'DELETE_REPAIR_LINE';
export const CREATE_REPAIR_LINE = 'CREATE_REPAIR_LINE';
export const REPAIR_LINE_REQUEST = 'REPAIR_LINE_REQUEST';
export const REPAIR_LINES_RESPONSE = 'REPAIR_LINE_RESPONSE';
export const REPAIR_LINE_ERROR = 'REPAIR_LINE_ERROR';
export const CREATE_REPAIR_LINE_RESPONSE = 'CREATE_REPAIR_LINE_RESPONSE';
export const UPDATE_REPAIR_LINE_RESPONSE = 'UPDATE_REPAIR_LINE_RESPONSE';
export const DELETE_REPAIR_LINE_RESPONSE = 'DELETE_REPAIR_LINE_RESPONSE';
export const ADD_REPAIR_LINE_DECLINATION_REASON = 'ADD_REPAIR_LINE_DECLINATION_REASON';
export const ADD_REPAIR_LINE_DECLINATION_REASON_RESPONSE = 'ADD_REPAIR_LINE_DECLINATION_REASON_RESPONSE';
export const REMOVE_REPAIR_LINE_DECLINATION_REASON = 'REMOVE_REPAIR_LINE_DECLINATION_REASON';
export const REMOVE_REPAIR_LINE_DECLINATION_REASON_RESPONSE = 'REMOVE_REPAIR_LINE_DECLINATION_REASON_RESPONSE';
