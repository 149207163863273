import {Message} from '../../models/Message';
import {ReduxAction} from '../../models/ReduxAction';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';

import {MESSAGES_ERROR, MESSAGES_REQUEST, MESSAGES_RESPONSE, READ_MESSAGE} from './message.types';

const readMessages = LocalStorage.get(LocalStorageKeys.READ_MESSAGES);

export interface MessageState {
    error: Error | null;
    loading: boolean;
    messages: Message[];
    readMessageIds: Set<number>;
}

const initialState: MessageState = {
    error: null,
    loading: false,
    messages: [],
    readMessageIds: Array.isArray(readMessages) ? new Set(readMessages) : new Set(),
};

export default function messageReducer(state = initialState, action: ReduxAction): MessageState {
    switch (action.type) {
        case MESSAGES_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case MESSAGES_RESPONSE:
            return {
                ...state,
                loading: false,
                messages: action.payload,
            };
        case MESSAGES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case READ_MESSAGE:
            const newReadMessageIdsSet = new Set(state.readMessageIds);
            newReadMessageIdsSet.add(action.payload.messageID);

            return {
                ...state,
                readMessageIds: newReadMessageIdsSet,
            };
        default:
            return state;
    }
}
