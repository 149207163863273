import React, {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import DetailsTitle from '../../components/details-title/details-title.component';
import {useHeader} from '../../hooks/use-header';
import {useQueryParams} from '../../hooks/use-query-params';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';

import WorkOrderSpecialinstructionsComponent from './work-order-special-instructions.component';

export type EWorkOrderSpecialInstructionsProps = {
    backToPath?: string;
};

const WorkOrderSpecialInstructionsContainer = ({backToPath}: EWorkOrderSpecialInstructionsProps) => {
    const {push} = useHistory();
    const queryParams = useQueryParams();
    const {workOrder, repairLines} = useContext(WorkOrderDataContext);
    const {UnitDetails: unitDetails, WorkOrderNumber: workOrderNumber} = workOrder || {};
    const specialInstructionAuthorizations = workOrder?.SpecialInstructionAuthorizations ? workOrder.SpecialInstructionAuthorizations : [];

    const onHeaderClick = useCallback(() => {
        push(`${backToPath}/${workOrder.ID}/header`, {menuPrevPath: location.pathname});
    }, [workOrder.ID, push]);

    const TitleTop = useCallback(() => {
        return <DetailsTitle onClick={onHeaderClick} unitINO={unitDetails?.UnitINO} workOrderNumber={workOrderNumber} />;
    }, [unitDetails?.UnitINO, workOrderNumber]);

    useHeader({
        backToPath: `${backToPath}/${workOrder.ID}`,
        middleElement: TitleTop,
    });

    const repairLineID = +(queryParams.get('repairLineID') || '');

    const filteredRepairLines = useMemo(() => {
        return repairLineID ? repairLines.filter(({ID}) => ID === repairLineID) : repairLines.filter((rl) => rl.SpecialInstructions.length);
    }, [repairLineID, repairLines]);

    return <WorkOrderSpecialinstructionsComponent repairLines={filteredRepairLines} specialInstructions={specialInstructionAuthorizations} />;
};

export default WorkOrderSpecialInstructionsContainer;
