import {takeLatest, call, put} from 'redux-saga/effects';

import {ReduxAction} from '../../models/ReduxAction';
import {WorkOrder} from '../../models/WorkOrder';
import request, {ApiResponse} from '../../services/request';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {workOrderResponse} from '../work-order/work-order.actions';

import {AuthorizeSpecialInstructions, AUTHORIZE_SPECIAL_INSTRUCTIONS} from './special-instruction.types';

export function* authorizeSpecialInstructions({payload}: ReduxAction<AuthorizeSpecialInstructions>) {
    const {data, error}: ApiResponse<WorkOrder> = yield call(request, {
        url: `/specialinstructions/authorize/${payload.workOrderID}`,
        method: 'post',
    });

    if (error) {
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield put(workOrderResponse(data));
    }
}

export default function* specialInstructionRootSaga() {
    yield takeLatest<ReduxAction>(AUTHORIZE_SPECIAL_INSTRUCTIONS, authorizeSpecialInstructions);
}
