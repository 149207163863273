import {createSelector} from 'reselect';

import {State} from '../root-reducer';

import {UserState} from './user.reducer';

/** Selector to retrieve the UserState from the Redux store. */
export const selectUserState = (state: State): UserState => state.userReducer;

/** Selector to retrieve the list of Users. */
export const selectUsers = createSelector(selectUserState, ({users}) => users);

/** Selector to retrieve the currently logged-in User. */
export const selectUser = createSelector(selectUserState, ({selectedUser}) => selectedUser);
