/**
 * Standard session information properties (IUser).
 *
 * @export
 * @interface IUser
 * @typedef {IUser}
 */
export interface IUser {
    /**
     * A JSON Web Token (JWT). For CAS, this holds the service ticket number from the initial service validation,
     * to prove that an authenticated session was created.
     */
    id_token?: string;
    /** The session state value returned from the OIDC provider. */
    session_state: string | null;
    /**
     * The requested access token returned from the OIDC provider. The application can use this token to
     * authenticate itself to the secured resource.
     * For CAS, this is unused and assigned an empty string.
     */
    access_token: string;
    /**
     * An OAuth 2.0 refresh token. The app can use this token to acquire additional access tokens after the
     * current access token expires. Refresh tokens are long-lived and can be used to maintain access to resources
     * for extended periods of time.
     * Not used with CAS.
     */
    refresh_token?: string;
    /** Ticket to send to the API so it can check if the user is authenticated. */
    proxy_granting_ticket?: string;
    /** Typically "Bearer" in the Authorization request header. Not used with CAS. */
    token_type: string;
    /** The scopes that the requested access token is valid for. */
    scope?: string;
    /** The claims represented by a combination of the `id_token` and the user info endpoint. */
    profile: IUserProfile;
    /** The expires at returned from the OIDC provider. */
    expires_at?: number;
    /** custom state data set during the initial signin request */
    readonly state: unknown;
}

/**
 * Standard ID Token claims (user profile properties).
 *
 * @public
 * @see https://openid.net/specs/openid-connect-core-1_0.html#IDToken
 */
export declare interface IUserProfile {
    [claim: string]: unknown;
    /** Subject - Identifier for the End-User at the Issuer. */
    sub?: string;
    /** Time when the End-User authentication occurred. */
    auth_time?: number;
    /** String value used to associate a Client session with an ID Token, and to mitigate replay attacks. */
    nonce?: string;
    /** Authentication Context Class Reference. */
    acr?: string;
    /** Authentication Methods References. */
    amr?: unknown;
    /** Authorized party - the party to which the ID Token was issued. */
    azp?: string;
    /**
     * Session ID - String identifier for a Session. */
    sid?: string;
}

/** Interface representing a generic authentication service. */
export interface IAuthService {
    /**
     * Get the name of the authentication service.
     *
     * @returns {string}
     */
    readonly authServiceName: string;

    /**
     * Determine if an authenticated session has been established.
     *
     * @returns {Promise<boolean>}
     */
    isAuthenticated(): Promise<boolean>;

    /**
     * Get the authentication claims object associated with the current user.
     *
     * @returns {Promise<IUserProfile | null>}
     */
    getClaims(): Promise<IUserProfile | null>;

    /**
     * Get a string containing the Authorization header value for the current user.
     *
     * @returns {Promise<string | null>}
     */
    getAuthorizationHeaderValue(): Promise<string | null>;

    /**
     * Submit a sign-out request for the current user.
     */
    logout(): void;

    /**
     * Start the sign-in sequence for the current user.
     *
     * @returns {Promise<void>}
     */
    startAuthentication(): Promise<void>;

    /**
     * Get the session information for the current user.
     *
     * @async
     * @returns {(Promise<IUser | null>)}
     */
    getUser(): Promise<IUser | null>;

    /**
     * Complete the sign-in sequence.
     *
     * @returns {Promise<void>}
     */
    completeAuthentication(): Promise<void>;

    /**
     * Optional function to supply extra headers to include with all service requests.
     *
     * @type {Headers}
     */
    getExtraRequestHeaders?: () => Headers;

    /**
     * Optional function to supply extra headers to include with all service responses.
     *
     * @type {Headers}
     */
    getExtraResponseHeaders?: () => Headers;

    /**
     * Remove the session information for the current user in case authentication processing fails.
     *
     * @async
     * @returns {(Promise<void>)}
     */
    removeUser(): Promise<void>;

    /**
     * Get the username from the session information for the current user.
     *
     * @async
     * @returns {(Promise<string | null>)}
     */
    getUsername(): Promise<string | null>;
}

/**
 * Class representing an authentication service.
 *
 * @export
 * @abstract
 * @class AuthService
 * @typedef {AuthService}
 * @implements {IAuthService}
 */
export abstract class AuthService implements IAuthService {
    /**
     * Get the official identifier for this authentication service.
     *
     * @readonly
     * @returns {string}
     */
    abstract readonly authServiceName: string;

    /** @constructor */
    constructor() {}

    // Get the time in seconds from the epoch.
    public static getEpochTime(): number {
        return Math.floor(Date.now() / 1000);
    }

    /**
     * Determine if an authenticated session has been established.
     *
     * @returns {boolean}
     */
    isAuthenticated = (): Promise<boolean> => {
        return Promise.resolve(false);
    };

    /**
     * Get the authentication claims (aka UserProfile) object associated with the current user.
     * This is a custom collection for every authentication service.
     *
     * @returns {(IUserProfile | null)}
     */
    getClaims = (): Promise<IUserProfile | null> => {
        return Promise.resolve(null);
    };

    /**
     * Get a string containing the Authorization header value for the current user.
     *
     * @returns {(string | null)}
     */
    getAuthorizationHeaderValue = (): Promise<string | null> => {
        return Promise.resolve(null);
    };

    /**
     * Submit a sign-out request for the current user.
     */
    logout = (): void => {};

    /**
     * Start the sign-in sequence for the current user.
     *
     * @returns {Promise<void>}
     */
    startAuthentication = (): Promise<void> => {
        return Promise.resolve();
    };

    /**
     * Get the session information (aka IUser) for the current user and return it in a fulfilled promise.
     *
     * @async
     * @returns {(Promise<IUser | null>)}
     */
    getUser = async (): Promise<IUser | null> => {
        return Promise.resolve(null);
    };

    /**
     * Complete the sign-in sequence.
     *
     * @returns {Promise<void>}
     */
    completeAuthentication = (): Promise<void> => {
        return Promise.resolve();
    };

    /**
     * Optional function to supply extra headers included with all service requests.
     *
     * @type {Headers}
     */
    getExtraRequestHeaders?: () => Headers;

    /**
     * Optional function to supply extra headers included with all service responses.
     *
     * @type {Headers}
     */
    getExtraResponseHeaders?: () => Headers;

    /**
     * Remove the session information for the current user in case authentication processing fails.
     *
     * @async
     * @returns {(Promise<void>)}
     */
    removeUser = (): Promise<void> => {
        return Promise.resolve();
    };

    /**
     * Get the username from the session information for the current user.
     *
     * @async
     * @returns {(Promise<string | null>)}
     */
    getUsername = (): Promise<string | null> => {
        return Promise.resolve(null);
    };
}
