import {CircularProgress, Typography} from '@mui/material';
import {ListViewComponent} from '@syncfusion/ej2-react-lists';
import {format} from 'date-fns';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {HistoryListItem} from '../../components/history-list-item/history-list-item.component';
import {useHeader} from '../../hooks/use-header';
import {UserHistory} from '../../models/History';
import {HistoryDto} from '../../models/dtos/history.dto';
import {fetchNextHistoryData, resetHistory} from '../../redux/history/history.action';
import {selectHistoryState} from '../../redux/history/history.selectors';
import {selectUser} from '../../redux/user/user.selectors';
import {getTimeZoneAbbreviation} from '../../utils/getTimeZoneAbbreviation';
import {HeaderTitle} from '../approval/approval.styles';

import {Container, HistoryTitle, ProgressContainer} from './history.styles';

const History = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const {t} = useTranslation();
    const {history, noMoreData, loading} = useSelector(selectHistoryState);
    const location = useLocation<{menuPrevPath?: string}>();
    const renderMiddleElement = useCallback(() => <HeaderTitle>{t('user_history')}</HeaderTitle>, [t]);

    useHeader({
        backToPath: location.state?.menuPrevPath || '/',
        middleElement: renderMiddleElement,
    });

    useEffect(() => {
        dispatch(fetchNextHistoryData());

        return () => {
            dispatch(resetHistory());
        };
    }, []);

    const dataSource: HistoryDto[] = useMemo(
        () =>
            history.map((item: UserHistory) => {
                const timestamp = new Date(item.Timestamp);

                return {
                    date: `${format(timestamp, 'M/dd/yyyy HH:mm')} ${getTimeZoneAbbreviation(timestamp)}`,
                    unitINO: item.UnitINO,
                    rejectionReason: item.ErrorMessage,
                    workOrderNumber: item.WorkOrderNumber,
                    description: item.UserActionDescription,
                };
            }),
        [history],
    );

    const loaderElement = (
        <ProgressContainer>
            <CircularProgress color="primary" />
        </ProgressContainer>
    );

    return (
        <Container>
            <HistoryTitle>
                <Typography align="center" paragraph>
                    {t('user_name')}: <b>{user?.UserName}</b>
                </Typography>
                <Typography align="center" paragraph>
                    {t('history_list_hint')}
                </Typography>
            </HistoryTitle>
            <InfiniteScroll
                dataLength={history.length}
                next={() => dispatch(fetchNextHistoryData())}
                hasMore={!noMoreData}
                loader={loading ? loaderElement : null}>
                <ListViewComponent dataSource={dataSource} template={HistoryListItem} cssClass="e-list-template" />
            </InfiniteScroll>
        </Container>
    );
};

export default History;
