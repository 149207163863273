import {differenceInMilliseconds, endOfDay, isWithinInterval, subYears, addYears} from 'date-fns';
import {unionBy} from 'lodash';
import {call, put, select, takeEvery, takeLatest} from 'redux-saga/effects';

import config from '../../config';
import {Message} from '../../models/Message';
import {ReduxAction} from '../../models/ReduxAction';
import {StationCompanyRelation} from '../../models/StationCompanyRelation';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {formatDate} from '../../utils/formatDate';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {selectSelectedShop} from '../shop/shop.selectors';

import {messagesError, messagesRequest, messagesResponse} from './message.actions';
import {FETCH_MESSAGES, READ_MESSAGE} from './message.types';

const {resourceCacheFallback} = config;

const PAST_DATE = subYears(new Date(), 100);
const FUTURE_DATE = addYears(new Date(), 100);

export function* fetchMessages() {
    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_MESSAGES_REFRESH_TIME, LocalStorageKeys.LAST_MESSAGES_UPDATE_TIME);

    const selectedShop: StationCompanyRelation | null = yield select(selectSelectedShop);
    let stationID: number;
    if (selectedShop?.StationID) {
        stationID = selectedShop.StationID;
    } else {
        return;
    }
    let cachedMessages: Message[] = yield call(IndexedDB.getMany, IndexedDBKeys.MESSAGES);

    cachedMessages = cachedMessages.filter((message) => message.IsMobile && !message.IsDeleted);

    if (lastUpdateTime && differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
        yield put(messagesResponse(cachedMessages));
    }

    yield put(messagesRequest());

    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_MESSAGES_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<Message[]> = yield call(request, {
        url: `/broadcastmessages/${stationID}`,
        method: 'get',
        params: lastUpdateTime
            ? {
                  lastUpdateTime: formatDate({
                      date: lastUpdateTime,
                      ISOFormat: true,
                  }),
              }
            : undefined,
    });

    if (error) {
        yield put(messagesError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedMessages) {
            yield put(messagesResponse(cachedMessages));
        }
    }

    if (data) {
        // unionBy takes the left array's object when the uniqueness check matches.
        // Also remove messages that should no longer be displayed.
        const combinedMessages = unionBy(data, cachedMessages, 'ID').filter((message) => {
            if (!message.IsMobile || message.IsDeleted) {
                return false;
            }

            const startDate = message.EffectiveStartDate ? new Date(message.EffectiveStartDate) : PAST_DATE;
            const endDate = message.EffectiveEndDate ? endOfDay(new Date(message.EffectiveEndDate)) : FUTURE_DATE;

            return isWithinInterval(new Date(), {start: startDate, end: endDate});
        });

        yield put(messagesResponse(combinedMessages));

        yield call(IndexedDB.createMany, IndexedDBKeys.MESSAGES, combinedMessages);

        LocalStorage.set(LocalStorageKeys.LAST_MESSAGES_UPDATE_TIME, requestTime);
    }
}

export function* readMessage({payload}: ReduxAction<{messageID: number}>) {
    const readMessages = LocalStorage.get(LocalStorageKeys.READ_MESSAGES);

    LocalStorage.set(LocalStorageKeys.READ_MESSAGES, Array.isArray(readMessages) ? [...readMessages, payload.messageID] : [payload.messageID]);
}

export default function* messageRootSaga() {
    yield takeLatest<ReduxAction>(FETCH_MESSAGES, fetchMessages);
    yield takeEvery<ReduxAction>(READ_MESSAGE, readMessage);
}
