import {differenceInMilliseconds} from 'date-fns';
import {unionBy} from 'lodash';
import {takeLeading, call, put, select} from 'redux-saga/effects';

import config from '../../config';
import {ReduxAction} from '../../models/ReduxAction';
import {StationCompanyRelation} from '../../models/StationCompanyRelation';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {formatDate} from '../../utils/formatDate';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {selectIsOfflineStatus} from '../application/application.selectors';
import {fetchFeatures} from '../feature/feature.actions';
import {fetchJobCodes} from '../job-code/job-code.actions';
import {fetchParts} from '../part/part.actions';
import {fetchUsers} from '../user/user.actions';
import {fetchWorkOrders} from '../work-order/work-order.actions';

import {shopsError, shopsRequest, shopsResponse} from './shop.actions';
import {FETCH_SHOPS, SELECT_SHOP} from './shop.types';

const {resourceCacheFallback} = config;

export function* fetchShops({payload}: ReduxAction<{onFinishCallback: (shops: StationCompanyRelation[]) => void}>) {
    const isOfflineMode: boolean = yield select(selectIsOfflineStatus);

    if (isOfflineMode) {
        const cachedShops: StationCompanyRelation[] = yield call(IndexedDB.getMany, IndexedDBKeys.SHOPS);

        yield put(shopsResponse(cachedShops));

        if (payload.onFinishCallback) {
            payload.onFinishCallback(cachedShops);
        }

        return;
    }

    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_SHOPS_REFRESH_TIME, LocalStorageKeys.LAST_SHOPS_UPDATE_TIME);

    let cachedShops: StationCompanyRelation[] = [];

    if (lastUpdateTime && cachedShops.length) {
        cachedShops = yield call(IndexedDB.getMany, IndexedDBKeys.SHOPS);

        if (differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
            yield put(shopsResponse(cachedShops));

            if (payload.onFinishCallback) {
                payload.onFinishCallback(cachedShops);
            }

            return;
        }
    }

    yield put(shopsRequest());

    const requestTime = new Date();

    if (!lastUpdateTime && !cachedShops.length) {
        LocalStorage.set(LocalStorageKeys.LAST_SHOPS_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<StationCompanyRelation[]> = yield call(request, {
        url: '/shops',
        method: 'get',
        params:
            lastUpdateTime && cachedShops.length
                ? {
                      includeDeleted: true,
                      lastUpdateTime: formatDate({
                          date: lastUpdateTime,
                          ISOFormat: true,
                      }),
                  }
                : undefined,
        priority: 'high',
    });

    if (error) {
        yield put(shopsError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedShops) {
            yield put(shopsResponse(cachedShops));

            if (typeof payload.onFinishCallback === 'function') {
                if (cachedShops && cachedShops.length) {
                    payload.onFinishCallback(cachedShops);
                } else {
                    payload.onFinishCallback([]);
                }
            }
        }
    }

    if (data) {
        const combinedShops = unionBy(data, cachedShops, 'ID').filter((shop) => !shop.IsDeleted);

        yield put(shopsResponse(combinedShops));

        if (payload.onFinishCallback) {
            payload.onFinishCallback(combinedShops);
        }

        yield call(IndexedDB.createMany, IndexedDBKeys.SHOPS, combinedShops);
        LocalStorage.set(LocalStorageKeys.LAST_SHOPS_UPDATE_TIME, requestTime);
    }
}

export function* selectShop({payload}: ReduxAction<{shop: StationCompanyRelation}>) {
    const oldShopID = LocalStorage.get(LocalStorageKeys.SELECTED_SHOP_ID);

    // If the shop changed, we need to fetch the shop specific data.
    if (oldShopID !== payload.shop.ID) {
        LocalStorage.remove(LocalStorageKeys.LAST_WORK_ORDERS_UPDATE_TIME);
        LocalStorage.remove(LocalStorageKeys.LAST_USERS_UPDATE_TIME);
        yield put(fetchWorkOrders(true));
        yield put(fetchUsers());
        yield put(fetchFeatures(true));
        yield put(fetchParts(true));
        yield put(fetchJobCodes(true));
    }
    LocalStorage.set(LocalStorageKeys.SELECTED_SHOP_ID, payload.shop.ID);
}

export default function* shopsRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_SHOPS, fetchShops);
    yield takeLeading<ReduxAction>(SELECT_SHOP, selectShop);
}
