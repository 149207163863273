import {differenceInMilliseconds} from 'date-fns';
import {unionBy} from 'lodash';
import {takeLeading, call, put, select} from 'redux-saga/effects';

import config from '../../config';
import {Feature} from '../../models/Feature';
import {ReduxAction} from '../../models/ReduxAction';
import {StationCompanyRelation} from '../../models/StationCompanyRelation';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {formatDate} from '../../utils/formatDate';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {selectIsOfflineStatus} from '../application/application.selectors';
import {selectSelectedShop} from '../shop/shop.selectors';

import {featureError, featureRequest, featuresResponse} from './feature.actions';
import {FETCH_FEATURES} from './feature.types';

const {resourceCacheFallback} = config;

export function* fetchFeatures({payload: forceFetch}: ReduxAction<{forceFetch?: boolean}>) {
    let lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_FEATURES_REFRESH_TIME, LocalStorageKeys.LAST_FEATURES_UPDATE_TIME);
    let cachedFeatures: Feature[] = [];
    const isOfflineMode: boolean = yield select(selectIsOfflineStatus);

    if ((!forceFetch && lastUpdateTime) || isOfflineMode) {
        cachedFeatures = yield call(IndexedDB.getMany, IndexedDBKeys.FEATURES);

        if (
            isOfflineMode ||
            (cachedFeatures &&
                lastUpdateTime &&
                cachedFeatures.length &&
                differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback)
        ) {
            yield put(featuresResponse(cachedFeatures));

            return;
        } else {
            lastUpdateTime = null;
        }
    }

    yield put(featureRequest());

    const shop: StationCompanyRelation = yield select(selectSelectedShop);
    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_FEATURES_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<Feature[]> = yield call(request, {
        url: '/features',
        method: 'get',
        params:
            !forceFetch && lastUpdateTime
                ? {
                      stationID: shop.StationID,
                      companyID: shop.CompanyID,
                      includeDeleted: true,
                      lastUpdateTime: formatDate({
                          date: lastUpdateTime,
                          ISOFormat: true,
                      }),
                  }
                : {stationID: shop.StationID, companyID: shop.CompanyID},
    });

    if (error) {
        yield put(featureError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedFeatures) {
            yield put(featuresResponse(cachedFeatures));
        }
    }

    if (data) {
        // Don't try to combine the cached feature when the full list was fetched.
        const combinedFeatures = unionBy(data, forceFetch || !lastUpdateTime ? [] : cachedFeatures, 'ID').filter((feature) => !feature.IsDeleted);

        yield put(featuresResponse(combinedFeatures));

        yield call(IndexedDB.createMany, IndexedDBKeys.FEATURES, combinedFeatures);
        LocalStorage.set(LocalStorageKeys.LAST_FEATURES_UPDATE_TIME, requestTime);
    }
}

export default function* featuresRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_FEATURES, fetchFeatures);
}
