import {Form} from '../../models/Form';

import {FORM_ERROR, FORM_REQUEST, GET_FORM, GET_FORMS, FORM_RESPONSE, FORMS_RESPONSE, FormName} from './form.types';

export interface FormResponse {
    [key: string]: Form;
}

export const formRequest = () => ({
    type: FORM_REQUEST,
});

export const formError = (error: Error | unknown) => ({
    type: FORM_ERROR,
    payload: error,
});

export const getForm = (formName: FormName) => ({
    type: GET_FORM,
    payload: formName,
});

export const getForms = (formNames: FormName[]) => ({
    type: GET_FORMS,
    payload: formNames,
});

export const formResponse = (data: Form) => ({
    type: FORM_RESPONSE,
    payload: data,
});

export const formsResponse = () => ({
    type: FORMS_RESPONSE,
});
