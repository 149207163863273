import {differenceInMilliseconds} from 'date-fns';
import {unionBy} from 'lodash';
import {call, put, select, takeEvery} from 'redux-saga/effects';

import {Form} from '../../models/Form';
import {ReduxAction} from '../../models/ReduxAction';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {selectIsOfflineStatus} from '../application/application.selectors';

import {formError, formRequest, formResponse, formsResponse} from './form.actions';
import {GET_FORM, GET_FORMS} from './form.types';

type FormName = string;

export function* getForm({payload}: ReduxAction<FormName>) {
    yield put(formRequest());

    const isOfflineMode: boolean = yield select(selectIsOfflineStatus);

    if (isOfflineMode) {
        const cachedForms: Form[] = yield call(IndexedDB.getMany, IndexedDBKeys.FORMS);
        const form = cachedForms.find(({Name}) => Name.toUpperCase() === payload.toUpperCase());

        if (form) {
            yield put(formResponse(form));
        } else {
            const message = `No cached form with name ${payload} found.`;

            yield showErrorMessages([message]);
            yield put(formError(message));
        }

        return;
    }

    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_FORMS_REFRESH_TIME, LocalStorageKeys.LAST_FORMS_UPDATE_TIME);

    let cachedForms: Form[] = [];

    const requestTime = new Date();
    if (lastUpdateTime) {
        cachedForms = yield call(IndexedDB.getMany, IndexedDBKeys.FORMS);

        if (differenceInMilliseconds(new Date(), lastUpdateTime) <= 60) {
            const form = cachedForms.filter(({Name}) => Name.toUpperCase() === payload.toUpperCase());
            if (form.length) {
                yield put(formResponse(form[0]));

                return;
            }
        }
    } else {
        LocalStorage.set(LocalStorageKeys.LAST_FORMS_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<Form> = yield call(request, {
        url: `/forms/${payload}`,
        method: 'get',
    });

    if (!data || error) {
        const message = error?.message || `No form with name ${payload} found.`;

        yield showErrorMessages([message]);
        const cachedForms: Form[] = yield call(IndexedDB.getMany, IndexedDBKeys.FORMS);
        yield put(formError(message));
        yield put(formResponse(cachedForms.filter(({Name}) => Name.toUpperCase() === payload.toUpperCase())?.[0]));
    } else {
        const combined = unionBy([data], cachedForms, 'Name');
        yield call(IndexedDB.createMany, IndexedDBKeys.FORMS, combined);
        LocalStorage.set(LocalStorageKeys.LAST_FORMS_UPDATE_TIME, requestTime);
        yield put(formResponse(data));
    }
}

export function* getForms({payload}: ReduxAction<FormName[]>) {
    yield put(formRequest());
    let forms: Form[] = [];
    const requestTime = new Date();

    for (let i = 0; i < payload.length; i++) {
        const {data, error}: ApiResponse<Form> = yield call(request, {
            url: `/forms/${payload[i]}`,
            method: 'get',
        });

        if (!data || error) {
            const message = error?.message || `No form with name ${payload} found.`;

            yield showErrorMessages([message]);
            yield put(formError(message));
        } else {
            forms = forms.concat(data);
        }
    }

    yield call(IndexedDB.createMany, IndexedDBKeys.FORMS, forms);
    LocalStorage.set(LocalStorageKeys.LAST_FORMS_UPDATE_TIME, requestTime);
    yield put(formsResponse());
}

export default function* formRootSaga() {
    yield takeEvery<ReduxAction>(GET_FORM, getForm);
    yield takeEvery<ReduxAction>(GET_FORMS, getForms);
}
