import {ReactElement, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import config from '../../config';
import {setUser} from '../../redux/user/user.actions';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';

export type SelectSelectedUserData = {
    username?: string | null;
};

export type PrivateRoutesProps = {
    children: ReactElement;
};

const {hardcodedUser} = config;

export const PrivateRoutesOfflineMode = ({children}: PrivateRoutesProps): ReactElement => {
    const dispatch = useDispatch();

    const user = LocalStorage.get(LocalStorageKeys.SELECTED_USER);

    useEffect(() => {
        dispatch(setUser(user ? user : hardcodedUser, ''));
    }, []);

    return children;
};
