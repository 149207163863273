import {WorkOrderRepairLine} from '../../models/WorkOrderRepairLine';
import {AddDeclinationReasonDto} from '../../models/dtos/add-declination-reason.dto';
import {CreateRepairLineDto} from '../../models/dtos/create-repair-line.dto';
import {RemoveDeclinationReasonDto} from '../../models/dtos/remove-declination-reason.dto';
import {UpdateRepairLineDto} from '../../models/dtos/update-repair-line.dto';

import {
    UPDATE_REPAIR_LINE,
    CREATE_REPAIR_LINE,
    DELETE_REPAIR_LINE,
    FETCH_REPAIR_LINES,
    REPAIR_LINE_ERROR,
    REPAIR_LINE_REQUEST,
    REPAIR_LINES_RESPONSE,
    DELETE_REPAIR_LINE_RESPONSE,
    CREATE_REPAIR_LINE_RESPONSE,
    UPDATE_REPAIR_LINE_RESPONSE,
    ADD_REPAIR_LINE_DECLINATION_REASON,
    REMOVE_REPAIR_LINE_DECLINATION_REASON,
    REMOVE_REPAIR_LINE_DECLINATION_REASON_RESPONSE,
    ADD_REPAIR_LINE_DECLINATION_REASON_RESPONSE,
} from './repair-line.types';

export const repairLineRequest = () => ({
    type: REPAIR_LINE_REQUEST,
});

export const createRepairLine = (data: CreateRepairLineDto) => ({
    type: CREATE_REPAIR_LINE,
    payload: data,
});

export const updateRepairLine = (data: UpdateRepairLineDto) => ({
    type: UPDATE_REPAIR_LINE,
    payload: data,
});

export const deleteRepairLine = (repairLineID: number, workOrderID: number) => ({
    type: DELETE_REPAIR_LINE,
    payload: {repairLineID, workOrderID},
});

export const repairLineError = (error: string) => ({
    type: REPAIR_LINE_ERROR,
    payload: error,
});

export const repairLinesResponse = (workOrderID: number, data: WorkOrderRepairLine[]) => ({
    type: REPAIR_LINES_RESPONSE,
    payload: {workOrderID, repairLines: data},
});

export const fetchRepairLines = (workOrderID: number) => ({
    type: FETCH_REPAIR_LINES,
    payload: {workOrderID},
});

export const createRepairLineResponse = (workOrderID: number, data: WorkOrderRepairLine) => ({
    type: CREATE_REPAIR_LINE_RESPONSE,
    payload: {workOrderID, repairLine: data},
});

export const updateRepairLineResponse = (workOrderID: number, repairLineID: number, data?: WorkOrderRepairLine) => ({
    type: UPDATE_REPAIR_LINE_RESPONSE,
    payload: {workOrderID, repairLineID, repairLine: data},
});

export const deleteRepairLineResponse = (workOrderID: number, repairLineID: number) => ({
    type: DELETE_REPAIR_LINE_RESPONSE,
    payload: {workOrderID, repairLineID},
});

export const addRepairLineDeclinationReason = (data: AddDeclinationReasonDto) => ({
    type: ADD_REPAIR_LINE_DECLINATION_REASON,
    payload: data,
});

export const addRepairLineDeclinationReasonResponse = () => ({
    type: ADD_REPAIR_LINE_DECLINATION_REASON_RESPONSE,
});

export const removeRepairLineDeclinationReason = (data: RemoveDeclinationReasonDto) => ({
    type: REMOVE_REPAIR_LINE_DECLINATION_REASON,
    payload: data,
});

export const removeRepairLineDeclinationReasonResponse = (data: RemoveDeclinationReasonDto) => ({
    type: REMOVE_REPAIR_LINE_DECLINATION_REASON_RESPONSE,
    payload: data,
});
