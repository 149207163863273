import {push} from 'connected-react-router';
import React, {useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';

import {RepairLineDetailForm} from '../../components/repair-line-details-form/repair-line-detail-form.component';
import {useHeader} from '../../hooks/use-header';
import {WorkOrderRepairLine} from '../../models/WorkOrderRepairLine';
import {WorkOrderRepairLineStatus} from '../../models/enumerations/WorkOrderRepairLineStatus';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {getForm} from '../../redux/form-validation/form.actions';
import {selectForm} from '../../redux/form-validation/form.selectors';
import {FormName} from '../../redux/form-validation/form.types';
import {clearRepairLinePricing, fetchRepairLinePricing} from '../../redux/pricing/pricing.actions';
import {createRepairLine, updateRepairLine} from '../../redux/repair-line/repair-line.actions';

export type RepairLineDetailsProps = {
    backToPath?: string;
};

const RepairLineDetails = ({backToPath}: RepairLineDetailsProps) => {
    const dispatch = useDispatch();
    const {params} = useRouteMatch<{lineId?: string}>();
    const {workOrder, readOnly: workOrderReadOnly, repairLines} = useContext(WorkOrderDataContext);
    const repairLineID = Number(params.lineId);
    const repairLine = repairLines.find(({ID}) => ID === repairLineID);
    const form = useSelector(selectForm(FormName.RepairLine));
    const readOnly = workOrderReadOnly || repairLine?.Status === WorkOrderRepairLineStatus.Rejected;

    useEffect(() => {
        dispatch(getForm(FormName.RepairLine));
    }, [dispatch]);

    useHeader({
        backToPath: `${backToPath}/${workOrder.ID}`,
    });

    const onSubmit = useCallback(
        (data: WorkOrderRepairLine) => {
            dispatch(
                repairLine
                    ? updateRepairLine({
                          workOrderID: workOrder.ID,
                          repairLineID: repairLine.ID,
                          repairLine: data,
                      })
                    : createRepairLine({
                          repairLine: data,
                          workOrderID: workOrder.ID,
                      }),
            );

            dispatch(push(`${backToPath}/${workOrder.ID}`));
        },
        [dispatch, repairLine, workOrder.ID],
    );

    const fetchPricing = useCallback(
        (jobCodeID: number, conditionCodeID: number, repairSizeID?: number) =>
            dispatch(
                fetchRepairLinePricing({
                    companyID: workOrder.Company.ID,
                    stationID: workOrder.RepairLocation.ID,
                    jobCodeID,
                    conditionCodeID,
                    equipmentGroupID: workOrder.EquipmentGroups,
                    repairSizeID,
                    repairDate: workOrder.RepairDate,
                }),
            ),
        [dispatch, workOrder],
    );
    const clearPricing = useCallback(() => dispatch(clearRepairLinePricing()), [dispatch]);

    return (
        <RepairLineDetailForm
            formSchemas={form}
            key={repairLine?.ID}
            onSubmit={onSubmit}
            initialValues={repairLine!}
            readOnly={readOnly}
            fetchPricing={fetchPricing}
            clearPricing={clearPricing}
        />
    );
};

export default RepairLineDetails;
