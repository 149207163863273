import React, {ReactElement, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import config from '../../config';
import {CheckOfflineDuration} from '../../redux/application/application.actions';
import {selectGlobalLoading, selectIsOfflineStatus} from '../../redux/application/application.selectors';
import {fetchCompanies, fetchCompaniesTypes} from '../../redux/company/company.actions';
import {fetchConditionCodes} from '../../redux/condition-code/condition-code.actions';
import {fetchExceptionReasons} from '../../redux/exception-reason/exception-reason.actions';
import {fetchFeatures} from '../../redux/feature/feature.actions';
import {getForm} from '../../redux/form-validation/form.actions';
import {FormName} from '../../redux/form-validation/form.types';
import {fetchJobCodes} from '../../redux/job-code/job-code.actions';
import {fetchMessages} from '../../redux/message/message.actions';
import {fetchOfflineEstimates, cleanUpOfflineData} from '../../redux/offline-estimate/offline-estimate.actions';
import {fetchParts} from '../../redux/part/part.actions';
import {fetchRepairSizes} from '../../redux/repair-size/repair-size.actions';
import {selectSelectedShop} from '../../redux/shop/shop.selectors';
import {fetchStations} from '../../redux/station/station.actions';
import {fetchUnitsStatus, fetchUnitsTypes} from '../../redux/unit/unit.actions';
import {fetchUnitLocationCodes} from '../../redux/unit-location-code/user-location-code.actions';
import {fetchUsers} from '../../redux/user/user.actions';
import {fetchWhyMadeCodes} from '../../redux/why-made-code/why-made-code.actions';
import {fetchWorkOrders} from '../../redux/work-order/work-order.actions';
import {Fallback} from '../fallback/fallback.component';

const {resourcesRefetchInterval} = config;

interface ResourceProviderProps {
    children: ReactElement;
}

export const ResourceProvider = ({children}: ResourceProviderProps): ReactElement => {
    const dispatch = useDispatch();
    const isOfflineMode = useSelector(selectIsOfflineStatus);
    const selectedShop = useSelector(selectSelectedShop);
    const globalLoading = useSelector(selectGlobalLoading);

    const refetchData = useCallback(() => {
        dispatch(fetchWorkOrders());
        dispatch(fetchMessages());
    }, [dispatch]);

    const offlineReminder = useCallback(() => {
        dispatch(CheckOfflineDuration());
    }, [dispatch]);

    const offlineDataCleanUp = useCallback(() => {
        dispatch(cleanUpOfflineData());
    }, [dispatch]);

    // Some endpoints should be called periodically
    useEffect(() => {
        if (!isOfflineMode) {
            const refetchInterval = setInterval(refetchData, resourcesRefetchInterval);
            const cleanUpInterval = setInterval(offlineDataCleanUp, resourcesRefetchInterval);

            return () => {
                clearInterval(refetchInterval);
                clearInterval(cleanUpInterval);
            };
        } else {
            const reminderInterval = setInterval(offlineReminder, resourcesRefetchInterval);
            const cleanUpInterval = setInterval(offlineDataCleanUp, resourcesRefetchInterval);

            return () => {
                clearInterval(reminderInterval);
                clearInterval(cleanUpInterval);
            };
        }
    }, [refetchData, isOfflineMode]);

    useEffect(() => {
        dispatch(fetchFeatures());
        dispatch(fetchOfflineEstimates());

        if (!isOfflineMode) {
            if (selectedShop) {
                dispatch(fetchUsers());
                dispatch(fetchWorkOrders(true));
            }
            dispatch(fetchParts());
            dispatch(fetchCompanies());
            dispatch(fetchStations());
            dispatch(fetchJobCodes());
            dispatch(fetchUnitsTypes());
            dispatch(fetchRepairSizes());
            dispatch(fetchUnitsStatus());
            dispatch(fetchWhyMadeCodes());
            dispatch(fetchConditionCodes());
            dispatch(fetchCompaniesTypes());
            dispatch(fetchExceptionReasons());
            dispatch(fetchUnitLocationCodes());
            dispatch(fetchMessages());
        }
    }, [dispatch, isOfflineMode, selectedShop]);

    useEffect(() => {
        if (isOfflineMode) {
            dispatch(fetchJobCodes());
            dispatch(fetchWhyMadeCodes());
            dispatch(fetchConditionCodes());
            dispatch(fetchUnitLocationCodes());
            dispatch(fetchParts());
            dispatch(getForm(FormName.WorkOrderHeader));
            dispatch(getForm(FormName.RepairLine));
        }
    }, [dispatch]);

    return (
        <>
            {globalLoading ? <Fallback /> : null}
            {children}
        </>
    );
};
