import {differenceInMilliseconds} from 'date-fns';
import _uniqBy from 'lodash/uniqBy';
import {takeLatest, call, put, takeLeading, select} from 'redux-saga/effects';

import config from '../../config';
import {Part} from '../../models/Part';
import {ReduxAction} from '../../models/ReduxAction';
import {CreatePartDto} from '../../models/dtos/create-part.dto';
import {DeletePartDto} from '../../models/dtos/delete-part.dto';
import {UpdatePartDto} from '../../models/dtos/update-part.dto';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {selectIsOfflineStatus} from '../application/application.selectors';
import {selectSelectedShop} from '../shop/shop.selectors';
import {fetchWorkOrder} from '../work-order/work-order.actions';

import {
    createRepairLinePartResponse,
    updateRepairLinePartResponse,
    deleteRepairLinePartResponse,
    fetchRepairLinePartResponse,
    partsError,
    partsRequest,
    partsResponse,
} from './part.actions';
import {CREATE_REPAIR_LINE_PART, DELETE_REPAIR_LINE_PART, FETCH_PARTS, FETCH_REPAIR_LINE_PART, UPDATE_REPAIR_LINE_PART} from './part.types';

const {resourceCacheFallback} = config;

export function* fetchParts({payload: forceFetchAll}: ReduxAction<{forceFetchAll?: boolean}>) {
    const isOfflineMode: boolean = yield select(selectIsOfflineStatus);

    let cachedParts: Part[] = [];

    if (isOfflineMode) {
        cachedParts = yield call(IndexedDB.getMany, IndexedDBKeys.PARTS);

        yield put(partsResponse(cachedParts));

        return;
    }

    const {StationID, CompanyID} = yield select(selectSelectedShop);
    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_PARTS_REFRESH_TIME, LocalStorageKeys.LAST_PARTS_UPDATE_TIME);

    if (lastUpdateTime && !forceFetchAll) {
        cachedParts = yield call(IndexedDB.getMany, IndexedDBKeys.PARTS);

        if (differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
            yield put(partsResponse(cachedParts));

            return;
        }
    }

    yield put(partsRequest());

    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_PARTS_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<Part[]> = yield call(request, {
        url: `/parts/${StationID}/${CompanyID}`,
        method: 'get',
    });

    if (error) {
        yield put(partsError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedParts) {
            yield put(partsResponse(cachedParts));
        }
    }

    if (data) {
        // This endpoint always returns all parts. The cache should be fully replaced in this case.
        const parts = _uniqBy(
            data.filter((part) => !part.IsDeleted),
            'ID',
        );

        yield put(partsResponse(parts));

        yield call(IndexedDB.createMany, IndexedDBKeys.PARTS, parts);
        LocalStorage.set(LocalStorageKeys.LAST_PARTS_UPDATE_TIME, requestTime);
    }
}

export function* fetchRepairLinePart(action: ReduxAction<{partID: Part['ID']}>) {
    const {data, error}: ApiResponse<Part> = yield call(request, {
        url: `/parts/repairlines/${action.payload.partID}`,
        method: 'get',
    });

    if (error) {
        yield put(partsError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield put(fetchRepairLinePartResponse(data));
    }
}

function* createRepairLinePart({payload}: ReduxAction<CreatePartDto>) {
    yield put(partsRequest());

    const {data, error}: ApiResponse<Part> = yield call(request, {
        data: payload.part,
        url: `/parts/repairlines/${payload.repairLineID}`,
        method: 'post',
    });

    if (error) {
        yield put(partsError(error.message));
        yield showErrorMessages(error.messages);

        if (payload.onFailure) {
            payload.onFailure();
        }
    } else if (data) {
        yield put(createRepairLinePartResponse(payload.workOrderID, payload.repairLineID, data));
        yield put(fetchWorkOrder({workOrderID: payload.workOrderID}));

        if (payload.onSuccess) {
            payload.onSuccess();
        }
    }
}

function* updateRepairLinePart({payload}: ReduxAction<UpdatePartDto>) {
    yield put(partsRequest());

    const {data, error}: ApiResponse<Part> = yield call(request, {
        url: `/parts/repairlineparts/${payload.repairLinePartID ?? -1}`,
        method: 'put',
        data: payload.part,
    });

    if (error) {
        yield put(partsError(error.message));
        yield showErrorMessages(error.messages);

        if (payload.onFailure) {
            payload.onFailure();
        }
    } else if (data) {
        yield put(updateRepairLinePartResponse(payload.workOrderID, payload.repairLineID, data));
        yield put(fetchWorkOrder({workOrderID: payload.workOrderID}));

        if (payload.onSuccess) {
            payload.onSuccess();
        }
    }
}

function* deleteRepairLinePart({payload}: ReduxAction<DeletePartDto>) {
    yield put(partsRequest());

    const {data, error}: ApiResponse<Part> = yield call(request, {
        url: `/parts/repairlines/${payload.partID}`,
        method: 'delete',
    });

    if (error) {
        yield put(partsError(error.message));
        yield showErrorMessages(error.messages);
    } else if (data) {
        yield put(deleteRepairLinePartResponse(payload.workOrderID, payload.repairLineID, data.RepairLinePartID));
        yield put(fetchWorkOrder({workOrderID: payload.workOrderID}));
    }
}

export default function* partsRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_PARTS, fetchParts);
    yield takeLatest<ReduxAction>(FETCH_REPAIR_LINE_PART, fetchRepairLinePart);
    yield takeLatest<ReduxAction>(CREATE_REPAIR_LINE_PART, createRepairLinePart);
    yield takeLatest<ReduxAction>(UPDATE_REPAIR_LINE_PART, updateRepairLinePart);
    yield takeLatest<ReduxAction>(DELETE_REPAIR_LINE_PART, deleteRepairLinePart);
}
