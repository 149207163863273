import {RepairSize} from '../../models/RepairSize';

import {FETCH_REPAIR_SIZES, REPAIR_SIZE_ERROR, REPAIR_SIZE_REQUEST, REPAIR_SIZES_RESPONSE} from './repair-size.types';

export const repairSizeRequest = () => ({
    type: REPAIR_SIZE_REQUEST,
});

export const repairSizeError = (error: Error | unknown) => ({
    type: REPAIR_SIZE_ERROR,
    payload: error,
});

export const repairSizesResponse = (data: RepairSize[]) => ({
    type: REPAIR_SIZES_RESPONSE,
    payload: data,
});

export const fetchRepairSizes = () => ({
    type: FETCH_REPAIR_SIZES,
});
