import {differenceInMilliseconds} from 'date-fns';
import {unionBy} from 'lodash';
import {takeLeading, call, put, select} from 'redux-saga/effects';

import config from '../../config';
import {FMCSA} from '../../models/FMCSA';
import {ReduxAction} from '../../models/ReduxAction';
import {UnitStatus} from '../../models/UnitStatus';
import {UnitType} from '../../models/UnitType';
import {FMCSACheckDto} from '../../models/dtos/fmcsa-check.dto';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {formatDate} from '../../utils/formatDate';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {selectSelectedShop} from '../shop/shop.selectors';

import {unitsError, unitsRequest, unitsTypesResponse, unitsStatusResponse, fmcsaCheckResponse, resetFMCSA} from './unit.actions';
import {selectUnitState} from './unit.selectors';
import {FETCH_UNITS_TYPES, FETCH_UNITS_STATUS, FMCSA_CHECK, FMCSA_CHECK_SUBMIT} from './unit.types';

const {resourceCacheFallback} = config;

export function* fetchUnitsTypes() {
    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_UNITS_TYPES_REFRESH_TIME, LocalStorageKeys.LAST_UNITS_TYPES_UPDATE_TIME);

    let cachedUnitsTypes: UnitType[] = [];

    if (lastUpdateTime) {
        cachedUnitsTypes = yield call(IndexedDB.getMany, IndexedDBKeys.UNITS_TYPES);

        if (differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
            yield put(unitsTypesResponse(cachedUnitsTypes));

            return;
        }
    }

    yield put(unitsRequest());

    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_UNITS_TYPES_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<UnitType[]> = yield call(request, {
        url: '/units/types',
        method: 'get',
        params: lastUpdateTime
            ? {
                  includeDeleted: true,
                  lastUpdateTime: formatDate({
                      date: lastUpdateTime,
                      ISOFormat: true,
                  }),
              }
            : undefined,
    });

    if (error) {
        yield put(unitsError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedUnitsTypes) {
            yield put(unitsTypesResponse(cachedUnitsTypes));
        }
    }

    if (data) {
        const combinedUnitTypes = unionBy(data, cachedUnitsTypes, 'ID').filter((unitType) => !unitType.IsDeleted);

        yield put(unitsTypesResponse(combinedUnitTypes));

        yield call(IndexedDB.createMany, IndexedDBKeys.UNITS_TYPES, combinedUnitTypes);
        LocalStorage.set(LocalStorageKeys.LAST_UNITS_TYPES_UPDATE_TIME, requestTime);
    }
}

export function* fetchUnitsStatus() {
    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_UNITS_STATUS_REFRESH_TIME, LocalStorageKeys.LAST_UNITS_STATUS_UPDATE_TIME);

    let cachedUnitsStatuses: UnitStatus[] = [];

    if (lastUpdateTime) {
        cachedUnitsStatuses = yield call(IndexedDB.getMany, IndexedDBKeys.UNITS_STATUS);

        if (differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
            yield put(unitsStatusResponse(cachedUnitsStatuses));

            return;
        }
    }

    yield put(unitsRequest());

    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_UNITS_STATUS_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<UnitStatus[]> = yield call(request, {
        url: '/units/statuses',
        method: 'get',
        params: lastUpdateTime
            ? {
                  includeDeleted: true,
                  lastUpdateTime: formatDate({
                      date: lastUpdateTime,
                      ISOFormat: true,
                  }),
              }
            : undefined,
    });

    if (error) {
        yield put(unitsError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedUnitsStatuses) {
            yield put(unitsStatusResponse(cachedUnitsStatuses));
        }
    }

    if (data) {
        const combinedUnitStatuses = unionBy(data, cachedUnitsStatuses, 'ID').filter((unitStatus) => !unitStatus.IsDeleted);

        yield put(unitsStatusResponse(combinedUnitStatuses));

        yield call(IndexedDB.createMany, IndexedDBKeys.UNITS_STATUS, combinedUnitStatuses);
        LocalStorage.set(LocalStorageKeys.LAST_UNITS_STATUS_UPDATE_TIME, requestTime);
    }
}

export function* fmcsaCheck({payload}: ReduxAction<{unitINO: string}>) {
    const {StationID, CompanyID} = yield select(selectSelectedShop);

    yield put(unitsRequest());

    const {data, error}: ApiResponse<FMCSA> = yield call(request, {
        url: '/units/fmcsacheck',
        method: 'post',
        priority: 'high',
        params: {
            unitINO: payload.unitINO,
            stationID: StationID,
            companyID: CompanyID,
        },
    });

    if (error) {
        yield put(unitsError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield put(fmcsaCheckResponse(data));
    }
}

export function* fmcsaCheckSubmit({payload}: ReduxAction<FMCSACheckDto>) {
    const {fmcsaCheck} = yield select(selectUnitState);
    const {StationID, CompanyID} = yield select(selectSelectedShop);

    yield put(unitsRequest());

    const {data, error}: ApiResponse<FMCSA> = yield call(request, {
        url: '/units/fmcsacheck',
        method: 'put',
        data: {
            ...fmcsaCheck,
            IsBad: payload.isBad,
            StickerDate: payload.stickerDate,
            CreateEstimate: payload.createEstimate,
            StationID: StationID,
            CompanyID: CompanyID,
        },
    });

    if (error) {
        yield put(unitsError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield put(resetFMCSA());
    }
}

export default function* unitsRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_UNITS_TYPES, fetchUnitsTypes);
    yield takeLeading<ReduxAction>(FETCH_UNITS_STATUS, fetchUnitsStatus);
    yield takeLeading<ReduxAction>(FMCSA_CHECK, fmcsaCheck);
    yield takeLeading<ReduxAction>(FMCSA_CHECK_SUBMIT, fmcsaCheckSubmit);
}
