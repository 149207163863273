import {ReduxAction} from '../../models/ReduxAction';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';

import {ApplicationMode, SET_APPLICATION_MODE, SET_MODE_LOADING} from './application.types';

export interface ApplicationState {
    mode: ApplicationMode;
    modeLoading: boolean;
}

const initialState: ApplicationState = {
    mode: LocalStorage.get(LocalStorageKeys.APPLICATION_MODE) || ApplicationMode.ONLINE,
    modeLoading: false,
};

export default function applicationReducer(state = initialState, action: ReduxAction): ApplicationState {
    switch (action.type) {
        case SET_APPLICATION_MODE:
            return {
                ...state,
                mode: action.payload.mode,
            };
        case SET_MODE_LOADING:
            return {
                ...state,
                modeLoading: action.payload,
            };
        default:
            return state;
    }
}
