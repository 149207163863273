import styled from 'styled-components';

export const TitleWrap = styled.div`
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
        margin: 0;
        &:first-child {
            color: ${(props) => props.theme.palette.primary.main};
        }
    }
`;
