export type StatusItem = {
    Status: number;
    Description: string;
    IsMobile: boolean;
};

export const WorkOrderStatus: Record<string, StatusItem> = {
    Unknown: {
        Status: -1,
        Description: 'unknown',
        IsMobile: false,
    },
    Created: {
        Status: 0,
        Description: 'created',
        IsMobile: false,
    },
    Open: {
        Status: 10,
        Description: 'open',
        IsMobile: true,
    },
    SubmittedForPricing: {
        Status: 15,
        Description: 'submitted_for_pricing',
        IsMobile: false,
    },
    SubmittedForApproval: {
        Status: 20,
        Description: 'submitted_for_approval',
        IsMobile: true,
    },
    PendingEscalation: {
        Status: 21,
        Description: 'pending_escalation',
        IsMobile: true,
    },
    ErrorEDI: {
        Status: 23,
        Description: 'error_edi',
        IsMobile: false,
    },
    DoNotRepair: {
        Status: 24,
        Description: 'do_not_repair',
        IsMobile: false,
    },
    WorkRejected: {
        Status: 25,
        Description: 'work_rejected',
        IsMobile: false,
    },
    Canceled: {
        Status: 26,
        Description: 'canceled',
        IsMobile: false,
    },
    Closed: {
        Status: 27,
        Description: 'closed',
        IsMobile: false,
    },
    Expired: {
        Status: 28,
        Description: 'expired',
        IsMobile: false,
    },
    WorkApproved: {
        Status: 30,
        Description: 'work_approved',
        IsMobile: true,
    },
    RepairsDone: {
        Status: 50,
        Description: 'repairs_done',
        IsMobile: true,
    },
    SubmittedForAudit: {
        Status: 60,
        Description: 'submitted_for_audit',
        IsMobile: false,
    },
    InAudit: {
        Status: 70,
        Description: 'in_audit',
        IsMobile: false,
    },
    Rejected: {
        Status: 90,
        Description: 'rejected',
        IsMobile: false,
    },
    PartiallyApproved: {
        Status: 95,
        Description: 'partially_approved',
        IsMobile: false,
    },
    Approved: {
        Status: 100,
        Description: 'approved',
        IsMobile: false,
    },
} as const;
