import * as i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        returnNull: false,
        fallbackLng: 'en',
        detection: {
            order: ['localStorage', 'cache', 'path'],
            caches: ['localStorage'],
        },
        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json',
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
