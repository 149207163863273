import {ReduxAction} from '../../models/ReduxAction';
import {WhyMadeCode} from '../../models/WhyMadeCode';

import {WHY_MADE_CODE_ERROR, WHY_MADE_CODE_REQUEST, WHY_MADE_CODES_RESPONSE} from './why-made-code.types';

export interface WhyMadeCodesState {
    error: Error | null;
    loading: boolean;
    whyMadeCodes: WhyMadeCode[];
}

const initialState: WhyMadeCodesState = {
    error: null,
    loading: false,
    whyMadeCodes: [],
};

export default function whyMadeCodesReducer(state = initialState, action: ReduxAction): WhyMadeCodesState {
    switch (action.type) {
        case WHY_MADE_CODE_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case WHY_MADE_CODES_RESPONSE:
            return {
                ...state,
                loading: false,
                whyMadeCodes: action.payload,
            };
        case WHY_MADE_CODE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
