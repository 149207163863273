import {List} from 'linked-list';

import {ApiRequest, ApiResponse} from '../services/api-request';

import {ApiRequestListItem} from './apiRequestListItem';

export type RequestPriority = 'default' | 'high';

/**
 * This class manage api requests using limited count of active requests at the same time
 */
export class ApiRequestPool {
    private activeRequestsCount = 0;
    private readonly apiRequestsList = new List<ApiRequestListItem>();

    constructor(public readonly maxActiveRequestsCount: number) {}

    private runRequest(): void {
        if (this.apiRequestsList.head && this.activeRequestsCount < this.maxActiveRequestsCount) {
            this.activeRequestsCount++;

            const apiRequestItem = this.apiRequestsList.head.detach();

            apiRequestItem.apiRequest.runRequest().then((result) => {
                apiRequestItem.onFinishCallback(result);

                this.activeRequestsCount--;

                this.runRequest();
            });
        }
    }

    public addApiRequest<T>(apiRequest: ApiRequest<T>, onFinish: (response: ApiResponse<T>) => void, priority: RequestPriority = 'default'): void {
        const listItem = new ApiRequestListItem<T>(apiRequest, onFinish);

        switch (priority) {
            case 'default':
                this.apiRequestsList.append(listItem);
                this.runRequest();
                break;
            case 'high':
                this.apiRequestsList.prepend(listItem);
                this.runRequest();
                break;
        }
    }
}
