import config from '../config';
import {ApiRequestPool, RequestPriority} from '../utils/apiRequestPool';

import {ApiResponse, ApiRequest, RequestParams} from './api-request';
export type {ApiResponse} from './api-request';

const apiRequestPool = new ApiRequestPool(config.maxActiveApiRequestsCount);

export type ExtendedRequestParams = RequestParams & {
    priority?: RequestPriority;
    timeout?: number;
};

const request = <T = unknown>(params: ExtendedRequestParams): Promise<ApiResponse<T>> => {
    return new Promise<ApiResponse<T>>((resolve) => {
        const apiRequest = new ApiRequest<T>(params);

        apiRequestPool.addApiRequest<T>(apiRequest, resolve, params.priority);
    });
};

export default request;
