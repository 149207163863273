import {Backdrop} from '@mui/material';
import styled from 'styled-components';

export const BackDropContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    .drawingBuffer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: 3px solid green;
    }
    video {
        width: 100%;
        height: 100%;
    }
`;

export const MainBackdrop = styled(Backdrop)`
    z-index: 200;
`;

export const BackdropLoader = styled(Backdrop)`
    position: absolute;
    z-index: 200;
`;

export const ButtonContainer = styled.div`
    position: absolute;
    z-index: 300;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 5px;
    opacity: 0.75;
`;
