export const SET_APPLICATION_MODE = 'SET_APPLICATION_MODE';
export const GO_OFFLINE = 'GO_OFFLINE';
export const GO_ONLINE = 'GO_ONLINE';
export const CHECK_OFFLINE_DURATION = 'CHECK_OFFLINE_DURATION';
export const SET_MODE_LOADING = 'SET_MODE_LOADING';

export enum ApplicationMode {
    ONLINE = 'online',
    OFFLINE = 'offline',
}
