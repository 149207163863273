import React from 'react';

/**
 * Authentication Sign-Out post-completion landing page.
 *
 * @returns {*}
 */
const AuthSignOut = () => {
    return (
        <div>
            <p>You have been logged out.</p>
        </div>
    );
};

export default AuthSignOut;
