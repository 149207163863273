export type GetValidJson = <T = Record<string, any>>(serializedJson: string) => T;

const getValidJson: GetValidJson = (str) => {
    try {
        if (str) {
            return JSON.parse(str);
        } else {
            return {};
        }
    } catch (e) {
        return {};
    }
};
export default getValidJson;
