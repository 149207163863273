import {IconButton, Snackbar} from '@mui/material';
import React, {ReactElement, useState} from 'react';

import {NetworkStatus} from '../../models/enumerations/NetworkStatus';

import {IconNoConnection, Wrapper} from './network-indicator.styles';

export type NetworkIndicatorProps = {
    status: NetworkStatus;
};

const SETTINGS = {
    [NetworkStatus.CONNECTED]: {IconComponent: null, onClickMessage: null},
    [NetworkStatus.DISCONNECTED]: {IconComponent: IconNoConnection, onClickMessage: 'No Connection'},
};

export const NetworkIndicator = ({status}: NetworkIndicatorProps): ReactElement | null => {
    const [open, setOpen] = useState(false);
    const {IconComponent, onClickMessage} = SETTINGS[status];

    const handleClick = () => {
        setOpen((prevState) => !prevState);
    };

    if (!IconComponent) {
        return null;
    }

    return (
        <>
            <Wrapper>
                <IconButton disableRipple onClick={handleClick}>
                    <IconComponent />
                </IconButton>
            </Wrapper>
            {onClickMessage && (
                <Snackbar
                    open={open}
                    autoHideDuration={2500}
                    onClose={handleClick}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    message={onClickMessage}
                />
            )}
        </>
    );
};
