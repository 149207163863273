import {WidgetProps} from '@rjsf/utils';
import {DropDownListComponent, PopupEventArgs} from '@syncfusion/ej2-react-dropdowns';
import _uniqBy from 'lodash/uniqBy';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {ConditionCode} from '../../models/ConditionCode';
import {ConditionCodeDto} from '../../models/dtos/condition-code.dto';
import {SyncfusionSelectEvent} from '../../models/types/SyncfusionSelectEvent';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {selectJobCodesState} from '../../redux/job-code/job-code.selectors';
import {selectSelectedOfflineEstimate} from '../../redux/offline-estimate/offline-estimate.selectors';
import dropDownFiltering from '../../utils/dropDownFiltering';

export const ConditionCodeDropDownInput = ({onChange, formContext, value, disabled}: WidgetProps) => {
    const {JobCodeId, WhyMadeCodeId, ID} = formContext.formData || {};
    const {t} = useTranslation();
    const [localValue, setLocalValue] = useState(value);
    const [options, setOptions] = useState<ConditionCodeDto[]>([]);
    const {jobCodes, loading: jobCodesLoading} = useSelector(selectJobCodesState);
    const workOrderEquipmentGroup =
        useSelector(selectSelectedOfflineEstimate)?.EquipmentGroupID ?? useContext(WorkOrderDataContext)?.workOrder?.EquipmentGroups;

    const onChangeHandler = useCallback(
        (newValue) => {
            setLocalValue(newValue);
            setTimeout(() => onChange(newValue), 0);
        },
        [onChange],
    );

    // The condition code dropdown list should only contain a unique list of all of WhyMade's ConditionCodes
    useEffect(() => {
        const jobCode = jobCodes.find(({ID}) => JobCodeId === ID);

        if (jobCode && WhyMadeCodeId) {
            const whyMade = jobCode.WhyMade.filter(
                ({WhyMadeCode, EquipmentGroupIDPriced, ConditionCode}) =>
                    // Existing repair lines should have their currently selected condition code even if it is unpriced.
                    (ID > 0 && ConditionCode.ID === value) ||
                    (WhyMadeCodeId === WhyMadeCode.ID && (workOrderEquipmentGroup == undefined || EquipmentGroupIDPriced[workOrderEquipmentGroup])),
            );

            const conditionCodes = _uniqBy(
                whyMade.map(({ConditionCode}) => ConditionCode),
                'ID',
            );

            setOptions(conditionCodes);

            if (!conditionCodes.find(({ID}) => ID === value)) {
                const newValue = conditionCodes.length === 1 ? conditionCodes[0].ID : null;

                onChangeHandler(newValue);
            }
        } else {
            setOptions([]);
            onChangeHandler(null);
        }
    }, [JobCodeId, jobCodes, onChangeHandler, WhyMadeCodeId, value]);

    const onSelect = useCallback(
        (event: SyncfusionSelectEvent<ConditionCode>) => {
            onChangeHandler(event.itemData.ID);
        },
        [onChangeHandler],
    );

    const onDropDownOpen = useCallback(
        ({popup}: PopupEventArgs) => {
            const input = popup.element.querySelector('input');

            if (input) {
                input.placeholder = t('search');
            }
        },
        [t],
    );

    return (
        <DropDownListComponent
            open={onDropDownOpen}
            placeholder={t('repair')}
            value={localValue}
            select={onSelect}
            dataSource={options}
            filtering={dropDownFiltering(['Description'], 'contains')}
            allowFiltering
            floatLabelType="Auto"
            fields={{text: 'Description', value: 'ID'}}
            enabled={!jobCodesLoading && Boolean(JobCodeId) && Boolean(WhyMadeCodeId) && Boolean(options.length) && !disabled}
        />
    );
};
