import {Modal} from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import {DropDownListComponent} from '@syncfusion/ej2-react-dropdowns';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {StationCompanyRelation} from '../../models/StationCompanyRelation';
import {StationCompanyRelationDto} from '../../models/dtos/station-company-relation.dto';
import {SyncfusionSelectEvent} from '../../models/types/SyncfusionSelectEvent';
import {selectShopState} from '../../redux/shop/shop.selectors';
import {selectWorkOrderState} from '../../redux/work-order/work-order.selectors';
import dropDownFiltering from '../../utils/dropDownFiltering';

import {ModalWrap} from './shop-modal-list.style';

type ShopModalListProps = {
    open: boolean;
    handleClose: () => void;
    onSubmit: (shop: StationCompanyRelationDto) => void;
    shops: StationCompanyRelationDto[];
    initialValue?: StationCompanyRelationDto | null;
};

export const ShopModalList = ({open, handleClose, onSubmit, shops, initialValue}: ShopModalListProps) => {
    const {t} = useTranslation();
    const {loading: shopLoading} = useSelector(selectShopState);
    const {loading: workOrderLoading} = useSelector(selectWorkOrderState);
    const [currentShop, setCurrentShop] = useState<StationCompanyRelationDto | undefined>(
        initialValue ?? (shops.length === 1 ? shops[0] : undefined),
    );

    const handleShopChange = (event: SyncfusionSelectEvent<StationCompanyRelation>) => {
        setCurrentShop(event.itemData);
    };

    const handleSubmit = () => {
        if (currentShop) {
            onSubmit(currentShop);
        }
    };

    const shopTemplate = ({ShopCode, Description}: StationCompanyRelation) => {
        return <div>{`[${ShopCode}] ${Description}`}</div>;
    };

    const isSubmitDisabled = workOrderLoading || shopLoading || !currentShop || initialValue?.ID === currentShop?.ID;

    return (
        <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <ModalWrap>
                <DropDownListComponent
                    value={currentShop?.ID}
                    placeholder={t('shops')}
                    select={handleShopChange}
                    dataSource={shops}
                    filtering={dropDownFiltering(['ShopCode', 'Description'], 'contains', [' ', '[', ']', '-'])}
                    allowFiltering
                    floatLabelType="Auto"
                    fields={{text: 'Description', value: 'ID'}}
                    itemTemplate={shopTemplate}
                    valueTemplate={shopTemplate}
                    enabled={!shopLoading}
                    index={shops.length === 1 ? 0 : undefined}
                />
                <DialogActions>
                    <Button variant="contained" onClick={handleClose} color="secondary">
                        {t('cancel')}
                    </Button>
                    <Button variant="contained" onClick={handleSubmit} color="primary" disabled={isSubmitDisabled}>
                        {t('assign')}
                    </Button>
                </DialogActions>
            </ModalWrap>
        </Modal>
    );
};
