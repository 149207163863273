import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HistoryTitle = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;

    &:after {
        content: '';
        background: black;
        position: absolute;
        bottom: 0;
        left: 10%;
        height: 1px;
        width: 80%;
    }
`;

export const ProgressContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px;
`;
