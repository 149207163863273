import {OptionsObject, SnackbarMessage, SnackbarKey} from 'notistack';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export type NotificationItem = {
    message: SnackbarMessage;
    options?: OptionsObject;
    key: SnackbarKey;
    dismissed?: boolean;
};
