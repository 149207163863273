import {useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {Fallback} from '../../components/fallback/fallback.component';
import {AuthContext, IAuthState, IAuthResult} from '../../providers/auth-provider';
import {unsetUser} from '../../redux/user/user.actions';
import {ErrorLike} from '../../utils/errorLike';

/**
 * Authentication Sign-Out completion callback page. Authentication sign-out is started from {@link NavigationMenu}.
 *
 * @returns {*}
 */
const AuthSignOutCallback = () => {
    const {logout, isAuthenticated} = useContext(AuthContext);
    const [authState, setAuthState] = useState({} as IAuthState);
    const [authResult, setAuthResult] = useState({} as IAuthResult);

    console.debug('>>> authsignoutcallback rendering authstate=%o', authState);

    const dispatch = useDispatch();

    useEffect(() => {
        // Initialize authentication state on first render.
        // Use the isAuthenticated promise to determine if the user is authenticated.
        isAuthenticated()
            .then((status) => {
                // Update final authentication status of user.
                setAuthState((prevState) => ({...prevState, isAuthenticated: status}));
            })
            .finally(() => {
                // Flag authentication status as completely initialized and ready to use.
                setAuthState((prevState) => ({...prevState, isInitialized: true}));
            });
    }, [isAuthenticated]);

    useEffect(() => {
        if (!authState.isInitialized) {
            // Wait until authentication has been initialized.
            return;
        }

        // Invalidate the currently logged-in user.
        if (authState.isAuthenticated) {
            try {
                logout();
                dispatch(unsetUser());
            } catch (e) {
                // WARNING! authentication exceptions must be caught; otherwise, the error will abort the application.
                const result: IAuthResult = {authError: ErrorLike.Copy(e)};
                setAuthResult(result);
            }
        } else {
            window.location.pathname = '/';
        }
    }, [authState.isInitialized, authState.isAuthenticated, logout]);

    // If authentication failed, notify the user.
    if (authResult.authError) {
        // Redirect with an authentication error and save the current location in the browser history.
        // The authentication result is passed in the to.state property but ends up in props.location.state.
        return <Redirect to={{pathname: '/session-error', state: {authResult: authResult, from: location}}} push={true} />;
    }

    return <Fallback />;
};

export default AuthSignOutCallback;
