import {Comment} from '../../models/Comment';
import {UpdateCommentDto} from '../../models/dtos/update-comment.dto';

import {
    COMMENTS_ERROR,
    COMMENTS_REQUEST,
    COMMENTS_RESPONSE,
    CREATE_COMMENT,
    CREATE_COMMENT_RESPONSE,
    DELETE_COMMENT,
    DELETE_COMMENT_RESPONSE,
    FETCH_COMMENTS,
    UPDATE_COMMENT,
    UPDATE_COMMENT_RESPONSE,
    CREATE_REPAIR_LINE_COMMENT,
    UPDATE_REPAIR_LINE_COMMENT,
    DELETE_REPAIR_LINE_COMMENT,
    REPAIR_LINE_COMMENTS_RESPONSE,
    CREATE_REPAIR_LINE_COMMENT_RESPONSE,
    UPDATE_REPAIR_LINE_COMMENT_RESPONSE,
    DELETE_REPAIR_LINE_COMMENT_RESPONSE,
    FetchComments,
    CreateComment,
    CreateRepairLineComment,
} from './comment.types';

export const commentsRequest = () => ({
    type: COMMENTS_REQUEST,
});

export const createComment = (data: CreateComment) => ({
    type: CREATE_COMMENT,
    payload: data,
});

export const updateComment = (data: UpdateCommentDto) => ({
    type: UPDATE_COMMENT,
    payload: data,
});

export const deleteComment = (commentID: number, workOrderID: number) => ({
    type: DELETE_COMMENT,
    payload: {commentID, workOrderID},
});

export const commentsError = (error: Error | unknown) => ({
    type: COMMENTS_ERROR,
    payload: error,
});

export const commentsResponse = (data: Comment[]) => ({
    type: COMMENTS_RESPONSE,
    payload: data,
});

export const repairLineCommentsResponse = (repairLineID: number, comments: Comment[]) => ({
    type: REPAIR_LINE_COMMENTS_RESPONSE,
    payload: {repairLineID, comments},
});

export const fetchComments = (data: FetchComments) => ({
    type: FETCH_COMMENTS,
    payload: data,
});

export const createCommentResponse = (data: Comment) => ({
    type: CREATE_COMMENT_RESPONSE,
    payload: data,
});

export const updateCommentResponse = (data: Comment) => ({
    type: UPDATE_COMMENT_RESPONSE,
    payload: data,
});

export const deleteCommentResponse = (commentID: number) => ({
    type: DELETE_COMMENT_RESPONSE,
    payload: commentID,
});

export const updateRepairLineComment = (data: UpdateCommentDto) => ({
    type: UPDATE_REPAIR_LINE_COMMENT,
    payload: data,
});

export const deleteRepairLineComment = (commentID: number, repairLineID: number, workOrderID: number) => ({
    type: DELETE_REPAIR_LINE_COMMENT,
    payload: {commentID, workOrderID, repairLineID},
});

export const createRepairLineComment = (data: CreateRepairLineComment) => ({
    type: CREATE_REPAIR_LINE_COMMENT,
    payload: data,
});

export const createRepairLineCommentResponse = (repairLineID: number, comment: Comment) => ({
    type: CREATE_REPAIR_LINE_COMMENT_RESPONSE,
    payload: {repairLineID, comment},
});

export const updateRepairLineCommentResponse = (repairLineID: number, comment: Comment) => ({
    type: UPDATE_REPAIR_LINE_COMMENT_RESPONSE,
    payload: {repairLineID, comment},
});

export const deleteRepairLineCommentResponse = (repairLineID: number, commentID: number) => ({
    type: DELETE_REPAIR_LINE_COMMENT_RESPONSE,
    payload: {repairLineID, commentID},
});
