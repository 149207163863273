export const FETCH_PARTS = 'FETCH_PARTS';
export const PARTS_REQUEST = 'PARTS_REQUEST';
export const PARTS_RESPONSE = 'PARTS_RESPONSE';
export const PARTS_ERROR = 'PARTS_ERROR';
export const FETCH_REPAIR_LINE_PART = 'FETCH_REPAIR_LINE_PART';
export const CREATE_REPAIR_LINE_PART = 'CREATE_REPAIR_LINE_PART';
export const DELETE_REPAIR_LINE_PART = 'DELETE_REPAIR_LINE_PART';
export const UPDATE_REPAIR_LINE_PART = 'UPDATE_REPAIR_LINE_PART';
export const FETCH_REPAIR_LINE_PART_RESPONSE = 'FETCH_REPAIR_LINE_PART_RESPONSE';
export const CREATE_REPAIR_LINE_PART_RESPONSE = 'CREATE_REPAIR_LINE_PART_RESPONSE';
export const UPDATE_REPAIR_LINE_PART_RESPONSE = 'UPDATE_REPAIR_LINE_PART_RESPONSE';
export const DELETE_REPAIR_LINE_PART_RESPONSE = 'DELETE_REPAIR_LINE_PART_RESPONSE';
