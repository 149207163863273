import {OfflineEstimate} from '../../models/OfflineEstimate';
import {ReduxAction} from '../../models/ReduxAction';
import {OfflineEstimateSentStatus} from '../../models/enumerations/OfflineEstimateSentStatus';

import {
    OFFLINE_ESTIMATES_RESPONSE,
    POLL_ERROR,
    POLL_FINISH,
    POLL_RESPONSE,
    POLL_START,
    PUT_OFFLINE_ESTIMATE,
    REMOVE_OFFLINE_ESTIMATE,
    SELECT_OFFLINE_ESTIMATE,
    UNSELECT_OFFLINE_ESTIMATE,
    UPLOAD_ERROR,
    UPLOAD_FINISH,
    UPLOAD_RESPONSE,
    UPLOAD_START,
} from './offline-estimate.types';

export interface OfflineEstimateState {
    estimates: OfflineEstimate[];
    selectedOfflineEstimate: OfflineEstimate | null;
    uploading: boolean;
    polling: boolean;
}

const initialState: OfflineEstimateState = {
    estimates: [],
    selectedOfflineEstimate: null,
    uploading: false,
    polling: false,
};

export default function offlineEstimateReducer(state = initialState, action: ReduxAction): OfflineEstimateState {
    switch (action.type) {
        case OFFLINE_ESTIMATES_RESPONSE: {
            return {
                ...state,
                estimates: action.payload.offlineEstimates,
            };
        }
        case PUT_OFFLINE_ESTIMATE: {
            return {
                ...state,
                selectedOfflineEstimate:
                    state.selectedOfflineEstimate?.ID === action.payload.offlineEstimate.ID
                        ? action.payload.offlineEstimate
                        : state.selectedOfflineEstimate,
                estimates: state.estimates.map((estimate) =>
                    estimate.ID === action.payload.offlineEstimate.ID ? {...estimate, ...action.payload.offlineEstimate} : estimate,
                ),
            };
        }
        case REMOVE_OFFLINE_ESTIMATE: {
            return {
                ...state,
                selectedOfflineEstimate:
                    state.selectedOfflineEstimate?.ID === action.payload.offlineEstimateID ? null : state.selectedOfflineEstimate,
                estimates: state.estimates.filter((estimate) => estimate.ID !== action.payload.offlineEstimateID),
            };
        }
        case SELECT_OFFLINE_ESTIMATE: {
            return {
                ...state,
                selectedOfflineEstimate: action.payload.offlineEstimate,
            };
        }
        case UNSELECT_OFFLINE_ESTIMATE: {
            return {
                ...state,
                selectedOfflineEstimate: null,
            };
        }
        case UPLOAD_START: {
            return {
                ...state,
                selectedOfflineEstimate:
                    state.selectedOfflineEstimate != null && state.selectedOfflineEstimate?.ID === action.payload.offlineEstimateID
                        ? {
                              ...state.selectedOfflineEstimate,
                              SentStatus: OfflineEstimateSentStatus.Uploading.ID,
                              UploadStartDate: action.payload.startDate,
                          }
                        : state.selectedOfflineEstimate,
                estimates: state.estimates.map((estimate) =>
                    estimate.ID === action.payload.offlineEstimateID
                        ? {...estimate, SentStatus: OfflineEstimateSentStatus.Uploading.ID, UploadStartDate: action.payload.startDate}
                        : estimate,
                ),
                uploading: true,
            };
        }
        case UPLOAD_FINISH: {
            return {
                ...state,
                uploading: false,
            };
        }
        case UPLOAD_RESPONSE: {
            return {
                ...state,
                selectedOfflineEstimate:
                    state.selectedOfflineEstimate != null && state.selectedOfflineEstimate?.ID === action.payload.offlineEstimateID
                        ? {
                              ...state.selectedOfflineEstimate,
                              SentStatus: OfflineEstimateSentStatus.Processing.ID,
                              BackgroundTaskID: action.payload.backgroundTaskID,
                          }
                        : state.selectedOfflineEstimate,
                estimates: state.estimates.map((estimate) =>
                    estimate.ID === action.payload.offlineEstimateID
                        ? {...estimate, SentStatus: OfflineEstimateSentStatus.Processing.ID, BackgroundTaskID: action.payload.backgroundTaskID}
                        : estimate,
                ),
            };
        }
        case UPLOAD_ERROR: {
            return {
                ...state,
                selectedOfflineEstimate:
                    state.selectedOfflineEstimate != null && state.selectedOfflineEstimate?.ID === action.payload.offlineEstimateID
                        ? {...state.selectedOfflineEstimate, SentStatus: OfflineEstimateSentStatus.Unsent.ID}
                        : state.selectedOfflineEstimate,
                estimates: state.estimates.map((estimate) =>
                    estimate.ID === action.payload.offlineEstimateID ? {...estimate, SentStatus: OfflineEstimateSentStatus.Unsent.ID} : estimate,
                ),
            };
        }
        case POLL_RESPONSE: {
            return {
                ...state,
                selectedOfflineEstimate:
                    state.selectedOfflineEstimate != null && state.selectedOfflineEstimate?.ID === action.payload.oldID
                        ? {
                              ...state.selectedOfflineEstimate,
                              WorkOrderID: action.payload.newID,
                              Status: action.payload.status,
                              SentStatus: action.payload.sentStatus,
                              BackgroundTaskID: undefined,
                          }
                        : state.selectedOfflineEstimate,
                estimates: state.estimates.map((estimate) =>
                    estimate.ID === action.payload.oldID
                        ? {
                              ...estimate,
                              WorkOrderID: action.payload.newID,
                              SentStatus: action.payload.sentStatus,
                              Status: action.payload.status,
                              BackgroundTaskID: undefined,
                          }
                        : estimate,
                ),
                polling: true,
            };
        }
        case POLL_ERROR: {
            return {
                ...state,
                selectedOfflineEstimate:
                    state.selectedOfflineEstimate != null && state.selectedOfflineEstimate?.ID === action.payload.offlineEstimateID
                        ? {...state.selectedOfflineEstimate, SentStatus: OfflineEstimateSentStatus.Error.ID, BackgroundTaskID: undefined}
                        : state.selectedOfflineEstimate,
                estimates: state.estimates.map((estimate) =>
                    estimate.ID === action.payload.offlineEstimateID
                        ? {...estimate, SentStatus: OfflineEstimateSentStatus.Error.ID, BackgroundTaskID: undefined}
                        : estimate,
                ),
                polling: true,
            };
        }
        case POLL_START: {
            return {
                ...state,
                polling: true,
            };
        }
        case POLL_FINISH: {
            return {
                ...state,
                polling: false,
            };
        }
        default:
            return state;
    }
}
