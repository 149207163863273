import config from '../config';
import localStorage, {LocalStorageKeys} from '../services/local-storage';

const refreshDBIntervalMinutes = config.refreshDBIntervalMinutes;

const getLastUpdateTime = (refreshKey: LocalStorageKeys, updateKey: LocalStorageKeys): Date | null => {
    const lastRefresh = Number(localStorage.get(refreshKey));
    return !lastRefresh || Date.now() < lastRefresh + 1000 * 60 * refreshDBIntervalMinutes ? localStorage.get(updateKey) : null;
};

export default getLastUpdateTime;
