import {t} from 'i18next';

import {ReduxAction} from '../models/ReduxAction';
import {WorkOrder} from '../models/WorkOrder';
import {Permission} from '../models/enumerations/Permission';
import {WorkOrderStatus} from '../models/enumerations/WorkOrderStatus';
import {rejectWorkOrder, updateWorkOrderStatus} from '../redux/work-order/work-order.actions';

enum Group {
    Group_1 = 1,
    Group_2 = 2,
    Group_3 = 3,
}

export enum WorkOrderActionType {
    CLOSE,
    EXPIRE,
    REJECT,
    CANCEL,
    APPROVE,
    REPAIRS_DONE,
    REASSIGN_SHOP,
    DO_NOT_REPAIR,
    SUBMIT_FOR_APPROVAL,
    ESCALATION_WORK_APPROVED,
}

export type WorkOrderAction = {
    label: string;
    group: Group;
    title?: string;
    submitButtonLabel?: string;
    isStatusRejected?: boolean;
    actionType: WorkOrderActionType;
    color: 'red' | 'green' | 'darkRed';
    action?: (data?: unknown) => ReduxAction;
    permissions?: Permission[];
    event?: () => void;
};

const useNextWorkOrderActions = (workOrder: WorkOrder, toggleShopListModal?: () => void): WorkOrderAction[] => {
    switch (workOrder.Status) {
        case WorkOrderStatus.Open.Status: {
            return [
                {
                    label: t('submitted_for_approval_action'),
                    color: 'green',
                    group: Group.Group_1,
                    action: updateWorkOrderStatus.bind(null, {
                        workOrderID: workOrder.ID,
                        status: WorkOrderStatus.SubmittedForApproval.Status,
                    }),
                    actionType: WorkOrderActionType.SUBMIT_FOR_APPROVAL,
                    permissions: [Permission.WORK_ORDER_SUBMITTED_FOR_APPROVAL],
                },
                {
                    label: t('reassign_shop'),
                    color: 'green',
                    group: Group.Group_1,
                    event: toggleShopListModal,
                    actionType: WorkOrderActionType.REASSIGN_SHOP,
                    permissions: [Permission.WORK_ORDER_REASSIGN_SHOP],
                },
                {
                    label: t('work_rejected_action'),
                    isStatusRejected: true,
                    color: 'red',
                    group: Group.Group_2,
                    action: () =>
                        rejectWorkOrder({
                            workOrderID: workOrder.ID,
                            status: WorkOrderStatus.WorkRejected.Status,
                        }),
                    actionType: WorkOrderActionType.REJECT,
                    permissions: [Permission.WORK_ORDER_REJECTED],
                },
                {
                    label: t('canceled_action'),
                    isStatusRejected: true,
                    color: 'red',
                    group: Group.Group_2,
                    submitButtonLabel: t('okay'),
                    title: t('are_you_sure_cancel_estimate'),
                    action: () =>
                        rejectWorkOrder({
                            workOrderID: workOrder.ID,
                            status: WorkOrderStatus.Canceled.Status,
                        }),
                    actionType: WorkOrderActionType.CANCEL,
                    permissions: [Permission.WORK_ORDER_CANCELED],
                },
                {
                    label: t('closed_action'),
                    isStatusRejected: true,
                    color: 'red',
                    group: Group.Group_2,
                    submitButtonLabel: t('closed_action'),
                    title: t('are_you_sure_close_estimate'),
                    action: () =>
                        rejectWorkOrder({
                            workOrderID: workOrder.ID,
                            status: WorkOrderStatus.Closed.Status,
                        }),
                    actionType: WorkOrderActionType.CLOSE,
                    permissions: [Permission.WORK_ORDER_CLOSED],
                },
                {
                    label: t('expired_action'),
                    isStatusRejected: true,
                    color: 'red',
                    group: Group.Group_2,
                    submitButtonLabel: t('expired_action'),
                    title: t('are_you_sure_expire_estimate'),
                    action: () =>
                        rejectWorkOrder({
                            workOrderID: workOrder.ID,
                            status: WorkOrderStatus.Expired.Status,
                        }),
                    actionType: WorkOrderActionType.EXPIRE,
                    permissions: [Permission.WORK_ORDER_EXPIRED],
                },
                {
                    label: t('do_not_repair_action'),
                    isStatusRejected: true,
                    color: 'darkRed',
                    group: Group.Group_3,
                    submitButtonLabel: t('do_not_repair'),
                    title: t('are_you_sure_mark_this_unit_do_not_repair'),
                    action: (sendExternalIntegrationMessage: unknown) => {
                        return rejectWorkOrder({
                            workOrderID: workOrder.ID,
                            status: WorkOrderStatus.DoNotRepair.Status,
                            sendExternalIntegrationMessage: Boolean(sendExternalIntegrationMessage),
                        });
                    },
                    actionType: WorkOrderActionType.DO_NOT_REPAIR,
                    permissions: [Permission.WORK_ORDER_DO_NOT_REPAIR],
                },
            ];
        }
        case WorkOrderStatus.SubmittedForApproval.Status: {
            const actions: WorkOrderAction[] = [
                {
                    label: t('work_approved_action'),
                    color: 'green',
                    group: Group.Group_1,
                    action: updateWorkOrderStatus.bind(null, {
                        workOrderID: workOrder.ID,
                        status: WorkOrderStatus.WorkApproved.Status,
                    }),
                    actionType: WorkOrderActionType.APPROVE,
                    permissions: [Permission.WORK_ORDER_WORK_APPROVED],
                },
                {
                    label: t('pending_escalation_action'),
                    color: 'green',
                    group: Group.Group_1,
                    action: updateWorkOrderStatus.bind(null, {
                        workOrderID: workOrder.ID,
                        status: WorkOrderStatus.PendingEscalation.Status,
                    }),
                    actionType: WorkOrderActionType.CANCEL,
                    permissions: [Permission.WORK_ORDER_ESCALATION],
                },
                {
                    label: t('work_rejected_action'),
                    isStatusRejected: true,
                    color: 'red',
                    group: Group.Group_2,
                    action: () =>
                        rejectWorkOrder({
                            workOrderID: workOrder.ID,
                            status: WorkOrderStatus.WorkRejected.Status,
                        }),
                    actionType: WorkOrderActionType.REJECT,
                    permissions: [Permission.WORK_ORDER_REJECTED],
                },
                {
                    label: t('canceled_action'),
                    isStatusRejected: true,
                    color: 'red',
                    group: Group.Group_2,
                    submitButtonLabel: t('okay'),
                    title: t('are_you_sure_cancel_estimate'),
                    action: () =>
                        rejectWorkOrder({
                            workOrderID: workOrder.ID,
                            status: WorkOrderStatus.Canceled.Status,
                        }),
                    actionType: WorkOrderActionType.CANCEL,
                    permissions: [Permission.WORK_ORDER_CANCELED],
                },
                {
                    label: t('closed_action'),
                    isStatusRejected: true,
                    color: 'red',
                    group: Group.Group_2,
                    submitButtonLabel: t('closed_action'),
                    title: t('are_you_sure_close_estimate'),
                    action: () =>
                        rejectWorkOrder({
                            workOrderID: workOrder.ID,
                            status: WorkOrderStatus.Closed.Status,
                        }),
                    actionType: WorkOrderActionType.CLOSE,
                    permissions: [Permission.WORK_ORDER_CLOSED],
                },
                {
                    label: t('expired_action'),
                    isStatusRejected: true,
                    color: 'red',
                    group: Group.Group_2,
                    submitButtonLabel: t('expired_action'),
                    title: t('are_you_sure_expire_estimate'),
                    action: () =>
                        rejectWorkOrder({
                            workOrderID: workOrder.ID,
                            status: WorkOrderStatus.Expired.Status,
                        }),
                    actionType: WorkOrderActionType.EXPIRE,
                    permissions: [Permission.WORK_ORDER_EXPIRED],
                },
                {
                    label: t('do_not_repair_action'),
                    isStatusRejected: true,
                    color: 'darkRed',
                    group: Group.Group_3,
                    submitButtonLabel: t('do_not_repair'),
                    title: t('are_you_sure_mark_this_unit_do_not_repair'),
                    action: (sendExternalIntegrationMessage: unknown) =>
                        rejectWorkOrder({
                            workOrderID: workOrder.ID,
                            status: WorkOrderStatus.DoNotRepair.Status,
                            sendExternalIntegrationMessage: Boolean(sendExternalIntegrationMessage),
                        }),
                    actionType: WorkOrderActionType.DO_NOT_REPAIR,
                    permissions: [Permission.WORK_ORDER_DO_NOT_REPAIR],
                },
            ];

            if (toggleShopListModal) {
                actions.push({
                    label: t('reassign_shop'),
                    color: 'green',
                    group: Group.Group_1,
                    event: toggleShopListModal,
                    actionType: WorkOrderActionType.REASSIGN_SHOP,
                    permissions: [Permission.WORK_ORDER_REASSIGN_SHOP],
                });
            }

            return actions;
        }
        case WorkOrderStatus.PendingEscalation.Status: {
            return [
                {
                    label: t('work_approved_action'),
                    color: 'green',
                    group: Group.Group_1,
                    action: updateWorkOrderStatus.bind(null, {
                        workOrderID: workOrder.ID,
                        status: WorkOrderStatus.WorkApproved.Status,
                    }),
                    actionType: WorkOrderActionType.ESCALATION_WORK_APPROVED,
                    permissions: [Permission.WORK_ORDER_ESCALATION_WORK_APPROVED],
                },
            ];
        }
        case WorkOrderStatus.WorkApproved.Status: {
            return [
                {
                    label: t('repairs_done_action'),
                    color: 'green',
                    group: Group.Group_1,
                    action: updateWorkOrderStatus.bind(null, {
                        workOrderID: workOrder.ID,
                        status: WorkOrderStatus.RepairsDone.Status,
                    }),
                    actionType: WorkOrderActionType.REPAIRS_DONE,
                    permissions: [Permission.WORK_ORDER_REPAIRS_DONE],
                },
                {
                    label: t('work_rejected_action'),
                    isStatusRejected: true,
                    color: 'red',
                    group: Group.Group_2,
                    action: () =>
                        rejectWorkOrder({
                            workOrderID: workOrder.ID,
                            status: WorkOrderStatus.WorkRejected.Status,
                        }),
                    actionType: WorkOrderActionType.REJECT,
                    permissions: [Permission.WORK_ORDER_REJECTED],
                },
                {
                    label: t('canceled_action'),
                    isStatusRejected: true,
                    color: 'red',
                    group: Group.Group_2,
                    submitButtonLabel: t('okay'),
                    title: t('are_you_sure_cancel_work_order'),
                    action: () =>
                        rejectWorkOrder({
                            workOrderID: workOrder.ID,
                            status: WorkOrderStatus.Canceled.Status,
                        }),
                    actionType: WorkOrderActionType.CANCEL,
                    permissions: [Permission.WORK_ORDER_CANCELED],
                },
                {
                    label: t('closed_action'),
                    isStatusRejected: true,
                    color: 'red',
                    group: Group.Group_2,
                    submitButtonLabel: t('closed_action'),
                    title: t('are_you_sure_close_work_order'),
                    action: () =>
                        rejectWorkOrder({
                            workOrderID: workOrder.ID,
                            status: WorkOrderStatus.Closed.Status,
                        }),
                    actionType: WorkOrderActionType.CLOSE,
                    permissions: [Permission.WORK_ORDER_CLOSED],
                },
                {
                    label: t('expired_action'),
                    isStatusRejected: true,
                    color: 'red',
                    group: Group.Group_2,
                    submitButtonLabel: t('expired_action'),
                    title: t('are_you_sure_expire_work_order'),
                    action: () =>
                        rejectWorkOrder({
                            workOrderID: workOrder.ID,
                            status: WorkOrderStatus.Expired.Status,
                        }),
                    actionType: WorkOrderActionType.EXPIRE,
                    permissions: [Permission.WORK_ORDER_EXPIRED],
                },
                {
                    label: t('do_not_repair_action'),
                    isStatusRejected: true,
                    color: 'darkRed',
                    group: Group.Group_3,
                    submitButtonLabel: t('do_not_repair'),
                    title: t('are_you_sure_mark_this_unit_do_not_repair'),
                    action: (sendExternalIntegrationMessage: unknown) =>
                        rejectWorkOrder({
                            workOrderID: workOrder.ID,
                            status: WorkOrderStatus.DoNotRepair.Status,
                            sendExternalIntegrationMessage: Boolean(sendExternalIntegrationMessage),
                        }),
                    actionType: WorkOrderActionType.DO_NOT_REPAIR,
                    permissions: [Permission.WORK_ORDER_DO_NOT_REPAIR],
                },
            ];
        }
        case WorkOrderStatus.RepairsDone.Status: {
            return [
                {
                    label: t('approved_action'),
                    color: 'green',
                    group: Group.Group_1,
                    action: updateWorkOrderStatus.bind(null, {
                        workOrderID: workOrder.ID,
                        status: WorkOrderStatus.Approved.Status,
                    }),
                    actionType: WorkOrderActionType.APPROVE,
                    permissions: [Permission.WORK_ORDER_APPROVED],
                },
            ];
        }
        default: {
            return [];
        }
    }
};

export default useNextWorkOrderActions;
