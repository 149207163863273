import {OfflineEstimate} from '../../models/OfflineEstimate';
import {CreateOfflineEstimateDto} from '../../models/dtos/create-offline-estimate.dto';
import {FetchOfflineEstimateDto} from '../../models/dtos/fetch-offline-estimate.dto';

import {
    FETCH_OFFLINE_ESTIMATES,
    CREATE_OFFLINE_ESTIMATE,
    UPDATE_OFFLINE_ESTIMATE,
    DISCARD_OFFLINE_ESTIMATE,
    OFFLINE_ESTIMATES_RESPONSE,
    PUT_OFFLINE_ESTIMATE,
    QUEUE_OFFLINE_ESTIMATES,
    DEQUEUE_OFFLINE_ESTIMATE,
    REMOVE_OFFLINE_ESTIMATE,
    SEND_ALL_UNSENT_OFFLINE_ESTIMATES,
    FETCH_OFFLINE_ESTIMATE,
    SELECT_OFFLINE_ESTIMATE,
    UNSELECT_OFFLINE_ESTIMATE,
    CreateOfflineEstimateFileDto,
    DeleteOfflineEstimateFileDto,
    CREATE_OFFLINE_ESTIMATE_FILE,
    DELETE_OFFLINE_ESTIMATE_FILE,
    CREATE_OFFLINE_ESTIMATE_LINE,
    UPDATE_OFFLINE_ESTIMATE_LINE,
    DELETE_OFFLINE_ESTIMATE_LINE,
    CreateOfflineEstimateLineDto,
    UpdateOfflineEstimateLineDto,
    CREATE_OFFLINE_ESTIMATE_PART,
    UPDATE_OFFLINE_ESTIMATE_PART,
    DELETE_OFFLINE_ESTIMATE_PART,
    CreateOfflineEstimatePartDto,
    UpdateOfflineEstimatePartDto,
    CREATE_OFFLINE_ESTIMATE_COMMENT,
    UPDATE_OFFLINE_ESTIMATE_COMMENT,
    DELETE_OFFLINE_ESTIMATE_COMMENT,
    CreateOfflineEstimateCommentDto,
    UpdateOfflineEstimateCommentDto,
    DeleteOfflineEstimateCommentDto,
    POLL_OFFLINE_ESTIMATE_TASKS,
    UPLOAD_ERROR,
    POLL_ERROR,
    UPLOAD_RESPONSE,
    POLL_RESPONSE,
    POLL_START,
    POLL_FINISH,
    UPLOAD_START,
    UPLOAD_FINISH,
    SEND_QUEUED_OFFLINE_ESTIMATES,
    COPY_OFFLINE_ESTIMATE,
    UPLOAD_OFFLINE_ESTIMATES,
    CLEAN_UP_OFFLINE_DATA,
} from './offline-estimate.types';

export const fetchOfflineEstimates = () => ({
    type: FETCH_OFFLINE_ESTIMATES,
});

export const cleanUpOfflineData = () => ({
    type: CLEAN_UP_OFFLINE_DATA,
});

export const createOfflineEstimate = (dto: CreateOfflineEstimateDto) => ({
    type: CREATE_OFFLINE_ESTIMATE,
    payload: dto,
});

export const updateOfflineEstimate = (ID: number, data: Partial<OfflineEstimate>) => ({
    type: UPDATE_OFFLINE_ESTIMATE,
    payload: {ID, data},
});

export const offlineEstimatesResponse = (data: OfflineEstimate[]) => ({
    type: OFFLINE_ESTIMATES_RESPONSE,
    payload: {offlineEstimates: data},
});

export const sendAllUnsentOfflineEstimates = () => ({
    type: SEND_ALL_UNSENT_OFFLINE_ESTIMATES,
});

export const uploadOfflineEstimate = (offlineEstimateIDs: number[]) => ({
    type: UPLOAD_OFFLINE_ESTIMATES,
    payload: {offlineEstimateIDs},
});

export const queueOfflineEstimates = (offlineEstimateIDs: number[]) => ({
    type: QUEUE_OFFLINE_ESTIMATES,
    payload: {offlineEstimateIDs},
});

export const dequeueOfflineEstimate = (offlineEstimateID: number) => ({
    type: DEQUEUE_OFFLINE_ESTIMATE,
    payload: {offlineEstimateID},
});

export const putOfflineEstimate = (offlineEstimate: OfflineEstimate) => ({
    type: PUT_OFFLINE_ESTIMATE,
    payload: {offlineEstimate},
});

export const discardOfflineEstimate = (offlineEstimateID: number) => ({
    type: DISCARD_OFFLINE_ESTIMATE,
    payload: {offlineEstimateID},
});

export const copyOfflineEstimate = (offlineEstimateID: number) => ({
    type: COPY_OFFLINE_ESTIMATE,
    payload: {offlineEstimateID},
});

export const removeOfflineEstimate = (offlineEstimateID: number) => ({
    type: REMOVE_OFFLINE_ESTIMATE,
    payload: {offlineEstimateID},
});

export const fetchOfflineEstimate = (payload: FetchOfflineEstimateDto) => ({
    type: FETCH_OFFLINE_ESTIMATE,
    payload,
});

export const selectOfflineEstimate = (offlineEstimate: OfflineEstimate) => ({
    type: SELECT_OFFLINE_ESTIMATE,
    payload: {offlineEstimate},
});

export const unselectOfflineEstimate = () => ({
    type: UNSELECT_OFFLINE_ESTIMATE,
});

export const createOfflineEstimateFile = (dto: CreateOfflineEstimateFileDto) => ({
    type: CREATE_OFFLINE_ESTIMATE_FILE,
    payload: dto,
});

export const deleteOfflineEstimateFile = (dto: DeleteOfflineEstimateFileDto) => ({
    type: DELETE_OFFLINE_ESTIMATE_FILE,
    payload: dto,
});

export const createOfflineEstimateLine = (dto: CreateOfflineEstimateLineDto) => ({
    type: CREATE_OFFLINE_ESTIMATE_LINE,
    payload: dto,
});

export const updateOfflineEstimateLine = (dto: UpdateOfflineEstimateLineDto) => ({
    type: UPDATE_OFFLINE_ESTIMATE_LINE,
    payload: dto,
});

export const deleteOfflineEstimateLine = (offlineEstimateID: number, offlineEstimateLineID: number) => ({
    type: DELETE_OFFLINE_ESTIMATE_LINE,
    payload: {offlineEstimateID, offlineEstimateLineID},
});

export const createOfflineEstimatePart = (dto: CreateOfflineEstimatePartDto) => ({
    type: CREATE_OFFLINE_ESTIMATE_PART,
    payload: dto,
});

export const updateOfflineEstimatePart = (dto: UpdateOfflineEstimatePartDto) => ({
    type: UPDATE_OFFLINE_ESTIMATE_PART,
    payload: dto,
});

export const deleteOfflineEstimatePart = (offlineEstimateID: number, offlineEstimateLineID: number, partID: number) => ({
    type: DELETE_OFFLINE_ESTIMATE_PART,
    payload: {offlineEstimateID, offlineEstimateLineID, partID},
});

export const createOfflineEstimateComment = (dto: CreateOfflineEstimateCommentDto) => ({
    type: CREATE_OFFLINE_ESTIMATE_COMMENT,
    payload: dto,
});

export const updateOfflineEstimateComment = (dto: UpdateOfflineEstimateCommentDto) => ({
    type: UPDATE_OFFLINE_ESTIMATE_COMMENT,
    payload: dto,
});

export const deleteOfflineEstimateComment = (dto: DeleteOfflineEstimateCommentDto) => ({
    type: DELETE_OFFLINE_ESTIMATE_COMMENT,
    payload: dto,
});

export const pollOfflineEstimateTasks = (startImmediately?: boolean) => ({
    type: POLL_OFFLINE_ESTIMATE_TASKS,
    payload: startImmediately,
});

export const sendQueuedOfflineEstimates = () => ({
    type: SEND_QUEUED_OFFLINE_ESTIMATES,
});

export const uploadStart = (offlineEstimateID: number, startDate: Date) => ({
    type: UPLOAD_START,
    payload: {offlineEstimateID, startDate},
});

export const uploadFinish = () => ({
    type: UPLOAD_FINISH,
});

export const uploadError = (offlineEstimateID: number) => ({
    type: UPLOAD_ERROR,
    payload: {offlineEstimateID},
});

export const pollError = (offlineEstimateID: number) => ({
    type: POLL_ERROR,
    payload: {offlineEstimateID},
});

export const uploadResponse = (offlineEstimateID: number, backgroundTaskID: number) => ({
    type: UPLOAD_RESPONSE,
    payload: {offlineEstimateID, backgroundTaskID},
});

export const pollResponse = (oldID: number, newID: number | undefined, status: number | undefined, sentStatus: number) => ({
    type: POLL_RESPONSE,
    payload: {oldID, newID, status, sentStatus},
});

export const pollStart = () => ({
    type: POLL_START,
});

export const pollFinish = () => ({
    type: POLL_FINISH,
});
