import {SelectEventArgs} from '@syncfusion/ej2-lists/src/list-view/list-view';
import {ListViewComponent} from '@syncfusion/ej2-react-lists';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {MessageListItem} from '../../components/message-list-item/message-list-item.component';
import {useHeader} from '../../hooks/use-header';
import {MessageDto} from '../../models/dtos/message.dto';
import {readMessage} from '../../redux/message/message.actions';
import {selectMessages, selectReadMessages} from '../../redux/message/message.selectors';

import {HeaderTitle} from './message-list.styles';

const MessageList = () => {
    const dispatch = useDispatch();
    const messages = useSelector(selectMessages);
    const readMessageIds = useSelector(selectReadMessages);

    const renderMiddleElement = useCallback(() => <HeaderTitle>Messages</HeaderTitle>, []);

    useHeader({
        backToPath: '/',
        middleElement: renderMiddleElement,
    });

    const dataSource: MessageDto[] = useMemo(
        () =>
            messages
                .map((message) => ({
                    id: message.ID,
                    message: message.Message,
                    endDate: message.EffectiveEndDate,
                    startDate: message.EffectiveStartDate,
                    isRead: readMessageIds.has(message.ID),
                }))
                .sort((previous, next) => next.id - previous.id),
        [readMessageIds, messages],
    );

    const onSelect = useCallback(
        (e: SelectEventArgs) => {
            const {id, isRead} = dataSource[e.index];

            if (!isRead) {
                dispatch(readMessage(id));
            }
        },
        [dataSource],
    );

    useEffect(() => {
        return () => {
            dataSource.forEach(({id, isRead}) => {
                if (!isRead) {
                    dispatch(readMessage(id));
                }
            });
        };
    }, []);

    return <ListViewComponent select={onSelect} template={MessageListItem} cssClass="e-list-template" dataSource={dataSource} />;
};

export default MessageList;
