import {StationCompanyRelation} from '../../models/StationCompanyRelation';

import {SHOPS_ERROR, SHOPS_REQUEST, SHOPS_RESPONSE, FETCH_SHOPS, SELECT_SHOP, UNSET_SHOP} from './shop.types';

/** Action to process the reponse for a list of fetched Shops. */
export const shopsResponse = (data: StationCompanyRelation[]) => ({
    type: SHOPS_RESPONSE,
    payload: data,
});

/** Action to process an error response for shop requests. */
export const shopsError = (error: Error | unknown) => ({
    type: SHOPS_ERROR,
    payload: error,
});

/** Base action for shop requests. */
export const shopsRequest = () => ({
    type: SHOPS_REQUEST,
});

/** Action to fetch a list of Shops from the database. */
export const fetchShops = ({onFinishCallback}: {onFinishCallback?: (shops: StationCompanyRelation[]) => void} = {}) => ({
    type: FETCH_SHOPS,
    payload: {onFinishCallback},
});

/** Action to update the currently selected Shop. */
export const selectShop = (shop: StationCompanyRelation) => ({
    type: SELECT_SHOP,
    payload: {shop},
});

export const unsetShop = () => ({
    type: UNSET_SHOP,
});
