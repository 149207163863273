import {SnackbarKey, OptionsObject} from 'notistack';

import {CLOSE_SNACKBAR, ENQUEUE_SNACKBAR, REMOVE_SNACKBAR} from './notistack.types';

export const enqueueSnackbar = (message: string, options?: OptionsObject) => {
    const key = options?.key;

    return {
        type: ENQUEUE_SNACKBAR,
        payload: {
            message,
            options,
            key: key || new Date().getTime() + Math.random(),
        },
    };
};

export const closeSnackbar = (key?: SnackbarKey) => ({
    type: CLOSE_SNACKBAR,
    payload: {
        dismissAll: !key, // dismiss all if no key has been defined
        key,
    },
});

export const removeSnackbar = (key: SnackbarKey) => ({
    type: REMOVE_SNACKBAR,
    payload: {
        key,
    },
});
