import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {FMCSAFormData} from '../pages/fmcsa-check/fmcsa-check.schema';
import {resetFMCSA} from '../redux/unit/unit.actions';

const initialState: FMCSAFormData = {
    StickerDate: '',
    CreateEstimate: false,
    SystemDate: '-',
    UnitINO: '-',
};

export type FMCSADataContext = {
    formData: FMCSAFormData;
    setFormData: React.Dispatch<React.SetStateAction<FMCSAFormData>>;
    resetFMCSAFormData: () => void;
};

export const FMCSADataContext = React.createContext<FMCSADataContext>({formData: initialState, setFormData: () => {}, resetFMCSAFormData: () => {}});

export type FMCSADataProviderProps = {
    children: ReactElement | ReactElement[];
};

export const FMCSADataProvider = ({children}: FMCSADataProviderProps) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState<FMCSAFormData>({
        ...initialState,
    });

    useEffect(() => {
        return () => {
            dispatch(resetFMCSA());
            setFormData(initialState);
        };
    }, []);

    const resetFMCSAFormData = useCallback(() => setFormData(initialState), []);

    const providerData: FMCSADataContext = {
        formData,
        setFormData,
        resetFMCSAFormData,
    };

    return <FMCSADataContext.Provider value={providerData}>{children}</FMCSADataContext.Provider>;
};
