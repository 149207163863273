import {all, fork} from 'redux-saga/effects';

import accessControlRootSaga from './access-control/access-control.saga';
import applicationRootSaga from './application/application.saga';
import commentRootSaga from './comment/comment.saga';
import companiesRootSaga from './company/company.saga';
import conditionCodesRootSaga from './condition-code/condition-code.saga';
import exceptionReasonsRootSaga from './exception-reason/exception-reason.saga';
import featuresRootSaga from './feature/feature.saga';
import fileRootSaga from './file/file.saga';
import formRootSaga from './form-validation/form.saga';
import historyRootSaga from './history/history.saga';
import jobCodesRootSaga from './job-code/job-code.saga';
import messageRootSaga from './message/message.saga';
import offlineEstimateRootSaga from './offline-estimate/offline-estimate.saga';
import partsRootSaga from './part/part.saga';
import pricingSaga from './pricing/pricing.saga';
import repairLineRootSaga from './repair-line/repair-line.saga';
import repairSizesRootSaga from './repair-size/repair-size.saga';
import shopsRootSaga from './shop/shop.saga';
import specialInstructionsSaga from './special-instructions/special-instruction.saga';
import stationsRootSaga from './station/station.saga';
import unitsRootSaga from './unit/unit.saga';
import unitGroupRootSaga from './unit-group/unit-group.saga';
import unitLocationCodesRootSaga from './unit-location-code/user-location-code.saga';
import usersRootSaga from './user/user.saga';
import whyMadeCodesRootSaga from './why-made-code/why-made-code.saga';
import workOrderRootSaga from './work-order/work-order.saga';

const sagas = function* rootSaga() {
    yield all(
        [
            shopsRootSaga,
            usersRootSaga,
            partsRootSaga,
            unitsRootSaga,
            featuresRootSaga,
            commentRootSaga,
            historyRootSaga,
            workOrderRootSaga,
            companiesRootSaga,
            stationsRootSaga,
            jobCodesRootSaga,
            repairLineRootSaga,
            repairSizesRootSaga,
            whyMadeCodesRootSaga,
            conditionCodesRootSaga,
            exceptionReasonsRootSaga,
            unitLocationCodesRootSaga,
            messageRootSaga,
            formRootSaga,
            fileRootSaga,
            specialInstructionsSaga,
            accessControlRootSaga,
            pricingSaga,
            applicationRootSaga,
            offlineEstimateRootSaga,
            unitGroupRootSaga,
        ].map((saga) => fork(saga)),
    );
};

export default sagas;
