import {ApplicationMode, CHECK_OFFLINE_DURATION, GO_OFFLINE, GO_ONLINE, SET_APPLICATION_MODE, SET_MODE_LOADING} from './application.types';

export const setApplicationMode = (mode: ApplicationMode) => ({
    type: SET_APPLICATION_MODE,
    payload: {mode},
});

export const goOffline = () => ({
    type: GO_OFFLINE,
});

export const goOnline = (isCancel?: boolean) => ({
    type: GO_ONLINE,
    payload: {isCancel},
});

export const CheckOfflineDuration = () => ({
    type: CHECK_OFFLINE_DURATION,
});

export const setModeLoading = (value: boolean) => ({
    type: SET_MODE_LOADING,
    payload: value,
});
