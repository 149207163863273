import {LinearProgress} from '@mui/material';
import React, {ReactElement} from 'react';

import {Wrapper} from './fallback.styles';

export const Fallback = (): ReactElement => (
    <Wrapper>
        <LinearProgress color="primary" />
    </Wrapper>
);
