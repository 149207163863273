import {Button} from '@mui/material';
import styled from 'styled-components';

export const HeaderTitle = styled.h2`
    color: ${({theme}) => theme.palette.primary.main};
`;

export const Results = styled.div`
    padding: 0 15px;
    p {
        color: ${({theme}) => theme.palette.primary.main};
        font-size: 16px;
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const QuickFilters = styled.div`
    p {
        margin: 0;
    }

    margin: 15px 0;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    flex-wrap: wrap;
`;

export const QuickFilterButton = styled(Button)`
    min-width: 66px !important;
`;

export const NegateButton = styled.div`
    margin: -25px 0 0 0;
`;

export const ButtonWrapper = styled.div`
    margin: 0 0 5px 0;
`;

export const ContingencyButtonWrapper = styled.div`
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    flex-wrap: wrap;
`;

export const PollingButton = styled(Button)`
    margin-left: 15px;
    color: #fff !important;
    -webkit-animation: pulse 1.5s infinite;
    -moz-animation: pulse 1.5s infinite;
    -o-animation: pulse 1.5s infinite;
    animation: pulse 1.5s infinite;

    @-webkit-keyframes pulse {
        50% {
            background-color: ${({theme}) => theme.palette.primary.main};
        }
    }
    @-moz-keyframes pulse {
        50% {
            background-color: ${({theme}) => theme.palette.primary.main};
        }
    }
    @-o-keyframes pulse {
        50% {
            background-color: ${({theme}) => theme.palette.primary.main};
        }
    }
    @-ms-keyframes pulse {
        50% {
            background-color: ${({theme}) => theme.palette.primary.main};
        }
    }
    @keyframes pulse {
        50% {
            background-color: ${({theme}) => theme.palette.primary.main};
        }
    }
`;
