import {ReduxAction} from '../../models/ReduxAction';
import {User} from '../../models/User';

import {USER_ERROR, USER_REQUEST, USERS_RESPONSE, SET_USER, UNSET_USER} from './user.types';

export interface UserState {
    error: Error | null;
    loading: boolean;
    users: User[];
    selectedUser: User | null;
    initialFetching: boolean;
    pgt: string | null;
}

const initialState: UserState = {
    /** Error thrown for a failed user request. */
    error: null,
    /** Flag indicating the UserState data is loading. */
    loading: false,
    /** List of Users. */
    users: [],
    /** User object representing the currently logged-in user. */
    selectedUser: null,
    /** Flag indicating the UserState data is initial loading. */
    initialFetching: true,
    /** Proxy granting ticket. */
    pgt: null,
};

export default function userReducer(state = initialState, action: ReduxAction): UserState {
    switch (action.type) {
        case USER_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case USERS_RESPONSE:
            return {
                ...state,
                loading: false,
                users: action.payload,
            };
        case USER_ERROR:
            return {
                ...state,
                initialFetching: false,
                loading: false,
                error: action.payload,
            };
        case SET_USER:
            return {
                ...state,
                initialFetching: false,
                selectedUser: action.payload.user,
                pgt: action.payload.pgt,
            };
        case UNSET_USER:
            return initialState;
        default:
            return state;
    }
}
