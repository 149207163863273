import {createSelector} from 'reselect';

import {State} from '../root-reducer';

import {CompanyState} from './company.reducer';

export const selectCompanyState = (state: State): CompanyState => state.companyReducer;

export const selectCompanies = createSelector(selectCompanyState, ({companies}) => companies);

export const selectCompaniesTypes = createSelector(selectCompanyState, ({companyType}) => companyType);
