import {Feature} from '../../models/Feature';
import {ReduxAction} from '../../models/ReduxAction';

import {FEATURE_ERROR, FEATURE_REQUEST, FEATURES_RESPONSE} from './feature.types';

export interface FeatureState {
    error: Error | null;
    loading: boolean;
    features: Feature[];
}

const initialState: FeatureState = {
    error: null,
    loading: false,
    features: [],
};

export default function featuresReducer(state = initialState, action: ReduxAction): FeatureState {
    switch (action.type) {
        case FEATURE_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FEATURES_RESPONSE:
            return {
                ...state,
                loading: false,
                features: action.payload,
            };
        case FEATURE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
