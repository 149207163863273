import {ExceptionReason} from '../../models/ExceptionReason';
import {RejectionReason} from '../../models/RejectionReason';

import {
    FETCH_EXCEPTION_REASONS,
    EXCEPTION_REASON_ERROR,
    EXCEPTION_REASON_REQUEST,
    EXCEPTION_REASONS_RESPONSE,
    CREATE_EXCEPTION_REASON_RESPONSE,
    UPDATE_EXCEPTION_REASON_RESPONSE,
} from './exception-reason.types';

export const exceptionReasonRequest = () => ({
    type: EXCEPTION_REASON_REQUEST,
});

export const exceptionReasonError = (error: Error | unknown) => ({
    type: EXCEPTION_REASON_ERROR,
    payload: error,
});

export const exceptionReasonsResponse = (data: ExceptionReason[]) => ({
    type: EXCEPTION_REASONS_RESPONSE,
    payload: data,
});

export const fetchExceptionReasons = () => ({
    type: FETCH_EXCEPTION_REASONS,
});

export const createExceptionReasonResponse = (data: RejectionReason) => ({
    type: CREATE_EXCEPTION_REASON_RESPONSE,
    payload: data,
});

export const updateExceptionReasonResponse = (data: RejectionReason) => ({
    type: UPDATE_EXCEPTION_REASON_RESPONSE,
    payload: data,
});
