import {createTheme} from '@mui/material';

// A custom theme for this app
const theme = createTheme({
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
    },
    palette: {
        primary: {
            main: '#19857b', // Green
        },
        secondary: {
            main: '#556cd6', // Blue
        },
        warning: {
            main: '#e6b400', // Yellow
        },
        info: {
            main: '#556cd6', // Blue
            dark: '#2840a9', // Darker Blue
        },
        success: {
            main: '#19857b', // Green
        },
        error: {
            main: '#ff1744', // Red
            dark: '#b71c1c', // Darker Red
        },
        background: {
            default: '#fff', // White
        },
    },
});

export default theme;
