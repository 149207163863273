import {Part} from '../../models/Part';
import {CreatePartDto} from '../../models/dtos/create-part.dto';
import {DeletePartDto} from '../../models/dtos/delete-part.dto';
import {UpdatePartDto} from '../../models/dtos/update-part.dto';

import {
    FETCH_PARTS,
    PARTS_REQUEST,
    PARTS_RESPONSE,
    PARTS_ERROR,
    FETCH_REPAIR_LINE_PART,
    CREATE_REPAIR_LINE_PART,
    UPDATE_REPAIR_LINE_PART,
    DELETE_REPAIR_LINE_PART,
    FETCH_REPAIR_LINE_PART_RESPONSE,
    CREATE_REPAIR_LINE_PART_RESPONSE,
    UPDATE_REPAIR_LINE_PART_RESPONSE,
    DELETE_REPAIR_LINE_PART_RESPONSE,
} from './part.types';

export const fetchParts = (forceFetchAll?: boolean) => ({
    type: FETCH_PARTS,
    payload: forceFetchAll,
});

export const partsRequest = () => ({
    type: PARTS_REQUEST,
});

export const partsResponse = (data: Part[]) => ({
    type: PARTS_RESPONSE,
    payload: {parts: data},
});

export const partsError = (error: string) => ({
    type: PARTS_ERROR,
    payload: {error},
});

export const fetchRepairLinePart = (partID: Part['ID']) => ({
    type: FETCH_REPAIR_LINE_PART,
    payload: {partID},
});

export const createRepairLinePart = (data: CreatePartDto) => ({
    type: CREATE_REPAIR_LINE_PART,
    payload: data,
});

export const updateRepairLinePart = (data: UpdatePartDto) => ({
    type: UPDATE_REPAIR_LINE_PART,
    payload: data,
});

export const deleteRepairLinePart = (data: DeletePartDto) => ({
    type: DELETE_REPAIR_LINE_PART,
    payload: data,
});

export const fetchRepairLinePartResponse = (data: Part) => ({
    type: FETCH_REPAIR_LINE_PART_RESPONSE,
    payload: {part: data},
});

export const createRepairLinePartResponse = (workOrderID: number, repairLineID: number, data: Part) => ({
    type: CREATE_REPAIR_LINE_PART_RESPONSE,
    payload: {workOrderID, repairLineID, part: data},
});

export const updateRepairLinePartResponse = (workOrderID: number, repairLineID: number, data: Part) => ({
    type: UPDATE_REPAIR_LINE_PART_RESPONSE,
    payload: {workOrderID, repairLineID, part: data},
});

export const deleteRepairLinePartResponse = (workOrderID: number, repairLineID: number, partID: Part['ID']) => ({
    type: DELETE_REPAIR_LINE_PART_RESPONSE,
    payload: {workOrderID, repairLineID, partID},
});
