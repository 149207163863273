import {createSelector} from 'reselect';

import {Feature} from '../../models/Feature';
import {State} from '../root-reducer';

import {FeatureState} from './feature.reducer';

export const selectFeatureState = (state: State): FeatureState => state.featuresReducer;

export const selectFeatures = createSelector(selectFeatureState, ({features}) => features);

export const isFeatureEnabled = (name: Feature['Name']) =>
    createSelector(selectFeatureState, ({features}) => {
        const feature = features.find((feature) => feature.Name === name);

        return Boolean(feature?.IsEnabled && !feature.IsDeleted);
    });
