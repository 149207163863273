import {ReactElement} from 'react';
import {useSelector} from 'react-redux';

import {AuthProvider} from '../../providers/auth-provider';
import {selectIsOfflineStatus} from '../../redux/application/application.selectors';

interface AuthProviderWrapperProps {
    children: ReactElement;
}

export const AuthProviderWrapper = ({children}: AuthProviderWrapperProps) => {
    const isOfflineMode = useSelector(selectIsOfflineStatus);

    if (isOfflineMode) {
        return children;
    }

    return <AuthProvider>{children}</AuthProvider>;
};
