import React, {ReactElement, useContext, useEffect} from 'react';

import {HeaderContext} from '../providers/header-provider';

export type UseHeaderProps = {
    backToPath?: string | React.Dispatch<React.SetStateAction<any>> | (() => void);
    middleElement?: () => ReactElement | null;
    setStateParam?: any;
    basePath?: string;
};

export const useHeader = ({backToPath, middleElement, setStateParam, basePath}: UseHeaderProps) => {
    const {updateHeaderData, basePath: existingBasePath} = useContext(HeaderContext);

    useEffect(() => {
        // Keep the existing basePath until the callback nulls it.
        updateHeaderData({
            backToPath: backToPath || null,
            middleElement: middleElement || null,
            setStateParam: setStateParam,
            basePath: basePath ? basePath : existingBasePath,
        });

        return () => {
            // If the new location doesn't contain a number, the base path has been reached so clear it.
            updateHeaderData({
                backToPath: null,
                middleElement: null,
                setStateParam: setStateParam,
                basePath: window.location.pathname.match(/\d/) ? basePath : null,
            });
        };
    }, [backToPath, middleElement, setStateParam, updateHeaderData]);
};
