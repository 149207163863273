import {WhyMadeCode} from '../../models/WhyMadeCode';

import {FETCH_WHY_MADE_CODES, WHY_MADE_CODE_ERROR, WHY_MADE_CODE_REQUEST, WHY_MADE_CODES_RESPONSE} from './why-made-code.types';

export const whyMadeCodeRequest = () => ({
    type: WHY_MADE_CODE_REQUEST,
});

export const whyMadeCodeError = (error: Error | unknown) => ({
    type: WHY_MADE_CODE_ERROR,
    payload: error,
});

export const whyMadeCodesResponse = (data: WhyMadeCode[]) => ({
    type: WHY_MADE_CODES_RESPONSE,
    payload: data,
});

export const fetchWhyMadeCodes = () => ({
    type: FETCH_WHY_MADE_CODES,
});
