import {t} from 'i18next';

import {ReduxAction} from '../models/ReduxAction';
import {WorkOrder} from '../models/WorkOrder';
import {WorkOrderRepairLine} from '../models/WorkOrderRepairLine';
import {Permission} from '../models/enumerations/Permission';
import {WorkOrderRepairLineStatus} from '../models/enumerations/WorkOrderRepairLineStatus';
import {WorkOrderStatus} from '../models/enumerations/WorkOrderStatus';
import {updateWorkOrderStatus} from '../redux/work-order/work-order.actions';

export type WorkOrderAction = {
    label: string;
    action: () => ReduxAction;
    permissions?: string[];
};

const useNextWorkOrderAction = (workOrder: WorkOrder, repairlines: WorkOrderRepairLine[]): WorkOrderAction | null => {
    if (
        workOrder.Status >= WorkOrderStatus.WorkApproved.Status &&
        repairlines.filter((rl) => rl.Status === WorkOrderRepairLineStatus.Created).length > 0
    ) {
        return {
            label: t('submitted_for_approval_action'),
            action: updateWorkOrderStatus.bind(null, {
                workOrderID: workOrder.ID,
                status: WorkOrderStatus.SubmittedForApproval.Status,
            }),
            permissions: [Permission.WORK_ORDER_SUBMITTED_FOR_APPROVAL],
        };
    }

    switch (workOrder.Status) {
        case WorkOrderStatus.Open.Status: {
            return {
                label: t('submitted_for_approval_action'),
                action: updateWorkOrderStatus.bind(null, {
                    workOrderID: workOrder.ID,
                    status: WorkOrderStatus.SubmittedForApproval.Status,
                }),
                permissions: [Permission.WORK_ORDER_SUBMITTED_FOR_APPROVAL],
            };
        }
        case WorkOrderStatus.SubmittedForApproval.Status: {
            return {
                label: t('work_approved_action'),
                action: updateWorkOrderStatus.bind(null, {
                    workOrderID: workOrder.ID,
                    status: WorkOrderStatus.WorkApproved.Status,
                }),
                permissions: [Permission.WORK_ORDER_WORK_APPROVED],
            };
        }
        case WorkOrderStatus.PendingEscalation.Status: {
            return {
                label: t('work_approved_action'),
                action: updateWorkOrderStatus.bind(null, {
                    workOrderID: workOrder.ID,
                    status: WorkOrderStatus.WorkApproved.Status,
                }),
                permissions: [Permission.WORK_ORDER_ESCALATION_WORK_APPROVED],
            };
        }
        case WorkOrderStatus.WorkApproved.Status: {
            return {
                label: t('repairs_done_action'),
                action: updateWorkOrderStatus.bind(null, {
                    workOrderID: workOrder.ID,
                    status: WorkOrderStatus.RepairsDone.Status,
                }),
                permissions: [Permission.WORK_ORDER_REPAIRS_DONE],
            };
        }
        case WorkOrderStatus.RepairsDone.Status: {
            return {
                label: t('approved_action'),
                action: updateWorkOrderStatus.bind(null, {
                    workOrderID: workOrder.ID,
                    status: WorkOrderStatus.Approved.Status,
                }),
                permissions: [Permission.WORK_ORDER_APPROVED],
            };
        }
        default: {
            return null;
        }
    }
};

export default useNextWorkOrderAction;
