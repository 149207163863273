import styled from 'styled-components';

export const Container = styled.div`
    background-color: yellow;
    margin-bottom: 5px;
`;

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const AuditMessageText = styled.span`
    color: black;
    display: block;
    white-space: normal;
    padding: 0 5px 0 5px;
`;
