import {Feature} from '../../models/Feature';

import {FETCH_FEATURES, FEATURE_ERROR, FEATURE_REQUEST, FEATURES_RESPONSE} from './feature.types';

export const featureRequest = () => ({
    type: FEATURE_REQUEST,
});

export const featureError = (error: Error | unknown) => ({
    type: FEATURE_ERROR,
    payload: error,
});

export const featuresResponse = (data: Feature[]) => ({
    type: FEATURES_RESPONSE,
    payload: data,
});

export const fetchFeatures = (forceFetch?: boolean) => ({
    type: FETCH_FEATURES,
    payload: forceFetch,
});
