import {ItemModel} from '@syncfusion/ej2-navigations';
import {ClickEventArgs} from '@syncfusion/ej2-navigations/src/toolbar/toolbar';
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    InfiniteScroll,
    EditSettingsModel,
    Filter,
    Inject,
    Sort,
    Edit,
    Toolbar,
    Column,
    QueryCellInfoEventArgs,
} from '@syncfusion/ej2-react-grids';
import {push} from 'connected-react-router';
import {t} from 'i18next';
import React, {memo, useCallback, useMemo} from 'react';
import * as ReactDOM from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';

import {OfflineEstimateLine} from '../../models/OfflineEstimateLine';
import {WorkOrderRepairLine} from '../../models/WorkOrderRepairLine';
import {OfflineEstimateLineDataGridDto} from '../../models/dtos/offline-estimate-line-data-grid-dto';
import {WorkOrderRepairLineDataGridDto} from '../../models/dtos/work-order-repair-line-data-grid-dto';
import {WorkOrderRepairLineStatus} from '../../models/enumerations/WorkOrderRepairLineStatus';
import {selectConditionCodes} from '../../redux/condition-code/condition-code.selectors';
import {selectJobCodes} from '../../redux/job-code/job-code.selectors';
import {deleteOfflineEstimateLine} from '../../redux/offline-estimate/offline-estimate.actions';
import {selectOfflineEstimate} from '../../redux/offline-estimate/offline-estimate.selectors';
import {fetchRepairLinePricing} from '../../redux/pricing/pricing.actions';
import {selectUnitLocationCodes} from '../../redux/unit-location-code/user-location-code.selectors';
import {selectWhyMadeCodes} from '../../redux/why-made-code/why-made-code.selectors';
import {convertOfflineEstimateLinesToOfflineEstimateLineDataGridDtos} from '../../utils/convertOfflineEstimateLineToOfflineEstimateLineDataGridDto';
import {isOfflineEstimateReadOnly} from '../../utils/isOfflineEstimateReadOnly';

import {Counter, ButtonDetails} from './offline-estimate-item-details.styles';

export type OfflineEstimateItemDetailsProps = {
    onDetailsClick: (line: OfflineEstimateLine) => void;
    detailsUrls: {
        create: string;
        update: string;
        files: string;
        parts: string;
        comments: string;
    };
};

const OfflineEstimateItemDetails = ({onDetailsClick, detailsUrls}: OfflineEstimateItemDetailsProps) => {
    const dispatch = useDispatch();
    const {params} = useRouteMatch<{id?: string}>();
    const offlineEstimateID = Number(params.id);
    const offlineEstimate = useSelector(selectOfflineEstimate(offlineEstimateID))!;
    const isReadOnly = isOfflineEstimateReadOnly(offlineEstimate?.SentStatus);
    const jobCodes = useSelector(selectJobCodes);
    const whyMadeCodes = useSelector(selectWhyMadeCodes);
    const conditionCodes = useSelector(selectConditionCodes);
    const unitLocationCodes = useSelector(selectUnitLocationCodes);
    const offlineEstimateLines = useMemo(
        () =>
            convertOfflineEstimateLinesToOfflineEstimateLineDataGridDtos(
                offlineEstimate?.RepairLines,
                jobCodes,
                whyMadeCodes,
                conditionCodes,
                unitLocationCodes,
            ),
        [offlineEstimate?.RepairLines, jobCodes, whyMadeCodes, conditionCodes, unitLocationCodes],
    );

    const onRowSelected = ({data}: {data: WorkOrderRepairLineDataGridDto}) => {
        const buttons = document.querySelectorAll('.e-toolbar-item[title=Delete], .e-toolbar-item[title=Reject], .e-toolbar-item[title=Update]');

        if (data.Status === WorkOrderRepairLineStatus.Rejected) {
            buttons.forEach((button) => button.classList.add('e-overlay'));
        } else {
            buttons.forEach((button) => button.classList.remove('e-overlay'));
        }
    };
    const onRowDeselected = () => {
        document
            .querySelectorAll('.e-toolbar-item[title=Delete], .e-toolbar-item[title=Reject], .e-toolbar-item[title=Update]')
            .forEach((button) => button.classList.add('e-overlay'));
    };

    const onDetailsFieldClick = useCallback(
        (offlineEstimateLine: OfflineEstimateLine) => () => {
            onDetailsClick(offlineEstimateLine);
        },
        [onDetailsClick],
    );

    const clickHandler = function (this: GridComponent, args: ClickEventArgs) {
        const properties = this.properties as {selectedRowIndex: number; dataSource: WorkOrderRepairLineDataGridDto[]};

        const repairLine = properties.dataSource[properties.selectedRowIndex];

        if (args.item.id === 'Create') {
            dispatch(push(detailsUrls.create));
        }

        if (args.item.id === 'Update') {
            dispatch(push(`${detailsUrls.update}/${repairLine.ID}`));
            dispatch(
                fetchRepairLinePricing({
                    companyID: offlineEstimate.CompanyID,
                    stationID: offlineEstimate.StationID,
                    equipmentGroupID: offlineEstimate.EquipmentGroupID,
                    jobCodeID: repairLine.JobCodeId,
                    conditionCodeID: repairLine.ConditionCodeId,
                    repairSizeID: repairLine.RepairSizeId,
                    suppressError: false,
                }),
            );
        }

        if (args.item.id === 'Delete') {
            dispatch(deleteOfflineEstimateLine(offlineEstimate.ID, repairLine.ID));
        }
    };

    const editSettings: EditSettingsModel = {
        allowDeleting: true,
        mode: 'Dialog',
    };

    let toolbarOptions: ItemModel[] = [
        {
            tooltipText: 'Delete',
            prefixIcon: 'e-delete-1',
            id: 'Delete',
            align: 'Right',
            disabled: true,
        },
        {
            tooltipText: 'Update',
            prefixIcon: 'e-edit-5',
            id: 'Update',
            align: 'Right',
            disabled: true,
        },
        {
            tooltipText: 'Create',
            prefixIcon: 'e-plus-small',
            id: 'Create',
            align: 'Right',
        },
    ];

    const actionBegin: GridComponent['actionBegin'] = useCallback(function (state) {
        if (state.requestType === 'filterbeforeopen' && state.columnName === 'Status') {
            state.filterModel.sBox.firstChild.setAttribute('hidden', '');
        }
    }, []);

    // https://www.syncfusion.com/forums/175268/get-error-when-enablestickyheader
    const destroy = function (this: GridComponent) {
        document.removeEventListener('scroll', this.scrollModule['makeStickyHeader']);
    };

    const queryCellInfo = (args: QueryCellInfoEventArgs) => {
        const cell = args.cell as Element;
        const field = (args.column as Column).field;
        const data = args.data as OfflineEstimateLineDataGridDto;

        switch (field) {
            case 'Status':
                ReactDOM.render(<span>{t(WorkOrderRepairLineStatus[data.Status])}</span>, cell);
                break;
            case 'FilesCount':
                ReactDOM.render(<Counter onClick={() => dispatch(push(`${detailsUrls.files}?lineID=${data.ID}`))}>{data.FilesCount}</Counter>, cell);
                break;
            case 'CommentsCount':
                ReactDOM.render(<Counter onClick={() => dispatch(push(`${detailsUrls.comments}#${data.ID}`))}>{data.CommentsCount}</Counter>, cell);
                break;
            case 'PartsCount':
                ReactDOM.render(<Counter onClick={() => dispatch(push(`${detailsUrls.parts}?lineID=${data.ID}`))}>{data.PartsCount}</Counter>, cell);
                break;
            case 'DetailsField':
                ReactDOM.render(<ButtonDetails onClick={onDetailsFieldClick(data)}>{t('details')}</ButtonDetails>, cell);
                break;
        }
    };

    return (
        <>
            <div className="e-adaptive-demo e-bigger" key="repair-item-details-key">
                <div className="e-mobile-layout">
                    <div className="e-mobile-content">
                        <GridComponent
                            readOnly={isReadOnly}
                            selectionSettings={{type: 'Single'}}
                            rowSelected={onRowSelected}
                            rowDeselected={onRowDeselected}
                            allowPaging
                            allowSorting
                            className="repairLine-grid"
                            allowSelection
                            allowFiltering
                            enableAdaptiveUI
                            queryCellInfo={queryCellInfo}
                            actionBegin={actionBegin}
                            toolbarClick={clickHandler}
                            editSettings={editSettings}
                            toolbar={isReadOnly ? [] : toolbarOptions}
                            dataSource={offlineEstimateLines}
                            rowRenderingMode="Vertical"
                            // @ts-ignore
                            destroy={destroy}
                            enableStickyHeader
                            enableInfiniteScrolling
                            filterSettings={{type: 'Menu'}}>
                            <ColumnsDirective>
                                <ColumnDirective field="JobCodeNumber" headerText={t('code')} />
                                <ColumnDirective field="JobCodeDescription" headerText={t('description')} />
                                <ColumnDirective field="UnitLocationCodeDescription" headerText={t('location')} />
                                <ColumnDirective field="Defect" headerText={t('defect')} />
                                <ColumnDirective field="ConditionCodeDescription" headerText={t('repair')} />
                                <ColumnDirective field="RepairQuantity" headerText={t('quantity')} />
                                <ColumnDirective
                                    headerText={t('status')}
                                    field="Status"
                                    filter={{type: 'CheckBox'}}
                                    filterItemTemplate={({Status}: WorkOrderRepairLine) => t(WorkOrderRepairLineStatus[Status])}
                                />
                                <ColumnDirective headerText={t('files')} field="FilesCount" />
                                <ColumnDirective headerText={t('comments')} field="CommentsCount" />
                                <ColumnDirective headerText={t('parts')} field="PartsCount" />
                                <ColumnDirective field="DetailsField" headerText="" />
                            </ColumnsDirective>
                            <Inject services={[InfiniteScroll, Filter, Sort, Toolbar, Edit]} />
                        </GridComponent>
                    </div>
                </div>
            </div>
        </>
    );
};

export const buildDetailsUrls = (path: string, offlineEstimateID: number): OfflineEstimateItemDetailsProps['detailsUrls'] => ({
    create: `${path}/${offlineEstimateID}/line`,
    update: `${path}/${offlineEstimateID}/line`,
    files: `${path}/${offlineEstimateID}/files`,
    parts: `${path}/${offlineEstimateID}/parts`,
    comments: `${path}/${offlineEstimateID}/comments`,
});

export default memo(OfflineEstimateItemDetails);
