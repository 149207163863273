import React, {memo, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import CommentsGridListComponent from '../../components/comments-grid-list/comments-grid-list.component';
import RepairLineCommentsList from '../../components/repair-line-comments-list/repair-line-comments-list.component';
import {Comment} from '../../models/Comment';
import {WorkOrderRepairLine} from '../../models/WorkOrderRepairLine';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {fetchComments} from '../../redux/comment/comment.actions';

interface CommentsComponentProps {
    comments: Comment[];
    repairLines: WorkOrderRepairLine[];
    scrollToRepairLineID: number | null;
}

const WorkOrderCommentsComponent = ({comments = [], repairLines = [], scrollToRepairLineID}: CommentsComponentProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        repairLines.forEach((item) => {
            dispatch(
                fetchComments({
                    associateObjectID: item.ID,
                    associatedTypeID: AssociatedTypes.WorkOrderRepairLine,
                }),
            );
        });
    }, [dispatch, repairLines]);

    return (
        <>
            <CommentsGridListComponent payload={comments} title="Work order comments" />
            <RepairLineCommentsList repairLines={repairLines} scrollToRepairLineID={scrollToRepairLineID} title="Repair line comments" />
        </>
    );
};

export default memo(WorkOrderCommentsComponent);
