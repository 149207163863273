import {UnitGroup} from '../../models/UnitGroup';

import {FETCH_UNIT_GROUPS, UNIT_GROUP_ERROR, UNIT_GROUP_RESPONSE} from './unit-group.types';

export const unitGroupError = (error: Error | unknown) => ({
    type: UNIT_GROUP_ERROR,
    payload: error,
});

export const unitGroupResponse = (data: UnitGroup[]) => ({
    type: UNIT_GROUP_RESPONSE,
    payload: data,
});

export const fetchUnitGroups = () => ({
    type: FETCH_UNIT_GROUPS,
});
