import {Button} from '@mui/material';
import {t} from 'i18next';
import React, {useCallback, useRef} from 'react';
import {default as useSound} from 'use-sound';

import scanSound from '../../sounds/scannerbeep.mp3';
import {BarcodeScanner as BarcodeScannerComponent} from '../barcode-scanner/barcode-scanner.component';
import {CircularProgressComponent} from '../circular-progress/circular-progress.component';

import {BackDropContainer, BackdropLoader, MainBackdrop, ButtonContainer} from './barcode-scanner.styles';

export type BarcodeScannerProps = {
    isOpen: boolean;
    isLoading: boolean;
    onScanned: (unpause: () => void, code: string) => void;
    onClose: () => void;
};

export const BarcodeScanner = ({isOpen, onScanned, isLoading, onClose}: BarcodeScannerProps) => {
    const scannerRef = useRef<HTMLDivElement>(null);
    const [playBeep] = useSound(scanSound, {volume: 0.5});

    const onDetected = useCallback(
        (unpause: () => void, code: string) => {
            playBeep();
            onScanned(unpause, code);
        },
        [onScanned, playBeep],
    );

    return (
        <MainBackdrop open={isOpen} onClick={onClose}>
            <BackDropContainer ref={scannerRef}>
                <ButtonContainer>
                    <Button aria-label="close" variant="contained" color="primary" size="large" onClick={onClose} fullWidth>
                        {t('done')}
                    </Button>
                </ButtonContainer>
                <BackdropLoader open={isLoading}>
                    <CircularProgressComponent />
                </BackdropLoader>
                {isOpen ? <BarcodeScannerComponent scannerRef={scannerRef} onDetected={onDetected} /> : null}
            </BackDropContainer>
        </MainBackdrop>
    );
};
