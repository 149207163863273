import {RJSFSchema} from '@rjsf/utils';

import i18n from '../../i18n';

export enum Status {
    GOOD,
    BAD,
}

export type FMCSAFormData = {
    Status?: Status;
    StickerDate: string;
    CreateEstimate: boolean;
    SystemDate: string | Date;
    UnitINO: string;
};

export const getFMCSASchema = (): RJSFSchema => ({
    type: 'object',
    properties: {
        UnitINO: {
            type: 'string',
            title: i18n.t('unit_ino'),
        },
        SystemDate: {
            type: 'string',
            title: i18n.t('system_date'),
        },
        Status: {
            type: 'number',
            title: i18n.t('status'),
            oneOf: [
                {const: Status.GOOD, title: i18n.t('good')},
                {const: Status.BAD, title: i18n.t('bad')},
            ],
        },
    },
    required: ['Status'],
    dependencies: {
        Status: {
            oneOf: [
                {
                    properties: {
                        Status: {
                            const: Status.GOOD,
                        },
                    },
                },
                {
                    required: ['StickerDate', 'CreateEstimate'],
                    properties: {
                        Status: {
                            const: Status.BAD,
                        },
                        StickerDate: {
                            title: i18n.t('sticker-date'),
                            type: ['null', 'string'],
                            format: 'date',
                            default: '',
                        },
                        CreateEstimate: {
                            type: 'boolean',
                            enum: [true, false],
                            title: i18n.t('create_estimate'),
                            default: false,
                        },
                    },
                },
            ],
        },
    },
});

export const uiSchema = {
    Priority: {
        'ui:enumDisabled': [false],
    },
    SystemDate: {
        'ui:readonly': true,
    },
    UnitINO: {
        'ui:readonly': true,
    },
    StickerDate: {
        'ui:widget': 'date',
    },
};
