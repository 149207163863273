import React, {ReactElement, useEffect, useState} from 'react';

import IndexedDB from '../../services/indexedDB';
import {Fallback} from '../fallback/fallback.component';

interface IndexedDBProviderProps {
    children: ReactElement;
}

export const IndexedDBProvider = ({children}: IndexedDBProviderProps): ReactElement => {
    const [loading, setLoading] = useState(true);

    const openDBConnection = async () => {
        await IndexedDB.openConnection();

        setLoading(false);
    };

    useEffect(() => {
        openDBConnection();
    }, []);

    return loading ? <Fallback /> : children;
};
