import {User as CasUser, UserManager as CasUserManager, UserProfile as CasUserProfile, WebStorageStateStore} from 'cas-client-ts';

import config from '../config';

import {AuthService, IUser, IUserProfile} from './auth-service';
import LocalStorage, {LocalStorageKeys} from './local-storage';

export {Log as CasLog} from 'cas-client-ts';

/** Debug flags. */
interface DebugCasAuthService {
    isLoggedIn?: boolean;
    getClaims?: boolean;
    getAuthorizationHeaderValue?: boolean;
    logout?: boolean;
    startAuthentication?: boolean;
    getUser?: boolean;
    completeAuthentication?: boolean;
    removeUser?: boolean;
} /** */

/**
 * Class representing authentication services using CAS.
 *
 * @export
 * @class CasAuthService
 * @typedef {CasAuthService}
 */
export default class CasAuthService extends AuthService {
    /** Official identifier for this service. */
    static readonly AuthServiceName = 'cas';

    /** Flags to enable mocking results of authentication client interface. */
    private debugFlags: DebugCasAuthService = {
        // isLoggedIn: true,
        // getClaims: true,
        // getAuthorizationHeaderValue: true,
        // logout: true,
        // startAuthentication: true,
        // getUser: true,
        // completeAuthentication: true,
        // removeUser: true,
    };

    /**
     * Get the official identifier for this authentication service.
     *
     * @readonly
     * @type {"cas"}
     */
    readonly authServiceName = CasAuthService.AuthServiceName;

    /**
     * Get or set the UserManager object.
     *
     * @type {CasUserManager}
     */
    UserManager: CasUserManager;

    /**
     * Create an instance of CasAuthService.
     *
     * @constructor
     */
    constructor() {
        super();
        // Destructure authentication properties into new variables for easier renaming and manipulation.
        const {
            authUrl: authority,
            clientId: client_id,
            metadata: metadataSeed,
            metadataUrl,
            extraRequestHeaders,
            extraResponseHeaders,
            ...rest // Remaining properties can be passed without renaming.
        } = config.casAuthConfig;
        this.UserManager = new CasUserManager({
            authority: authority,
            client_id: client_id,
            redirect_uri: `${location.origin}/signin-auth`,
            post_logout_redirect_uri: `${location.origin}/signout-auth`,
            // filterProtocolClaims: true,
            loadUserInfo: true,
            metadataSeed: metadataSeed, // Metadata values to replace or initialize.
            metadataUrl: metadataUrl, // URL of metadata service; undefined for default.
            // response_type: 'code',
            // scope: 'openid api2',
            userStore: new WebStorageStateStore({store: sessionStorage}),
            getExtraRequestHeaders: () => new Headers(extraRequestHeaders),
            getExtraResponseHeaders: () => new Headers(extraResponseHeaders),
            silentRequestTimeoutInSeconds: 30,
            ...rest,
        });
    }

    /**
     * Determine if an authenticated session has been established.
     *
     * @returns {Promise<boolean>}
     */
    isAuthenticated = (): Promise<boolean> => {
        /** Mock result for debugging. */
        if (this.debugFlags.isLoggedIn) {
            return Promise.resolve(true);
        } /** */

        return this.getUser()
            .then((user) => {
                return Boolean(user?.id_token);
            })
            .catch((reason) => {
                console.error('error getting user', reason);
                return false;
            });
    };

    /**
     * Get the authentication claims object associated with the current user.
     *
     * @returns {Promise<IUserProfile | null>}
     */
    getClaims = (): Promise<IUserProfile | null> => {
        /** Mock result for debugging. */
        if (this.debugFlags.getClaims) {
            const mockUserProfile = {
                sub: 'b.rubble@bedrock.org',
                auth_time: AuthService.getEpochTime(),
                email: 'b.rubble@bedrock.org',
                family_name: 'Rubble',
                given_name: 'Barney',
                phone_number: '123-555-1212',
            } as IUserProfile;
            return Promise.resolve(mockUserProfile);
        } /** */

        // Return user claims; null if not authenticated.
        return this.getUser()
            .then((user) => user?.profile || null)
            .catch(() => null);
    };

    /**
     * Get a string containing the Authorization header value for the current user.
     *
     * @returns {Promise<string | null>}
     */
    getAuthorizationHeaderValue = (): Promise<string | null> => {
        // For CAS, this is unused and assigned an empty string.
        return Promise.resolve('');
    };

    /**
     * Submit a sign-out request for the current user.
     */
    logout = (): void => {
        /** Mock result for debugging. */
        if (this.debugFlags.logout) {
            return;
        } /** */

        this.UserManager.signoutRedirect();

        this.UserManager.clearStaleState();
    };

    /**
     * Start the sign-in sequence for the current user.
     *
     * @returns {Promise<void>}
     */
    startAuthentication = (): Promise<void> => {
        /** Mock result for debugging. */
        if (this.debugFlags.startAuthentication) {
            return Promise.resolve();
        } /** */

        // Save the current address to return to after authenticating.
        LocalStorage.set(LocalStorageKeys.REDIRECT_URI, window.location.pathname);

        return this.UserManager.signinRedirect();
    };

    /**
     * Get the session information for the current user and return it in a promise.
     *
     * @returns {(Promise<IUser | null>)} The result of the promise is the authenticated `User`.
     */
    getUser = async (): Promise<IUser | null> => {
        /** Mock result for debugging. */
        if (this.debugFlags.getUser) {
            const mockUserProfile = {
                sub: 'b.rubble@bedrock.org',
                auth_time: AuthService.getEpochTime(),
                email: 'b.rubble@bedrock.org',
                family_name: 'Rubble',
                given_name: 'Barney',
                phone_number: '123-555-1212',
            } as CasUserProfile;
            return {
                id_token: 'ST-12345ABCDE',
                session_state: null,
                access_token: '',
                refresh_token: undefined,
                token_type: 'Cookie',
                scope: undefined,
                profile: mockUserProfile,
                expires_at: AuthService.getEpochTime() + 3600, // 1 hour
                userState: undefined,
                state: undefined,
                expires_in: undefined,
                expired: undefined,
                scopes: [],
                toStorageString: () => '',
            } as CasUser;
        } /** */

        // Return promise with user session information after it has been fulfilled.
        return (await this.UserManager.getUser()) as unknown as IUser;
    };

    /**
     * Complete the sign-in sequence.
     *
     * @returns {Promise<void>}
     */
    completeAuthentication = (): Promise<void> => {
        /** Mock result for debugging. */
        if (this.debugFlags.completeAuthentication) {
            window.location.pathname = location.origin;
            return Promise.resolve();
        } /** */

        return this.UserManager.signinRedirectCallback().then(() => {
            // Retrieve from storage the address to return to.
            const redirectUri = LocalStorage.get(LocalStorageKeys.REDIRECT_URI);

            // Setting just the pathname preserves the rest of the URL including query parameters.
            window.location.pathname = redirectUri ?? location.origin;
        });
    };

    /**
     * Remove the session information for the current user in case authentication processing fails.
     *
     * @async
     * @returns {(Promise<void>)}
     */
    removeUser = (): Promise<void> => {
        /** Mock result for debugging. */
        if (this.debugFlags.removeUser) {
            return Promise.resolve();
        } /** */

        return this.UserManager.removeUser();
    };

    /**
     * Get the username from the session information for the current user.
     *
     * @async
     * @returns {(Promise<string | null>)}
     */
    getUsername = (): Promise<string | null> => {
        return this.getUser().then((user) => user?.profile.sub ?? null);
    };
}
