import {CircularProgress, LinearProgress} from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import {AutoCompleteComponent} from '@syncfusion/ej2-react-dropdowns';
import React, {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {useAbac} from 'react-abac';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';

import {ErrorMessage} from '../../models/ErrorMessage';
import {RejectionReason} from '../../models/RejectionReason';
import {User} from '../../models/User';
import {AddDeclinationReasonDto} from '../../models/dtos/add-declination-reason.dto';
import {ExceptionReasonDto} from '../../models/dtos/exception-reason.dto';
import {RemoveDeclinationReasonDto} from '../../models/dtos/remove-declination-reason.dto';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {Permission} from '../../models/enumerations/Permission';
import {fetchExceptionReasons} from '../../redux/exception-reason/exception-reason.actions';
import {selectExceptionReasonState} from '../../redux/exception-reason/exception-reason.selectors';
import {selectUser} from '../../redux/user/user.selectors';
import {fetchWorkOrder} from '../../redux/work-order/work-order.actions';
import DeclinationReasonsTemplate from '../declination-reasons/declination-reasons-template';

import {ButtonContainer, DialogWrap, AutoCompleteWrap, AddExceptionButton, DeclinationReasonsTitle} from './do-not-repair-dialog.styles';

type DoNotRepairDialogProps = {
    open: boolean;
    itemID: number;
    loading: boolean;
    itemName: string;
    itemType: AssociatedTypes;
    onCancel: () => void;
    onReject: (itemID: number, sendExternalIntegrationMessage?: boolean) => void;
    declinationReasons: ErrorMessage[];
    addDeclinationReasonAction: (dto: AddDeclinationReasonDto) => {};
    removeDeclinationReasonAction: (dto: RemoveDeclinationReasonDto) => {};
};

type SelectExceptionReason = {
    value: string;
};

const DoNotRepairDialog = ({
    open,
    itemID,
    loading,
    onReject,
    onCancel,
    itemType,
    declinationReasons,
    addDeclinationReasonAction,
    removeDeclinationReasonAction,
}: DoNotRepairDialogProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {userHasPermissions} = useAbac();
    const {params} = useRouteMatch<{id?: string}>();
    const workOrderID = Number(params.id);
    const user = useSelector(selectUser) as User;
    const [reason, setReason] = useState<string | null>(null);
    const {exceptionReasons} = useSelector(selectExceptionReasonState);
    const [sendExternalIntegrationMessage, setSendExternalIntegrationMessage] = useState(true);

    const filteredExceptionReasons = useMemo<ExceptionReasonDto[]>(
        () => exceptionReasons.filter((exceptionReason) => exceptionReason.IsDoNotRepairReason),
        [exceptionReasons],
    );

    useEffect(() => {
        dispatch(fetchExceptionReasons());
        dispatch(
            fetchWorkOrder({
                workOrderID,
            }),
        );
    }, [workOrderID, reason]);

    const addDoNotRepairReason = () => {
        if (reason) {
            const exceptionReason = filteredExceptionReasons.find(({Description}) => Description === reason);

            const rejectionReason: RejectionReason = {
                ExceptionReasonID: exceptionReason?.ID,
                RejectionExceptionReason: exceptionReason?.Description ?? reason,
                UserId: user.ID,
                ErrorMessageID: -1,
                IsDeleted: false,
            };

            dispatch(
                addDeclinationReasonAction({
                    ID: itemID,
                    rejectionReason: rejectionReason,
                }),
            );
        }
    };

    const onRemoveDeclinationReasonClick = useCallback(
        (errorMessage: ErrorMessage) => {
            dispatch(
                removeDeclinationReasonAction({
                    ID: itemID,
                    errorMessageID: errorMessage.ID,
                }),
            );
        },
        [dispatch, itemID],
    );

    const isRemoveDeclinationReasonsAllowed = userHasPermissions(Permission.WORK_ORDER_REJECTED);

    const onAutocompleteFocus = function (this: AutoCompleteComponent) {
        this?.showPopup();
    };

    const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSendExternalIntegrationMessage(event.target.checked);
    };

    return (
        <Dialog onClose={onCancel} open={open}>
            <DialogWrap>
                <DialogTitle>
                    {`${t('are_you_sure_mark_this_unit_do_not_repair')}`}
                    <br />
                    {t('please_select_dnr_reason_below')}
                </DialogTitle>
                <AutoCompleteWrap>
                    <AutoCompleteComponent
                        type="text"
                        enabled={!loading}
                        placeholder={t('select_DNR_reason')}
                        dataSource={filteredExceptionReasons}
                        onChange={({value}: SelectExceptionReason) => setReason(value)}
                        fields={{text: 'Description', value: 'Description'}}
                        floatLabelType="Auto"
                        onFocus={onAutocompleteFocus}
                    />
                    <AddExceptionButton variant="contained" onClick={addDoNotRepairReason} color="primary" disabled={!Boolean(reason) || loading}>
                        {t('add')}
                    </AddExceptionButton>
                </AutoCompleteWrap>
                {loading && <LinearProgress color="primary" />}
                {Boolean(declinationReasons.length) && (
                    <>
                        <DeclinationReasonsTitle>{t('dnr_reason_is_required')}</DeclinationReasonsTitle>
                        <DeclinationReasonsTemplate
                            loading={loading}
                            declinationReasons={declinationReasons}
                            onRemoveClick={isRemoveDeclinationReasonsAllowed ? onRemoveDeclinationReasonClick : undefined}
                        />
                    </>
                )}
                <FormControlLabel
                    id="GeneratePO"
                    control={<Checkbox color="primary" checked={sendExternalIntegrationMessage} onChange={onCheckboxChange} />}
                    label={<b>{t('send_boes_in_service_message')}</b>}
                />
                <ButtonContainer>
                    <DialogActions>
                        <Button variant="contained" onClick={onCancel} color="secondary">
                            {t('canceled_action')}
                        </Button>
                        {itemType !== AssociatedTypes.WorkOrderRepairLine && (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => onReject(itemID, sendExternalIntegrationMessage)}
                                disabled={loading || !declinationReasons.length}>
                                {loading ? <CircularProgress size={30} color="primary" /> : `${t('do_not_repair')}`}
                            </Button>
                        )}
                    </DialogActions>
                </ButtonContainer>
            </DialogWrap>
        </Dialog>
    );
};

export default DoNotRepairDialog;
