import {Button} from '@mui/material';
import styled from 'styled-components';

export const PaperWrap = styled.div`
    width: 100%;
    padding: 24px;
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const UserName = styled.p`
    text-align: center;
    font-size: 18px;
`;

export const ButtonWrap = styled(Button)`
    margin-top: 20px;
`;
