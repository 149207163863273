import {RJSFSchema} from '@rjsf/utils';

import i18n from '../../i18n';
import {UnitType} from '../../models/UnitType';
import {EquipmentCategory} from '../../models/enumerations/EquipmentCategory';
import {WorkOrderStatus} from '../../models/enumerations/WorkOrderStatus';

type GetUiSchema = {
    unitTypes: UnitType[];
};

export const getSchema = ({unitTypes}: GetUiSchema): RJSFSchema => ({
    type: 'object',
    properties: {
        UnitINO: {
            type: 'string',
            title: i18n.t('unit'),
        },
        WorkOrderNumber: {
            type: 'string',
            title: i18n.t('estimate_work_order'),
        },
        UnitType: {
            type: 'number',
            title: i18n.t('unit_type'),
            default: 'Any',
            oneOf: [
                {const: -1, title: i18n.t('Any')},
                ...unitTypes
                    .filter((unitType) => unitType.Category === EquipmentCategory.Intermodal)
                    .map(({ID, Description}) => ({const: ID, title: i18n.t(Description)})),
            ],
        },
        Status: {
            type: 'number',
            title: i18n.t('status'),
            oneOf: [
                {const: -1, title: i18n.t('Any')},
                ...Object.values(WorkOrderStatus)
                    .filter((item) => item.IsMobile)
                    .map((item) => ({const: +item.Status, title: i18n.t(item.Description)})),
            ],
        },
        Lot: {
            type: 'string',
            title: i18n.t('lot'),
        },
        Row: {
            type: 'string',
            title: i18n.t('row'),
        },
        Priority: {
            type: 'boolean',
            enum: [true, false],
            title: i18n.t('priority'),
        },
    },
});

export const uiSchema = {
    Priority: {
        'ui:enumDisabled': [false],
    },
};
