import React, {useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {FMCSACheckDto} from '../../models/dtos/fmcsa-check.dto';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {FMCSADataContext} from '../../providers/fmcsa-data-provider';
import {fetchFiles} from '../../redux/file/file.actions';
import {selectFileState} from '../../redux/file/file.selectors';
import {fmcsaCheckSubmit} from '../../redux/unit/unit.actions';
import {selectUnitState} from '../../redux/unit/unit.selectors';
import {formatDate} from '../../utils/formatDate';

import FMCSACheck from './fmcsa-check.component';
import {FMCSAFormData, Status} from './fmcsa-check.schema';
import {Loader} from './fmcsa-check.styles';

const FMCSACheckContainer = () => {
    const dispatch = useDispatch();
    const {setFormData} = useContext(FMCSADataContext);
    const {loading: fmcsaLoading, fmcsaCheck} = useSelector(selectUnitState);
    const {loading: filesLoading, files} = useSelector(selectFileState);

    useEffect(() => {
        if (fmcsaCheck?.ID) {
            setFormData((prevData) => ({
                ...prevData,
                SystemDate: fmcsaCheck.SystemFMCSADate ? formatDate(fmcsaCheck.SystemFMCSADate) : '-',
                UnitINO: fmcsaCheck.UnitINO,
            }));
            dispatch(
                fetchFiles({
                    associateObjectID: fmcsaCheck.ID,
                    associatedTypeID: AssociatedTypes.FMCSACheck,
                }),
            );
        }
    }, [dispatch]);

    const onSubmit = useCallback(
        (data: FMCSAFormData) => {
            const dto: FMCSACheckDto = {
                isBad: data.Status === Status.BAD,
            };

            if (data.Status === Status.BAD) {
                dto.stickerDate = data.StickerDate;
                dto.createEstimate = data.CreateEstimate;
            }

            dispatch(fmcsaCheckSubmit(dto));
        },
        [dispatch],
    );

    if (!fmcsaCheck) {
        return <Loader />;
    }

    return <FMCSACheck fmcsaLoading={fmcsaLoading} filesLoading={filesLoading} filesCount={files.length} onSubmit={onSubmit} />;
};

export default FMCSACheckContainer;
