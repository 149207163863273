import {WidgetProps} from '@rjsf/utils';
import {DropDownListComponent, PopupEventArgs} from '@syncfusion/ej2-react-dropdowns';
import React, {useCallback, useContext, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {JobCode} from '../../models/JobCode';
import {SyncfusionSelectEvent} from '../../models/types/SyncfusionSelectEvent';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {selectJobCodesState} from '../../redux/job-code/job-code.selectors';
import {selectSelectedOfflineEstimate} from '../../redux/offline-estimate/offline-estimate.selectors';
import dropDownFiltering from '../../utils/dropDownFiltering';

export const JobCodeDropDownInput = ({onChange, value, disabled}: WidgetProps) => {
    const {t} = useTranslation();
    const {jobCodes, loading: jobCodesLoading} = useSelector(selectJobCodesState);
    const workOrderEquipmentGroup =
        useSelector(selectSelectedOfflineEstimate)?.EquipmentGroupID ?? useContext(WorkOrderDataContext)?.workOrder?.EquipmentGroups;

    const options = useMemo(
        // Filter out non-active job code unless they are already selected.
        () =>
            jobCodes
                .filter(
                    (item) =>
                        item.ID === value ||
                        ((workOrderEquipmentGroup == undefined ||
                            item.WhyMade.some(({EquipmentGroupIDPriced}) => EquipmentGroupIDPriced[workOrderEquipmentGroup!])) &&
                            new Date(item.EffectiveStartDate) <= new Date() &&
                            (item.EffectiveEndDate === null || new Date() <= new Date(item.EffectiveEndDate))),
                )
                .map((item) => ({
                    ID: item.ID,
                    Description: `[${item.JobCodeNumber}] ${item.Description}`,
                })),
        [jobCodes],
    );

    const onSelect = useCallback(
        (event: SyncfusionSelectEvent<JobCode>) => {
            onChange(event.itemData.ID);
        },
        [onChange],
    );

    const onDropDownOpen = useCallback(
        ({popup}: PopupEventArgs) => {
            const input = popup.element.querySelector('input');

            if (input) {
                input.placeholder = t('search');
            }
        },
        [t],
    );

    return (
        <DropDownListComponent
            open={onDropDownOpen}
            placeholder={t('code')}
            value={value}
            select={onSelect}
            dataSource={options}
            filtering={dropDownFiltering(['Description'], 'contains')}
            allowFiltering
            floatLabelType="Auto"
            fields={{text: 'Description', value: 'ID'}}
            enabled={!jobCodesLoading && Boolean(options.length) && !disabled}
        />
    );
};
