import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Company} from '../../models/Company';
import {StationCompanyRelation} from '../../models/StationCompanyRelation';
import {selectCompanies} from '../../redux/company/company.selectors';
import {setUser} from '../../redux/user/user.actions';
import {selectUser} from '../../redux/user/user.selectors';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import theme from '../../theme';
import {CompanyDropDownList} from '../company/company.component';
import {SelectShopForm, SelectShopFormData} from '../select-shop-form/select-shop-form.component';

import {CompanyContainer, Container, RoleButton, RoleButtonContainer, UserContainer} from './admin-tools.styles';

export type AdminToolsProps = {
    selectedShop: StationCompanyRelation;
    selectedCompany: Company;
    onShopSelect: (props: SelectShopFormData) => void;
};

export const AdminTools = ({selectedShop, selectedCompany, onShopSelect}: AdminToolsProps) => {
    const dispatch = useDispatch();
    const companies = useSelector(selectCompanies);
    let user = useSelector(selectUser)!;
    const [, rerender] = useState({});

    const changeCompany = (companyID: number) => {
        user.Company = companies.find(({ID}) => ID === companyID)!;
        dispatch(setUser(user, ''));
        LocalStorage.set(LocalStorageKeys.SELECTED_USER, user);
        window.location.reload();
    };

    const toggleRole = (role: string) => {
        user.Roles = user.Roles.includes(role) ? user.Roles.filter((r) => r !== role) : user.Roles.concat(role);
        dispatch(setUser(user, ''));
        LocalStorage.set(LocalStorageKeys.SELECTED_USER, user);
        rerender({});
    };

    return (
        <Container>
            <h2 key={'AdminTools'} style={{margin: '20px 0 0 0', textAlign: 'center', color: theme.palette.primary.main}}>
                Admin Tools
            </h2>
            <CompanyContainer key={'CompanyContainer'}>
                <h3 key={'ChangeCompany'} style={{margin: '20px 0 0 0', textAlign: 'center', color: theme.palette.primary.main}}>
                    Change Company
                </h3>
                <CompanyDropDownList initialValue={user.Company.ID} onSubmit={changeCompany} />
            </CompanyContainer>
            <UserContainer key={'UserContainer'}>
                <SelectShopForm
                    key={'ChangeShop'}
                    initialValues={{shopID: selectedShop.ID, companyID: selectedCompany.ID}}
                    onSubmit={onShopSelect}
                    companies={companies}
                    all
                />
            </UserContainer>
            <RoleButtonContainer key={'RoleButtonContainer'}>
                <h3 key={'ToggleRoles'} style={{margin: '0', textAlign: 'center', color: theme.palette.primary.main}}>
                    Toggle Roles
                </h3>
                <h4 key={'Internal'} style={{margin: '20px 0 5px 0', textAlign: 'center', color: theme.palette.primary.main}}>
                    Internal
                </h4>
                <RoleButton key={'ADMIN'} hasrole={user.Roles.includes('ADMIN').toString()} onClick={() => toggleRole('ADMIN')}>
                    Admin
                </RoleButton>
                <RoleButton
                    key={'AUDITOR_SUPERVISOR'}
                    hasrole={user.Roles.includes('AUDITOR_SUPERVISOR').toString()}
                    onClick={() => toggleRole('AUDITOR_SUPERVISOR')}>
                    Escalation
                </RoleButton>
                <RoleButton key={'AUDITOR'} hasrole={user.Roles.includes('AUDITOR').toString()} onClick={() => toggleRole('AUDITOR')}>
                    Auditor
                </RoleButton>
                <RoleButton key={'INSPECTOR'} hasrole={user.Roles.includes('INSPECTOR').toString()} onClick={() => toggleRole('INSPECTOR')}>
                    Inspector
                </RoleButton>
                <h4 key={'Vendor'} style={{margin: '15px 0 5px 0', textAlign: 'center', color: theme.palette.primary.main}}>
                    Vendor
                </h4>
                <RoleButton
                    key={'VENDOR_SUPERVISOR'}
                    hasrole={user.Roles.includes('VENDOR_SUPERVISOR').toString()}
                    onClick={() => toggleRole('VENDOR_SUPERVISOR')}>
                    Supervisor
                </RoleButton>
                <RoleButton
                    key={'VENDOR_MECHANIC'}
                    hasrole={user.Roles.includes('VENDOR_MECHANIC').toString()}
                    onClick={() => toggleRole('VENDOR_MECHANIC')}>
                    Mechanic
                </RoleButton>
            </RoleButtonContainer>
        </Container>
    );
};
