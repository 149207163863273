import {RJSFSchema} from '@rjsf/utils';
import {t} from 'i18next';

import {ElectiveInspectionTypes} from '../../models/enumerations/ElectiveInspectionTypes';

export const getSchema = ({showSelectShopInput, isOfflineMode}: {showSelectShopInput: boolean; isOfflineMode: boolean}): RJSFSchema => ({
    type: 'object',
    required: ['unitINO', 'electiveInspectionType', 'priority'],
    properties: {
        unitINO: {
            type: 'string',
            title: 'Unit',
        },
        electiveInspectionType: {
            type: 'number',
            title: t('elective_inspection_type'),
            oneOf: Object.values(ElectiveInspectionTypes).map((item) => ({const: item.ID, title: t(item.Description)})),
            default: ElectiveInspectionTypes.PreRepair.ID,
        },
        ...(showSelectShopInput && {
            shopID: {
                readOnly: isOfflineMode,
                title: 'Shop',
                type: 'number',
            },
        }),
        priority: {
            title: 'Priority',
            type: 'boolean',
            default: false,
        },
    },
});

export const getUiSchema = () => ({
    shopID: {
        'ui:widget': 'select-shop',
    },
});
