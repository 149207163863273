import {ReduxAction} from '../../models/ReduxAction';

import {
    REPAIR_LINE_ERROR,
    REPAIR_LINE_REQUEST,
    REPAIR_LINES_RESPONSE,
    DELETE_REPAIR_LINE_RESPONSE,
    CREATE_REPAIR_LINE_RESPONSE,
    UPDATE_REPAIR_LINE_RESPONSE,
} from './repair-line.types';

export interface RepairLineState {
    error: Error | null;
    loading: boolean;
}

const initialState: RepairLineState = {
    error: null,
    loading: false,
};

export default function repairLineReducer(state = initialState, action: ReduxAction): RepairLineState {
    switch (action.type) {
        case REPAIR_LINE_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case REPAIR_LINE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case REPAIR_LINES_RESPONSE:
        case CREATE_REPAIR_LINE_RESPONSE:
        case UPDATE_REPAIR_LINE_RESPONSE:
        case DELETE_REPAIR_LINE_RESPONSE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}
