export const UNITS_REQUEST = 'UNITS_REQUEST';
export const UNITS_ERROR = 'UNITS_ERROR';
export const FETCH_UNITS_TYPES = 'FETCH_UNITS';
export const FETCH_UNITS_STATUS = 'FETCH_UNITS';
export const FMCSA_CHECK = 'FMCSA_CHECK';
export const RESET_FMCSA = 'RESET_FMCSA';
export const FMCSA_CHECK_SUBMIT = 'FMCSA_CHECK_SUBMIT';
export const FMCSA_CHECK_RESPONSE = 'FMCSA_CHECK_RESPONSE';
export const UNITS_TYPES_RESPONSE = 'UNITS_TYPES_RESPONSE';
export const UNITS_STATUS_RESPONSE = 'UNITS_STATUS_RESPONSE';
