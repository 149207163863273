import {t} from 'i18next';
import {IDBPDatabase, openDB, DBSchema} from 'idb';
import {enqueueSnackbar} from 'notistack';

import {Company} from '../models/Company';
import {CompanyType} from '../models/CompanyType';
import {ConditionCode} from '../models/ConditionCode';
import {ExceptionReason} from '../models/ExceptionReason';
import {Feature} from '../models/Feature';
import {FileInformation} from '../models/FileInformation';
import {Form} from '../models/Form';
import {JobCode} from '../models/JobCode';
import {Message} from '../models/Message';
import {OfflineEstimate} from '../models/OfflineEstimate';
import {Part} from '../models/Part';
import {RepairSize} from '../models/RepairSize';
import {ShopPricing} from '../models/ShopPricing';
import {Station} from '../models/Station';
import {StationCompanyRelation} from '../models/StationCompanyRelation';
import {UnitGroup} from '../models/UnitGroup';
import {UnitLocationCode} from '../models/UnitLocationCode';
import {UnitStatus} from '../models/UnitStatus';
import {UnitType} from '../models/UnitType';
import {User} from '../models/User';
import {WhyMadeCode} from '../models/WhyMadeCode';
import {WorkOrder} from '../models/WorkOrder';
import {ApplicationMode} from '../redux/application/application.types';
import localStorage, {LocalStorageKeys} from '../services/local-storage';

enum IndexedDBKeysV1 {
    SHOPS = 'shops',
    USERS = 'users',
    PARTS = 'parts',
    FEATURES = 'features',
    STATIONS = 'stations',
    JOB_CODES = 'job_codes',
    COMPANIES = 'companies',
    WORK_ORDERS = 'work_orders',
    UNITS_TYPES = 'units_types',
    REPAIR_SIZES = 'repair_sizes',
    UNITS_STATUS = 'units_status',
    WHY_MADE_CODES = 'why_made_codes',
    COMPANIES_TYPES = 'companies_types',
    CONDITION_CODES = 'condition_codes',
    EXCEPTION_REASONS = 'exception_reasons',
    UNIT_LOCATION_CODES = 'unit_location_codes',
    MESSAGES = 'messages',
    FILES = 'files',
}

// Not sure what happened to 2.

enum IndexedDBKeysV3 {
    FORMS = 'forms',
}

enum IndexedDBKeysV4 {
    OFFLINE_ESTIMATES = 'offline_estimates',
    UNIT_GROUPS = 'unit_groups',
    PRICING = 'pricing',
}

export const IndexedDBKeys = {...IndexedDBKeysV1, ...IndexedDBKeysV3, ...IndexedDBKeysV4};
export type IndexedDBKeys = IndexedDBKeysV1 | IndexedDBKeysV3 | IndexedDBKeysV4;

// If something should be changed in this scheme (like key, value or index):
//  1)  new interface should be created;
//  2)  ACTUAL_DB_VERSION variable should be increased;
//  3)  ACTUAL_DB_SCHEME variable should get created interface;
//  4)  new conditional block with mutations should be created in 'upgrade' method.
interface IMRSDBV3 extends DBSchema {
    readonly shops: {key: number; value: StationCompanyRelation};
    readonly users: {key: number; value: User};
    readonly parts: {key: number; value: Part};
    readonly features: {key: number; value: Feature};
    readonly stations: {key: number; value: Station};
    readonly job_codes: {key: number; value: JobCode};
    readonly companies: {key: number; value: Company};
    readonly work_orders: {key: number; value: WorkOrder};
    readonly units_types: {key: number; value: UnitType};
    readonly repair_sizes: {key: number; value: RepairSize};
    readonly units_status: {key: number; value: UnitStatus};
    readonly why_made_codes: {key: number; value: WhyMadeCode};
    readonly companies_types: {key: number; value: CompanyType};
    readonly condition_codes: {key: number; value: ConditionCode};
    readonly exception_reasons: {key: number; value: ExceptionReason};
    readonly unit_location_codes: {key: number; value: UnitLocationCode};
    readonly messages: {key: number; value: Message};
    readonly forms: {key: string; value: Form};
    readonly files: {key: number; value: FileInformation};
}

interface IMRSDBV4 extends IMRSDBV3 {
    readonly offline_estimates: {key: number; value: OfflineEstimate};
    readonly unit_groups: {key: number; value: UnitGroup};
    readonly pricing: {key: number; value: ShopPricing};
}

const DB_NAME = 'IMRS';
const ACTUAL_DB_VERSION = 4;
type ACTUAL_DB_SCHEME = IMRSDBV4;

class IndexedDB {
    private db: IDBPDatabase<ACTUAL_DB_SCHEME> | null = null;

    async openConnection() {
        this.db = await openDB(DB_NAME, ACTUAL_DB_VERSION, {
            upgrade(db, oldVersion) {
                // Cast the current database to the latest version.
                const v1Db = db as IDBPDatabase<ACTUAL_DB_SCHEME>;

                // This is initial db mutations
                // Only add new mutations for new db versions.
                if (oldVersion < 2) {
                    const tableNames = Object.values(IndexedDBKeysV1);
                    for (const tableName of tableNames) {
                        if (v1Db.objectStoreNames.contains(tableName)) {
                            continue;
                        } else {
                            // All of the the tables in V1 use ID as the keyPath.
                            v1Db.createObjectStore(tableName, {keyPath: 'ID'});
                        }
                    }
                }

                if (oldVersion < 3) {
                    const tableNames = Object.values(IndexedDBKeysV3);
                    for (const tableName of tableNames) {
                        if (v1Db.objectStoreNames.contains(tableName)) {
                            continue;
                        } else {
                            v1Db.createObjectStore(tableName, {keyPath: 'Name'});
                        }
                    }
                }

                if (oldVersion < 4) {
                    const tableNames = Object.values(IndexedDBKeysV4);
                    for (const tableName of tableNames) {
                        if (v1Db.objectStoreNames.contains(tableName)) {
                            continue;
                        }

                        switch (tableName) {
                            case IndexedDBKeys.OFFLINE_ESTIMATES: {
                                v1Db.createObjectStore(tableName, {keyPath: 'ID'});
                                break;
                            }
                            case IndexedDBKeys.UNIT_GROUPS:
                            case IndexedDBKeys.PRICING: {
                                v1Db.createObjectStore(tableName, {autoIncrement: true});
                                break;
                            }
                        }
                    }
                }
            },
        });
    }

    getMany = <T extends IndexedDBKeys>(key: T) => {
        return this.db?.getAll(key);
    };

    getOne = <T extends IndexedDBKeys>(key: T, index: ACTUAL_DB_SCHEME[T]['key']) => {
        return this.db?.get(key, index);
    };

    createOne = async <T extends IndexedDBKeys>(key: T, value: ACTUAL_DB_SCHEME[T]['value']): Promise<void> => {
        this.db?.add(key, value);
    };

    createMany = async <T extends IndexedDBKeys>(key: T, values: ACTUAL_DB_SCHEME[T]['value'][]): Promise<void> => {
        const transaction = await this.db?.transaction(key, 'readwrite');

        if (transaction) {
            await transaction.objectStore(key).clear();
            await Promise.all([...values.map((item) => transaction.store.add(item)), transaction.done]);
        }
    };

    updateOne = <T extends IndexedDBKeys>(key: T, value: ACTUAL_DB_SCHEME[T]['value']): void => {
        this.db?.put(key, value);
    };

    deleteOne = <T extends IndexedDBKeys>(key: T, index: ACTUAL_DB_SCHEME[T]['key']): void => {
        this.db?.delete(key, index);
    };
}

export default new IndexedDB();
