import {ColumnDirective, ColumnsDirective, DetailRow, Grid, GridComponent, GridModel, Inject} from '@syncfusion/ej2-react-grids';
import React, {useMemo} from 'react';

import {WorkOrderRepairLine} from '../../models/WorkOrderRepairLine';
import {RepairLineSpecialInstructionDto} from '../../models/dtos/repair-line-special-instruction-dto';
import {convertSpecialInstructionToSpecialInstructionDto} from '../../utils/convertSpecialInstructionToSpecialInstructionDto';

import {Title} from './repair-line-special-instructions-list.styles';

interface RepairLineSpecialInstructionsListProps {
    repairLines: WorkOrderRepairLine[];
    title: string;
}

const RepairLineSpecialInstructionsList = ({repairLines = [], title}: RepairLineSpecialInstructionsListProps) => {
    const specialInstructions: RepairLineSpecialInstructionDto[] = useMemo(
        () =>
            repairLines.flatMap(
                (rl) =>
                    rl.SpecialInstructionAuthorizations.map((specialInstructions) => ({
                        ...convertSpecialInstructionToSpecialInstructionDto(specialInstructions),
                        repairLineID: rl.ID,
                    })),
                [repairLines],
            ),
        [repairLines],
    );

    const dataBound = function (this: Grid) {
        // This try/catch need to avoid Syncfusion error
        try {
            this.detailRowModule.expandAll();
        } catch {}
    };

    const childGridOptions = useMemo<GridModel>(
        () => ({
            dataSource: specialInstructions,
            enableAdaptiveUI: true,
            rowRenderingMode: 'Vertical',
            queryString: 'repairLineID',
            load: function (this: Grid) {
                const parentRowData = this.parentDetails.parentRowData as {ID: string};
                this.parentDetails.parentKeyFieldValue = parentRowData.ID;
            },
            allowTextWrap: true,
            columns: [
                {
                    field: 'SpecialInstruction.Description',
                    headerText: 'Special Instruction',
                },
                {
                    field: 'AuthorizedBy',
                    headerText: 'Authorized By',
                },
                {
                    field: 'SpecialInstruction.MinimumAuthorizationLevel',
                    headerText: 'Authorization Level',
                },
            ],
        }),
        [specialInstructions],
    );

    return (
        <div className="e-adaptive-demo e-bigger repairline-comments">
            <div className="e-mobile-layout">
                <div className="e-mobile-content">
                    <Title>{title}</Title>
                    <GridComponent
                        dataBound={dataBound}
                        childGrid={childGridOptions}
                        enableAdaptiveUI
                        dataSource={repairLines}
                        rowRenderingMode="Vertical">
                        <ColumnsDirective>
                            <ColumnDirective field="JobCodeNumber" headerText="Code" />
                            <ColumnDirective field="JobCodeDescription" headerText="Desc" />
                            <ColumnDirective field="ConditionCodeDescription" headerText="Repair" />
                            <ColumnDirective
                                field="UnitLocationCodeDescription"
                                headerText="Loc"
                                template={(repairLine: WorkOrderRepairLine) =>
                                    repairLine.UnitLocationCodeDescription ? repairLine.UnitLocationCodeDescription : 'Unknown'
                                }
                            />
                            <ColumnDirective field="RepairQuantity" headerText="Qty" />
                        </ColumnsDirective>
                        <Inject services={[DetailRow]} />
                    </GridComponent>
                </div>
            </div>
        </div>
    );
};

export default RepairLineSpecialInstructionsList;
