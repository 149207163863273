import React, {ReactElement} from 'react';

import {Container} from './logo.styles';

const Logo = (): ReactElement => {
    return (
        <Container>
            <img alt="logo" src="/kaleris-logo.png" width="320" height="80" />
        </Container>
    );
};

export default Logo;
