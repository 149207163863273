import {DropDownListComponent} from '@syncfusion/ej2-react-dropdowns';
import React, {ReactElement, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Redirect, useLocation} from 'react-router-dom';

import {Company} from '../../models/Company';
import {StationCompanyRelation} from '../../models/StationCompanyRelation';
import {CompanyDto} from '../../models/dtos/company.dto';
import {StationCompanyRelationDto} from '../../models/dtos/station-company-relation.dto';
import {SyncfusionSelectEvent} from '../../models/types/SyncfusionSelectEvent';
import {IAuthResult, IAuthState} from '../../providers/auth-provider';
import {selectIsOfflineStatus} from '../../redux/application/application.selectors';
import {selectShops, selectShopState} from '../../redux/shop/shop.selectors';
import {selectUser} from '../../redux/user/user.selectors';
import theme from '../../theme';
import dropDownFiltering from '../../utils/dropDownFiltering';
import {ErrorLike} from '../../utils/errorLike';
import {isUserPrivileged} from '../../utils/isUserPrivileged';

import {Container, PaperWrap, UserName, ButtonWrap} from './select-shop-form.styles';

export type SelectShopFormData = {
    shopID: StationCompanyRelation['ID'];
    companyID?: Company['ID'];
};

export type SelectShopFormProps = {
    initialValues?: Partial<SelectShopFormData>;
    onSubmit: (data: SelectShopFormData) => void;
    all?: boolean;
    companies: CompanyDto[];
};

/**
 * Display a form to allow the user to select a shop from the list of available shops.
 *
 * @param {SelectShopFormProps} {initialValues, onSubmit}
 * @returns {ReactElement} Component to render.
 */
export const SelectShopForm = ({initialValues, onSubmit, all, companies}: SelectShopFormProps): ReactElement => {
    const user = useSelector(selectUser);
    const location = useLocation();
    const isOfflineMode = useSelector(selectIsOfflineStatus);

    if (!user) {
        // Display an error message for an unauthenticated user.
        const errorResult: IAuthResult = {authError: new ErrorLike('No shops are available for an unauthenticated user.')};
        const [authState, setAuthState] = useState({} as IAuthState);
        const [authResult, setAuthResult] = useState({} as IAuthResult);

        // Redirect to AuthSignInError and save the current location to come back to. Our state object containing the
        // current location and authentication result are passed in to.state but ends up in props.location.state.
        return <Redirect to={{pathname: '/session-error', state: {authResult: authResult, fromUrl: location}}} push={true} />;
    }

    const {t} = useTranslation();
    const shops = useSelector(selectShops);
    const {loading} = useSelector(selectShopState);
    const [companyID, setCompanyID] = useState(() => {
        if (initialValues?.companyID !== undefined) {
            return initialValues.companyID;
        }

        if (companies.length === 1) {
            return companies[0].ID;
        }

        return undefined;
    });

    const filteredShops = useMemo<StationCompanyRelationDto[]>(() => {
        if (companyID === undefined) {
            return [];
        }

        const userStations = user.Stations.map(({ID}) => ID);

        // If the user is privileged, they should get all shops.
        // If the user is a system company user, they should get all shops associated with their assigned stations.
        // Otherwise, only their station-company combos.
        return shops.filter(
            ({StationID, CompanyID, IsDeleted, Active}) =>
                !IsDeleted &&
                Active &&
                (isUserPrivileged(user.Roles) || ((companyID === 0 || companyID === CompanyID) && userStations.includes(StationID))),
        );
    }, [shops, companyID]);

    const [shopID, setShopID] = useState(
        initialValues?.shopID ? initialValues!.shopID : filteredShops.length === 1 ? filteredShops[0].ID : undefined,
    );

    const onCompanyChange = (event: SyncfusionSelectEvent<Company>) => {
        const userStations = user.Stations.map(({ID}) => ID);
        const fs = shops.filter(
            ({StationID, CompanyID, IsDeleted, Active}) =>
                !IsDeleted && Active && (event.itemData.ID === 0 || (event.itemData.ID === CompanyID && userStations.includes(StationID))),
        );

        setShopID(fs.length === 1 ? fs[0].ID : undefined);
        setCompanyID(event.itemData.ID);
    };

    const onShopChange = (event: SyncfusionSelectEvent<StationCompanyRelation>) => {
        return setShopID(event.itemData.ID);
    };

    const onSubmitClick = () => {
        if (shopID !== undefined && companyID !== undefined) {
            onSubmit({shopID, companyID});
        }
    };

    const isSubmitButtonDisabled =
        isOfflineMode || loading || shopID === undefined || (initialValues?.shopID === shopID && initialValues?.companyID === companyID);

    const shopTemplate = ({ShopCode, Description}: StationCompanyRelation) => {
        return <div>{`[${ShopCode}] ${Description}`}</div>;
    };

    return (
        <Container>
            <PaperWrap>
                {all ? (
                    <h3 key={'Label'} style={{margin: '20px 0 5px 0', textAlign: 'center', color: theme.palette.primary.main}}>
                        Change Shop
                    </h3>
                ) : (
                    <UserName>{`${t('user_name')}: ${user?.UserName ?? ''}`}</UserName>
                )}
                {companies.length > 1 && (
                    <div>
                        <DropDownListComponent
                            placeholder={t('company')}
                            value={companyID}
                            select={onCompanyChange}
                            dataSource={companies}
                            filtering={dropDownFiltering(['CompanyName'], 'contains', [' ', '[', ']', '-'])}
                            allowFiltering
                            floatLabelType="Auto"
                            fields={{text: 'CompanyName', value: 'ID'}}
                            enabled={!isOfflineMode && !loading}
                        />
                    </div>
                )}
                <div>
                    <DropDownListComponent
                        placeholder={t('shop')}
                        value={shopID}
                        select={onShopChange}
                        dataSource={filteredShops}
                        filtering={dropDownFiltering(['ShopCode', 'Description'], 'contains', [' ', '[', ']', '-'])}
                        allowFiltering
                        floatLabelType="Auto"
                        fields={{text: 'Description', value: 'ID'}}
                        itemTemplate={shopTemplate}
                        valueTemplate={shopTemplate}
                        enabled={!isOfflineMode && !loading && (companies.length > 1 ? companyID !== undefined : true)}
                        index={filteredShops.length === 1 ? 0 : undefined}
                    />
                </div>
                <ButtonWrap size="large" variant="contained" color="primary" onClick={onSubmitClick} disabled={isSubmitButtonDisabled} fullWidth>
                    {all ? 'Save' : t('submit')}
                </ButtonWrap>
            </PaperWrap>
        </Container>
    );
};
