import {Company} from '../../models/Company';
import {CompanyType} from '../../models/CompanyType';
import {ReduxAction} from '../../models/ReduxAction';

import {COMPANIES_ERROR, COMPANIES_REQUEST, COMPANIES_RESPONSE, COMPANIES_TYPES_RESPONSE, SELECT_COMPANY, UNSET_COMPANY} from './company.types';

export interface CompanyState {
    error: Error | null;
    loading: boolean;
    companies: Company[];
    companyType: CompanyType[];
    selectedCompany: Company | null;
}

const initialState: CompanyState = {
    error: null,
    loading: false,
    companies: [],
    companyType: [],
    selectedCompany: null,
};

export default function companyReducer(state = initialState, action: ReduxAction): CompanyState {
    switch (action.type) {
        case COMPANIES_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case COMPANIES_RESPONSE:
            return {
                ...state,
                loading: false,
                companies: action.payload,
            };
        case COMPANIES_TYPES_RESPONSE:
            return {
                ...state,
                loading: false,
                companyType: action.payload,
            };
        case COMPANIES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SELECT_COMPANY:
            return {
                ...state,
                selectedCompany: action.payload.company,
            };
        case UNSET_COMPANY:
            return {
                ...state,
                selectedCompany: null,
            };
        default:
            return state;
    }
}
