import {ListItemText} from '@mui/material';
import styled from 'styled-components';

export const BlackListItemText = styled(ListItemText)`
    text-align: center;
`;

export const GreenListItemText = styled(ListItemText)`
    color: ${({theme}) => theme.palette.primary.main};
    text-align: center;
`;

export const RedListItemText = styled(ListItemText)`
    color: ${({theme}) => theme.palette.error.main};
    text-align: center;
`;
