export enum Permission {
    WORK_ORDER_DELETE = 'work_order_delete',
    WORK_ORDER_SUBMITTED_FOR_APPROVAL = 'work_order_submitted_for_approval',
    WORK_ORDER_REPAIRS_DONE = 'work_order_repairs_done',
    WORK_ORDER_APPROVED = 'work_order_approved',
    WORK_ORDER_WORK_APPROVED = 'work_order_work_approved',
    WORK_ORDER_REJECTED = 'work_order_rejected',
    WORK_ORDER_DO_NOT_REPAIR = 'work_order_do_not_repair',
    WORK_ORDER_CANCELED = 'work_order_canceled',
    WORK_ORDER_CLOSED = 'work_order_closed',
    WORK_ORDER_EXPIRED = 'work_order_expired',
    WORK_ORDER_ESCALATION_WORK_APPROVED = 'work_order_escalation_work_approved',
    WORK_ORDER_ESCALATION = 'work_order_escalation',
    WORK_ORDER_REASSIGN_SHOP = 'work_order_reassign_shop',
    WORK_ORDER_GENERATE_PURCHASE_ORDER_CHECKBOX = 'work_order_generate_purchase_order_checkbox',
}
