export const getTimeZoneAbbreviation = (date: Date): string => {
    const timeZone = new Intl.DateTimeFormat('en-US', {
        timeZoneName: 'short',
    })
        .formatToParts(date)
        .find(({type}) => type === 'timeZoneName');

    if (!timeZone?.value) {
        return '';
    }

    // Correct value for generalized Time Zones in United States
    switch (timeZone.value) {
        case 'PST':
        case 'PDT': {
            return 'PT';
        }
        case 'MST':
        case 'MDT': {
            return 'MT';
        }
        case 'CST':
        case 'CDT': {
            return 'CT';
        }
        case 'EST':
        case 'EDT': {
            return 'ET';
        }
        default: {
            return timeZone.value;
        }
    }
};
