import axios, {isAxiosError, AxiosError, AxiosRequestConfig, AxiosResponse, Method} from 'axios';
import {XMLParser} from 'fast-xml-parser';
import {t} from 'i18next';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isPlainObject from 'lodash/isPlainObject';

import config from '../config';
import {checkApiError, ErrorObject} from '../utils/checkApiError';
import {sleep} from '../utils/sleep';

import LocalStorage, {LocalStorageKeys} from './local-storage';

const {baseUrl, casBaseUrl, appServiceUrl, noAuth} = config;

export interface RequestParams {
    readonly method: Method;
    readonly url: string;
    readonly data?: Record<string, any> | FormData | string;
    readonly params?: Record<string, string | number | boolean | undefined>;
    readonly timeout?: number | undefined;
    readonly headers?: Record<string, string>;
    readonly ignoreApiError?: boolean;
    readonly retriesCount?: number;
    readonly responseType?: AxiosRequestConfig['responseType'];
}

export type ApiResponse<T = undefined> = {
    data?: T;
    error?: ErrorObject;
    request?: Record<string, string>;
};

export class ApiRequest<T> {
    private static readonly PROXY_TICKET_HEADER = 'X-IMRS-Proxy-Ticket';
    private static readonly NETWORK_ERROR_MESSAGE = 'Network Error';
    private static readonly AUTHENTICATION_ERROR_MESSAGE = 'Authentication Error';

    readonly ignoreApiError;
    readonly retriesCount;

    // The Safari browser caches GET requests, and it can't be disabled, so random query parameter resolves this issue
    private static PGT_REQUEST_COUNTER = 0;

    private readonly casConfig?: AxiosRequestConfig;
    private readonly apiConfig: AxiosRequestConfig;

    constructor({method = 'get', url, data, params, timeout, headers, responseType, ignoreApiError = false, retriesCount = 10}: RequestParams) {
        this.ignoreApiError = ignoreApiError;
        this.retriesCount = retriesCount;

        this.apiConfig = {
            method: method,
            url: `${baseUrl}${url}`,
            params,
            headers,
            data,
            responseType,
            paramsSerializer: {indexes: null},
            timeout: timeout,
        };

        const proxyGrantingTicket = LocalStorage.get(LocalStorageKeys.PROXY_GRANTING_TICKET);

        if (!noAuth && proxyGrantingTicket) {
            this.casConfig = {
                method: 'get',
                url: `${casBaseUrl}/cas/proxy`,
                timeout: 5000,
                params: {'targetService': appServiceUrl, 'pgt': proxyGrantingTicket, pgtrc: ApiRequest.PGT_REQUEST_COUNTER++},
            };
        }
    }

    public runRequest = async (): Promise<ApiResponse<T>> => {
        try {
            // Use the proxy granting ticket to get a proxy ticket for each request.
            await this.proxyTicketRequest();

            return await this.apiRequest();
        } catch (error: Error | unknown) {
            if (
                (error instanceof Error && error.message === ApiRequest.NETWORK_ERROR_MESSAGE) ||
                (error instanceof Error && error.message.includes('timeout of'))
            ) {
                return {
                    error: {
                        message: t('unable_to_reach_server'),
                        messages: [t('unable_to_reach_server')],
                    },
                };
            }

            window.location.href = `${window.origin}/signout-auth`;

            return {
                error: {
                    message: t('unknown_error'),
                    messages: [t('unknown_error')],
                },
            };
        }
    };

    private apiRequest = async (
        availableRetries = this.retriesCount,
    ): Promise<Partial<Pick<AxiosResponse<T>, 'request' | 'data'> & {error: ErrorObject}>> => {
        try {
            const {data, request} = await axios.request(this.apiConfig);
            const error = !this.ignoreApiError && _isPlainObject(data) ? checkApiError(data) : undefined;

            return {
                request,
                error,
                data: _has(data, 'Data') ? data.Data : data,
            };
        } catch (error: AxiosError | Error | unknown) {
            if (isAxiosError(error) && (error.response?.status === 400 || error.response?.status === 500)) {
                // For webapi's BadRequest() response, look for the message.
                if (error.response.data?.Message) {
                    return {
                        error: {message: error.response.data?.Message, messages: [error.response.data?.Message]},
                    };
                } else {
                    return {
                        error: {message: t('unknown_error'), messages: [t('unknown_error')]},
                    };
                }
            }

            if (isAxiosError(error) && error.response?.status === 403) {
                // Access control says no.
                return {
                    error: {message: t('not_authorized'), messages: [t('not_authorized')]},
                };
            }

            /*
             * Sometimes CAS will timeout on a perfectly valid proxy ticket and the API will return a 407.
             * 407 requests should be retried a few times to make sure they really are logged out.
             * This happens when CAS gets too many requests in a short period of time or when the connection is so poor the proxy ticket aged out.
             * To avoid blasting the API with batches of retries, wait a pseudo random amount of time before trying again.
             * Hopefully, if we get back a 407 5 times in a row, they are really logged off.
             * It may be necessary to keep track of how long it takes from send to the 407 response to detect a poor internet connection.
             * If users with poor connections are getting logged off, this would be the place to do it.
             */
            if (availableRetries) {
                await sleep(Math.random() * 2000);

                return this.apiRequest(availableRetries - 1);
            }

            if (isAxiosError(error) && error.message === ApiRequest.NETWORK_ERROR_MESSAGE) {
                throw new Error(ApiRequest.NETWORK_ERROR_MESSAGE);
            }

            throw error;
        }
    };

    private proxyTicketRequest = async (availableRetries = this.retriesCount): Promise<void> => {
        if (!this.casConfig) {
            return undefined;
        }

        try {
            /*
             * Sometimes CAS will not respond to PGT requests.
             * This seems to only happen when we make 2 or more in rapid succession.
             * If it runs long, it should cancel after 3000 milliseconds and retry.
             */
            const response = await axios.request(this.casConfig);

            if (response.status !== 200 || !response.data) {
                throw new Error('Something wrong');
            }

            // Parse the XML response to pull the proxy ticket out.
            const parser = new XMLParser({removeNSPrefix: true});
            const parsedXml = parser.parse(response.data);

            const proxyTicket = _get(parsedXml, ['serviceResponse', 'proxySuccess', 'proxyTicket']);

            if (!proxyTicket) {
                throw new Error('Something wrong');
            }

            // Set the request header the API uses to the proxy ticket.
            if (this.apiConfig.headers) {
                this.apiConfig.headers[ApiRequest.PROXY_TICKET_HEADER] = proxyTicket;
            } else {
                this.apiConfig.headers = {[ApiRequest.PROXY_TICKET_HEADER]: proxyTicket};
            }
        } catch (error: AxiosError | Error | unknown) {
            /*
             * Sometimes CAS just kills the connection with a valid PGT.
             * Add a little bit of delay in case it takes a second for CAS to wake up and try again.
             */
            if (availableRetries) {
                await sleep(500);

                return this.proxyTicketRequest(availableRetries - 1);
            }

            if (isAxiosError(error) && error.message === ApiRequest.NETWORK_ERROR_MESSAGE) {
                throw new Error(ApiRequest.NETWORK_ERROR_MESSAGE);
            }

            throw new Error(ApiRequest.AUTHENTICATION_ERROR_MESSAGE);
        }
    };
}
