import {differenceInMilliseconds} from 'date-fns';
import {unionBy} from 'lodash';
import {takeLeading, call, put} from 'redux-saga/effects';

import config from '../../config';
import {ReduxAction} from '../../models/ReduxAction';
import {RepairSize} from '../../models/RepairSize';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {formatDate} from '../../utils/formatDate';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';

import {repairSizeError, repairSizeRequest, repairSizesResponse} from './repair-size.actions';
import {FETCH_REPAIR_SIZES} from './repair-size.types';

const {resourceCacheFallback} = config;

export function* fetchRepairSizes() {
    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_REPAIR_SIZES_REFRESH_TIME, LocalStorageKeys.LAST_REPAIR_SIZES_UPDATE_TIME);

    let cachedRepairSizes: RepairSize[] = [];

    if (lastUpdateTime) {
        cachedRepairSizes = yield call(IndexedDB.getMany, IndexedDBKeys.REPAIR_SIZES);

        if (differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
            yield put(repairSizesResponse(cachedRepairSizes));

            return;
        }
    }

    yield put(repairSizeRequest());

    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_REPAIR_SIZES_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<RepairSize[]> = yield call(request, {
        url: '/repairsizes',
        method: 'get',
        params: lastUpdateTime
            ? {
                  includeDeleted: true,
                  lastUpdateTime: formatDate({
                      date: lastUpdateTime,
                      ISOFormat: true,
                  }),
              }
            : undefined,
    });

    if (error) {
        yield put(repairSizeError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedRepairSizes) {
            yield put(repairSizesResponse(cachedRepairSizes));
        }
    }

    if (data) {
        const combinedRepairSizes = unionBy(data, cachedRepairSizes, 'ID').filter((repairSize) => !repairSize.IsDeleted);

        yield put(repairSizesResponse(combinedRepairSizes));

        yield call(IndexedDB.createMany, IndexedDBKeys.REPAIR_SIZES, combinedRepairSizes);
        LocalStorage.set(LocalStorageKeys.LAST_REPAIR_SIZES_UPDATE_TIME, requestTime);
    }
}

export default function* repairSizesRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_REPAIR_SIZES, fetchRepairSizes);
}
