import {push} from 'connected-react-router';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import WorkOrdersGrid from '../../components/work-orders-grid/work-orders-grid.component';
import {useHeader} from '../../hooks/use-header';
import {WorkOrder} from '../../models/WorkOrder';
import {selectSelectedShop} from '../../redux/shop/shop.selectors';
import {selectWorkOrders} from '../../redux/work-order/work-order.selectors';

import {HeaderTitle, Results} from './work-orders.styles';

const WorkOrders = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const shop = useSelector(selectSelectedShop);
    const workOrders = useSelector(selectWorkOrders(shop?.StationID ?? 0, shop?.CompanyID ?? 0));

    const renderMiddleElement = useCallback(() => <HeaderTitle>{t('work_orders')}</HeaderTitle>, [t]);

    useHeader({
        backToPath: '/',
        middleElement: renderMiddleElement,
    });

    const onRowClick = useCallback(
        ({data}: {data: WorkOrder}) => {
            dispatch(push(`/work-orders/${data.ID}`));
        },
        [dispatch],
    );

    return (
        <section>
            <Results>
                <p>
                    {t('total_results')}: {workOrders.length}
                </p>
            </Results>
            <WorkOrdersGrid data={workOrders} onRowClick={onRowClick} />
        </section>
    );
};

export default WorkOrders;
