import {Button, Dialog, DialogActions, DialogTitle} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {goOnline} from '../../redux/application/application.actions';
import {DialogWrap} from '../go-offline-dialog/go-offline-dialog.styles';

import {GoOfflineOnlineButtonProps} from './go-offline-online-button.types';

const GoOnlineButton = (({size = 'large', fullWidth = true}: GoOfflineOnlineButtonProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const goOnlineClick = () => dispatch(goOnline());

    return (
        <>
            <Button variant="contained" sx={{backgroundColor: '#000'}} onClick={() => setOpenDialog(true)} size={size} fullWidth={fullWidth}>
                {t('go_online')}
            </Button>
            <Dialog open={openDialog} onClose={() => setOpenDialog(true)}>
                <DialogWrap>
                    <DialogTitle>{t('go_online_confirmation')}</DialogTitle>
                    <DialogActions>
                        <Button color="primary" variant="contained" onClick={goOnlineClick}>
                            {t('continue')}
                        </Button>
                        <Button variant="contained" onClick={() => setOpenDialog(false)} color="secondary">
                            {t('canceled_action')}
                        </Button>
                    </DialogActions>
                </DialogWrap>
            </Dialog>
        </>
    );
}) as React.FC<GoOfflineOnlineButtonProps | {}>;

export default GoOnlineButton;
