import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import styled from 'styled-components';

export const IconNoConnection = styled(SignalWifiOffIcon)`
    color: red;
`;

export const Wrapper = styled.div`
    bottom: 2px;
    right: 2px;
    transform: scale(2);
    z-index: 500;
    opacity: 90%;
    position: fixed;
`;
