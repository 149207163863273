import React, {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import DetailsTitle from '../../components/details-title/details-title.component';
import PartsGrid from '../../components/parts-grid/parts-grid.component';
import {useHeader} from '../../hooks/use-header';
import {useQueryParams} from '../../hooks/use-query-params';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';

export type WorkOrderPartsProps = {
    backToPath?: string;
};

const WorkOrderPartsContainer = ({backToPath}: WorkOrderPartsProps) => {
    const {push} = useHistory();
    const queryParams = useQueryParams();
    const {workOrder, repairLines} = useContext(WorkOrderDataContext);

    const {UnitDetails: unitDetails, WorkOrderNumber: workOrderNumber} = workOrder || {};

    const onHeaderClick = useCallback(() => {
        push(`${backToPath}/${workOrder.ID}/header`, {menuPrevPath: location.pathname});
    }, [workOrder.ID, push]);

    const TitleTop = useCallback(() => {
        return <DetailsTitle onClick={onHeaderClick} unitINO={unitDetails?.UnitINO} workOrderNumber={workOrderNumber} />;
    }, [unitDetails?.UnitINO, workOrderNumber]);

    useHeader({
        backToPath: `${backToPath}/${workOrder.ID}`,
        middleElement: TitleTop,
    });

    const repairLineID = +(queryParams.get('repairLineID') || '');

    const {repairLinesWithRepairLineID, parts} = useMemo(() => {
        const workOrderRepairLines = repairLineID ? repairLines.filter(({ID}) => ID === repairLineID) : repairLines;

        const repairLinesWithRepairLineID = workOrderRepairLines.map((repairLine) => ({
            ...repairLine,
            repairLineID: repairLine.ID,
        }));

        const parts = workOrderRepairLines
            .map((repairLine) =>
                repairLine.Parts.map((part) => ({
                    ...part,
                    repairLineID: repairLine.ID,
                })),
            )
            .flat();

        return {repairLinesWithRepairLineID, parts};
    }, [repairLineID, repairLines]);

    return <PartsGrid repairLines={repairLinesWithRepairLineID} parts={parts} />;
};

export default WorkOrderPartsContainer;
