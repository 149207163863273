import {WidgetProps} from '@rjsf/utils';
import {DropDownListComponent, PopupEventArgs} from '@syncfusion/ej2-react-dropdowns';
import {addYears, endOfDay, isWithinInterval} from 'date-fns';
import _uniqBy from 'lodash/uniqBy';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {WhyMadeCode} from '../../models/WhyMadeCode';
import {WhyMadeCodeDto} from '../../models/dtos/why-made-code.dto';
import {SyncfusionSelectEvent} from '../../models/types/SyncfusionSelectEvent';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {selectJobCodesState} from '../../redux/job-code/job-code.selectors';
import {selectSelectedOfflineEstimate} from '../../redux/offline-estimate/offline-estimate.selectors';
import {fetchWhyMadeCodes} from '../../redux/why-made-code/why-made-code.actions';
import dropDownFiltering from '../../utils/dropDownFiltering';

export const WhyMadeCodeDropDownInput = ({onChange, formContext, value, disabled}: WidgetProps) => {
    const {JobCodeId, ID} = formContext.formData || {};
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [localValue, setLocalValue] = useState(value);
    const [options, setOptions] = useState<WhyMadeCodeDto[]>([]);
    const {jobCodes, loading: jobCodesLoading} = useSelector(selectJobCodesState);
    const workOrderEquipmentGroup =
        useSelector(selectSelectedOfflineEstimate)?.EquipmentGroupID ?? useContext(WorkOrderDataContext)?.workOrder?.EquipmentGroups;

    useEffect(() => {
        dispatch(fetchWhyMadeCodes());
    }, []);

    const onChangeHandler = useCallback(
        (newValue) => {
            setLocalValue(newValue);
            setTimeout(() => onChange(newValue), 0);
        },
        [onChange],
    );

    // The why made code dropdown list should only contain a unique list of all of WhyMade's WhyMadeCodes
    useEffect(() => {
        const jobCode = jobCodes.find(({ID}) => JobCodeId === ID);

        if (jobCode) {
            const whyMadeCodes = _uniqBy(
                jobCode.WhyMade.filter((item) => {
                    const startDate = new Date(item.EffectiveStartDate);
                    const endDate = endOfDay(new Date(item.EffectiveEndDate ?? addYears(new Date(), 100)));

                    return isWithinInterval(new Date(), {start: startDate, end: endDate});
                })
                    // Existing repair lines should have their currently selected why made even if it is unpriced.
                    .filter(
                        (item) =>
                            (ID > 0 && item.WhyMadeCode.ID === value) ||
                            workOrderEquipmentGroup == undefined ||
                            item.EquipmentGroupIDPriced[workOrderEquipmentGroup!],
                    )
                    .map(({WhyMadeCode}) => WhyMadeCode),
                'ID',
            );

            setOptions(whyMadeCodes);

            if (!whyMadeCodes.find(({ID}) => ID === value)) {
                const newValue = whyMadeCodes.length === 1 ? whyMadeCodes[0].ID : null;

                onChangeHandler(newValue);
            }
        } else {
            setOptions([]);
            onChangeHandler(null);
        }
    }, [JobCodeId, jobCodes, onChangeHandler, value]);

    const onSelect = useCallback(
        (event: SyncfusionSelectEvent<WhyMadeCode>) => {
            onChangeHandler(event.itemData.ID);
        },
        [onChangeHandler],
    );

    const onDropDownOpen = useCallback(
        ({popup}: PopupEventArgs) => {
            const input = popup.element.querySelector('input');

            if (input) {
                input.placeholder = t('search');
            }
        },
        [t],
    );

    return (
        <DropDownListComponent
            open={onDropDownOpen}
            placeholder={t('defect')}
            value={localValue}
            select={onSelect}
            dataSource={options}
            filtering={dropDownFiltering(['Description'], 'contains')}
            allowFiltering
            floatLabelType="Auto"
            enabled={!jobCodesLoading && Boolean(JobCodeId) && Boolean(options.length) && !disabled}
            fields={{text: 'Description', value: 'ID'}}
        />
    );
};
