import React from 'react';

import HeaderPageTitleControls from '../header-page-title-controls/header-page-title-controls.component';
import {HeaderPageMode} from '../header-page-title-controls/header-page-title-controls.types';

import {Container, Title} from './header-page-title.styles';

const HeaderPageTitle = ({
    mode,
    onSubmit,
    onCancel,
    onEdit,
    title,
    readOnly,
}: {
    mode: HeaderPageMode;
    onSubmit: () => void;
    onCancel: () => void;
    onEdit: () => void;
    title: string;
    readOnly?: boolean;
}) => {
    return (
        <Container>
            <Title>{title}</Title>
            {!readOnly ? <HeaderPageTitleControls mode={mode} onSubmit={onSubmit} onCancel={onCancel} onEdit={onEdit} /> : null}
        </Container>
    );
};

export default HeaderPageTitle;
