import {createSelector} from 'reselect';

import {selectCommentState} from '../comment/comment.selectors';
import {selectCompanyState} from '../company/company.selectors';
import {selectConditionCodesState} from '../condition-code/condition-code.selectors';
import {selectFeatureState} from '../feature/feature.selectors';
import {selectFileState} from '../file/file.selectors';
import {selectFormsState} from '../form-validation/form.selectors';
import {selectJobCodesState} from '../job-code/job-code.selectors';
import {selectMessagesState} from '../message/message.selectors';
import {selectPartState} from '../part/part.selectors';
import {selectPricingState} from '../pricing/pricing.selectors';
import {selectRepairLineState} from '../repair-line/repair-line.selectors';
import {selectRepairSizesState} from '../repair-size/repair-size.selectors';
import {State} from '../root-reducer';
import {selectShopState} from '../shop/shop.selectors';
import {selectStationState} from '../station/station.selectors';
import {selectUnitState} from '../unit/unit.selectors';
import {selectUnitGroupState} from '../unit-group/unit-group.selectors';
import {selectUnitLocationCodesState} from '../unit-location-code/user-location-code.selectors';
import {selectUserState} from '../user/user.selectors';
import {selectWhyMadeCodesState} from '../why-made-code/why-made-code.selectors';
import {selectWorkOrderState} from '../work-order/work-order.selectors';

import {ApplicationState} from './application.reducer';
import {ApplicationMode} from './application.types';

export const selectApplicationState = (state: State): ApplicationState => state.applicationReducer;

export const selectApplicationMode = createSelector(selectApplicationState, ({mode}) => mode);

export const selectIsOfflineStatus = createSelector(selectApplicationMode, (mode) => mode === ApplicationMode.OFFLINE);

export const selectAllLoaders = createSelector(
    selectShopState,
    selectUnitState,
    selectUserState,
    selectPartState,
    selectCommentState,
    selectCompanyState,
    selectStationState,
    selectJobCodesState,
    selectFeatureState,
    selectWorkOrderState,
    selectRepairSizesState,
    selectWhyMadeCodesState,
    selectConditionCodesState,
    selectUnitLocationCodesState,
    selectFormsState,
    selectMessagesState,
    selectFileState,
    selectRepairLineState,
    selectPricingState,
    selectUnitGroupState,
    (...states) => states.map((state) => state.loading),
);

export const selectGlobalLoading = createSelector(selectAllLoaders, (states) => states.some(Boolean));
