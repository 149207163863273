import {WorkOrder} from '../models/WorkOrder';
import {SearchDto} from '../models/dtos/search.dto';

const UNIT_TYPE_ANY_VALUE = -1;

const filterWorkOrders = <T extends WorkOrder>(workOrders: T[], search: SearchDto): T[] => {
    return workOrders.filter(({Status, Priority, Row, Lot, UnitDetails, WorkOrderNumber}) => {
        if (search.UnitINO && !UnitDetails.UnitINO.toUpperCase()?.includes(search.UnitINO.toUpperCase())) {
            return false;
        }

        if (search.WorkOrderNumber && !WorkOrderNumber.toUpperCase()?.includes(search.WorkOrderNumber.toUpperCase())) {
            return false;
        }

        if (search.UnitType !== UNIT_TYPE_ANY_VALUE && UnitDetails.UnitType !== search.UnitType) {
            return false;
        }

        if (search.Lot) {
            if (Lot === '' || !Lot.toUpperCase().includes(search.Lot.toUpperCase())) {
                return false;
            }
        }

        if (search.Row) {
            if (Row === '' || !Row.toUpperCase().includes(search.Row.toUpperCase())) {
                return false;
            }
        }

        if (search.Priority && Priority !== search.Priority) {
            return false;
        }

        if (search.Status !== UNIT_TYPE_ANY_VALUE && search.Status !== Status) {
            return false;
        }

        return true;
    });
};

export default filterWorkOrders;
