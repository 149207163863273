import {Tab, Tabs} from '@mui/material';
import {GridComponent, Predicate} from '@syncfusion/ej2-react-grids';
import {push} from 'connected-react-router';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Route, Switch, useLocation, useRouteMatch} from 'react-router-dom';

import {WorkOrderRoutes} from '../../components/routes/work-order-routes.component';
import {SearchForm} from '../../components/search-form/search-form.component';
import {SearchHeader} from '../../components/search-header/search-header.component';
import WorkOrdersGrid from '../../components/work-orders-grid/work-orders-grid.component';
import {WorkOrder} from '../../models/WorkOrder';
import {SearchDto} from '../../models/dtos/search.dto';
import {WorkOrderStatus} from '../../models/enumerations/WorkOrderStatus';
import {resetSearchParams, setSearchParams} from '../../redux/search/search.actions';
import {selectSearchParams} from '../../redux/search/search.selectors';
import {selectSelectedShop} from '../../redux/shop/shop.selectors';
import {selectEstimates, selectWorkOrders} from '../../redux/work-order/work-order.selectors';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import filterWorkOrders from '../../utils/filterWorkOrders';

import {PaperWrap, Container, Results} from './search.styles';

export enum SearchRoutes {
    MAIN = '/search',
    ESTIMATES = '/search/estimates',
    WORK_ORDERS = '/search/work-orders',
}

const Search = () => {
    const estimatesGridRef = useRef<GridComponent>(null);
    const workOrdersGridRef = useRef<GridComponent>(null);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {path} = useRouteMatch();
    const {pathname} = useLocation();
    const params = useSelector(selectSearchParams);
    const shop = useSelector(selectSelectedShop);
    const estimates = useSelector(selectEstimates(shop?.StationID ?? 0, shop?.CompanyID ?? 0));
    const workOrders = useSelector(selectWorkOrders(shop?.StationID ?? 0, shop?.CompanyID ?? 0));
    // These states need to store actual count of filtered estimates and work-orders including filtering by Syncfusion grid filter
    // Otherwise it ignores Syncfusion grid filtering and count doesn't update
    const [filteredEstimatesLength, setFilteredEstimatesLength] = useState<number | null>(null);
    const [filteredWorkOrdersLength, setFilteredWorkOrdersLength] = useState<number | null>(null);

    useEffect(() => {
        if (pathname === SearchRoutes.MAIN && LocalStorage.get(LocalStorageKeys.RESET_SEARCH_FILTER)) {
            dispatch(resetSearchParams());
        }
    }, [dispatch, pathname]);

    const estimateData = useMemo(() => {
        return filterWorkOrders(estimates, params);
    }, [estimates, params]);

    const workOrderData = useMemo(() => {
        return filterWorkOrders(workOrders, params);
    }, [params, workOrders]);

    const onRowClick = useCallback(
        ({data}: {data: WorkOrder}) => {
            setFilteredEstimatesLength(null);
            setFilteredWorkOrdersLength(null);
            const route = pathname === SearchRoutes.WORK_ORDERS ? 'work-orders' : 'estimates';

            dispatch(push(`${path}/${route}/${data.ID}`, {menuPrevPath: location.pathname}));
        },
        [dispatch, pathname],
    );

    const onTabChange = useCallback(
        (_: React.ChangeEvent<{}>, value: string) => {
            dispatch(push(value));
        },
        [dispatch],
    );

    const changeInputField = useCallback((data: SearchDto) => dispatch(setSearchParams(data)), [dispatch]);

    const submitSearch = useCallback(() => {
        LocalStorage.set(LocalStorageKeys.RESET_SEARCH_FILTER, false);
        updateCounts();
        if (params.Status < WorkOrderStatus.WorkApproved.Status) {
            dispatch(push(SearchRoutes.ESTIMATES));
        } else {
            dispatch(push(SearchRoutes.WORK_ORDERS));
        }
    }, [dispatch, params.Status]);

    const getTabLabelCount = (filteredLength: number | null, totalLength: number): string => {
        if (filteredLength === null || filteredLength === totalLength) {
            return totalLength.toString();
        }

        return `${filteredLength} of ${totalLength}`;
    };

    // Before a Syncfusion filter is set, use the search component filtered number.
    const estimatesTabLabel = `${getTabLabelCount(Math.min(filteredEstimatesLength ?? 99999, estimateData.length), estimates.length)} ${
        estimates.length === 1 ? t('estimate') : t('estimates')
    }`;

    const workOrdersTabLabel = `${getTabLabelCount(Math.min(filteredWorkOrdersLength ?? 99999, workOrderData.length), workOrders.length)} ${
        workOrders.length === 1 ? t('work_order') : t('work_orders')
    }`;

    const updateCounts = () => {
        if (workOrdersGridRef.current && estimatesGridRef.current) {
            setFilteredWorkOrdersLength(
                Object.keys(workOrdersGridRef.current.filterModule['values']).length
                    ? (workOrdersGridRef.current.getFilteredRecords() as WorkOrder[]).length
                    : workOrderData.length,
            );
            setFilteredEstimatesLength(
                Object.keys(estimatesGridRef.current.filterModule['values']).length
                    ? (estimatesGridRef.current.getFilteredRecords() as WorkOrder[]).length
                    : estimateData.length,
            );
        } else {
            // If the grids don't exist, use the search filtered data instead.
            setFilteredWorkOrdersLength(workOrderData.length);
            setFilteredEstimatesLength(estimateData.length);
        }
    };

    const onFilterChange = useCallback(
        (route: SearchRoutes) => (column: string, operator: string, value: Predicate['value']) => {
            if (workOrdersGridRef.current && estimatesGridRef.current) {
                switch (route) {
                    case SearchRoutes.ESTIMATES:
                        operator
                            ? workOrdersGridRef.current.filterByColumn(column, operator, value)
                            : workOrdersGridRef.current.clearFiltering([column]);
                        break;
                    case SearchRoutes.WORK_ORDERS:
                        operator
                            ? estimatesGridRef.current.filterByColumn(column, operator, value)
                            : estimatesGridRef.current.clearFiltering([column]);
                        break;
                }
                updateCounts();
            }
        },
        [],
    );

    return (
        <Switch>
            <Route exact path={[SearchRoutes.MAIN, SearchRoutes.ESTIMATES, SearchRoutes.WORK_ORDERS]}>
                <SearchHeader />
                {pathname === SearchRoutes.MAIN ? (
                    <Container>
                        <PaperWrap>
                            <SearchForm formData={params} changeInputField={changeInputField} submitSearch={submitSearch} />
                        </PaperWrap>
                    </Container>
                ) : (
                    <section>
                        <Results>
                            <p>{`${t('total_results')}: ${estimateData.length + workOrderData.length}`}</p>
                        </Results>
                        <Tabs value={pathname} onChange={onTabChange} variant="fullWidth" textColor="primary" indicatorColor="primary">
                            <Tab label={estimatesTabLabel} value={SearchRoutes.ESTIMATES} />
                            <Tab label={workOrdersTabLabel} value={SearchRoutes.WORK_ORDERS} />
                        </Tabs>
                        <div hidden={pathname !== SearchRoutes.ESTIMATES}>
                            <WorkOrdersGrid
                                gridRef={estimatesGridRef}
                                data={estimateData}
                                onRowClick={onRowClick}
                                onRefresh={updateCounts}
                                onFilterChange={onFilterChange(SearchRoutes.ESTIMATES)}
                            />
                        </div>
                        <div hidden={pathname !== SearchRoutes.WORK_ORDERS}>
                            <WorkOrdersGrid
                                gridRef={workOrdersGridRef}
                                data={workOrderData}
                                onRowClick={onRowClick}
                                onRefresh={updateCounts}
                                onFilterChange={onFilterChange(SearchRoutes.WORK_ORDERS)}
                            />
                        </div>
                    </section>
                )}
            </Route>
            <Route path={`${path}/work-orders`}>
                <WorkOrderRoutes basePath={`${path}/work-orders`} detailsTitle={t('work_order')} />
            </Route>
            <Route path={`${path}/estimates`}>
                <WorkOrderRoutes basePath={`${path}/estimates`} detailsTitle={t('estimate')} />
            </Route>
            <Redirect to={SearchRoutes.MAIN} />
            <Route path="*" render={() => <Redirect to="/" push />} />
        </Switch>
    );
};

export default Search;
