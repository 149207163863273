import {CircularProgress} from '@mui/material';
import styled from 'styled-components';

import {LinearProgressWithLabel} from '../linear-progress-with-value/linear-progress-with-value.component';

export const DialogWrap = styled.div`
    margin: 15px;

    .MuiInput-root {
        width: 100%;
        margin: 35px 0;
    }
`;

export const LinearProgressWrap = styled(LinearProgressWithLabel)`
    margin-top: 15px;
`;

export const Loader = styled(CircularProgress)`
    color: #ffffff;
`;
