import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {DialogWrap} from './confirming-dialog.styles';

type ConfirmDialogProps = {
    open: boolean;
    title: string;
    onCancel: () => void;
    onSubmit: () => void;
    submitButtonLabel: string;
};

const ConfirmDialog = ({open, submitButtonLabel, onCancel, title, onSubmit}: ConfirmDialogProps) => {
    const {t} = useTranslation();

    return (
        <Dialog onClose={onCancel} open={open}>
            <DialogWrap>
                <DialogTitle>{`${title}`}</DialogTitle>
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={onSubmit}>
                        {submitButtonLabel}
                    </Button>
                    <Button variant="contained" onClick={onCancel} color="secondary">
                        {t('canceled_action')}
                    </Button>
                </DialogActions>
            </DialogWrap>
        </Dialog>
    );
};

export default ConfirmDialog;
