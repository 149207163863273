import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import TextField from '@mui/material/TextField';
import {WidgetProps} from '@rjsf/utils';
import {t} from 'i18next';
import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {useAbac} from 'react-abac';

import {Permission} from '../../models/enumerations/Permission';
import {OfflineEstimateDataContext} from '../../providers/offline-estimate-data-provider';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';

export const GeneratePurchaseOrderCheckbox = ({formContext, readonly, onChange}: WidgetProps) => {
    const {initialValues, updateFieldValue} = formContext;
    const {userHasPermissions} = useAbac();
    const {workOrder} = useContext(WorkOrderDataContext);
    const {offlineEstimate} = useContext(OfflineEstimateDataContext);
    const [generatePO, setGeneratePO] = useState(Boolean(initialValues?.GeneratePO));
    const [PONumber, setPONumber] = useState(initialValues?.PurchaseOrderNumber ?? '');
    const isGeneratePOEditable = !readonly && userHasPermissions(Permission.WORK_ORDER_GENERATE_PURCHASE_ORDER_CHECKBOX);
    const showGeneratePOCheckbox = workOrder.Status < 30 || Boolean(offlineEstimate);

    useEffect(() => {
        setGeneratePO(Boolean(initialValues?.GeneratePO));
        setPONumber(initialValues?.PurchaseOrderNumber ?? '');
    }, [initialValues]);

    useEffect(() => {
        if (showGeneratePOCheckbox) {
            updateFieldValue('GeneratePO', generatePO);
        } else {
            onChange(PONumber);
        }
    }, [generatePO, updateFieldValue, PONumber, onChange, readonly]);

    const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setGeneratePO(event.target.checked);
    };
    const onTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPONumber(event.target.value);
    };

    return showGeneratePOCheckbox ? (
        <FormControlLabel
            disabled={!isGeneratePOEditable}
            id="GeneratePO"
            control={<Checkbox color="primary" checked={generatePO} onChange={onCheckboxChange} />}
            label={t('generate_po')}
        />
    ) : (
        <TextField disabled={readonly} value={PONumber} id="PurchaseOrderNumber" onChange={onTextFieldChange} label="PO Number" />
    );
};
