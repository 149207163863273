import AddIcon from '@mui/icons-material/Add';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Resizer from 'react-image-file-resizer';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {useHeader} from '../../hooks/use-header';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {createFile, fetchFiles} from '../../redux/file/file.actions';
import {selectFileState} from '../../redux/file/file.selectors';
import {enqueueSnackbar} from '../../redux/notistack/notistack.actions';
import {selectUnitState} from '../../redux/unit/unit.selectors';
import FilesViewModal from '../files-page/files-page.view-modal';

import {AddButton, Container, Count, GalleryWrap, ImageItem, Input, Label, HeaderTitle} from './fmcsa-file-page.styles';

const FMCSAFilePage = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {fmcsaCheck} = useSelector(selectUnitState);
    const {loading, files} = useSelector(selectFileState);
    const {readOnly} = useContext(WorkOrderDataContext);
    const [viewModal, setViewModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(0);

    const dataURItoFile = (dataURI: string, fileName: string) => {
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        let ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        let blob = new Blob([ia], {type: mimeString});

        // Strip the old file extension from the name and replace it with jpg.
        let extensionIndex = fileName.lastIndexOf('.');
        if (extensionIndex) {
            fileName = fileName.substring(0, extensionIndex) + '.jpg';
        }

        return new File([blob], fileName, {type: blob.type});
    };

    // NOTE: These parameters can be adjusted.
    const resizeFile = async (val: File) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                val,
                2000,
                2000,
                'JPEG',
                50,
                0,
                (uri) => {
                    resolve(dataURItoFile(uri.toString(), val.name));
                },
                'base64',
            );
        });
    };

    const handleFile = async (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();
        const reader = new FileReader();
        let file = (e.target as HTMLFormElement).files[0];

        if (!file.type.includes('image')) {
            return dispatch(
                enqueueSnackbar(t('invalid_image_format'), {
                    variant: 'error',
                }),
            );
        }

        file = await resizeFile(file);
        reader.onloadend = () => {
            const formData = new FormData();
            formData.append('File', file);
            formData.append('Info', file.name);
            if (fmcsaCheck?.ID) {
                dispatch(
                    createFile({
                        associateObjectID: fmcsaCheck?.ID,
                        associatedTypeID: AssociatedTypes.FMCSACheck,
                        file: formData,
                        src: reader.result,
                        type: 3,
                    }),
                );
            }
        };

        reader.readAsDataURL(file);
    };

    const onInputClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const element = e.target as HTMLInputElement;

        element.value = '';
    };
    const renderMiddleElement = useCallback(() => <HeaderTitle>{t('fmcsa_files')}</HeaderTitle>, [t]);

    useHeader({
        backToPath: viewModal ? setViewModal : '/fmcsa',
        middleElement: renderMiddleElement,
        setStateParam: !viewModal,
    });

    useEffect(() => {
        if (fmcsaCheck?.ID && !viewModal) {
            dispatch(
                fetchFiles({
                    associateObjectID: fmcsaCheck?.ID,
                    associatedTypeID: AssociatedTypes.FMCSACheck,
                }),
            );
        }
    }, [dispatch, viewModal]);

    const openViewFile = (index: number) => {
        setSelectedFile(index);
        setViewModal(true);
    };

    if (!fmcsaCheck) {
        return <Redirect to="/fmcsa" />;
    }

    return (
        <Container>
            {viewModal ? (
                <FilesViewModal
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    handleFile={handleFile}
                    setViewModal={setViewModal}
                    readOnly={readOnly}
                    files={files}
                />
            ) : (
                <>
                    <GalleryWrap>
                        {files.map((item, index) => (
                            <ImageItem key={item.ID} onClick={loading ? undefined : () => openViewFile(index)}>
                                {item.src ? (
                                    <img src={URL.createObjectURL(new Blob([item.src], {type: item.FileCategory}))} alt={item.OriginalFileName} />
                                ) : (
                                    <img src={item.URL} alt={item.OriginalFileName} />
                                )}
                            </ImageItem>
                        ))}
                    </GalleryWrap>
                    <Count>Count: {files.length}</Count>
                    {!readOnly ? (
                        <AddButton>
                            <Input
                                type="file"
                                multiple
                                accept="image/*;capture=camera"
                                onClick={onInputClick}
                                onChange={(e) => {
                                    handleFile(e);
                                }}
                                id="upload-file"
                            />
                            <Label htmlFor="upload-file">
                                <AddIcon />
                            </Label>
                        </AddButton>
                    ) : null}
                </>
            )}
        </Container>
    );
};

export default FMCSAFilePage;
