import {useSelector} from 'react-redux';

import {WorkOrderStatus} from '../models/enumerations/WorkOrderStatus';
import {selectUser} from '../redux/user/user.selectors';
import {selectWorkOrder} from '../redux/work-order/work-order.selectors';

const readOnlyStatutes = [
    WorkOrderStatus.Canceled.Status,
    WorkOrderStatus.WorkRejected.Status,
    WorkOrderStatus.Closed.Status,
    WorkOrderStatus.Expired.Status,
    WorkOrderStatus.DoNotRepair.Status,
    WorkOrderStatus.PartiallyApproved.Status,
    WorkOrderStatus.Approved.Status,
];

export const useIsWorkOrderReadOnly = (workOrderID: number) => {
    const user = useSelector(selectUser);
    const workOrder = useSelector(selectWorkOrder(workOrderID));

    if (!workOrder) {
        return true;
    }

    return (
        readOnlyStatutes.includes(workOrder.Status) ||
        (((user?.Roles.length === 1 && user.Roles.includes('VENDOR_MECHANIC')) ||
            (user?.Roles.length === 2 && user.Roles.includes('VENDOR_MECHANIC') && user.Roles.includes('EXTERNAL'))) &&
            workOrder.Status === WorkOrderStatus.RepairsDone.Status)
    );
};
