import React, {ReactElement, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {CircularProgressComponent} from '../components/circular-progress/circular-progress.component';
import {SelectShopForm, SelectShopFormData} from '../components/select-shop-form/select-shop-form.component';
import {StationCompanyRelation} from '../models/StationCompanyRelation';
import {selectCompany} from '../redux/company/company.actions';
import {fetchShops, selectShop} from '../redux/shop/shop.actions';
import {selectShopState} from '../redux/shop/shop.selectors';
import {selectUser} from '../redux/user/user.selectors';
import LocalStorage, {LocalStorageKeys} from '../services/local-storage';

export type ShopProviderProps = {
    children: ReactElement;
};

/**
 * Component that provides a list of Shops from the Redux state to child components.
 *
 * @param - {children}
 * @returns Rendered ShopProvider component.
 */
export const ShopProvider = ({children}: ShopProviderProps): ReactElement => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const {shops, selectedShop} = useSelector(selectShopState);
    const shopID = LocalStorage.get(LocalStorageKeys.SELECTED_SHOP_ID);
    const companyID = LocalStorage.get(LocalStorageKeys.SELECTED_COMPANY_ID);
    const [initialLoading, setInitialLoading] = useState(!(selectedShop || shopID));

    const companies = user?.Companies ?? [];

    // This gives us an indicator to tell if the component is mounted or not.
    const isMounted = useRef(false);
    useEffect(() => {
        console.debug('shopprovider was mounted');
        isMounted.current = true;
        return () => {
            isMounted.current = false;
            console.debug('shopprovider was unmounted');
        };
    }, []);

    const onShopsFetched = useCallback(
        (fetchedShops: StationCompanyRelation[]) => {
            console.debug('shopprovider isMounted=%d', isMounted.current);
            console.debug('in onShopsFetched initialLoading=%d, shopID=%d, selectedShop=%o', initialLoading, shopID, selectedShop);
            if (shopID && !selectedShop) {
                const shop = fetchedShops.find(({ID}) => shopID === ID);
                const company = companies.find(({ID}) => companyID === ID);

                if (shop && company) {
                    console.debug('calling dispatch(selectShop())');
                    console.debug('isMounted=%d', isMounted.current);
                    dispatch(selectCompany(company));
                    dispatch(selectShop(shop));
                }
            }
            setInitialLoading(false);
        },
        [initialLoading, shopID, companyID, selectedShop, companies, dispatch],
    );

    useEffect(() => {
        console.debug('shopprovider entered useEffect()');
        console.debug('shopprovider isMounted=%d', isMounted.current);
        dispatch(fetchShops({onFinishCallback: onShopsFetched}));
        return () => {
            console.debug('shopprovider exited useEffect()');
            console.debug('shopprovider isMounted=%d', isMounted.current);
        };
    }, [dispatch, onShopsFetched]);

    const onShopSelect = useCallback(
        ({shopID, companyID}: SelectShopFormData) => {
            const shop = shops.find(({ID}) => shopID === ID);
            const company = companies.find(({ID}) => companyID === ID);

            if (shop && company) {
                dispatch(selectCompany(company));
                dispatch(selectShop(shop));
            }
        },
        [dispatch, shops, companies],
    );

    if ((shops == null || shops.length === 0) && (initialLoading || !selectedShop)) {
        return <CircularProgressComponent />;
    }

    if (!selectedShop) {
        return <SelectShopForm onSubmit={onShopSelect} companies={companies} />;
    }

    return children;
};
