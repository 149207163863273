import styled from 'styled-components';

export const Counter = styled.div`
    background-color: #19857b;
    color: #fff;
    border-radius: 5px;
    display: inline-block;
    line-height: 1;
    padding: 6px 9px;
    cursor: pointer;
`;

export const ButtonDetails = styled.button`
    margin: 10px auto;
    background-color: #19857b;
    padding: 10px 20px;
    color: #fff;
    border: 1px solid #000000;
    border-radius: 18px;
    cursor: pointer;
`;
