import {Button, Stack} from '@mui/material';
import {FormProps} from '@rjsf/core';
import {Form} from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import {t} from 'i18next';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {SearchDto} from '../../models/dtos/search.dto';
import {resetSearchParams} from '../../redux/search/search.actions';
import {selectUnitTypes} from '../../redux/unit/unit.selectors';
import {selectWorkOrderState} from '../../redux/work-order/work-order.selectors';

import {getSchema, uiSchema} from './schema-form.schema';
import {Loader, Reset} from './search-form.styles';

export type SearchFormProps = {
    formData: SearchDto;
    changeInputField: (values: SearchDto) => void;
    submitSearch: () => void;
};

export const SearchForm = ({formData, changeInputField, submitSearch}: SearchFormProps) => {
    const unitTypes = useSelector(selectUnitTypes);
    const {loading} = useSelector(selectWorkOrderState);
    const dispatch = useDispatch();

    const onChangeHandler: FormProps<SearchDto>['onChange'] = ({formData}) => {
        if (formData) {
            changeInputField(formData);
        }
    };

    const schema = useMemo(() => {
        return getSchema({unitTypes});
    }, [unitTypes]);

    return (
        <Form schema={schema} uiSchema={uiSchema} formData={formData} onSubmit={submitSearch} onChange={onChangeHandler} validator={validator}>
            <Stack spacing={2}>
                <Button size="large" variant="contained" color="primary" type="submit" disabled={loading} fullWidth>
                    {loading ? <Loader size={26} /> : t('search')}
                </Button>
                <Reset onClick={() => dispatch(resetSearchParams())} size="large" variant="contained" type="reset" disabled={loading} fullWidth>
                    {t('reset')}
                </Reset>
            </Stack>
        </Form>
    );
};
