import {Company} from '../../models/Company';
import {CompanyType} from '../../models/CompanyType';

import {
    UNSET_COMPANY,
    SELECT_COMPANY,
    FETCH_COMPANIES,
    COMPANIES_ERROR,
    COMPANIES_REQUEST,
    COMPANIES_RESPONSE,
    FETCH_COMPANIES_TYPES,
    COMPANIES_TYPES_RESPONSE,
} from './company.types';

export const companiesRequest = () => ({
    type: COMPANIES_REQUEST,
});

export const companyError = (error: Error | unknown) => ({
    type: COMPANIES_ERROR,
    payload: error,
});

export const companiesResponse = (data: Company[]) => ({
    type: COMPANIES_RESPONSE,
    payload: data,
});

export const companiesTypesResponse = (data: CompanyType[]) => ({
    type: COMPANIES_TYPES_RESPONSE,
    payload: data,
});

export const fetchCompaniesTypes = () => ({
    type: FETCH_COMPANIES_TYPES,
});

export const fetchCompanies = () => ({
    type: FETCH_COMPANIES,
});

/** Action to update the currently selected Company. */
export const selectCompany = (company: Company) => ({
    type: SELECT_COMPANY,
    payload: {company},
});

export const unsetCompany = () => ({
    type: UNSET_COMPANY,
});
