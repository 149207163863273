import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {DocumentTypeCodes} from '../../models/enumerations/DocumentTypeCodes';

export const FETCH_FILES = 'FETCH_FILES';
export const DELETE_FILE = 'DELETE_FILE';
export const CREATE_FILE = 'CREATE_FILE';
export const FILES_REQUEST = 'FILES_REQUEST';
export const FILES_RESPONSE = 'FILES_RESPONSE';
export const FILES_ERROR = 'FILES_ERROR';
export const CREATE_FILE_RESPONSE = 'CREATE_FILE_RESPONSE';
export const DELETE_FILE_RESPONSE = 'DELETE_FILE_RESPONSE';
export const CLEAR_FILE_STATE = 'CLEAR_FILE_STATE';

export type FetchFiles = {
    associateObjectID: number;
    associatedTypeID: AssociatedTypes;
};

export type CreateFile = {
    workOrderID?: number;
    associateObjectID?: number;
    associatedTypeID?: AssociatedTypes;
    file?: FormData;
    src?: string | ArrayBuffer | null;
    type?: DocumentTypeCodes;
};
