import {Query} from '@syncfusion/ej2-data';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-react-dropdowns';

const dropDownFiltering = (fieldsAndCharacters: string[], operator: string, ignore?: string[]) =>
    function (this: DropDownListComponent, args: FilteringEventArgs) {
        let text = args.text;

        if (ignore !== undefined && ignore.length) {
            ignore.forEach((t) => (text = text.replaceAll(t, '')));
        }

        if (text === '') {
            args.updateData(this.selectData);
        } else {
            let query = new Query();
            query = query.search(text, fieldsAndCharacters, operator, true, true);

            args.updateData(this.selectData, query);
        }
    };

export default dropDownFiltering;
