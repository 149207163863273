import styled from 'styled-components';

export const Container = styled.div`
    cursor: default;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto 24px;
    width: 170px;
    height: 90px;
`;
