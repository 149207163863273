import {push} from 'connected-react-router';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {AdminTools} from '../../components/admin-tools/admin-tools.component';
import {SelectShopForm, SelectShopFormData} from '../../components/select-shop-form/select-shop-form.component';
import config from '../../config';
import {useHeader} from '../../hooks/use-header';
import {selectCompany} from '../../redux/company/company.actions';
import {selectCompanyState} from '../../redux/company/company.selectors';
import {selectShop} from '../../redux/shop/shop.actions';
import {selectShopState} from '../../redux/shop/shop.selectors';
import {selectUser} from '../../redux/user/user.selectors';
import {HeaderTitle} from '../estimates/estimates.styles';

import {Container} from './my-account.styles';

const {noAuth} = config;

const MyAccount = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const {shops, selectedShop} = useSelector(selectShopState);
    const {selectedCompany} = useSelector(selectCompanyState);
    const {t} = useTranslation();

    const companies = user?.Companies ?? [];

    const renderMiddleElement = useCallback(() => <HeaderTitle>{t('my_account')}</HeaderTitle>, [t]);

    useHeader({
        backToPath: '/',
        middleElement: renderMiddleElement,
    });

    const onShopSelect = useCallback(
        ({shopID, companyID}: SelectShopFormData) => {
            const shop = shops.find(({ID}) => shopID === ID);
            const company = companies.find(({ID}) => companyID === ID);

            if (shop && company) {
                dispatch(selectCompany(company));
                dispatch(selectShop(shop));
                dispatch(push('/'));
            }
        },
        [dispatch, shops, companies],
    );

    return (
        <Container>
            <SelectShopForm
                initialValues={{shopID: selectedShop?.ID, companyID: selectedCompany?.ID}}
                onSubmit={onShopSelect}
                companies={companies}
            />
            {noAuth && selectedCompany && <AdminTools selectedShop={selectedShop!} selectedCompany={selectedCompany!} onShopSelect={onShopSelect} />}
        </Container>
    );
};

export default MyAccount;
