import {Comment} from '../../models/Comment';

import {FetchComments, COMMENTS_ERROR, COMMENTS_RESPONSE, FETCH_COMMENTS} from './comment.types';

export const fetchComments = (data: FetchComments) => ({
    type: FETCH_COMMENTS,
    payload: data,
});

export const commentResponse = (data: Comment[]) => ({
    type: COMMENTS_RESPONSE,
    payload: data,
});

export const commentsError = (error: Error | unknown) => ({
    type: COMMENTS_ERROR,
    payload: error,
});
