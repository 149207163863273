import {ReduxAction} from '../../models/ReduxAction';
import {UnitGroup} from '../../models/UnitGroup';

import {UNIT_GROUP_ERROR, UNIT_GROUP_RESPONSE, FETCH_UNIT_GROUPS} from './unit-group.types';

export interface UnitGroupState {
    error: Error | null;
    loading: boolean;
    unitGroups: UnitGroup[];
}

const initialState: UnitGroupState = {
    error: null,
    loading: false,
    unitGroups: [],
};

export default function unitGroupReducer(state = initialState, action: ReduxAction): UnitGroupState {
    switch (action.type) {
        case FETCH_UNIT_GROUPS:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case UNIT_GROUP_RESPONSE:
            return {
                ...state,
                loading: false,
                unitGroups: action.payload,
            };
        case UNIT_GROUP_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
