import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 90px 24px;
`;

export const Message = styled.section`
    text-align: center;
    color: ${({theme}) => theme.palette.error.main};
`;
