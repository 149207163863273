import {User} from '../models/User';

export const getUserNameOrDefault = (
    {FirstName, LastName, UserName}: Partial<Pick<User, 'FirstName' | 'LastName' | 'UserName'>>,
    defaultName: string = 'Unknown',
) => {
    if (FirstName && LastName) {
        return `${FirstName} ${LastName}`;
    }

    if (UserName) {
        return UserName;
    }

    return defaultName;
};
