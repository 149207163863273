import {
    ColumnDirective,
    ColumnsDirective,
    DetailRow,
    DialogEditEventArgs,
    Edit,
    Filter,
    Grid,
    GridComponent,
    GridModel,
    InfiniteScroll,
    Inject,
    QueryCellInfoEventArgs,
    Sort,
    Toolbar,
    ToolbarItems,
} from '@syncfusion/ej2-react-grids';
import {debounce} from 'lodash';
import React, {useContext, useMemo, useRef} from 'react';
import * as ReactDOM from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';

import {OfflineEstimateComment} from '../../models/OfflineEstimateComment';
import {OfflineEstimateLine} from '../../models/OfflineEstimateLine';
import {WorkOrderRepairLine} from '../../models/WorkOrderRepairLine';
import {CommentDto} from '../../models/dtos/comment-dto';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {WorkOrderRepairLineStatus} from '../../models/enumerations/WorkOrderRepairLineStatus';
import {OfflineEstimateDataContext} from '../../providers/offline-estimate-data-provider';
import {selectConditionCodes} from '../../redux/condition-code/condition-code.selectors';
import {selectJobCodes} from '../../redux/job-code/job-code.selectors';
import {
    createOfflineEstimateComment,
    deleteOfflineEstimateComment,
    updateOfflineEstimateComment,
} from '../../redux/offline-estimate/offline-estimate.actions';
import {selectUnitLocationCodes} from '../../redux/unit-location-code/user-location-code.selectors';
import {selectUser} from '../../redux/user/user.selectors';
import {selectWhyMadeCodes} from '../../redux/why-made-code/why-made-code.selectors';
import {convertCommentsToCommentDtos} from '../../utils/convertCommentToCommentDto';
import {convertOfflineEstimateLinesToOfflineEstimateLineDataGridDtos} from '../../utils/convertOfflineEstimateLineToOfflineEstimateLineDataGridDto';
import {getUserNameOrDefault} from '../../utils/getUserNameOrDefault';
import {isOfflineEstimateReadOnly} from '../../utils/isOfflineEstimateReadOnly';

import {Title} from './repair-line-comments-list.style';

interface OfflineRepairLineCommentsListProps {
    offlineEstimateLines: OfflineEstimateLine[];
    title: string;
    scrollToRepairLineID: number | null;
}

type ChangesRepairLineComments = {
    type: string;
    action: string;
    requestType: string;
    data: OfflineEstimateComment;
    row: HTMLElement;
};

const toolbarOptions: ToolbarItems[] = ['Add', 'Edit', 'Delete'];

const OfflineEstimateLineCommentsList = ({
    offlineEstimateLines = [],
    title,
    scrollToRepairLineID: repairLineID,
}: OfflineRepairLineCommentsListProps) => {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const {offlineEstimate} = useContext(OfflineEstimateDataContext);
    const isReadOnly = isOfflineEstimateReadOnly(offlineEstimate?.SentStatus);
    const jobCodes = useSelector(selectJobCodes);
    const whyMadeCodes = useSelector(selectWhyMadeCodes);
    const conditionCodes = useSelector(selectConditionCodes);
    const unitLocationCodes = useSelector(selectUnitLocationCodes);

    const gridRef = useRef<GridComponent>(null);
    const rowRef = useRef<null | HTMLElement>();

    const scrollToRow = debounce(() => {
        rowRef.current?.scrollIntoView();
    }, 500);

    const comments: OfflineEstimateComment[] = useMemo(() => {
        return offlineEstimateLines.flatMap(({ID, Comments}) => Comments.map((comment) => ({...comment, AssociatedID: ID})));
    }, [offlineEstimateLines]);

    const dataBound = function (this: GridComponent) {
        // This try/catch need to avoid Syncfusion error
        try {
            this.detailRowModule.expandAll();

            if (rowRef.current) {
                let index = rowRef.current.getAttribute('aria-rowindex');
                if (index) {
                    gridRef.current?.selectRow(parseInt(index, 10));
                }
            }
        } catch {}
    };

    const rowDataBound = function (args: ChangesRepairLineComments) {
        if (args.data.ID == repairLineID) {
            rowRef.current = args.row;
        }
    };

    const actionComplete = function (args: ChangesRepairLineComments) {
        if (args.requestType === 'refresh' && rowRef.current) {
            scrollToRow();
        }
    };

    const queryCellInfo = (args: QueryCellInfoEventArgs) => {
        const {UnitLocationCodeDescription} = args.data as WorkOrderRepairLine;

        if (args.column?.field === 'UnitLocationCodeDescription') {
            ReactDOM.render(<span>{UnitLocationCodeDescription ? UnitLocationCodeDescription : 'Unknown'}</span>, args.cell as Element);
        }
    };

    const childQueryCellInfo = (args: QueryCellInfoEventArgs) => {
        const {FormattedTimeStamp} = args.data as CommentDto;

        if (args.column?.field === 'TimeStampDate') {
            ReactDOM.render(<span>{FormattedTimeStamp}</span>, args.cell as Element);
        }
    };

    const childGridOptions = useMemo<GridModel>(
        () => ({
            dataSource: convertCommentsToCommentDtos(comments),
            enableAdaptiveUI: true,
            rowRenderingMode: 'Vertical',
            queryString: 'AssociatedID',
            allowSorting: true,
            allowFiltering: true,
            filterSettings: {
                type: 'Menu',
            },
            queryCellInfo: childQueryCellInfo,
            load: function (this: Grid) {
                const parentRowData = this.parentDetails.parentRowData as {ID: string};
                this.parentDetails.parentKeyFieldValue = parentRowData.ID;
            },
            created: function (this: Grid) {
                const repairLineID = Number(this.parentDetails.parentKeyFieldValue);
                const repairLine = offlineEstimateLines.find((item) => item.ID === repairLineID);

                const isStatusRejected = repairLine?.Status === WorkOrderRepairLineStatus.Rejected;

                if (isStatusRejected) {
                    const gridId = this.toolbarModule['gridID'];

                    setTimeout(() => {
                        this.toolbarModule.enableItems([`${gridId}_add`, `${gridId}_edit`, `${gridId}_delete`], false);
                    }, 0);
                }
            },
            actionComplete: (args: DialogEditEventArgs) => {
                if (args.requestType === 'add' || args.requestType === 'beginEdit') {
                    if (args?.dialog?.position?.Y) {
                        args.dialog.position.Y = 'top';
                    }
                }
            },
            actionBegin: function (this: Grid, state: ChangesRepairLineComments) {
                if (state.requestType === 'delete') {
                    const repairLineID = Number(this.parentDetails.parentKeyFieldValue);

                    return Array.isArray(state.data)
                        ? state.data.forEach((item: OfflineEstimateComment) =>
                              dispatch(
                                  deleteOfflineEstimateComment({
                                      offlineEstimateID: offlineEstimate.ID,
                                      associatedTypeID: AssociatedTypes.WorkOrderRepairLine,
                                      commentID: item.ID,
                                      associateObjectID: repairLineID,
                                  }),
                              ),
                          )
                        : dispatch(
                              deleteOfflineEstimateComment({
                                  offlineEstimateID: offlineEstimate.ID,
                                  associatedTypeID: AssociatedTypes.WorkOrderRepairLine,
                                  commentID: state.data.ID,
                                  associateObjectID: repairLineID,
                              }),
                          );
                }

                if (
                    user &&
                    state.action === 'add' &&
                    state.requestType === 'save' &&
                    this.parentDetails &&
                    !Number.isNaN(Number(this.parentDetails.parentKeyFieldValue))
                ) {
                    const {Message} = state.data as OfflineEstimateComment;

                    const comment: Omit<OfflineEstimateComment, 'ID'> = {
                        Message,
                        TimeStamp: new Date().toISOString(),
                        User: {
                            ID: user.ID,
                            FirstName: user.FirstName,
                            LastName: user.LastName,
                            UserName: user.UserName,
                        },
                    };

                    return dispatch(
                        createOfflineEstimateComment({
                            offlineEstimateID: offlineEstimate.ID,
                            associateObjectID: Number(this.parentDetails.parentKeyFieldValue),
                            associatedTypeID: AssociatedTypes.WorkOrderRepairLine,
                            comment,
                        }),
                    );
                }

                if (
                    state.action === 'edit' &&
                    state.requestType === 'save' &&
                    this.parentDetails &&
                    !Number.isNaN(Number(this.parentDetails.parentKeyFieldValue))
                ) {
                    const {ID, Message} = state.data as OfflineEstimateComment;

                    return dispatch(
                        updateOfflineEstimateComment({
                            offlineEstimateID: offlineEstimate.ID,
                            associateObjectID: Number(this.parentDetails?.parentKeyFieldValue),
                            associatedTypeID: AssociatedTypes.WorkOrderRepairLine,
                            commentID: ID,
                            comment: {
                                Message,
                            },
                        }),
                    );
                }
            },
            editSettings: isReadOnly
                ? undefined
                : {
                      allowEditing: true,
                      allowAdding: true,
                      allowDeleting: true,
                      mode: 'Dialog',
                  },
            allowTextWrap: true,
            toolbar: isReadOnly ? [] : toolbarOptions,
            columns: [
                {
                    field: 'UserName',
                    headerText: 'User',
                    width: 'auto',
                    allowEditing: false,
                    defaultValue: user ? getUserNameOrDefault(user) : 'Unknown',
                },
                {
                    type: 'date',
                    field: 'TimeStampDate',
                    headerText: 'Date',
                    allowEditing: false,
                    defaultValue: 'Now',
                },
                {field: 'Message', headerText: 'Message', validationRules: {required: true}},
            ],
        }),
        [comments],
    );

    const gridData = useMemo(
        () =>
            convertOfflineEstimateLinesToOfflineEstimateLineDataGridDtos(
                offlineEstimateLines,
                jobCodes,
                whyMadeCodes,
                conditionCodes,
                unitLocationCodes,
            ),
        [offlineEstimateLines, jobCodes, whyMadeCodes, conditionCodes, unitLocationCodes],
    );

    return (
        <div className="e-bigger repairline-comments">
            <Title>{title}</Title>
            <GridComponent
                dataBound={dataBound}
                rowDataBound={rowDataBound}
                childGrid={childGridOptions}
                allowPaging
                ref={gridRef}
                queryCellInfo={queryCellInfo}
                actionComplete={actionComplete}
                enableAdaptiveUI
                dataSource={gridData}
                enableInfiniteScrolling
                rowRenderingMode="Vertical">
                <ColumnsDirective>
                    <ColumnDirective field="JobCodeNumber" headerText="Code" />
                    <ColumnDirective field="JobCodeDescription" headerText="Desc" />
                    <ColumnDirective field="ConditionCodeDescription" headerText="Repair" />
                    <ColumnDirective field="UnitLocationCodeDescription" headerText="Loc" />
                    <ColumnDirective field="RepairQuantity" headerText="Qty" />
                </ColumnsDirective>
                <Inject services={[Edit, Filter, Sort, Toolbar, DetailRow, InfiniteScroll]} />
            </GridComponent>
        </div>
    );
};

export default OfflineEstimateLineCommentsList;
