import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {SelectProps} from '@mui/material/Select/Select';
import {t} from 'i18next';
import React, {useState} from 'react';

import {DocumentTypeCodes} from '../../models/enumerations/DocumentTypeCodes';

import {DialogWrap} from './dialog-photo-status.styles';

type DialogPhotoStatusProps = {
    onClose: (arg: DocumentTypeCodes) => void;
    open: boolean;
    onCancel: () => void;
};

const DialogPhotoStatus = ({onClose, open, onCancel}: DialogPhotoStatusProps) => {
    const [selectedValue, setSelectedValue] = useState<DocumentTypeCodes>(3);

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleSelect: SelectProps['onChange'] = (event) => {
        setSelectedValue(+(event.target as HTMLInputElement).value);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="dialog-photo-status" open={open}>
            <DialogWrap>
                <DialogTitle>Please select photo type</DialogTitle>
                <Select value={selectedValue} onChange={handleSelect}>
                    <MenuItem value={3}>{t('before_repair')}</MenuItem>
                    <MenuItem value={4}>{t('after_repair')}</MenuItem>
                </Select>
                <DialogActions>
                    <Button variant="contained" onClick={onCancel} color="secondary">
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleClose} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </DialogWrap>
        </Dialog>
    );
};

export default DialogPhotoStatus;
