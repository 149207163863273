import {Item} from 'linked-list';

import {ApiRequest, ApiResponse} from '../services/api-request';

/**
 * This class need for ApiRequestPool list
 */
export class ApiRequestListItem<T = any> extends Item {
    constructor(public readonly apiRequest: ApiRequest<T>, public readonly onFinishCallback: (response: ApiResponse<T>) => void) {
        super();
    }
}
