import styled from 'styled-components';

export const HeaderTitle = styled.h2`
    color: ${({theme}) => theme.palette.primary.main};
`;

export const Results = styled.div`
    padding: 0 15px;
    p {
        color: ${({theme}) => theme.palette.primary.main};
        font-size: 16px;
    }
`;
