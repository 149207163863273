import {push} from 'connected-react-router';
import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import HeaderPage from '../../components/header-page/header-page.component';
import {useUnitHeader} from '../../hooks/use-unit-header';
import {UnitTypeCodes} from '../../models/enumerations/UnitTypeCodes';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {getForm} from '../../redux/form-validation/form.actions';
import {selectForm} from '../../redux/form-validation/form.selectors';
import {FormName} from '../../redux/form-validation/form.types';
import {updateWorkOrder} from '../../redux/work-order/work-order.actions';
import {formatDate, formatDateForForm} from '../../utils/formatDate';

export type WorkOrderHeaderProps = {
    backToPath?: string;
};

export interface InitialValues {
    BITDueDate: string;
    BuiltDate: string;
    EquipmentGroups: number | string;
    FHWADueDate: string;
    ElectiveInspectionType: number;
    IsHardwireLights: boolean;
    IsRadialTire: boolean;
    Mate: string;
    Lot: string;
    PurchaseOrderNumber: string;
    ReferenceNumber: string;
    RefurbishedDate: string;
    RemanufactureDate: string;
    RepairDate: string;
    Row: string;
    Spot: string;
    Priority: boolean;
    GeneratePO: boolean;
    TireSize: string;
    UnitTypeDescription: string | UnitTypeCodes;
}

const WorkOrderHeader = ({backToPath}: WorkOrderHeaderProps) => {
    const dispatch = useDispatch();
    const {workOrder, readOnly} = useContext(WorkOrderDataContext);
    const form = useSelector(selectForm(FormName.WorkOrderHeader));
    const location = useLocation<{menuPrevPath?: string; basePath?: string}>();

    useEffect(() => {
        if (workOrder.ID) {
            dispatch(getForm(FormName.WorkOrderHeader));
        } else {
            dispatch(push('/work-orders'));
        }
    }, [dispatch, workOrder.ID]);

    useUnitHeader({
        workOrderNumber: workOrder.WorkOrderNumber,
        unitNumber: workOrder.UnitDetails?.UnitINO,
        redirectToPath: location.state?.menuPrevPath || `${backToPath}/${workOrder.ID}`,
        backToPath: location.state?.menuPrevPath || `${backToPath}/${workOrder.ID}`,
        basePath: location.state?.basePath,
    });

    const onUpdateWorkOrder = useCallback(
        (data: Record<string, unknown>) => {
            dispatch(updateWorkOrder({ID: workOrder!.ID, ...data}));
        },
        [dispatch, workOrder],
    );

    const initialValues = useMemo<InitialValues>(
        () => ({
            BITDueDate: workOrder?.BITDueDate && formatDateForForm(workOrder.BITDueDate),
            BuiltDate: (workOrder?.UnitDetails?.BuiltYear || '').toString(),
            EquipmentGroups: workOrder?.EquipmentGroups,
            FHWADueDate: workOrder?.FHWADueDate && formatDateForForm(workOrder.FHWADueDate),
            ElectiveInspectionType: workOrder?.ElectiveInspectionType,
            IsHardwireLights: workOrder?.UnitDetails?.IsHardwireLights,
            IsRadialTire: workOrder?.UnitDetails?.IsRadialTire,
            Lot: workOrder?.Lot,
            Mate: workOrder?.Mate,
            PurchaseOrderNumber: workOrder?.PurchaseOrderNumber,
            ReferenceNumber: workOrder?.ReferenceNumber,
            RefurbishedDate: workOrder?.RefurbishedDate,
            RemanufactureDate: workOrder?.UnitDetails?.RemanufactureDate,
            RepairDate: workOrder?.RepairDate && formatDate({date: workOrder.RepairDate, includeTime: true, checkForDateOnly: true}),
            Row: workOrder?.Row,
            Spot: workOrder?.Spot,
            Priority: workOrder?.Priority,
            GeneratePO: workOrder?.GeneratePO,
            TireSize: workOrder?.UnitDetails?.TireSize,
            UnitTypeDescription:
                (workOrder.UnitDetails?.UnitType ?? -1) >= 0
                    ? UnitTypeCodes[workOrder!.UnitDetails.UnitType as unknown as keyof typeof UnitTypeCodes]
                    : UnitTypeCodes[UnitTypeCodes.Unknown],
        }),
        [workOrder],
    );

    if (!workOrder) {
        return null;
    }

    return (
        <HeaderPage
            readOnly={readOnly}
            workOrderStatus={workOrder.Status}
            formSchemas={form}
            onUpdate={onUpdateWorkOrder}
            initialValues={initialValues}
        />
    );
};

export default WorkOrderHeader;
