import React, {useCallback, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

import {NetworkIndicator} from '../components/network-indicator/network-indicator';
import {NetworkStatus} from '../models/enumerations/NetworkStatus';

export const useNetworkStatus = () => {
    const [status, setStatus] = useState<NetworkStatus>(() => {
        switch (navigator.onLine) {
            case true: {
                return NetworkStatus.CONNECTED;
            }
            case false: {
                return NetworkStatus.DISCONNECTED;
            }
        }
    });

    // detect online/offline
    useEffect(() => {
        const handleOnline = () => setStatus(NetworkStatus.CONNECTED);
        const handleOffline = () => setStatus(NetworkStatus.DISCONNECTED);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    const NetworkStatusIndicator = useCallback(() => ReactDOM.createPortal(<NetworkIndicator status={status} />, document.body), [status]);

    return {NetworkStatusIndicator};
};
