import {WifiTethering, WifiTetheringOff} from '@mui/icons-material';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    height: 65px;
    width: 100%;
    border-bottom: 1px rgba(0, 0, 0, 0.25) solid;
    box-sizing: border-box;
`;

export const LeftContainer = styled.div`
    display: flex;
    text-align: left;
    align-self: center;
    padding-left: 15px;
`;

export const CenterContainer = styled.div`
    width: 100%;
    text-align: center;
    align-self: center;
`;

export const RightContainer = styled.div`
    display: flex;
    text-align: right;
    align-self: center;
    padding-right: 15px;
`;

export const OnlineIcon = styled(WifiTethering)`
    align-self: center;
    transform: scale(1.2);
    margin-right: 15px;
`;

export const OfflineIcon = styled(WifiTetheringOff)`
    align-self: center;
    transform: scale(1.2);
    margin-right: 15px;
`;
