import {UserHistory} from '../../models/History';
import {ReduxAction} from '../../models/ReduxAction';

import {HISTORY_ERROR, HISTORY_REQUEST, HISTORY_RESPONSE, RESET_HISTORY} from './history.types';

export const PER_PAGE = 30;

export interface HistoryState {
    error: Error | null;
    loading: boolean;
    history: UserHistory[];
    skip: number;
    take: number;
    noMoreData: boolean;
}

const initialState: HistoryState = {
    error: null,
    loading: false,
    history: [],
    skip: 0,
    take: PER_PAGE,
    noMoreData: false,
};

export default function historyReducer(state = initialState, action: ReduxAction): HistoryState {
    switch (action.type) {
        case HISTORY_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case HISTORY_RESPONSE:
            return {
                ...state,
                loading: false,
                history: action.payload.history,
                skip: action.payload.skip,
                noMoreData: action.payload.noMoreData,
            };
        case HISTORY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case RESET_HISTORY:
            return initialState;
        default:
            return state;
    }
}
