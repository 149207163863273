import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import {useHeader} from '../../hooks/use-header';
import {SearchRoutes} from '../../pages/search/search.component';

import {HeaderTitle} from './search-header.styles';

export const SearchHeader = (): null => {
    const {t} = useTranslation();
    const {pathname} = useLocation();

    const renderMiddleElement = useCallback(() => (pathname === SearchRoutes.MAIN ? <HeaderTitle>{t('search')}</HeaderTitle> : null), [pathname, t]);
    const backToPath = useMemo(() => (pathname === SearchRoutes.MAIN ? '/' : '/search'), [pathname]);

    useHeader({
        backToPath,
        middleElement: renderMiddleElement,
    });

    return null;
};
