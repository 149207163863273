import {differenceInDays} from 'date-fns';
import {createSelector} from 'reselect';

import {OfflineEstimateSentStatus} from '../../models/enumerations/OfflineEstimateSentStatus';
import {State} from '../root-reducer';

import {OfflineEstimateState} from './offline-estimate.reducer';

const RECENTLY_CREATED_OFFLINE_ESTIMATE_DAYS = 5;

export const selectOfflineEstimateState = (state: State): OfflineEstimateState => state.offlineEstimateReducer;

export const selectOfflineEstimates = createSelector(selectOfflineEstimateState, ({estimates}) => estimates);

export const selectOfflineEstimate = (offlineEstimateID: number) =>
    createSelector(selectOfflineEstimates, (estimates) => estimates.find(({ID}) => ID === offlineEstimateID));

export const selectNotLiveOfflineEstimates = createSelector(selectOfflineEstimateState, ({estimates}) =>
    estimates.filter(({SentStatus: status}) =>
        (
            [
                OfflineEstimateSentStatus.Unsent.ID,
                OfflineEstimateSentStatus.Queued.ID,
                OfflineEstimateSentStatus.Uploading.ID,
                OfflineEstimateSentStatus.Error.ID,
            ] as number[]
        ).includes(status),
    ),
);

export const selectRecentlyCreatedOfflineEstimates = createSelector(selectOfflineEstimateState, ({estimates}) =>
    estimates.filter(({CreatedDate: createdAt}) => differenceInDays(new Date(createdAt), new Date()) < RECENTLY_CREATED_OFFLINE_ESTIMATE_DAYS),
);

export const selectSelectedOfflineEstimate = createSelector(selectOfflineEstimateState, ({selectedOfflineEstimate}) => selectedOfflineEstimate);
