import {IconButton} from '@mui/material';
import styled from 'styled-components';

export const ErrorText = styled.span`
    color: red;
    display: block;
    white-space: normal;
    padding: 0 5px 0 5px;
`;

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const IconButtonStyled = styled(IconButton)`
    padding: 0;
    color: red;
`;
