import {push} from 'connected-react-router';
import React, {useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';

import {RepairLineDetailForm} from '../../components/repair-line-details-form/repair-line-detail-form.component';
import {useHeader} from '../../hooks/use-header';
import {OfflineEstimateLine} from '../../models/OfflineEstimateLine';
import {OfflineEstimateDataContext} from '../../providers/offline-estimate-data-provider';
import {getForm} from '../../redux/form-validation/form.actions';
import {selectForm} from '../../redux/form-validation/form.selectors';
import {FormName} from '../../redux/form-validation/form.types';
import {createOfflineEstimateLine, updateOfflineEstimateLine} from '../../redux/offline-estimate/offline-estimate.actions';
import {clearRepairLinePricing, fetchRepairLinePricing} from '../../redux/pricing/pricing.actions';

export type RepairLineDetailsProps = {
    backToPath?: string;
};

const OfflineEstimateLineDetails = ({backToPath}: RepairLineDetailsProps) => {
    const dispatch = useDispatch();
    const {params} = useRouteMatch<{lineId?: string}>();
    const {offlineEstimate} = useContext(OfflineEstimateDataContext);
    const offlineEstimateLineID = Number(params.lineId);
    const offlineEstimateLine = offlineEstimate.RepairLines.find(({ID}) => ID === offlineEstimateLineID);
    const form = useSelector(selectForm(FormName.RepairLine));

    useEffect(() => {
        dispatch(getForm(FormName.RepairLine));
    }, [dispatch]);

    useHeader({
        backToPath: `${backToPath}/${offlineEstimate.ID}`,
    });

    const onSubmit = useCallback(
        (data: OfflineEstimateLine) => {
            dispatch(
                offlineEstimateLine
                    ? updateOfflineEstimateLine({
                          offlineEstimateID: offlineEstimate.ID,
                          offlineEstimateLineID: offlineEstimateLine.ID,
                          offlineEstimateLine: data,
                      })
                    : createOfflineEstimateLine({
                          offlineEstimateID: offlineEstimate.ID,
                          offlineEstimateLine: data,
                      }),
            );

            dispatch(push(`${backToPath}/${offlineEstimate.ID}`));
        },
        [dispatch, offlineEstimateLine, offlineEstimate.ID],
    );

    const fetchPricing = useCallback(
        (jobCodeID: number, conditionCodeID: number, repairSizeID?: number, suppressError?: boolean) =>
            dispatch(
                fetchRepairLinePricing({
                    companyID: offlineEstimate.CompanyID,
                    stationID: offlineEstimate.StationID,
                    jobCodeID,
                    conditionCodeID,
                    equipmentGroupID: offlineEstimate.EquipmentGroupID,
                    repairSizeID,
                    suppressError,
                }),
            ),
        [dispatch, offlineEstimate],
    );
    const clearPricing = useCallback(() => dispatch(clearRepairLinePricing()), [dispatch]);

    return (
        <RepairLineDetailForm
            formSchemas={form}
            onSubmit={onSubmit}
            initialValues={offlineEstimateLine!}
            readOnly={false}
            fetchPricing={fetchPricing}
            clearPricing={clearPricing}
        />
    );
};

export default OfflineEstimateLineDetails;
