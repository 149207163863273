import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, useRouteMatch} from 'react-router-dom';

import FullPageFallback from '../components/full-page-fallback/full-page-fallback.component';
import {OfflineEstimate} from '../models/OfflineEstimate';
import {fetchOfflineEstimate, unselectOfflineEstimate} from '../redux/offline-estimate/offline-estimate.actions';
import {selectSelectedOfflineEstimate} from '../redux/offline-estimate/offline-estimate.selectors';

export type OfflineEstimateDataContext = {offlineEstimate: OfflineEstimate};

export const OfflineEstimateDataContext = React.createContext<OfflineEstimateDataContext>({
    offlineEstimate: {} as OfflineEstimate,
});

export type OfflineEstimateDataProviderProps = {
    basePath: string;
    children: ReactElement | ReactElement[];
};

export const OfflineEstimateDataProvider = ({children, basePath}: OfflineEstimateDataProviderProps) => {
    const dispatch = useDispatch();
    const {params} = useRouteMatch<{id?: string}>();
    const offlineEstimateID = Number(params.id);
    const offlineEstimate = useSelector(selectSelectedOfflineEstimate);
    const [initialLoading, setInitialLoading] = useState(!offlineEstimate);

    const onOfflineEstimateFetched = useCallback(() => {
        setInitialLoading(false);
    }, [offlineEstimateID]);

    useEffect(() => {
        dispatch(
            fetchOfflineEstimate({
                offlineEstimateID,
                onFinish: onOfflineEstimateFetched,
            }),
        );

        return () => {
            dispatch(unselectOfflineEstimate());
        };
    }, [offlineEstimateID, onOfflineEstimateFetched]);

    if (initialLoading) {
        return <FullPageFallback />;
    }

    if (!offlineEstimate) {
        return <Redirect to={basePath} />;
    }

    const providerData: OfflineEstimateDataContext = {
        offlineEstimate,
    };

    return <OfflineEstimateDataContext.Provider value={providerData}>{children}</OfflineEstimateDataContext.Provider>;
};
