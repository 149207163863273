import {Pricing} from '../../models/Pricing';
import {ShopPricing} from '../../models/ShopPricing';

import {
    FETCH_REPAIR_LINE_PRICING,
    FetchRepairLinePricing,
    REPAIR_LINE_PRICING_RESPONSE,
    CLEAR_REPAIR_LINE_PRICING,
    PRICING_ERROR,
    PRICING_REQUEST,
    SHOP_PRICING_RESPONSE,
    FETCH_SHOP_PRICING,
    SHOP_PRICING_ERROR,
} from './pricing.types';

export const fetchRepairLinePricing = (data?: FetchRepairLinePricing) => ({
    type: FETCH_REPAIR_LINE_PRICING,
    payload: data,
});

export const clearRepairLinePricing = () => ({
    type: CLEAR_REPAIR_LINE_PRICING,
});

export const repairLinePricingResponse = (data: Pricing) => ({
    type: REPAIR_LINE_PRICING_RESPONSE,
    payload: data,
});

export const fetchShopPricing = () => ({
    type: FETCH_SHOP_PRICING,
});

export const pricingRequest = () => ({
    type: PRICING_REQUEST,
});

export const shopPricingResponse = (data: ShopPricing) => ({
    type: SHOP_PRICING_RESPONSE,
    payload: data,
});

export const shopPricingError = (data: Error | unknown) => ({
    type: SHOP_PRICING_ERROR,
    payload: data,
});

export const pricingError = (error: boolean) => ({
    type: PRICING_ERROR,
    payload: error,
});
