import {t} from 'i18next';

type DataResponse<T = unknown> = {
    Count: number;
    Data: T;
    ErrorMessages: {Message: string}[];
    ReturnResult: boolean;
    Timestamp: string | null;
};

export type ErrorObject = {
    message: string;
    messages: string[];
};

export const checkApiError = (data: DataResponse): ErrorObject | undefined => {
    if (data.hasOwnProperty('ReturnResult') && !data.ReturnResult) {
        let messages: string[] = [t('unknown_error')];

        if (Array.isArray(data?.ErrorMessages)) {
            messages = data.ErrorMessages.map(({Message}) => Message);
        }

        return {messages, message: messages.join('. ')};
    }

    return undefined;
};
