import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import OfflineEstimateCommentsComponent from '../../pages/offline-estimate-comments/offline-estimate-comments.component';
import OfflineEstimateDetailsContainer from '../../pages/offline-estimate-details/offline-estimate-details.container';
import OfflineEstimateFilesPage from '../../pages/offline-estimate-files-page/offline-estimate-files-page.container';
import OfflineEstimateLineDetails from '../../pages/offline-estimate-line-detail/offline-estimate-line-detail.component';
import OfflineEstimatePartsContainer from '../../pages/offline-estimate-parts/offline-estimate-parts.container';
import OfflineEstimates from '../../pages/offline-estimates/offline-estimates.component';
import OfflineEstimateHeader from '../../pages/work-order-header/offline-estimate-header.component';
import {OfflineEstimateDataProvider} from '../../providers/offline-estimate-data-provider';

const basePath = '/offline-estimates';

export const OfflineEstimateRoutes = () => (
    <Switch>
        <Route exact path={basePath} component={OfflineEstimates} />
        <Route path={`${basePath}/:id(\\d+)`}>
            <OfflineEstimateDataProvider basePath={basePath}>
                <Route exact path={`${basePath}/:id`} render={() => <OfflineEstimateDetailsContainer backToPath={basePath} />} />
                <Route exact path={`${basePath}/:id/files`} render={() => <OfflineEstimateFilesPage backToPath={basePath} />} />
                <Route exact path={`${basePath}/:id/header`} render={() => <OfflineEstimateHeader backToPath={basePath} />} />
                <Route exact path={`${basePath}/:id/line`} render={() => <OfflineEstimateLineDetails backToPath={basePath} />} />
                <Route exact path={`${basePath}/:id/line/:lineId`} render={() => <OfflineEstimateLineDetails backToPath={basePath} />} />
                <Route exact path={`${basePath}/:id/parts`} render={() => <OfflineEstimatePartsContainer backToPath={basePath} />} />
                <Route exact path={`${basePath}/:id/comments`} render={() => <OfflineEstimateCommentsComponent backToPath={basePath} />} />
            </OfflineEstimateDataProvider>
        </Route>
        <Route path="*" render={() => <Redirect to="/" push />} />
    </Switch>
);
