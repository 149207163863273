import {routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';
import createSagaMiddleware from 'redux-saga';

export const history = createBrowserHistory({});

import createRootReducer from './root-reducer';
import sagas from './root-saga';

const composeEnhancers = composeWithDevTools({
    maxAge: 25,
    latency: 1500,
});

export default (() => {
    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(
        createRootReducer(history),
        composeEnhancers(
            applyMiddleware(
                sagaMiddleware,
                routerMiddleware(history), // for dispatching history actions
            ),
        ),
    );

    sagaMiddleware.run(sagas);

    return store;
})();
