import {FMCSA} from '../../models/FMCSA';
import {UnitStatus} from '../../models/UnitStatus';
import {UnitType} from '../../models/UnitType';
import {FMCSACheckDto} from '../../models/dtos/fmcsa-check.dto';

import {
    UNITS_REQUEST,
    UNITS_ERROR,
    UNITS_TYPES_RESPONSE,
    UNITS_STATUS_RESPONSE,
    FETCH_UNITS_TYPES,
    FETCH_UNITS_STATUS,
    FMCSA_CHECK_RESPONSE,
    FMCSA_CHECK,
    FMCSA_CHECK_SUBMIT,
    RESET_FMCSA,
} from './unit.types';

export const unitsRequest = () => ({
    type: UNITS_REQUEST,
});

export const unitsError = (error: Error | unknown) => ({
    type: UNITS_ERROR,
    payload: error,
});

export const unitsTypesResponse = (data: UnitType[]) => ({
    type: UNITS_TYPES_RESPONSE,
    payload: data,
});

export const unitsStatusResponse = (data: UnitStatus[]) => ({
    type: UNITS_STATUS_RESPONSE,
    payload: data,
});

export const fetchUnitsTypes = () => ({
    type: FETCH_UNITS_TYPES,
});

export const fetchUnitsStatus = () => ({
    type: FETCH_UNITS_STATUS,
});

export const fetchFmcsaCheck = (unitINO: string) => ({
    type: FMCSA_CHECK,
    payload: {unitINO},
});

export const fmcsaCheckSubmit = (data: FMCSACheckDto) => ({
    type: FMCSA_CHECK_SUBMIT,
    payload: data,
});

export const fmcsaCheckResponse = (data: FMCSA) => ({
    type: FMCSA_CHECK_RESPONSE,
    payload: data,
});

export const resetFMCSA = () => ({
    type: RESET_FMCSA,
});
