import {ConditionCode} from '../../models/ConditionCode';
import {ReduxAction} from '../../models/ReduxAction';

import {CONDITION_CODE_ERROR, CONDITION_CODE_REQUEST, CONDITION_CODES_RESPONSE} from './condition-code.types';
export interface ConditionCodeState {
    error: Error | null;
    loading: boolean;
    conditionCodes: ConditionCode[];
}

const initialState: ConditionCodeState = {
    error: null,
    loading: false,
    conditionCodes: [],
};

export default function conditionCodesReducer(state = initialState, action: ReduxAction): ConditionCodeState {
    switch (action.type) {
        case CONDITION_CODE_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case CONDITION_CODES_RESPONSE:
            return {
                ...state,
                loading: false,
                conditionCodes: action.payload,
            };
        case CONDITION_CODE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
