import {TextField} from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const PaperWrap = styled.div`
    width: 100%;
    padding: 24px;

    h2 {
        font-weight: normal;
        text-align: center;
        font-size: 64px;
        line-height: 75px;
        color: #19857b;
    }
`;

export const InputUnitINO = styled(TextField)`
    width: 100%;
    margin: 20px 0px !important;
`;

export const HeaderTitle = styled.h2`
    color: ${({theme}) => theme.palette.primary.main};
`;
