import {createElement} from '@syncfusion/ej2-base';
import {DropDownList} from '@syncfusion/ej2-dropdowns';
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    InfiniteScroll,
    Filter,
    Inject,
    Sort,
    PageSettingsModel,
    QueryCellInfoEventArgs,
    Column,
    Predicate,
} from '@syncfusion/ej2-react-grids';
import React, {ReactElement, RefObject, useCallback, useMemo, useRef} from 'react';
import * as ReactDOM from 'react-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {useViewportSize} from '../../hooks/use-viewport-size';
import {WorkOrder} from '../../models/WorkOrder';
import {WorkOrderStatus} from '../../models/enumerations/WorkOrderStatus';
import {selectShops} from '../../redux/shop/shop.selectors';
import {addLotRowSpotField} from '../../utils/addLotRowSpotField';
import {booleanAccessor} from '../../utils/booleanAccessor';
import {Fallback} from '../fallback/fallback.component';

export type WorkOrdersGridProps<T extends WorkOrder> = {
    data: T[];
    onRowClick?: ({data}: {data: T}) => void;
    gridRef?: RefObject<GridComponent>;
    onFilterChange?: (column: string, operator: string, value: Predicate['value']) => void;
    onRefresh?: () => void;
};

const pageSettings: PageSettingsModel = {pageSize: 10000};

interface FilteringWorkOrdersData {
    action: string;
    columns: Predicate[];
    currentFilterColumn: Column;
    currentFilteringColumn: string;
    requestType: string;
}
type readFilterStatus = {
    column: Column;
    element: HTMLInputElement;
    fltrObj: Filter;
    operator: string;
};

type WriteFilterStatus = {column: Column; target: Element; parent: GridComponent; filteredValue: number};

const WorkOrdersGrid = <T extends WorkOrder>({onFilterChange, onRefresh, gridRef, data, onRowClick}: WorkOrdersGridProps<T>): ReactElement => {
    const customDropDownRef = useRef<DropDownList>();
    const shops = useSelector(selectShops);
    const {width} = useViewportSize();
    const {t} = useTranslation();
    const workOrders = useMemo(() => addLotRowSpotField(data), [data]);

    const actionComplete = useCallback(
        function (state: FilteringWorkOrdersData) {
            if (onRefresh && state.requestType === 'refresh') {
                onRefresh();
            }

            if (onFilterChange && state.requestType === 'filtering' && state.action === 'clearFilter') {
                onFilterChange(state.currentFilterColumn.field, '', '');
            }

            if (onFilterChange && state.requestType === 'filtering' && state.action === 'filter') {
                const column = state.columns.filter((column: Predicate) => column.field === state.currentFilteringColumn);

                onFilterChange(state.currentFilteringColumn, column[0].operator, column[0].value);
            }
        },
        [data],
    );

    if (width === undefined) {
        return <Fallback />;
    }

    const enableMobileLayout = width < 1008;

    const labels = Object.keys(WorkOrderStatus).reduce<{}[]>((statuses, status) => {
        if (WorkOrderStatus[status as keyof typeof WorkOrderStatus].IsMobile) {
            statuses = [
                ...statuses,
                {
                    Status: WorkOrderStatus[status as keyof typeof WorkOrderStatus].Status,
                    Description: t(WorkOrderStatus[status as keyof typeof WorkOrderStatus].Description),
                },
            ];
        }
        return statuses;
    }, []);

    const filterStatus = {
        ui: {
            create: (args: React.ChangeEvent<HTMLInputElement>) => {
                const flValInput = createElement('input', {className: 'flm-input'});
                args.target.appendChild(flValInput);
                customDropDownRef.current = new DropDownList({
                    dataSource: labels,
                    fields: {text: 'Description', value: 'Status'},
                    placeholder: 'Select a status',
                    popupHeight: '200px',
                    value: customDropDownRef.current?.value,
                });
                customDropDownRef.current.appendTo(flValInput);
            },
            read: (args: readFilterStatus) => {
                args.fltrObj.filterByColumn(
                    args.column.field,
                    'equal',
                    Number(Object.values(WorkOrderStatus).find((el) => el.Status === customDropDownRef.current?.value)?.Status),
                );
            },
            write: (args: WriteFilterStatus) => {
                if (customDropDownRef.current) {
                    customDropDownRef.current.value = args.filteredValue;
                }
            },
        },
    };

    // https://www.syncfusion.com/forums/175268/get-error-when-enablestickyheader
    const destroy = function (this: GridComponent) {
        document.removeEventListener('scroll', this.scrollModule['makeStickyHeader']);
    };

    const queryCellInfo = (args: QueryCellInfoEventArgs) => {
        const cell = args.cell as Element;
        const field = (args.column as Column).field;
        const {Status, ShopCodeID, RepairLocation, Company, Mate, Lot, Row, Spot} = args.data as WorkOrder;

        switch (field) {
            case 'Status': {
                ReactDOM.render(<span>{t(Object.values(WorkOrderStatus).filter((status) => status.Status == Status)[0].Description)}</span>, cell);
                break;
            }
            case 'ShopCodeID': {
                // Work orders from the bulk endpoint don't come with a shop ID so find it using the station and company.
                const shop = shops.find(
                    (shop) => shop.ID === ShopCodeID || (shop.StationID === RepairLocation?.ID && shop.CompanyID === Company?.ID),
                );

                if (shop) {
                    ReactDOM.render(<span>{`[${shop.ShopCode}] ${shop.Description}`}</span>, cell);
                } else {
                    ReactDOM.render(<span>-</span>, cell);
                }

                break;
            }
            case 'Mate': {
                if (Mate) {
                    ReactDOM.render(<span>{Mate}</span>, cell);
                } else {
                    cell.setAttribute('style', 'padding: 0;');
                    ReactDOM.render(<span></span>, cell);
                }
                break;
            }
            case 'LotRowSpot': {
                if (Lot?.length || Row?.length || Spot?.length) {
                    ReactDOM.render(<span>{`${Lot}/${Row}/${Spot}`}</span>, cell);
                } else {
                    cell.setAttribute('style', 'padding: 0;');
                    ReactDOM.render(<span></span>, cell);
                }
                break;
            }
        }
    };

    return (
        <section>
            <div className="e-adaptive-demo e-bigger">
                <div className="e-mobile-layout">
                    <div className="e-mobile-content">
                        <GridComponent
                            ref={gridRef}
                            allowPaging
                            allowSorting
                            allowFiltering
                            // @ts-ignore
                            destroy={destroy}
                            enableStickyHeader
                            dataSource={workOrders}
                            enableInfiniteScrolling
                            rowSelected={onRowClick}
                            pageSettings={pageSettings}
                            queryCellInfo={queryCellInfo}
                            actionComplete={actionComplete}
                            filterSettings={{type: 'Menu'}}
                            enableAdaptiveUI={enableMobileLayout}
                            rowRenderingMode={enableMobileLayout ? 'Vertical' : 'Horizontal'}>
                            <Inject services={[InfiniteScroll, Filter, Sort]} />
                            <ColumnsDirective>
                                <ColumnDirective field="UnitDetails.UnitINO" headerText={t('unit')} />
                                <ColumnDirective field="Mate" headerText={t('mate')} />
                                <ColumnDirective field="Status" filter={filterStatus} headerText={t('work_order_status')} />
                                <ColumnDirective headerText={t('lot_row_spot')} field="LotRowSpot" />
                                <ColumnDirective field="DwellDays" headerText={t('dwell')} />
                                <ColumnDirective headerText={t('assigned')} field="HasAssignedRepairLine" valueAccessor={booleanAccessor} />
                                <ColumnDirective headerText={t('priority')} field="Priority" valueAccessor={booleanAccessor} />
                                <ColumnDirective field="ShopCodeID" headerText={t('vendor')} />
                            </ColumnsDirective>
                        </GridComponent>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WorkOrdersGrid;
