import Paper from '@mui/material/Paper';
import styled from 'styled-components';

export const PaperWrap = styled(Paper)`
    height: auto;
    width: 100%;
    max-width: 500px;
    padding: 24px;

    h2 {
        text-align: center;
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const HeaderTitle = styled.h2`
    color: ${({theme}) => theme.palette.primary.main};
`;

export const Results = styled.p`
    color: ${({theme}) => theme.palette.primary.main};
    font-size: 16px;
    padding: 0 15px;
`;
