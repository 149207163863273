import {CircularProgress} from '@mui/material';
import React from 'react';

import {Wrapper} from './circular-progress.styles';

export const CircularProgressComponent = () => (
    <Wrapper>
        <CircularProgress color="primary" />
    </Wrapper>
);
