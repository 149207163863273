import Paper from '@mui/material/Paper';
import styled from 'styled-components';

export const PaperWrap = styled(Paper)`
    height: auto;
    width: 100%;
    max-width: 500px;
    padding: 24px;

    h2 {
        text-align: center;
    }

    .e-input-group {
        display: flex;
        align-items: flex-end;
    }

    .e-input {
        font-size: 1rem !important;
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;
