import {differenceInMilliseconds} from 'date-fns';
import {takeLeading, call, put, select} from 'redux-saga/effects';

import config from '../../config';
import {ReduxAction} from '../../models/ReduxAction';
import {UnitGroup} from '../../models/UnitGroup';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {selectIsOfflineStatus} from '../application/application.selectors';

import {unitGroupError, unitGroupResponse} from './unit-group.actions';
import {FETCH_UNIT_GROUPS} from './unit-group.types';

const {resourceCacheFallback} = config;

export function* fetchUnitGroups() {
    const isOfflineMode: boolean = yield select(selectIsOfflineStatus);

    let cachedUnitGroups: UnitGroup[] = [];

    if (isOfflineMode) {
        cachedUnitGroups = yield call(IndexedDB.getMany, IndexedDBKeys.UNIT_GROUPS);

        yield put(unitGroupResponse(cachedUnitGroups));

        return;
    }

    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_UNIT_GROUPS_REFRESH_TIME, LocalStorageKeys.LAST_UNIT_GROUPS_UPDATE_TIME);

    if (lastUpdateTime && differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
        cachedUnitGroups = yield call(IndexedDB.getMany, IndexedDBKeys.UNIT_GROUPS);
        yield put(unitGroupResponse(cachedUnitGroups));
        return;
    }

    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_UNIT_GROUPS_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<UnitGroup[]> = yield call(request, {
        url: '/units/groups',
        method: 'get',
        priority: 'high',
    });

    if (error) {
        yield put(unitGroupError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedUnitGroups) {
            yield put(unitGroupResponse(cachedUnitGroups));
        }
    }

    if (data) {
        yield put(unitGroupResponse(data));
        yield call(IndexedDB.createMany, IndexedDBKeys.UNIT_GROUPS, data);
        LocalStorage.set(LocalStorageKeys.LAST_UNIT_GROUPS_UPDATE_TIME, requestTime);
    }
}

export default function* unitGroupRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_UNIT_GROUPS, fetchUnitGroups);
}
