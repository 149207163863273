import {ReduxAction} from '../../models/ReduxAction';
import {WorkOrder} from '../../models/WorkOrder';
import {WorkOrderRepairLineStatus} from '../../models/enumerations/WorkOrderRepairLineStatus';
import {WorkOrderStatus} from '../../models/enumerations/WorkOrderStatus';
import {CREATE_REPAIR_LINE_PART_RESPONSE, DELETE_REPAIR_LINE_PART_RESPONSE, UPDATE_REPAIR_LINE_PART_RESPONSE} from '../part/part.types';
import {
    CREATE_REPAIR_LINE_RESPONSE,
    DELETE_REPAIR_LINE_RESPONSE,
    REMOVE_REPAIR_LINE_DECLINATION_REASON_RESPONSE,
    REPAIR_LINES_RESPONSE,
    UPDATE_REPAIR_LINE_RESPONSE,
} from '../repair-line/repair-line.types';

import {
    WORK_ORDERS_ERROR,
    WORK_ORDERS_REQUEST,
    WORK_ORDER_RESPONSE,
    WORK_ORDERS_RESPONSE,
    UPDATE_WORK_ORDER_RESPONSE,
    DELETE_WORK_ORDER_RESPONSE,
    CREATE_WORK_ORDER_RESPONSE,
    ADD_WORK_ORDER_DECLINATION_REASON_RESPONSE,
    REMOVE_WORK_ORDER_DECLINATION_REASON_RESPONSE,
    UNSELECT_WORK_ORDER,
} from './work-order.types';

export interface WorkOrderState {
    error: Error | null;
    loading: boolean;
    workOrders: WorkOrder[];
    selectedWorkOrder: WorkOrder | null;
}

const initialState: WorkOrderState = {
    error: null,
    loading: false,
    workOrders: [],
    selectedWorkOrder: null,
};

export default function workOrderReducer(state = initialState, action: ReduxAction): WorkOrderState {
    switch (action.type) {
        case WORK_ORDERS_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case WORK_ORDERS_RESPONSE:
            return {
                ...state,
                loading: false,
                workOrders: action.payload,
            };
        case WORK_ORDERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case WORK_ORDER_RESPONSE:
            return {
                ...state,
                loading: false,
                selectedWorkOrder: action.payload,
                workOrders:
                    // Don't add non-mobile status work orders to the list and remove them if they are already in it.
                    // This can happen if a user is linked a work order or the status has changed since they last pulled.
                    Object.values(WorkOrderStatus)
                        .filter(({IsMobile}) => IsMobile)
                        .map(({Status}) => Status)
                        .includes(action.payload.Status)
                        ? state.workOrders.find(({ID}) => ID === action.payload.ID)
                            ? state.workOrders.map((item) => (item.ID === action.payload.ID ? action.payload : item))
                            : state.workOrders.concat(action.payload)
                        : state.workOrders.filter(({ID}) => ID !== action.payload.ID),
            };
        case CREATE_WORK_ORDER_RESPONSE:
            return {
                ...state,
                loading: false,
                workOrders: state.workOrders.concat([action.payload]),
            };
        case UPDATE_WORK_ORDER_RESPONSE:
            if (!action.payload.workOrder) {
                return {
                    ...state,
                    loading: false,
                    workOrders: state.workOrders.filter((item) => item.ID !== action.payload.workOrderID),
                };
            }

            return {
                ...state,
                loading: false,
                selectedWorkOrder: state.selectedWorkOrder?.ID === action.payload.workOrderID ? action.payload.workOrder : state.selectedWorkOrder,
                workOrders: state.workOrders.map((item) => (item.ID === action.payload.workOrderID ? {...item, ...action.payload.workOrder} : item)),
            };
        case DELETE_WORK_ORDER_RESPONSE:
            return {
                ...state,
                loading: false,
                workOrders: state.workOrders.filter((item) => item.ID !== action.payload.workOrderID),
            };
        case UNSELECT_WORK_ORDER:
            return {
                ...state,
                selectedWorkOrder: null,
            };
        case ADD_WORK_ORDER_DECLINATION_REASON_RESPONSE:
            return {
                ...state,
                loading: false,
            };
        case REMOVE_WORK_ORDER_DECLINATION_REASON_RESPONSE:
            return {
                ...state,
                loading: false,
                workOrders: state.workOrders.map((item) =>
                    item.ID === action.payload.ID
                        ? {...item, DeclinationReasons: item.DeclinationReasons.filter(({ID}) => ID !== action.payload.errorMessageID)}
                        : item,
                ),
            };
        case REPAIR_LINES_RESPONSE:
            return {
                ...state,
                loading: false,
                workOrders: state.workOrders.map((item) =>
                    item.ID === action.payload.workOrderID ? {...item, WorkOrderRepairLines: action.payload.repairLines} : item,
                ),
            };
        case CREATE_REPAIR_LINE_RESPONSE:
            return {
                ...state,
                workOrders: state.workOrders.map((item) =>
                    item.ID === action.payload.workOrderID
                        ? {...item, WorkOrderRepairLines: [action.payload.repairLine, ...item.WorkOrderRepairLines]}
                        : item,
                ),
            };
        case UPDATE_REPAIR_LINE_RESPONSE:
            return {
                ...state,
                workOrders: state.workOrders.map((item) =>
                    item.ID === action.payload.workOrderID
                        ? {
                              ...item,
                              WorkOrderRepairLines: item.WorkOrderRepairLines.map((repairLine) =>
                                  repairLine.ID === action.payload.repairLineID ? {...repairLine, ...action.payload.repairLine} : repairLine,
                              ),
                          }
                        : item,
                ),
            };
        case DELETE_REPAIR_LINE_RESPONSE:
            return {
                ...state,
                workOrders: state.workOrders.map((item) =>
                    item.ID === action.payload.workOrderID
                        ? {...item, WorkOrderRepairLines: item.WorkOrderRepairLines.filter((repairLine) => repairLine.ID !== action.payload)}
                        : item,
                ),
            };
        case CREATE_REPAIR_LINE_PART_RESPONSE:
            return {
                ...state,
                workOrders: state.workOrders.map((item) =>
                    item.ID === action.payload.workOrderID
                        ? {
                              ...item,
                              WorkOrderRepairLines: item.WorkOrderRepairLines.map((repairLine) =>
                                  action.payload.repairLineID === repairLine.ID
                                      ? {...repairLine, Parts: [...repairLine.Parts, action.payload.part]}
                                      : repairLine,
                              ),
                          }
                        : item,
                ),
            };
        case UPDATE_REPAIR_LINE_PART_RESPONSE:
            return {
                ...state,
                workOrders: state.workOrders.map((item) =>
                    item.ID === action.payload.workOrderID
                        ? {
                              ...item,
                              WorkOrderRepairLines: item.WorkOrderRepairLines.map((repairLine) =>
                                  action.payload.repairLineID === repairLine.ID
                                      ? {
                                            ...repairLine,
                                            Parts: repairLine.Parts.map((part) =>
                                                part.RepairLinePartID === action.payload.part.RepairLinePartID ? action.payload.part : part,
                                            ),
                                        }
                                      : repairLine,
                              ),
                          }
                        : item,
                ),
            };
        case DELETE_REPAIR_LINE_PART_RESPONSE:
            return {
                ...state,
                workOrders: state.workOrders.map((item) =>
                    item.ID === action.payload.workOrderID
                        ? {
                              ...item,
                              WorkOrderRepairLines: item.WorkOrderRepairLines.map((repairLine) =>
                                  action.payload.repairLineID === repairLine.ID
                                      ? {...repairLine, Parts: repairLine.Parts.filter((part) => part.RepairLinePartID !== action.payload.partID)}
                                      : repairLine,
                              ),
                          }
                        : item,
                ),
            };
        case REMOVE_REPAIR_LINE_DECLINATION_REASON_RESPONSE:
            return {
                ...state,
                workOrders: state.workOrders.map((item) =>
                    item.ID === action.payload.workOrderID
                        ? {
                              ...item,
                              WorkOrderRepairLines: item.WorkOrderRepairLines.map((repairLine) =>
                                  action.payload.ID === repairLine.ID
                                      ? {
                                            ...repairLine,
                                            DeclinationReasons: item.DeclinationReasons.filter(({ID}) => ID !== action.payload.errorMessageID),
                                            // If that was the last declination reason, unreject the repair line.
                                            Status:
                                                repairLine.DeclinationReasons.length === 1
                                                    ? WorkOrderRepairLineStatus.Created
                                                    : WorkOrderRepairLineStatus.Rejected,
                                        }
                                      : repairLine,
                              ),
                          }
                        : item,
                ),
            };
        default:
            return state;
    }
}
