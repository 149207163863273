import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import ForumIcon from '@mui/icons-material/Forum';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {Badge} from '@mui/material';
import {push} from 'connected-react-router';
import React from 'react';
import {useDispatch} from 'react-redux';

import {ReactComponent as BoltIcon} from '../../images/bolt.svg';

import {BoltWrap, GridItem, GridWrap} from './details-action-icons.styles';

type DetailsActionIconsProps = {
    urls: {
        files: string;
        parts: string;
        comments: string;
        specialInstructions: string;
    };
    badges: {
        files: number;
        parts: number;
        comments: number;
        authorizedSpecialInstructions: number;
        unauthorizedSpecialInstructions: number;
    };
};

const DetailsActionIcons = ({urls, badges}: DetailsActionIconsProps) => {
    const dispatch = useDispatch();
    const handleRouterPush = (type: string) => {
        switch (type) {
            case 'files':
                dispatch(push(urls.files));
                break;
            case 'parts':
                dispatch(push(urls.parts));
                break;
            case 'comments':
                dispatch(push(urls.comments));
                break;
            case 'instructions':
                dispatch(push(urls.specialInstructions));
                break;
            default:
                return;
        }
    };
    const showSpecialInstructions = badges.unauthorizedSpecialInstructions + badges.authorizedSpecialInstructions > 0;
    const getGridSize = (instructionsCount: boolean) => (instructionsCount ? 3 : 4);

    return (
        <GridWrap container spacing={2}>
            <GridItem
                item
                xs={getGridSize(showSpecialInstructions)}
                onClick={() => handleRouterPush('instructions')}
                hidden={!showSpecialInstructions}>
                <Badge
                    overlap="rectangular"
                    badgeContent={badges.unauthorizedSpecialInstructions}
                    color="error"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                    <Badge overlap="rectangular" badgeContent={badges.authorizedSpecialInstructions} showZero color="primary">
                        <AssignmentLateIcon />
                    </Badge>
                </Badge>
            </GridItem>
            <GridItem item xs={getGridSize(showSpecialInstructions)} onClick={() => handleRouterPush('files')}>
                <Badge overlap="rectangular" badgeContent={badges.files} showZero color="primary">
                    <InsertDriveFileIcon />
                </Badge>
            </GridItem>
            <GridItem item xs={getGridSize(showSpecialInstructions)} onClick={() => handleRouterPush('comments')}>
                <Badge overlap="rectangular" badgeContent={badges.comments} showZero color="primary">
                    <ForumIcon />
                </Badge>
            </GridItem>
            <GridItem item xs={getGridSize(showSpecialInstructions)} onClick={() => handleRouterPush('parts')}>
                <Badge overlap="rectangular" badgeContent={badges.parts} showZero color="primary">
                    <BoltWrap>
                        <BoltIcon />
                    </BoltWrap>
                </Badge>
            </GridItem>
        </GridWrap>
    );
};

export const buildUrls = (path: string, workOrderID: number) => ({
    files: `${path}/${workOrderID}/files`,
    parts: `${path}/${workOrderID}/parts`,
    comments: `${path}/${workOrderID}/comments`,
    specialInstructions: `${path}/${workOrderID}/special-instructions`,
});

export default DetailsActionIcons;
