import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {selectAllLoaders, selectApplicationState} from '../../redux/application/application.selectors';

import {DialogWrap, LinearProgressWrap, Loader} from './go-offline-dialog.styles';

type GoOfflineDialogProps = {
    open: boolean;
    onCancel: () => void;
    onCancelLoading: () => void;
    onSubmit: () => void;
};

const GoOfflineDialog = ({open, onCancel, onCancelLoading, onSubmit}: GoOfflineDialogProps) => {
    const {t} = useTranslation();
    const {modeLoading} = useSelector(selectApplicationState);
    const loaders = useSelector(selectAllLoaders);

    const onClose = (_: unknown, reason: string) => {
        if (modeLoading && reason === 'backdropClick') {
            return;
        }

        onCancel();
    };

    const linearProgressValue = modeLoading ? (loaders.reduce((acc, loader) => (loader ? acc : acc + 1), 0) / loaders.length) * 100 : 0;

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogWrap>
                <DialogTitle>{t('IMRS_will_be_restricted_to_creating_estimates_while_offline')}</DialogTitle>
                {modeLoading && (
                    <DialogContent>
                        <DialogContentText>{t('going_to_offline_mode_please_wait_until_data_is_updated')}</DialogContentText>
                        <LinearProgressWrap variant="determinate" value={linearProgressValue} />
                    </DialogContent>
                )}
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={onSubmit} disabled={modeLoading}>
                        {modeLoading ? <Loader size={26} /> : t('continue')}
                    </Button>
                    <Button variant="contained" onClick={modeLoading ? onCancelLoading : onCancel} color="secondary">
                        {t('canceled_action')}
                    </Button>
                </DialogActions>
            </DialogWrap>
        </Dialog>
    );
};

export default GoOfflineDialog;
