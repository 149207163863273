import {UserHistory} from '../../models/History';

import {FETCH_NEXT_HISTORIES, HISTORY_ERROR, HISTORY_REQUEST, HISTORY_RESPONSE, RESET_HISTORY} from './history.types';

export const historyRequest = () => ({
    type: HISTORY_REQUEST,
});

export const historyError = (error: Error | unknown) => ({
    type: HISTORY_ERROR,
    payload: error,
});

export const historyResponse = ({history, skip, noMoreData}: {history: UserHistory[]; skip: number; noMoreData: boolean}) => ({
    type: HISTORY_RESPONSE,
    payload: {history, skip, noMoreData},
});

export const fetchNextHistoryData = () => ({
    type: FETCH_NEXT_HISTORIES,
});

export const resetHistory = () => ({
    type: RESET_HISTORY,
});
