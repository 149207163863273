import {WidgetProps} from '@rjsf/utils';
import {DropDownListComponent, PopupEventArgs} from '@syncfusion/ej2-react-dropdowns';
import _uniqBy from 'lodash/uniqBy';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {SyncfusionSelectEvent} from '../../models/types/SyncfusionSelectEvent';
import {selectJobCodesState} from '../../redux/job-code/job-code.selectors';
import dropDownFiltering from '../../utils/dropDownFiltering';

type DropDownOption = {ID: number; Description: string};

export const UnitLocationCodeDropDownInput = ({onChange, formContext, value, disabled}: WidgetProps) => {
    const {JobCodeId} = formContext.formData || {};
    const {t} = useTranslation();
    const [localValue, setLocalValue] = useState(value);
    const [options, setOptions] = useState<DropDownOption[]>([]);
    const {jobCodes, loading: jobCodesLoading} = useSelector(selectJobCodesState);

    const onChangeHandler = useCallback(
        (newValue) => {
            setLocalValue(newValue);
            setTimeout(() => onChange(newValue), 0);
        },
        [onChange],
    );

    // The unit location dropdown list should only contain a unique list of the all WhyMade's UnitLocationCodes
    useEffect(() => {
        const jobCode = jobCodes.find(({ID}) => JobCodeId === ID);

        if (jobCode) {
            const unitLocationCodes = _uniqBy(
                jobCode.UnitLocationCode.filter(({IsDeleted}) => !IsDeleted),
                'ID',
            );

            setOptions(
                unitLocationCodes.map((item) => ({
                    ID: item.ID,
                    Description: `[${item.UnitLocation}] ${item.Description}`,
                })),
            );

            if (!unitLocationCodes.find(({ID}) => ID === value)) {
                const newValue = unitLocationCodes.length === 1 ? unitLocationCodes[0].ID : null;

                onChangeHandler(newValue);
            }
        } else {
            setOptions([]);
            onChangeHandler(null);
        }
    }, [JobCodeId, jobCodes, onChangeHandler, value]);

    const onSelect = useCallback(
        (event: SyncfusionSelectEvent<DropDownOption>) => {
            onChangeHandler(event.itemData.ID);
        },
        [onChangeHandler],
    );

    const onDropDownOpen = useCallback(
        ({popup}: PopupEventArgs) => {
            const input = popup.element.querySelector('input');

            if (input) {
                input.placeholder = t('search');
            }
        },
        [t],
    );

    return (
        <DropDownListComponent
            open={onDropDownOpen}
            placeholder={t('location')}
            value={localValue}
            select={onSelect}
            dataSource={options}
            filtering={dropDownFiltering(['Description'], 'contains')}
            allowFiltering
            floatLabelType="Auto"
            fields={{text: 'Description', value: 'ID'}}
            enabled={!jobCodesLoading && Boolean(JobCodeId) && Boolean(options.length) && !disabled}
        />
    );
};
