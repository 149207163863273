import {ListItemText} from '@mui/material';
import styled from 'styled-components';

export const StyledListItemText = styled(ListItemText)`
    color: ${({theme}) => theme.palette.primary.main};
    text-align: center;
`;

export const ProgressContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
`;
