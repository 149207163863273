import _uniq from 'lodash/uniq';
import React, {ReactElement, useEffect, useMemo} from 'react';
import {AbacProvider} from 'react-abac';
import {useDispatch, useSelector} from 'react-redux';

import {CircularProgressComponent} from '../components/circular-progress/circular-progress.component';
import {fetchAccessControl} from '../redux/access-control/access-control.actions';
import {selectAccessControl, selectAccessControlState} from '../redux/access-control/access-control.selectors';
import {selectUser} from '../redux/user/user.selectors';
import {parsePermissionRuleValue} from '../utils/parsePermissionRuleValue';

export type AccessControlContext = {};

export const AccessControlContext = React.createContext({});

export type AccessControlProviderProps = {
    children: ReactElement | ReactElement[];
};

type RoleRulesObject = Record<string, ReturnType<typeof parsePermissionRuleValue>>;
type Permissions = RoleRulesObject[];
type Rules = Record<string, RoleRulesObject>;

export const AccessControlProvider = ({children}: AccessControlProviderProps) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser)!;
    const accessControl = useSelector(selectAccessControl);
    const {initLoading} = useSelector(selectAccessControlState);

    useEffect(() => {
        dispatch(fetchAccessControl());
    }, [dispatch]);

    const [permissions, rules] = useMemo<[Permissions, Rules]>(() => {
        if (!accessControl || !accessControl.rules) {
            return [[], {}];
        }

        const parsedRules: Rules = Object.keys(accessControl.rules).reduce(
            (rulesObject, key) => ({
                ...rulesObject,
                [key]: accessControl.rules[key].reduce((roleRulesObject: RoleRulesObject, permissionString: string) => {
                    const [permissionKey, permissionValue] = permissionString.split(': ');
                    return {...roleRulesObject, [permissionKey]: parsePermissionRuleValue(permissionValue)};
                }, {}),
            }),
            {},
        );

        const parsedPermissions = _uniq(
            user.Roles.reduce<Permissions>((acc, item) => (parsedRules[item] ? [...acc, parsedRules[item]] : acc), []).flat(),
        );

        return [parsedPermissions, parsedRules];
    }, [accessControl?.rules, user.Roles]);

    const providerData: AccessControlContext = {};

    return (
        <AccessControlContext.Provider value={providerData}>
            <AbacProvider user={user} roles={user.Roles} permissions={permissions as any} rules={rules as any}>
                {initLoading ? <CircularProgressComponent /> : children}
            </AbacProvider>
        </AccessControlContext.Provider>
    );
};
