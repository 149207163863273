import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import DetailsTitle from '../../components/details-title/details-title.component';
import {useHeader} from '../../hooks/use-header';
import {useQueryParams} from '../../hooks/use-query-params';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {fetchComments} from '../../redux/comment/comment.actions';
import {selectWorkOrderComments} from '../../redux/comment/comment.selectors';

import WorkOrderCommentsComponent from './work-order-comments.component';

export type WorkOrderCommentsProps = {
    backToPath?: string;
};

const WorkOrderCommentsContainer = ({backToPath}: WorkOrderCommentsProps) => {
    const {push} = useHistory();
    const dispatch = useDispatch();
    const queryParams = useQueryParams();
    const workOrderComments = useSelector(selectWorkOrderComments);
    const {workOrder, repairLines} = useContext(WorkOrderDataContext);

    const {UnitDetails: unitDetails, WorkOrderNumber: workOrderNumber} = workOrder;

    useEffect(() => {
        dispatch(
            fetchComments({
                associateObjectID: workOrder.ID,
                associatedTypeID: AssociatedTypes.WorkOrder,
            }),
        );
    }, [dispatch, workOrder]);

    const onHeaderClick = useCallback(() => {
        push(`${backToPath}/${workOrder.ID}/header`, {menuPrevPath: location.pathname});
    }, [backToPath, workOrder, push]);

    const TitleTop = useCallback(
        () => <DetailsTitle onClick={onHeaderClick} unitINO={unitDetails?.UnitINO} workOrderNumber={workOrderNumber} />,
        [onHeaderClick, unitDetails?.UnitINO, workOrderNumber],
    );

    useHeader({
        backToPath: `${backToPath}/${workOrder.ID}`,
        middleElement: TitleTop,
    });

    const repairLineID = +(queryParams.get('repairLineID') || '');
    const scrollToRepairLineID = location.hash ? +location.hash.replace('#', '') : null;

    const filteredRepairLines = useMemo(() => {
        return repairLineID ? repairLines.filter(({ID}) => ID === repairLineID) : repairLines;
    }, [repairLineID, repairLines]);

    return <WorkOrderCommentsComponent repairLines={filteredRepairLines} comments={workOrderComments} scrollToRepairLineID={scrollToRepairLineID} />;
};

export default WorkOrderCommentsContainer;
