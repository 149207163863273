import {Comment} from '../../models/Comment';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';

export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const COMMENTS_REQUEST = 'COMMENTS_REQUEST';
export const COMMENTS_RESPONSE = 'COMMENTS_RESPONSE';
export const COMMENTS_ERROR = 'COMMENTS_ERROR';
export const CREATE_COMMENT_RESPONSE = 'CREATE_COMMENT_RESPONSE';
export const UPDATE_COMMENT_RESPONSE = 'UPDATE_COMMENT_RESPONSE';
export const DELETE_COMMENT_RESPONSE = 'DELETE_COMMENT_RESPONSE';
export const CREATE_REPAIR_LINE_COMMENT = 'CREATE_REPAIR_LINE_COMMENT';
export const UPDATE_REPAIR_LINE_COMMENT = 'UPDATE_REPAIR_LINE_COMMENT';
export const DELETE_REPAIR_LINE_COMMENT = 'DELETE_REPAIR_LINE_COMMENT';

export const REPAIR_LINE_COMMENTS_RESPONSE = 'REPAIR_LINE_COMMENTS_RESPONSE';
export const CREATE_REPAIR_LINE_COMMENT_RESPONSE = 'CREATE_REPAIR_LINE_COMMENT_RESPONSE';
export const UPDATE_REPAIR_LINE_COMMENT_RESPONSE = 'UPDATE_REPAIR_LINE_COMMENT_RESPONSE';
export const DELETE_REPAIR_LINE_COMMENT_RESPONSE = 'DELETE_REPAIR_LINE_COMMENT_RESPONSE';

export type FetchComments = {
    associateObjectID: number;
    associatedTypeID: AssociatedTypes;
};

export type CreateComment = {
    associateObjectID: number;
    associatedTypeID: AssociatedTypes;
    comment: Comment;
};

export type CreateRepairLineComment = {
    workOrderID: number;
    associateObjectID: number;
    associatedTypeID: AssociatedTypes;
    comment: Comment;
};
