import {SearchDto} from '../../models/dtos/search.dto';

import {GET_SEARCH_PARAMS, RESET_SEARCH_PARAMS, SET_SEARCH_PARAMS} from './search.types';

export const getSearchParams = () => ({
    type: GET_SEARCH_PARAMS,
});

export const setSearchParams = (values: SearchDto) => ({
    type: SET_SEARCH_PARAMS,
    payload: values,
});

export const resetSearchParams = () => ({
    type: RESET_SEARCH_PARAMS,
});
