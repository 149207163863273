import {Drawer, List, ListItemButton} from '@mui/material';
import {t} from 'i18next';
import _ from 'lodash';
import React, {ReactElement, useCallback, useState} from 'react';
import {AllowedTo} from 'react-abac';
import {useDispatch, useSelector} from 'react-redux';

import {WorkOrderAction, WorkOrderActionType} from '../../hooks/use-next-work-order-actions';
import {ReduxAction} from '../../models/ReduxAction';
import {WorkOrder} from '../../models/WorkOrder';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {addWorkOrderDeclinationReason, removeWorkOrderDeclinationReason} from '../../redux/work-order/work-order.actions';
import {selectWorkOrderState} from '../../redux/work-order/work-order.selectors';
import ConfirmDialog from '../confirming-dialog/confirming-dialog.component';
import DividerComponent from '../divider/divider.component';
import DoNotRepairDialog from '../do-not-repair-dialog/do-not-repair-dialog.component';
import RejectWorkOrderDialog from '../reject-work-order-dialog/reject-work-order-dialog.component';

import {BlackListItemText, DarkRedListItemText, GreenListItemText, RedListItemText} from './actions-menu.styles';

export type ActionsMenuProps = {
    open: boolean;
    workOrder: WorkOrder;
    onClose: () => void;
    actions: WorkOrderAction[];
    onViewHeaderClick: () => void;
};

export type ConfirmData = {
    title: string;
    submitButtonLabel: string;
    action: ((sendExternalIntegrationMessage?: boolean) => ReduxAction) | null;
};

export const ActionsMenu = ({open, workOrder, onClose, actions, onViewHeaderClick}: ActionsMenuProps): ReactElement => {
    const dispatch = useDispatch();
    const {loading} = useSelector(selectWorkOrderState);
    const groupedActions = _.groupBy(actions, 'group');
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [confirmData, setConfirmData] = useState<null | ConfirmData>(null);
    const [openDialogRejectWorkOrder, setOpenDialogRejectWorkOrder] = useState(false);
    const [openDoNotRepairDialog, setOpenDoNotRepairDialog] = useState(false);
    const itemName = workOrder.Status < 30 ? t('estimate') : t('work_order');

    const getListItemText = (color: string, label: string) => {
        switch (color) {
            case 'green':
                return <GreenListItemText primary={label} />;
            case 'red':
                return <RedListItemText primary={label} />;
            case 'darkRed':
                return <DarkRedListItemText primary={label} />;
        }
    };

    const onReject = useCallback(
        (itemID?: number, sendExternalIntegrationMessage?: boolean) => {
            if (confirmData?.action) {
                dispatch(confirmData?.action(sendExternalIntegrationMessage));
            }
            setConfirmData(null);
            setOpenConfirmDialog(false);
            setOpenDoNotRepairDialog(false);
            setOpenDialogRejectWorkOrder(false);
            onClose();
        },
        [confirmData?.action],
    );

    const handleCancel = useCallback(() => {
        setConfirmData(null);
        setOpenConfirmDialog(false);
        setOpenDoNotRepairDialog(false);
        setOpenDialogRejectWorkOrder(false);
    }, []);

    const setOpedDialog = (actionType: number) => {
        switch (actionType) {
            case WorkOrderActionType.REJECT:
                setOpenDialogRejectWorkOrder(true);
                break;
            case WorkOrderActionType.DO_NOT_REPAIR:
                setOpenDoNotRepairDialog(true);
                break;
            default:
                setOpenConfirmDialog(true);
                break;
        }
    };

    const renderAction = ({label, color, action, permissions, event, isStatusRejected, title, submitButtonLabel, actionType}: WorkOrderAction) => (
        <AllowedTo key={label} perform={permissions} data={workOrder}>
            <ListItemButton
                disabled={loading}
                onClick={() => {
                    if (action) {
                        if (isStatusRejected) {
                            setConfirmData({submitButtonLabel: submitButtonLabel || '', title: title || '', action: action});
                            setOpedDialog(actionType);
                        } else {
                            dispatch(action());
                            onClose();
                        }
                    } else if (event) {
                        return event();
                    }
                }}>
                {getListItemText(color, label)}
            </ListItemButton>
        </AllowedTo>
    );

    return (
        <>
            <Drawer anchor="right" open={open} onClose={onClose}>
                <List>
                    <ListItemButton onClick={onViewHeaderClick}>
                        <BlackListItemText primary={t('view_header')} />
                    </ListItemButton>
                    {Object.keys(groupedActions).map((key) => (
                        <div key={key}>
                            <DividerComponent />
                            {groupedActions[key].map(renderAction)}
                        </div>
                    ))}
                </List>
            </Drawer>
            {openDialogRejectWorkOrder && (
                <RejectWorkOrderDialog
                    loading={loading}
                    onReject={onReject}
                    onCancel={handleCancel}
                    itemID={workOrder.ID}
                    itemName={itemName}
                    itemType={AssociatedTypes.WorkOrder}
                    open={openDialogRejectWorkOrder}
                    declinationReasons={workOrder.DeclinationReasons}
                    addDeclinationReasonAction={addWorkOrderDeclinationReason}
                    removeDeclinationReasonAction={removeWorkOrderDeclinationReason}
                />
            )}
            {openDoNotRepairDialog && (
                <DoNotRepairDialog
                    loading={loading}
                    onReject={onReject}
                    onCancel={handleCancel}
                    itemID={workOrder.ID}
                    itemName={itemName}
                    itemType={AssociatedTypes.WorkOrder}
                    open={openDoNotRepairDialog}
                    declinationReasons={workOrder.DeclinationReasons}
                    addDeclinationReasonAction={addWorkOrderDeclinationReason}
                    removeDeclinationReasonAction={removeWorkOrderDeclinationReason}
                />
            )}
            {openConfirmDialog && confirmData && (
                <ConfirmDialog
                    onSubmit={onReject}
                    title={confirmData.title}
                    onCancel={handleCancel}
                    open={openConfirmDialog}
                    submitButtonLabel={confirmData.submitButtonLabel}
                />
            )}
        </>
    );
};
