import React from 'react';

import {TitleWrap} from './details-title.styles';

type DetailsTitleProps = {
    onClick?: () => void;
    unitINO?: string;
    workOrderNumber?: string;
};

const DetailsTitle = ({onClick, unitINO, workOrderNumber}: DetailsTitleProps) => {
    return (
        <TitleWrap onClick={onClick}>
            <h4>{unitINO}</h4>
            <h4>{workOrderNumber}</h4>
        </TitleWrap>
    );
};

export default DetailsTitle;
