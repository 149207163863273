import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const LinkWrap = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

export const Title = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px;
`;

export const UnitNumber = styled.h4`
    margin: 0;
    color: ${({theme}) => theme.palette.primary.main};
`;

export const WorkOrderNumber = styled.h4`
    margin: 0;
`;
