import {Grid} from '@mui/material';
import styled from 'styled-components';

export const GridWrap = styled(Grid)`
    margin: 10px 0;
    text-align: center;
    color: ${(props) => props.theme.palette.primary.main};
    svg {
        font-size: 3rem;
    }
    cursor: pointer;
`;

export const GridItem = styled(Grid)`
    cursor: pointer;
`;

export const BoltWrap = styled(Grid)`
    transform: rotate(-30deg);
`;
