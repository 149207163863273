import {Button} from '@mui/material';
import {push} from 'connected-react-router';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {goOffline, goOnline} from '../../redux/application/application.actions';
import GoOfflineDialog from '../go-offline-dialog/go-offline-dialog.component';

import {GoOfflineOnlineButtonProps} from './go-offline-online-button.types';

const GoOfflineButton = (({size = 'large', fullWidth = true}: GoOfflineOnlineButtonProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const onCancelLoading = () => {
        dispatch(goOnline(true));
        setOpenDialog(false);
    };

    return (
        <>
            <Button variant="contained" sx={{backgroundColor: '#000'}} onClick={() => setOpenDialog(true)} size={size} fullWidth={fullWidth}>
                {t('go_offline')}
            </Button>
            <GoOfflineDialog
                onSubmit={() => {
                    dispatch(goOffline());
                }}
                onCancel={() => setOpenDialog(false)}
                onCancelLoading={onCancelLoading}
                open={openDialog}
            />
        </>
    );
}) as React.FC<GoOfflineOnlineButtonProps | {}>;

export default GoOfflineButton;
