import {ReduxAction} from '../../models/ReduxAction';
import {SearchDto} from '../../models/dtos/search.dto';

import {SET_SEARCH_PARAMS, RESET_SEARCH_PARAMS} from './search.types';

export interface SearchState {
    params: SearchDto;
}

const initialState: SearchState = {
    params: {
        UnitINO: '',
        WorkOrderNumber: '',
        UnitType: -1,
        Lot: '',
        Row: '',
        Priority: false,
        Status: -1,
    },
};

export default function searchReducer(state = initialState, action: ReduxAction): SearchState {
    switch (action.type) {
        case SET_SEARCH_PARAMS:
            const values = action.payload;

            return {
                ...state,
                params: {
                    ...state.params,
                    ...values,
                },
            };
        case RESET_SEARCH_PARAMS:
            return initialState;
        default:
            return state;
    }
}
