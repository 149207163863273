import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {Button, LinearProgress} from '@mui/material';
import {push} from 'connected-react-router';
import _uniqBy from 'lodash/uniqBy';
import React, {useCallback, useContext, useMemo, useState} from 'react';
import {AllowedTo, useAbac} from 'react-abac';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {ActionsMenu} from '../../components/actions-menu/actions-menu.component';
import DeclinationReasonsTemplate from '../../components/declination-reasons/declination-reasons-template';
import DetailsActionIcons, {buildUrls} from '../../components/details-action-icons/details-action-icons.component';
import RepairItemDetails, {buildDetailsUrls} from '../../components/repair-item-details/repair-item-details.component';
import {ShopModalList} from '../../components/shop-modal-list/shop-modal-list.component';
import useNextWorkOrderAction from '../../hooks/use-next-work-order-action';
import useNextWorkOrderActions from '../../hooks/use-next-work-order-actions';
import {ErrorMessage} from '../../models/ErrorMessage';
import {WorkOrderRepairLine} from '../../models/WorkOrderRepairLine';
import {StationCompanyRelationDto} from '../../models/dtos/station-company-relation.dto';
import {Permission} from '../../models/enumerations/Permission';
import {UnitTypeCodes} from '../../models/enumerations/UnitTypeCodes';
import {WorkOrderStatus} from '../../models/enumerations/WorkOrderStatus';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {fetchRepairLinePricing} from '../../redux/pricing/pricing.actions';
import {selectRepairLineState} from '../../redux/repair-line/repair-line.selectors';
import {selectShops} from '../../redux/shop/shop.selectors';
import {removeWorkOrderDeclinationReason, reassignWorkOrderShop} from '../../redux/work-order/work-order.actions';
import {selectWorkOrderState} from '../../redux/work-order/work-order.selectors';
import {formatDate} from '../../utils/formatDate';

import {Container, Title, Text, ApprovalBtn, DeclinationReasonsTitle, RepairLinesSection} from './work-order-details.styles';

type WorkOrderDetailsProps = {
    path: string;
    title: string;
    badge: {
        files: number;
        parts: number;
        comments: number;
        authorizedSpecialInstructions: number;
        unauthorizedSpecialInstructions: number;
    };
};

const WorkOrderDetails = ({badge, title, path}: WorkOrderDetailsProps) => {
    const dispatch = useDispatch();
    const {userHasPermissions} = useAbac();
    const location = useLocation<{menuPrevPath?: string}>();
    const backToPath = location.state?.menuPrevPath || path;
    const {loading} = useSelector(selectWorkOrderState);
    const {loading: repairLinesLoading} = useSelector(selectRepairLineState);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {workOrder, repairLines, readOnly} = useContext(WorkOrderDataContext);
    const openMenu = () => setIsMenuOpen(true);
    const [isShopModalOpen, setShopModalOpen] = useState(false);
    const shops = useSelector(selectShops);
    const closeMenu = () => setIsMenuOpen(false);
    const {t} = useTranslation();
    const {
        FHWADueDate,
        UnitDetails: unitDetails,
        DwellDays: dwellDays,
        Status: status,
        PurchaseOrderNumber: purchaseOrderNumber,
        RepairDate: repairDate,
    } = workOrder;

    const openShopListModal = () => {
        setShopModalOpen(true);
        setIsMenuOpen(false);
    };

    const filteredShops = useMemo<StationCompanyRelationDto[]>(() => shops.filter(({IsDeleted, Active}) => !IsDeleted && Active), [shops]);

    const onWorkOrderLineDetailsClick = useCallback(
        (repairLine: WorkOrderRepairLine) => {
            dispatch(push(`${path}/${workOrder.ID}/line/${repairLine.ID}`));
            dispatch(
                fetchRepairLinePricing({
                    companyID: workOrder.Company.ID,
                    stationID: workOrder.RepairLocation.ID,
                    equipmentGroupID: workOrder.EquipmentGroups,
                    jobCodeID: repairLine.JobCodeId,
                    conditionCodeID: repairLine.ConditionCodeId,
                    repairSizeID: repairLine.RepairSizeId,
                    suppressError: false,
                }),
            );
        },
        [workOrder.ID, dispatch],
    );

    const goToHeader = useCallback(() => {
        dispatch(push(`${path}/${workOrder.ID}/header`));
        closeMenu();
    }, [dispatch, workOrder.ID]);

    const toggleShopListModal =
        workOrder.Status < WorkOrderStatus.WorkApproved.Status &&
        filteredShops.length > 0 &&
        repairLines.every((el: WorkOrderRepairLine) => el.Status === 0)
            ? openShopListModal
            : undefined;

    const nextAction = useNextWorkOrderAction(workOrder, repairLines);
    const actions = useNextWorkOrderActions(workOrder, toggleShopListModal);

    const assignNewShop = (itemData: StationCompanyRelationDto) => {
        if (itemData) {
            dispatch(
                reassignWorkOrderShop({
                    backToPath,
                    workOrderID: workOrder.ID,
                    companyID: itemData.CompanyID,
                    stationID: itemData.StationID,
                }),
            );
        }
    };

    const onRemoveDeclinationReasonClick = useCallback(
        (errorMessage: ErrorMessage) => {
            dispatch(
                removeWorkOrderDeclinationReason({
                    ID: workOrder.ID,
                    errorMessageID: errorMessage.ID,
                }),
            );
        },
        [dispatch, workOrder],
    );

    const statusDescription = Object.values(WorkOrderStatus).find((item) => item.Status === status)?.Description;
    const isRemoveDeclinationReasonsAllowed = userHasPermissions(Permission.WORK_ORDER_REJECTED) && readOnly;
    const declinationReasons = _uniqBy(workOrder.DeclinationReasons, 'ID').filter(({Message: message}) => Boolean(message));

    return (
        <>
            <Container>
                <Title>{t(`${title}`)}</Title>
                <Text>
                    {t('current_status')}: {statusDescription ? t(statusDescription) : '-'}
                </Text>
                {!readOnly && nextAction && (
                    <AllowedTo perform={nextAction.permissions} data={workOrder}>
                        <ApprovalBtn
                            onClick={() => dispatch(nextAction.action())}
                            startIcon={<ArrowRightAltIcon />}
                            variant="contained"
                            disabled={loading}
                            color="primary"
                            fullWidth>
                            {t(nextAction.label)}
                        </ApprovalBtn>
                    </AllowedTo>
                )}
                <Text>
                    {t('type')}: {unitDetails?.UnitType && t(UnitTypeCodes[unitDetails.UnitType])}
                </Text>
                <Text>
                    {t('dwell')}: {dwellDays}
                </Text>
                <Text>
                    {t('po_number')}: {purchaseOrderNumber}
                </Text>
                <Text>
                    {t('repair_date')}: {repairDate ? formatDate(repairDate) : '-'}
                </Text>
                <Text>
                    {t('fmcsa')}: {FHWADueDate ? formatDate(FHWADueDate) : '-'}
                </Text>
                <DetailsActionIcons badges={badge} urls={buildUrls(path, workOrder.ID)} />
                <Button variant="contained" fullWidth color="primary" onClick={openMenu}>
                    {t('actions')}
                </Button>
                {Boolean(declinationReasons.length) && (
                    <div>
                        <DeclinationReasonsTitle>{t('declination_reasons')}</DeclinationReasonsTitle>
                        <DeclinationReasonsTemplate
                            loading={loading}
                            declinationReasons={declinationReasons}
                            onRemoveClick={!readOnly && isRemoveDeclinationReasonsAllowed ? onRemoveDeclinationReasonClick : undefined}
                        />
                    </div>
                )}
                <RepairLinesSection>
                    <Title>
                        {t('repair_lines')} {`(${repairLines.length})`}
                    </Title>
                    {repairLinesLoading && <LinearProgress />}
                    <RepairItemDetails onLineDetailsClick={onWorkOrderLineDetailsClick} detailsUrls={buildDetailsUrls(path, workOrder.ID)} />
                </RepairLinesSection>
                {isShopModalOpen && (
                    <ShopModalList
                        shops={filteredShops}
                        open={isShopModalOpen}
                        onSubmit={assignNewShop}
                        handleClose={() => setShopModalOpen(false)}
                        initialValue={filteredShops.find(({ID}) => ID === workOrder.ShopCodeID)}
                    />
                )}
            </Container>
            <ActionsMenu workOrder={workOrder} actions={actions} onViewHeaderClick={goToHeader} onClose={closeMenu} open={isMenuOpen} />
        </>
    );
};

export default WorkOrderDetails;
