import {createSelector} from 'reselect';

import {State} from '../root-reducer';

import {MessageState} from './message.reducer';

export const selectMessagesState = (state: State): MessageState => state.messageReducer;

export const selectMessages = createSelector(selectMessagesState, ({messages}) => messages);

export const selectReadMessages = createSelector(selectMessagesState, ({readMessageIds}) => readMessageIds);

export const selectNewMessagesCount = createSelector(selectMessagesState, ({messages, readMessageIds, loading}) =>
    loading ? 0 : messages.reduce((count, {ID}) => (readMessageIds.has(ID) ? count : count + 1), 0),
);
