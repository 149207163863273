import styled from 'styled-components';

export const DialogWrap = styled.div`
    margin: 15px;

    .MuiInput-root {
        width: 100%;
        margin: 35px 0;
    }
`;
