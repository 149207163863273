export const FORM_REQUEST = 'FORM_REQUEST';
export const FORM_RESPONSE = 'FORM_RESPONSE';
export const FORMS_RESPONSE = 'FORMS_RESPONSE';
export const FORM_ERROR = 'FORM_ERROR';
export const GET_FORM = 'GET_FORM';
export const GET_FORMS = 'GET_FORMS';

export enum FormName {
    WorkOrderHeader = 'WorkOrderHeader',
    RepairLine = 'RepairLine',
}
