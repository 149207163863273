export const OfflineEstimateSentStatus = {
    Unsent: {
        ID: 0,
        Description: 'unsent',
    },
    Queued: {
        ID: 1,
        Description: 'queued',
    },
    Uploading: {
        ID: 2,
        Description: 'uploading',
    },
    Processing: {
        ID: 3,
        Description: 'processing',
    },
    Error: {
        ID: 4,
        Description: 'error',
    },
    Live: {
        ID: 5,
        Description: 'live',
    },
} as const;
