import TextField from '@mui/material/TextField';
import {WidgetProps} from '@rjsf/utils';
import React from 'react';

// The copy of https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/material-ui/src/UpDownWidget/UpDownWidget.tsx
export const OvertimeInput = ({label, value, onChange, autofocus, options, disabled}: WidgetProps) => {
    const inputProps = {
        min: 0,
        step: '.1',
    };

    const _onChange = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        onChange(value);
    };
    return (
        <TextField
            disabled={disabled}
            id="overtime-number"
            label={label}
            type="number"
            defaultValue={options.default}
            value={value ?? ''}
            variant="standard"
            onChange={_onChange}
            autoFocus={autofocus}
            InputProps={{inputProps}}
        />
    );
};
