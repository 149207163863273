import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import {IconButton, Badge} from '@mui/material';
import {push} from 'connected-react-router';
import _isFunction from 'lodash/isFunction';
import _isString from 'lodash/isString';
import React, {ReactElement, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {HeaderContext} from '../../providers/header-provider';
import {selectIsOfflineStatus} from '../../redux/application/application.selectors';
import {isFeatureEnabled} from '../../redux/feature/feature.selectors';
import {selectNewMessagesCount} from '../../redux/message/message.selectors';
import {NavigationMenu} from '../navigation-menu/navigation-menu.component';

import {CenterContainer, Container, LeftContainer, OfflineIcon, OnlineIcon, RightContainer} from './header.styles';

export const Header = (): ReactElement => {
    const dispatch = useDispatch();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {backToPath, middleElement, setStateParam, basePath, updateHeaderData} = useContext(HeaderContext);
    const isOfflineMode = useSelector(selectIsOfflineStatus);
    const hasOfflineMode = useSelector(isFeatureEnabled('OfflineMode'));

    const openMenu = () => setIsMenuOpen(true);
    const closeMenu = () => setIsMenuOpen(false);
    const backFunction = () => {
        if (setStateParam != null && _isFunction(backToPath)) {
            backToPath(setStateParam!);
        } else if (_isString(backToPath)) {
            // If backToPath doesn't contain a number, it must be a base path. Use basePath instead and unset it in the header context.
            if (basePath && !backToPath.match(/\d/)) {
                updateHeaderData({
                    backToPath: backToPath,
                    middleElement: middleElement,
                    setStateParam: setStateParam,
                    basePath: undefined,
                });
                dispatch(push(basePath));
            } else {
                dispatch(push(backToPath));
            }
        }
    };

    const messagesCount = useSelector(selectNewMessagesCount);

    return (
        <Container>
            <LeftContainer>
                <IconButton sx={{display: window.location.pathname === '/' ? 'none' : null}} onClick={backFunction}>
                    <ArrowBackIcon />
                </IconButton>
            </LeftContainer>
            <CenterContainer>{_isFunction(middleElement) ? middleElement() : middleElement}</CenterContainer>
            <RightContainer>
                {hasOfflineMode ? isOfflineMode ? <OfflineIcon /> : <OnlineIcon /> : ''}
                <IconButton onClick={openMenu}>
                    <Badge overlap="rectangular" invisible={messagesCount < 1} badgeContent={messagesCount} color="error">
                        <MenuIcon />
                    </Badge>
                </IconButton>
            </RightContainer>
            <NavigationMenu open={isMenuOpen} onClose={closeMenu} />
        </Container>
    );
};
