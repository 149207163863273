import React, {useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {useHeader} from '../../hooks/use-header';
import {Part} from '../../models/Part';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {createRepairLinePart, updateRepairLinePart} from '../../redux/part/part.actions';
import {selectPartState} from '../../redux/part/part.selectors';
import {selectRepairLineState} from '../../redux/repair-line/repair-line.selectors';

import {PartForm, PartFormData} from './part-form.component';
import {HeaderTitle} from './part-form.styles';

export type PartFormContainerProps = {
    repairLineID: number;
    partNumber: string | undefined;
    onClose: () => void;
};

const PartFormContainer = ({repairLineID, partNumber, onClose}: PartFormContainerProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {workOrder, repairLines} = useContext(WorkOrderDataContext);
    const {parts, loading: partsLoading} = useSelector(selectPartState);
    const {loading: repairLinesLoading} = useSelector(selectRepairLineState);

    const repairLine = repairLines.find((item) => item.ID === repairLineID);
    const part = repairLine?.Parts.find(({PartNumber}) => PartNumber === partNumber);
    const partsOptions: Part[] = parts.filter(
        ({AllowablePartCombinations, EquipmentGroupIDPriced}) =>
            AllowablePartCombinations.some(
                ({JobCodeID, ConditionCodeID}) => JobCodeID === repairLine?.JobCodeId && ConditionCodeID === repairLine?.ConditionCodeId,
            ) && EquipmentGroupIDPriced[String(workOrder.EquipmentGroups)],
    );
    const initialValues: PartFormData | undefined =
        part || partsOptions?.length === 1
            ? {
                  repairLinePartID: part?.RepairLinePartID,
                  partID: part?.ID ?? partsOptions[0].ID,
                  partNumber: part?.PartNumber ?? partsOptions[0].PartNumber,
                  manufacturer: part?.Manufacturer ?? partsOptions[0].Manufacturer,
                  partDescription: part?.PartDescription ?? partsOptions[0].PartDescription,
                  quantity: part?.Quantity ?? 1,
              }
            : undefined;

    const Header = useCallback(() => <HeaderTitle>{t(part?.PartNumber ? 'update_part' : 'create_part')}</HeaderTitle>, [part?.PartNumber, t]);

    useHeader({
        backToPath: onClose,
        middleElement: Header,
    });

    const onSubmit = useCallback(
        (data: PartFormData) => {
            dispatch(
                data.repairLinePartID
                    ? updateRepairLinePart({
                          workOrderID: workOrder.ID,
                          repairLinePartID: data.repairLinePartID,
                          part: {
                              ID: data.partID,
                              PartNumber: data.partNumber,
                              Manufacturer: data.manufacturer,
                              PartDescription: data.partDescription,
                              Quantity: data.quantity,
                          },
                          repairLineID: repairLineID,
                          onSuccess: onClose,
                      })
                    : createRepairLinePart({
                          workOrderID: workOrder.ID,
                          repairLineID: repairLineID,
                          part: {ID: data.partID, PartNumber: data.partNumber, Quantity: data.quantity},
                          onSuccess: onClose,
                      }),
            );
        },
        [dispatch, onClose, repairLineID],
    );

    return (
        <PartForm
            key={`${repairLineID}-${part?.ID}`}
            onSubmit={onSubmit}
            partsOptions={partsOptions}
            initialValues={initialValues}
            disableSubmit={partsLoading || repairLinesLoading}
        />
    );
};

export default PartFormContainer;
