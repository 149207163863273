import {takeLatest, takeEvery, call, put, select, all} from 'redux-saga/effects';

import {Comment} from '../../models/Comment';
import {ReduxAction} from '../../models/ReduxAction';
import {UpdateCommentDto} from '../../models/dtos/update-comment.dto';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import request, {ApiResponse} from '../../services/request';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {updateWorkOrderInStoreAndIndexedDB} from '../../utils/updateWorkOrderInStoreAndIndexedDB';
import {WorkOrderState} from '../work-order/work-order.reducer';
import {selectWorkOrderState} from '../work-order/work-order.selectors';

import {
    commentsError,
    commentsRequest,
    commentsResponse,
    createCommentResponse,
    updateCommentResponse,
    deleteCommentResponse,
    repairLineCommentsResponse,
    createRepairLineCommentResponse,
    deleteRepairLineCommentResponse,
    updateRepairLineCommentResponse,
} from './comment.actions';
import {
    CREATE_COMMENT,
    DELETE_COMMENT,
    FETCH_COMMENTS,
    UPDATE_COMMENT,
    CREATE_REPAIR_LINE_COMMENT,
    UPDATE_REPAIR_LINE_COMMENT,
    DELETE_REPAIR_LINE_COMMENT,
    FetchComments,
    CreateComment,
    CreateRepairLineComment,
} from './comment.types';

export function* fetchComments({payload}: ReduxAction<FetchComments>) {
    yield put(commentsRequest());

    const {data, error}: ApiResponse<Comment[]> = yield call(request, {
        url: '/comments',
        method: 'get',
        params: {
            associatedObjectID: payload.associateObjectID,
            associatedTypeID: payload.associatedTypeID,
        },
    });

    if (error) {
        yield put(commentsError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        switch (payload.associatedTypeID) {
            case AssociatedTypes.Estimate:
            case AssociatedTypes.WorkOrder:
                yield put(commentsResponse(data));
                break;
            case AssociatedTypes.EstimateLine:
            case AssociatedTypes.WorkOrderRepairLine: {
                yield put(repairLineCommentsResponse(payload.associateObjectID, data));
                break;
            }
            default:
                return;
        }
    }
}

function* createComment({payload}: ReduxAction<CreateComment>) {
    yield put(commentsRequest());

    const {data, error}: ApiResponse<Comment> = yield call(request, {
        data: payload.comment,
        url: '/comments',
        method: 'post',
        params: {
            associatedObjectID: payload.associateObjectID,
            associatedTypeID: payload.associatedTypeID,
        },
    });

    if (error) {
        yield all([put(commentsError(error.message)), showErrorMessages(error.messages)]);
    } else if (data) {
        yield put(createCommentResponse(data));

        const {workOrders}: WorkOrderState = yield select(selectWorkOrderState);

        const workOrder = workOrders.find(({ID}) => ID === payload.associateObjectID);

        if (workOrder) {
            yield updateWorkOrderInStoreAndIndexedDB({...workOrder, CommentsCount: workOrder.CommentsCount + 1});
        }
    }
}

function* updateComment({payload}: ReduxAction<UpdateCommentDto>) {
    yield put(commentsRequest());

    const {data, error}: ApiResponse<Comment> = yield call(request, {
        url: `/comments/${payload.commentID}`,
        method: 'put',
        data: payload.comment,
    });

    if (error) {
        yield put(commentsError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield put(updateCommentResponse(data));
    }
}

function* deleteComment({payload}: ReduxAction) {
    yield put(commentsRequest());

    const {data, error}: ApiResponse<Comment> = yield call(request, {
        url: `/comments/${payload.commentID}`,
        method: 'delete',
    });

    if (error) {
        yield all([put(commentsError(error.message)), showErrorMessages(error.messages)]);
    } else if (data) {
        yield put(deleteCommentResponse(data.ID));

        const {workOrders}: WorkOrderState = yield select(selectWorkOrderState);

        const workOrder = workOrders.find(({ID}) => ID === payload.workOrderID);

        if (workOrder) {
            yield updateWorkOrderInStoreAndIndexedDB({...workOrder, CommentsCount: workOrder.CommentsCount - 1});
        }
    }
}

function* createRepairLineComment({payload}: ReduxAction<CreateRepairLineComment>) {
    yield put(commentsRequest());

    const {data, error}: ApiResponse<Comment> = yield call(request, {
        data: payload.comment,
        url: '/comments',
        method: 'post',
        params: {
            associatedObjectID: payload.associateObjectID,
            associatedTypeID: payload.associatedTypeID,
        },
    });

    if (error) {
        yield all([put(commentsError(error.message)), showErrorMessages(error.messages)]);
    }

    if (data) {
        yield put(createRepairLineCommentResponse(payload.associateObjectID, data));

        const {workOrders}: WorkOrderState = yield select(selectWorkOrderState);

        const workOrder = workOrders.find(({ID}) => ID === payload.workOrderID);

        if (workOrder) {
            yield updateWorkOrderInStoreAndIndexedDB({...workOrder, CommentsCount: workOrder.CommentsCount + 1});
        }
    }
}

function* updateRepairLineComment({payload}: ReduxAction<UpdateCommentDto>) {
    yield put(commentsRequest());

    const {data, error}: ApiResponse<Comment> = yield call(request, {
        url: `/comments/${payload.commentID}`,
        method: 'put',
        data: payload.comment,
    });

    if (error) {
        yield put(commentsError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield put(updateRepairLineCommentResponse(payload.associateObjectID, data));
    }
}

function* deleteRepairLineComment({payload}: ReduxAction) {
    yield put(commentsRequest());

    const {data, error}: ApiResponse<Comment> = yield call(request, {
        url: `/comments/${payload.commentID}`,
        method: 'delete',
    });

    if (error) {
        yield all([put(commentsError(error.message)), showErrorMessages(error.messages)]);
    } else if (data) {
        yield put(deleteRepairLineCommentResponse(payload.repairLineID, data.ID));

        const {workOrders}: WorkOrderState = yield select(selectWorkOrderState);

        const workOrder = workOrders.find(({ID}) => ID === payload.workOrderID);

        if (workOrder) {
            yield updateWorkOrderInStoreAndIndexedDB({...workOrder, CommentsCount: workOrder.CommentsCount - 1});
        }
    }
}

export default function* commentRootSaga() {
    yield takeEvery<ReduxAction>(FETCH_COMMENTS, fetchComments);
    yield takeLatest<ReduxAction>(UPDATE_COMMENT, updateComment);
    yield takeLatest<ReduxAction>(DELETE_COMMENT, deleteComment);
    yield takeLatest<ReduxAction>(CREATE_COMMENT, createComment);
    yield takeLatest<ReduxAction>(CREATE_REPAIR_LINE_COMMENT, createRepairLineComment);
    yield takeLatest<ReduxAction>(UPDATE_REPAIR_LINE_COMMENT, updateRepairLineComment);
    yield takeLatest<ReduxAction>(DELETE_REPAIR_LINE_COMMENT, deleteRepairLineComment);
}
