import {CircularProgress, Portal} from '@mui/material';
import React from 'react';

import Logo from '../../components/logo/logo.component';

import {Wrapper} from './full-page-fallback.styles';

const FullPageFallback = () => {
    return (
        <Portal>
            <Wrapper>
                <Logo />
                <CircularProgress color="primary" />
            </Wrapper>
        </Portal>
    );
};

export default FullPageFallback;
