import React from 'react';

import {AuditMessage} from '../../models/AuditMessage';

import {ItemContainer, AuditMessageText, Container} from './audit-messages.styles';

export type AuditMessagesProps = {
    auditMessages: AuditMessage[];
};

const AuditMessages = ({auditMessages}: AuditMessagesProps) => {
    return (
        <Container>
            {auditMessages.map(({ID, Message}) => (
                <ItemContainer key={`${ID}-${Message}`}>
                    <AuditMessageText>{Message}</AuditMessageText>
                </ItemContainer>
            ))}
        </Container>
    );
};

export default AuditMessages;
