import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import packageJson from '../../../package.json';
import {useHeader} from '../../hooks/use-header';
import {HeaderTitle} from '../approval/approval.styles';

import {Container} from './about.styles';

const PHONE = '470-890-2714';
const EMAIL = 'mro.support@kaleris.com';

const About = () => {
    const {t} = useTranslation();
    const location = useLocation<{menuPrevPath?: string}>();
    const renderMiddleElement = useCallback(() => <HeaderTitle>{t('about')}</HeaderTitle>, [t]);

    useHeader({
        backToPath: location.state?.menuPrevPath || '/',
        middleElement: renderMiddleElement,
    });

    return (
        <Container>
            <p>
                IMRS Version: <b>{packageJson.version}</b>
            </p>
            <p>
                For assistance please contact IMRS Support at <a href={`tel:${PHONE}`}>{PHONE}</a> or <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
            </p>
        </Container>
    );
};

export default About;
