import {JobCode} from '../../models/JobCode';

import {FETCH_JOB_CODES, JOB_CODE_ERROR, JOB_CODE_REQUEST, JOB_CODES_RESPONSE} from './job-code.types';

export const jobCodeRequest = () => ({
    type: JOB_CODE_REQUEST,
});

export const jobCodeError = (error: Error | unknown) => ({
    type: JOB_CODE_ERROR,
    payload: error,
});

export const jobCodesResponse = (data: JobCode[]) => ({
    type: JOB_CODES_RESPONSE,
    payload: data,
});

export const fetchJobCodes = (forceFetchAll?: boolean) => ({
    type: FETCH_JOB_CODES,
    payload: forceFetchAll,
});
