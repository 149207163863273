import {Button} from '@mui/material';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';

import GoOfflineButton from '../../components/go-offline-online-buttons/go-offline-button.component';
import GoOnlineButton from '../../components/go-offline-online-buttons/go-online-button.component';
import Logo from '../../components/logo/logo.component';
import {selectIsOfflineStatus} from '../../redux/application/application.selectors';
import {isFeatureEnabled} from '../../redux/feature/feature.selectors';
import {selectRecentlyCreatedOfflineEstimates, selectNotLiveOfflineEstimates} from '../../redux/offline-estimate/offline-estimate.selectors';
import {resetSearchParams} from '../../redux/search/search.actions';
import {selectSelectedShop} from '../../redux/shop/shop.selectors';
import {selectWorkOrders, selectEstimates} from '../../redux/work-order/work-order.selectors';

import {Container, ButtonContainer} from './home.styles';

const Home = () => {
    const shop = useSelector(selectSelectedShop);
    const dispatch = useDispatch();
    const isOfflineMode = useSelector(selectIsOfflineStatus);
    const hasOfflineMode = useSelector(isFeatureEnabled('OfflineMode'));
    const estimates = useSelector(selectEstimates(shop?.StationID ?? 0, shop?.CompanyID ?? 0));
    const workOrders = useSelector(selectWorkOrders(shop?.StationID ?? 0, shop?.CompanyID ?? 0));
    const notLiveEstimates = useSelector(selectNotLiveOfflineEstimates);
    const recentlyCreatedOfflineEstimates = useSelector(selectRecentlyCreatedOfflineEstimates);
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(resetSearchParams());
    }, []);

    return (
        <Container>
            <Logo />
            <ButtonContainer>
                {isOfflineMode ? (
                    <>
                        <Button variant="contained" color="primary" component={Link} size="large" to="/create-estimate" fullWidth>
                            {t('create_estimate')}
                        </Button>
                        <GoOnlineButton />
                    </>
                ) : (
                    <>
                        <Button variant="contained" color="primary" component={Link} size="large" to="/search" fullWidth>
                            {t('search')}
                        </Button>
                        <Button variant="contained" color="primary" component={Link} size="large" to="/estimates" fullWidth>
                            {`${t('estimates')} (${estimates.length})`}
                        </Button>
                        <Button variant="contained" color="primary" component={Link} size="large" to="/work-orders" fullWidth>
                            {`${t('work_orders')} (${workOrders.length})`}
                        </Button>
                        <Button variant="contained" color="primary" component={Link} size="large" to="/fmcsa" fullWidth>
                            {t('fmcsa_check')}
                        </Button>
                        <Button variant="contained" color="primary" component={Link} size="large" to="/create-estimate" fullWidth>
                            {t('create_estimate')}
                        </Button>
                        {hasOfflineMode ? <GoOfflineButton /> : ''}
                    </>
                )}
                {isOfflineMode || notLiveEstimates.length || recentlyCreatedOfflineEstimates.length ? (
                    <Button variant="contained" color="primary" component={Link} size="large" to="/offline-estimates" fullWidth>
                        {t('drafts') + ` (${notLiveEstimates?.length ?? 0})`}
                    </Button>
                ) : null}
            </ButtonContainer>
        </Container>
    );
};

export default Home;
