import {ExceptionReason} from '../../models/ExceptionReason';
import {ReduxAction} from '../../models/ReduxAction';

import {EXCEPTION_REASON_ERROR, EXCEPTION_REASON_REQUEST, EXCEPTION_REASONS_RESPONSE} from './exception-reason.types';

export interface ExceptionReasonState {
    error: Error | null;
    loading: boolean;
    exceptionReasons: ExceptionReason[];
}

const initialState: ExceptionReasonState = {
    error: null,
    loading: false,
    exceptionReasons: [],
};

export default function exceptionReasonsReducer(state = initialState, action: ReduxAction): ExceptionReasonState {
    switch (action.type) {
        case EXCEPTION_REASON_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case EXCEPTION_REASONS_RESPONSE:
            return {
                ...state,
                loading: false,
                exceptionReasons: action.payload,
            };
        case EXCEPTION_REASON_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
