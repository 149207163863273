import {Comment} from '../models/Comment';
import {WhyMadeCode} from '../models/WhyMadeCode';
import {WorkOrderRepairLine} from '../models/WorkOrderRepairLine';
import {WorkOrderRepairLineDataGridDto} from '../models/dtos/work-order-repair-line-data-grid-dto';

export const convertRepairLineToRepairLineDataGridDto = (
    repairLine: WorkOrderRepairLine,
    comments?: Comment[],
    whyMadeCodesDescription?: string,
): WorkOrderRepairLineDataGridDto => {
    return {
        ...repairLine,
        Defect: whyMadeCodesDescription,
        DetailsField: repairLine.ID,
        AuditMessagesField: repairLine.ID,
        DeclinationReasonsField: repairLine.ID,
        CommentsCount: comments?.length ?? 0,
        PartsCount: repairLine.Parts?.length ?? 0,
        FilesCount: repairLine.FileList?.length ?? 0,
    };
};

export const convertRepairLinesToRepairLineDataGridDtos = (
    repairLines: WorkOrderRepairLine[],
    repairLineComments: Record<Comment['ID'], Comment[]>,
    whyMadeCodes: WhyMadeCode[],
): WorkOrderRepairLineDataGridDto[] =>
    repairLines.map((item) => {
        const whyMadeCode = whyMadeCodes.find((whyMadeCode) => whyMadeCode.ID === item.WhyMadeCodeId);

        return convertRepairLineToRepairLineDataGridDto(item, repairLineComments[item.ID], whyMadeCode?.Description ?? '');
    });
