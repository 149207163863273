import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, useRouteMatch} from 'react-router-dom';

import FullPageFallback from '../components/full-page-fallback/full-page-fallback.component';
import {useIsWorkOrderReadOnly} from '../hooks/use-is-work-order-read-only';
import {WorkOrder} from '../models/WorkOrder';
import {WorkOrderRepairLine} from '../models/WorkOrderRepairLine';
import {fetchRepairLines} from '../redux/repair-line/repair-line.actions';
import {fetchWorkOrder, unselectWorkOrder} from '../redux/work-order/work-order.actions';
import {selectSelectedWorkOrder} from '../redux/work-order/work-order.selectors';

export type WorkOrderDataContext = {workOrder: WorkOrder; repairLines: WorkOrderRepairLine[]; readOnly: boolean};

export const WorkOrderDataContext = React.createContext<WorkOrderDataContext>({workOrder: {} as WorkOrder, repairLines: [], readOnly: false});

export type WorkOrderDataProviderProps = {
    basePath: string;
    children: ReactElement | ReactElement[];
};

export const WorkOrderDataProvider = ({children, basePath}: WorkOrderDataProviderProps) => {
    const dispatch = useDispatch();
    const {params} = useRouteMatch<{id?: string}>();
    const workOrderID = Number(params.id);
    const isWorkOrderReadOnly = useIsWorkOrderReadOnly(workOrderID);
    const workOrder = useSelector(selectSelectedWorkOrder);
    const [initialLoading, setInitialLoading] = useState(!workOrder);

    const onWorkOrderFetched = useCallback(
        (error?: boolean) => {
            setInitialLoading(false);

            if (!error) {
                dispatch(fetchRepairLines(workOrderID));
            }
        },
        [workOrderID],
    );

    useEffect(() => {
        dispatch(
            fetchWorkOrder({
                workOrderID,
                onFinish: onWorkOrderFetched,
            }),
        );

        return () => {
            dispatch(unselectWorkOrder());
        };
    }, [workOrderID, onWorkOrderFetched]);

    if (initialLoading) {
        return <FullPageFallback />;
    }

    if (!workOrder) {
        return <Redirect to={basePath} />;
    }

    const providerData: WorkOrderDataContext = {
        workOrder,
        readOnly: isWorkOrderReadOnly,
        repairLines: workOrder.WorkOrderRepairLines,
    };

    return <WorkOrderDataContext.Provider value={providerData}>{children}</WorkOrderDataContext.Provider>;
};
