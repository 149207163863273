import {Button} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, {memo, useContext, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import RepairLineSpecialInstructionsList from '../../components/repair-line-special-instructions-list/repair-line-special-instructions-list.component';
import SpecialInstrucionsGridComponent from '../../components/special-instructions-grid-list/special-instructions-grid-list.component';
import {SpecialInstructionAuthorization} from '../../models/SpecialInstructionAuthorization';
import {WorkOrderRepairLine} from '../../models/WorkOrderRepairLine';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {authorizeSpecialInstructions} from '../../redux/special-instructions/special-instruction.actions';
import {selectUser} from '../../redux/user/user.selectors';

import {ButtonWrapper} from './work-order-special-instructions.component.styles';

interface SpecialinstructionsComponentProps {
    specialInstructions: SpecialInstructionAuthorization[];
    repairLines: WorkOrderRepairLine[];
}

const WorkOrderSpecialInstructionsComponent = ({specialInstructions = [], repairLines = []}: SpecialinstructionsComponentProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {workOrder, readOnly} = useContext(WorkOrderDataContext);
    const userAuthorizationLevel = useSelector(selectUser)?.AuthorizationLevel ?? 0;
    const combinedSpecialInstructions = useMemo(
        () => [...(specialInstructions ?? []), ...repairLines.map((rl) => rl.SpecialInstructionAuthorizations).flat()],
        [repairLines, specialInstructions],
    );

    const authorizeEnabled = useMemo(() => {
        return (
            combinedSpecialInstructions.filter(
                (si) => !si.AuthorizationDate && si.SpecialInstruction.MinimumAuthorizationLevel <= userAuthorizationLevel,
            ).length > 0
        );
    }, [combinedSpecialInstructions]);

    const tooltipText = useMemo(() => {
        if (!authorizeEnabled) {
            if (combinedSpecialInstructions.filter((si) => !si.AuthorizationDate).length === 0) {
                return t('no_unauthorized_special_instructions');
            } else {
                return t('insufficient_authorization_level');
            }
        }
        return '';
    }, [authorizeEnabled, combinedSpecialInstructions, t]);

    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

    const onAuthorizeClicked = () => {
        dispatch(authorizeSpecialInstructions({workOrderID: workOrder.ID}));
    };

    return (
        <>
            <Tooltip title={tooltipText} open={tooltipIsOpen} onClose={() => setTooltipIsOpen(false)}>
                <ButtonWrapper onClick={() => specialInstructions.length && !authorizeEnabled && setTooltipIsOpen(true)}>
                    {!readOnly ? (
                        <Button disabled={!authorizeEnabled} onClick={onAuthorizeClicked} variant="contained" fullWidth color="primary">
                            {t('authorize')}
                        </Button>
                    ) : null}
                </ButtonWrapper>
            </Tooltip>
            <SpecialInstrucionsGridComponent payload={specialInstructions} title={`${t('work_order')} ${t('special_instructions')}`} />
            <RepairLineSpecialInstructionsList repairLines={repairLines} title={`${t('repair_line')} ${t('special_instructions')}`} />
        </>
    );
};

export default memo(WorkOrderSpecialInstructionsComponent);
