import {addMinutes, formatISO} from 'date-fns';

type FormatDateParams = {
    date: string | Date;
    ISOFormat?: boolean;
    includeTime?: boolean;
    checkForDateOnly?: boolean;
};

export type FormatDateFunction = {
    (params: FormatDateParams): string;
    (date: string | Date): string;
};

export const formatDate: FormatDateFunction = (params) => {
    if (typeof params === 'string') {
        const dateWithoutTime = params.split('T').at(0); // '2023-04-18T00:00:00' -> '2023-04-18'

        return new Date(dateWithoutTime!).toLocaleString(undefined, {timeZone: 'UTC', year: 'numeric', month: 'numeric', day: 'numeric'});
    }

    let {date, ISOFormat, includeTime, checkForDateOnly} = params as FormatDateParams;

    // Ensure all dates are treated as UTC.
    if (typeof date === 'string' && !date.endsWith('Z')) {
        date += 'Z';
    }

    let dateObject = new Date(date);

    // If the UTC date is at midnight, only show the date, otherwise, show it in the user's timezone.
    if (checkForDateOnly) {
        const utcDate = addMinutes(dateObject, dateObject.getTimezoneOffset());
        if (utcDate.toTimeString().startsWith('00:00:00 GMT')) {
            dateObject = utcDate;
            includeTime = false;
        }
    }

    if (ISOFormat) {
        return formatISO(dateObject);
    } else if (includeTime) {
        return dateObject.toLocaleString(undefined, {timeZoneName: 'short'});
    } else {
        return dateObject.toLocaleString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric'});
    }
};

export const formatDateForForm = (date: string | number | Date): string => {
    const dateObject = new Date(date);

    return formatISO(addMinutes(dateObject, dateObject.getTimezoneOffset()), {format: 'extended', representation: 'date'});
};
