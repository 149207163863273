import styled from 'styled-components';

export const ModalWrap = styled.div.attrs(() => ({
    // This attribute have to be passed with '-' value to avoid incorrect drop down search input working
    tabIndex: '-',
}))`
    position: absolute;
    padding: 20px 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 450px;
    background-color: #fff;
    border: 1px solid #b2b2b2;
    border-radius: 5px;
`;
