import {User} from '../../models/User';

import {FETCH_USERS, USER_ERROR, USER_REQUEST, USERS_RESPONSE, SELECT_USER, SET_USER, UNSET_USER, LOGOUT_USER} from './user.types';

/** Base action for user requests. */
export const userRequest = () => ({
    type: USER_REQUEST,
});

/** Action to process an error response for user requests. */
export const userError = (error: Error | unknown) => ({
    type: USER_ERROR,
    payload: error,
});

/** Action to process the response for a fetched list of Users. */
export const usersResponse = (data: User[]) => ({
    type: USERS_RESPONSE,
    payload: data,
});

/** Action to fetch the list of Users from the database. */
export const fetchUsers = () => ({
    type: FETCH_USERS,
});

/** Action to update the currently logged-in User. */
export const updateUser = (username: string, pgt: string) => ({
    type: SELECT_USER,
    payload: {username, pgt},
});

export const setUser = (user: User, pgt: string) => ({
    type: SET_USER,
    payload: {user, pgt},
});

export const unsetUser = () => ({
    type: UNSET_USER,
});

export const logoutUser = (callback?: () => void) => ({
    type: LOGOUT_USER,
    payload: {callback},
});
