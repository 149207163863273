import React from 'react';
import {Route, Switch, RouteProps} from 'react-router-dom';

import FilesPage from '../../pages/files-page/files-page.container';
import RepairLineDetail from '../../pages/repair-line-detail/repair-line-detail.component';
import WorkOrderComments from '../../pages/work-order-comments/work-order-comments.container';
import WorkOrderDetails from '../../pages/work-order-details/work-order-details.container';
import WorkOrderHeader from '../../pages/work-order-header/work-order-header.component';
import WorkOrderParts from '../../pages/work-order-parts/work-order-parts.container';
import WorkOrderSpecialInstructions from '../../pages/work-order-special-instructions/work-order-special-instructions.container';
import {WorkOrderDataProvider} from '../../providers/work-order-data-provider';

export type WorkOrderRoutesProps = {
    listComponent?: RouteProps['component'];
    detailsTitle: string;
    basePath: string;
};

export const WorkOrderRoutes = ({basePath, listComponent, detailsTitle}: WorkOrderRoutesProps) => (
    <Switch>
        {listComponent && <Route exact path={basePath} component={listComponent} />}
        <Route path={`${basePath}/:id`}>
            <WorkOrderDataProvider basePath={basePath}>
                <Switch>
                    <Route exact path={`${basePath}/:id`} render={() => <WorkOrderDetails title={detailsTitle} backToPath={basePath} />} />
                    <Route exact path={`${basePath}/:id/files`} render={() => <FilesPage backToPath={basePath} />} />
                    <Route exact path={`${basePath}/:id/header`} render={() => <WorkOrderHeader backToPath={basePath} />} />
                    <Route exact path={`${basePath}/:id/line`} render={() => <RepairLineDetail backToPath={basePath} />} />
                    <Route exact path={`${basePath}/:id/line/:lineId`} render={() => <RepairLineDetail backToPath={basePath} />} />
                    <Route exact path={`${basePath}/:id/parts`} render={() => <WorkOrderParts backToPath={basePath} />} />
                    <Route exact path={`${basePath}/:id/comments`} render={() => <WorkOrderComments backToPath={basePath} />} />
                    <Route
                        exact
                        path={`${basePath}/:id/special-instructions`}
                        render={() => <WorkOrderSpecialInstructions backToPath={basePath} />}
                    />
                </Switch>
            </WorkOrderDataProvider>
        </Route>
    </Switch>
);
