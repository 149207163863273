import {CircularProgress, IconButton} from '@mui/material';
import styled from 'styled-components';

export const Loader = styled(CircularProgress)`
    color: #ffffff;
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const PaperWrap = styled.div`
    width: 100%;
    padding: 24px;

    h2 {
        font-weight: normal;
        text-align: center;
        font-size: 64px;
        line-height: 75px;
        color: #19857b;
    }
`;

export const ButtonWrap = styled.div`
    padding-top: 26px;
`;

export const IconButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    color: ${(props) => props.theme.palette.primary.main};
    svg {
        font-size: 3rem;
    }
`;
