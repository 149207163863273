import AddIcon from '@mui/icons-material/Add';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import {t} from 'i18next';
import React, {useContext} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {OfflineEstimateFile} from '../../models/OfflineEstimateFile';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {DocumentTypeCodes} from '../../models/enumerations/DocumentTypeCodes';
import {OfflineEstimateDataContext} from '../../providers/offline-estimate-data-provider';
import {deleteOfflineEstimateFile} from '../../redux/offline-estimate/offline-estimate.actions';
import {isOfflineEstimateReadOnly} from '../../utils/isOfflineEstimateReadOnly';

import {
    Count,
    ViewModalContainer,
    ImageContent,
    DeleteWrap,
    AddButton,
    Navigation,
    NavBtn,
    Input,
    Label,
    DocumentType,
} from './offline-estimate-files-page.styles';

interface FilesViewModalProps {
    setViewModal: (value: boolean | ((val: boolean) => boolean)) => void;
    selectedFile: number;
    files: OfflineEstimateFile[];
    handleFile: (e: React.SyntheticEvent<EventTarget>) => void;
    setSelectedFile: (e: number) => void;
}

const OfflineEstimateFilesViewModal: React.FC<FilesViewModalProps> = ({setViewModal, handleFile, selectedFile, files, setSelectedFile}) => {
    const dispatch = useDispatch();
    const img = files[selectedFile];
    const {offlineEstimate} = useContext(OfflineEstimateDataContext);
    const isReadOnly = isOfflineEstimateReadOnly(offlineEstimate?.SentStatus);
    const associatedType = window.location.search.toUpperCase().includes('LINE') ? AssociatedTypes.WorkOrderRepairLine : AssociatedTypes.WorkOrder;
    const offlineEstimateLineID = new URLSearchParams(useLocation().search).get('lineID');
    const associatedID = offlineEstimateLineID ? +offlineEstimateLineID : offlineEstimate?.ID;

    const handleNav = (val: number) => {
        const index = selectedFile + val;
        if (index >= 0 && index < files.length) {
            setSelectedFile(index);
        }
    };

    const handleDelete = () => {
        dispatch(
            deleteOfflineEstimateFile({
                fileID: files[selectedFile].ID,
                associateObjectID: associatedID,
                associatedTypeID: associatedType,
                offlineEstimateID: offlineEstimate.ID,
            }),
        );
        setViewModal(false);
    };

    return (
        <ViewModalContainer>
            <ImageContent>
                <img src={`data:${img.FileType};base64,` + btoa(img.Data)} alt={img.FileName} />
            </ImageContent>
            <DocumentType>{t(DocumentTypeCodes[img.DocumentTypeCode])}</DocumentType>
            {!isReadOnly ? (
                <DeleteWrap onClick={handleDelete}>
                    <DeleteIcon />
                </DeleteWrap>
            ) : (
                ''
            )}
            <Navigation>
                <NavBtn onClick={() => handleNav(-1)}>
                    <ArrowLeftIcon />
                </NavBtn>
                <Count>
                    {selectedFile + 1} of {files.length}
                </Count>
                <NavBtn onClick={() => handleNav(1)}>
                    <ArrowRightIcon />
                </NavBtn>
            </Navigation>
            {!isReadOnly ? (
                <AddButton>
                    <Input type="file" id="upload-file" accept="image/*;capture=camera" onChange={handleFile} />
                    <Label htmlFor="upload-file">
                        <AddIcon />
                    </Label>
                </AddButton>
            ) : (
                ''
            )}
        </ViewModalContainer>
    );
};

export default OfflineEstimateFilesViewModal;
