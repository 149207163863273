import {Message} from '../../models/Message';

import {FETCH_MESSAGES, MESSAGES_REQUEST, MESSAGES_RESPONSE, MESSAGES_ERROR, READ_MESSAGE} from './message.types';

export const messagesRequest = () => ({
    type: MESSAGES_REQUEST,
});

export const messagesError = (error: Error | unknown) => ({
    type: MESSAGES_ERROR,
    payload: error,
});

export const messagesResponse = (data: Message[]) => ({
    type: MESSAGES_RESPONSE,
    payload: data,
});

export const fetchMessages = () => ({
    type: FETCH_MESSAGES,
});

export const readMessage = (messageID: number) => ({
    type: READ_MESSAGE,
    payload: {messageID},
});
