import {Cancel} from '@mui/icons-material';
import React from 'react';

import {ErrorMessage} from '../../models/ErrorMessage';

import {ItemContainer, ErrorText, IconButtonStyled} from './declination-reasons-template.styles';

export type DeclinationReasonsTemplateProps = {
    itemID?: number;
    loading?: boolean;
    declinationReasons: ErrorMessage[];
    onRemoveClick?: (declinationReason: ErrorMessage, itemID?: number) => void;
};

const DeclinationReasonsTemplate = ({declinationReasons, onRemoveClick, loading, itemID}: DeclinationReasonsTemplateProps) => (
    <>
        {declinationReasons.map((reason) => {
            const {ID, RuleID, Message} = reason;

            return (
                <ItemContainer key={ID}>
                    {onRemoveClick && +reason.ErrorType !== 1 && (
                        <IconButtonStyled onClick={() => onRemoveClick(reason, itemID)} disabled={loading}>
                            <Cancel />
                        </IconButtonStyled>
                    )}
                    <ErrorText>
                        {RuleID ? 'RPE' + RuleID + ':' : ''} {Message}
                    </ErrorText>
                </ItemContainer>
            );
        })}
    </>
);

export default DeclinationReasonsTemplate;
