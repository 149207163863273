import styled from 'styled-components';
export const PaperWrap = styled.div`
    height: auto;
    width: 100%;
    padding: 24px;

    h2 {
        text-align: center;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const HeaderTitle = styled.h2`
    color: ${({theme}) => theme.palette.primary.main};
`;
