import CopyIcon from '@mui/icons-material/ContentCopy';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Button, IconButton, Typography} from '@mui/material';
import platform from 'platform';
import {MouseEvent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import packageJson from '../../../package.json';

import {
    Container,
    ButtonContainer,
    AccordionContainer,
    AccordionComponent,
    AccordionSummaryComponent,
    AccordionDetailsComponent,
} from './error-boundary-page.styles';

type ErrorBoundaryPageProps = {
    error?: Error;
    resetErrorBoundary?: () => void;
};

const ErrorBoundaryPage = ({error, resetErrorBoundary}: ErrorBoundaryPageProps) => {
    const {t} = useTranslation();

    const errorObject = useMemo(
        () => ({
            version: packageJson.version,
            date: new Date().toString(),
            error: {
                name: error?.name,
                message: error?.message,
                stack: error?.stack,
            },
            location: {
                href: window.location.href,
                pathname: window.location.pathname,
                search: window.location.search,
            },
            platform,
        }),
        [location, error],
    );

    const toMainPage = () => {
        if (resetErrorBoundary) {
            resetErrorBoundary();
        }

        window.location.href = '/';
    };

    const copyErrorToClipboard = async (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        await navigator.clipboard.writeText(JSON.stringify(errorObject));
    };

    return (
        <Container>
            <ErrorIcon style={{width: '50px', height: '50px'}} color="error" />
            <Typography style={{margin: '25px', textAlign: 'center'}} variant="h5">
                {t('something_went_wrong')}
            </Typography>
            <ButtonContainer>
                <Button variant="contained" size="large" onClick={toMainPage}>
                    {t('to_main_page')}
                </Button>
                <Button variant="contained" size="large" onClick={resetErrorBoundary}>
                    {t('reload_page')}
                </Button>
            </ButtonContainer>
            <AccordionContainer>
                <AccordionComponent disableGutters>
                    <AccordionSummaryComponent expandIcon={<ExpandMoreIcon />}>
                        <IconButton size="large" title={t('copy_error_info')} onClick={copyErrorToClipboard}>
                            <CopyIcon />
                        </IconButton>
                        <Typography variant="overline">{t('show_error_object')}</Typography>
                    </AccordionSummaryComponent>
                    <AccordionDetailsComponent>
                        <pre>{JSON.stringify(errorObject, null, 2)}</pre>
                    </AccordionDetailsComponent>
                </AccordionComponent>
            </AccordionContainer>
        </Container>
    );
};

export default ErrorBoundaryPage;
