import {ReduxAction} from '../../models/ReduxAction';

import {FormResponse} from './form.actions';
import {FORM_ERROR, FORM_REQUEST, FORM_RESPONSE, FORMS_RESPONSE} from './form.types';

export interface FormState {
    error: Error | null;
    loading: boolean;
    forms: FormResponse;
}

const initialState: FormState = {
    error: null,
    loading: false,
    forms: {},
};

export default function formReducer(state = initialState, action: ReduxAction): FormState {
    switch (action.type) {
        case FORM_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FORM_RESPONSE:
            return {
                ...state,
                loading: false,
                forms: {...state.forms, ...({[action.payload.Name]: action.payload} as FormResponse)},
            };
        case FORMS_RESPONSE:
            return {
                ...state,
                loading: false,
                forms: state.forms,
            };
        case FORM_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
