import {Drawer, List, ListItemButton} from '@mui/material';
import {t} from 'i18next';
import _ from 'lodash';
import React, {ReactElement, useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';

import {OfflineEstimateAction} from '../../hooks/use-next-offline-estimate-actions';
import {ReduxAction} from '../../models/ReduxAction';
import ConfirmDialog from '../confirming-dialog/confirming-dialog.component';
import DividerComponent from '../divider/divider.component';

import {BlackListItemText, GreenListItemText, RedListItemText} from './offline-estimate-actions-menu.styles';

export type ActionsMenuProps = {
    open: boolean;
    onClose: () => void;
    actions: OfflineEstimateAction[];
    onViewHeaderClick: () => void;
};

export type ConfirmData = {
    title: string;
    submitButtonLabel: string;
    action: ((sendExternalIntegrationMessage?: boolean) => ReduxAction) | null;
};

export const OfflineEstimateActionsMenu = ({open, onClose, actions, onViewHeaderClick}: ActionsMenuProps): ReactElement => {
    const dispatch = useDispatch();
    const groupedActions = _.groupBy(actions, 'group');
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [confirmData, setConfirmData] = useState<null | ConfirmData>(null);

    const getListItemText = (color: string, label: string) => {
        switch (color) {
            case 'green':
                return <GreenListItemText primary={label} />;
            case 'red':
                return <RedListItemText primary={label} />;
        }
    };

    const onConfirm = useCallback(() => {
        if (confirmData?.action) {
            dispatch(confirmData?.action());
        }
        setConfirmData(null);
        setOpenConfirmDialog(false);
        onClose();
    }, [confirmData?.action]);

    const handleCancel = useCallback(() => {
        setConfirmData(null);
        setOpenConfirmDialog(false);
    }, []);

    const renderAction = ({label, color, action, isStatusRejected, title, submitButtonLabel}: OfflineEstimateAction) => (
        <ListItemButton
            key={label}
            onClick={() => {
                if (action) {
                    if (isStatusRejected) {
                        setConfirmData({submitButtonLabel: submitButtonLabel || '', title: title || '', action: action});
                        setOpenConfirmDialog(true);
                    } else {
                        dispatch(action());
                        onClose();
                    }
                }
            }}>
            {getListItemText(color, label)}
        </ListItemButton>
    );

    return (
        <>
            <Drawer anchor="right" open={open} onClose={onClose}>
                <List>
                    <ListItemButton onClick={onViewHeaderClick}>
                        <BlackListItemText primary={t('view_header')} />
                    </ListItemButton>
                    {Object.keys(groupedActions).map((key) => (
                        <div key={key}>
                            <DividerComponent />
                            {groupedActions[key].map(renderAction)}
                        </div>
                    ))}
                </List>
            </Drawer>
            {openConfirmDialog && confirmData && (
                <ConfirmDialog
                    onSubmit={onConfirm}
                    title={confirmData.title}
                    onCancel={handleCancel}
                    open={openConfirmDialog}
                    submitButtonLabel={confirmData.submitButtonLabel}
                />
            )}
        </>
    );
};
