import {SpecialInstructionAuthorization} from '../models/SpecialInstructionAuthorization';
import {SpecialInstructionDto} from '../models/dtos/special-instruction-dto';

import {getUserNameOrDefault} from './getUserNameOrDefault';

const getAuthorizedBy = ({AuthorizedByUser, AuthorizationDate}: SpecialInstructionAuthorization): string => {
    if (!AuthorizationDate) {
        return '';
    }

    return getUserNameOrDefault(AuthorizedByUser, '');
};

export const convertSpecialInstructionToSpecialInstructionDto = (specialInstruction: SpecialInstructionAuthorization): SpecialInstructionDto => ({
    ...specialInstruction,
    AuthorizedBy: getAuthorizedBy(specialInstruction),
});

export const convertSpecialInstructionsToSpecialInstructionDtos = (specialInstructions: SpecialInstructionAuthorization[]) =>
    specialInstructions?.map(convertSpecialInstructionToSpecialInstructionDto);
