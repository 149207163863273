import {ReduxAction} from '../../models/ReduxAction';
import {StationCompanyRelation} from '../../models/StationCompanyRelation';

import {SELECT_SHOP, SHOPS_ERROR, SHOPS_REQUEST, SHOPS_RESPONSE, UNSET_SHOP} from './shop.types';

export interface ShopState {
    error: Error | null;
    loading: boolean;
    shops: StationCompanyRelation[];
    selectedShop: StationCompanyRelation | null;
}

const initialState: ShopState = {
    error: null,
    loading: false,
    shops: [],
    selectedShop: null,
};

export default function shopReducer(state = initialState, action: ReduxAction): ShopState {
    switch (action.type) {
        case SHOPS_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case SHOPS_RESPONSE:
            return {
                ...state,
                loading: false,
                shops: action.payload,
            };
        case SHOPS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SELECT_SHOP:
            return {
                ...state,
                selectedShop: action.payload.shop,
            };
        case UNSET_SHOP:
            return {
                ...state,
                selectedShop: null,
            };
        default:
            return state;
    }
}
