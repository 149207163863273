import {WidgetProps} from '@rjsf/utils';
import {DropDownListComponent} from '@syncfusion/ej2-react-dropdowns';
import _isUndefined from 'lodash/isUndefined';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {StationCompanyRelation} from '../../models/StationCompanyRelation';
import {User} from '../../models/User';
import {StationCompanyRelationDto} from '../../models/dtos/station-company-relation.dto';
import {SyncfusionSelectEvent} from '../../models/types/SyncfusionSelectEvent';
import {selectShops, selectShopState} from '../../redux/shop/shop.selectors';
import {selectUser} from '../../redux/user/user.selectors';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import dropDownFiltering from '../../utils/dropDownFiltering';

import {Container} from './create-estimate.style';

export type ShopDropDownInputProps = {
    onChange: (shopID?: number) => void;
    readonly: boolean;
};

export const SelectShop = ({onChange, readonly}: WidgetProps) => {
    const {t} = useTranslation();
    const shops = useSelector(selectShops);
    const {loading, selectedShop} = useSelector(selectShopState);
    const user = useSelector(selectUser) as unknown as User;

    const filteredShops = useMemo<StationCompanyRelationDto[]>(() => {
        return user.Companies.map(({ID}) => ID).includes(0) && user.Roles.includes('ADMIN')
            ? shops.filter(({IsDeleted, Active}) => !IsDeleted && Active)
            : shops.filter(
                  ({StationID, CompanyID, IsDeleted, Active}) =>
                      !IsDeleted &&
                      Active &&
                      (user.Companies.map(({ID}) => ID).includes(0) || user.Companies.map(({ID}) => ID).includes(CompanyID)) &&
                      user.Stations.map(({ID}) => ID).includes(StationID),
              );
    }, [shops]);

    const [shopID, setShopID] = useState<undefined | number>(selectedShop?.ID);

    useEffect(() => {
        if (_isUndefined(shopID) && filteredShops.length) {
            const lastSelectedShopID = LocalStorage.get(LocalStorageKeys.LAST_SELECTED_SHOP);

            const shop = lastSelectedShopID ? filteredShops.find(({ID}) => ID === lastSelectedShopID) ?? filteredShops[0] : filteredShops[0];

            setShopID(shop.ID);
            onChange(shop.ID);
        }
    }, [onChange, shopID, filteredShops]);

    const onSelect = useCallback(
        (event: SyncfusionSelectEvent<StationCompanyRelation>) => {
            setShopID(event.itemData.ID);
            onChange(event.itemData.ID);
        },
        [onChange],
    );

    const shopTemplate = ({ShopCode, Description}: StationCompanyRelation) => {
        return <div>{`[${ShopCode}] ${Description}`}</div>;
    };

    return (
        <Container>
            <DropDownListComponent
                placeholder={t('shop')}
                value={shopID}
                select={onSelect}
                dataSource={filteredShops}
                filtering={dropDownFiltering(['ShopCode', 'Description'], 'contains', [' ', '[', ']', '-'])}
                allowFiltering
                sortOrder="Ascending"
                floatLabelType="Auto"
                fields={{text: 'Description', value: 'ID'}}
                itemTemplate={shopTemplate}
                valueTemplate={shopTemplate}
                enabled={!loading && !readonly}
                index={filteredShops.length === 1 ? 0 : undefined}
            />
        </Container>
    );
};
