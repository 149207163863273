import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {Button} from '@mui/material';
import {push} from 'connected-react-router';
import React, {useCallback, useContext, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import DetailsActionIcons, {buildUrls} from '../../components/details-action-icons/details-action-icons.component';
import {OfflineEstimateActionsMenu} from '../../components/offline-estimate-actions-menu/offline-estimate-actions-menu.component';
import OfflineEstimateItemDetails, {buildDetailsUrls} from '../../components/offline-estimate-item-details/offline-estimate-item-details.component';
import useNextOfflineEstimateActions from '../../hooks/use-next-offline-estimate-actions';
import {WorkOrderAction} from '../../hooks/use-next-work-order-action';
import {OfflineEstimateLine} from '../../models/OfflineEstimateLine';
import {OfflineEstimateSentStatus} from '../../models/enumerations/OfflineEstimateSentStatus';
import {OfflineEstimateDataContext} from '../../providers/offline-estimate-data-provider';
import {queueOfflineEstimates} from '../../redux/offline-estimate/offline-estimate.actions';
import {selectOfflineEstimateState} from '../../redux/offline-estimate/offline-estimate.selectors';
import {fetchRepairLinePricing} from '../../redux/pricing/pricing.actions';
import {getUserFriendlySentStatus} from '../../utils/getUserFriendlySentStatus';

import {Container, Title, Text, ApprovalBtn, RepairLinesSection} from './offline-estimate-details.styles';

type OfflineEstimateDetailsProps = {
    path: string;
    title: string;
};

const OfflineEstimateDetails = ({title, path}: OfflineEstimateDetailsProps) => {
    const dispatch = useDispatch();
    const {offlineEstimate} = useContext(OfflineEstimateDataContext);
    const {uploading, polling} = useSelector(selectOfflineEstimateState);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const openMenu = () => setIsMenuOpen(true);
    const closeMenu = () => setIsMenuOpen(false);
    const {t} = useTranslation();

    const onOfflineEstimateLineDetailsClick = useCallback(
        (offlineEstimateLine: OfflineEstimateLine) => {
            dispatch(push(`${path}/${offlineEstimate.ID}/line/${offlineEstimateLine.ID}`));
            dispatch(
                fetchRepairLinePricing({
                    companyID: offlineEstimate.CompanyID,
                    stationID: offlineEstimate.StationID,
                    equipmentGroupID: offlineEstimate.EquipmentGroupID,
                    jobCodeID: offlineEstimateLine.JobCodeId,
                    conditionCodeID: offlineEstimateLine.ConditionCodeId,
                    repairSizeID: offlineEstimateLine.RepairSizeId,
                    suppressError: false,
                }),
            );
        },
        [offlineEstimate, dispatch],
    );

    const goToHeader = useCallback(() => {
        dispatch(push(`${path}/${offlineEstimate.ID}/header`));
        closeMenu();
    }, [dispatch, offlineEstimate]);

    const nextAction: WorkOrderAction = {
        label: t('queue'),
        action: queueOfflineEstimates.bind(null, [offlineEstimate.ID]),
    };
    const actions = useNextOfflineEstimateActions(offlineEstimate!);

    const badges = useMemo(
        () => ({
            files: offlineEstimate.Files.length,
            parts: offlineEstimate.RepairLines.reduce((acc, item) => acc + item.Parts.length, 0),
            comments: offlineEstimate.Comments.length + offlineEstimate.RepairLines.reduce((acc, item) => acc + item.Comments.length, 0),
            authorizedSpecialInstructions: 0,
            unauthorizedSpecialInstructions: 0,
        }),
        [offlineEstimate],
    );

    const {Mate, Lot, Row, Spot, Priority, SentStatus} = offlineEstimate;

    return (
        <>
            <Container>
                <Title>{t(`${title}`)}</Title>
                <Text>
                    {t('current_status')}:{' '}
                    {getUserFriendlySentStatus(
                        offlineEstimate.SentStatus,
                        offlineEstimate.Status,
                        offlineEstimate.UploadStartDate,
                        polling,
                        uploading,
                    )}
                </Text>
                {SentStatus === OfflineEstimateSentStatus.Unsent.ID && nextAction && (
                    <ApprovalBtn
                        onClick={() => dispatch(nextAction.action())}
                        startIcon={<ArrowRightAltIcon />}
                        variant="contained"
                        color="primary"
                        fullWidth>
                        {t(nextAction.label)}
                    </ApprovalBtn>
                )}
                <Text>
                    {t('mate')}: {Mate}
                </Text>
                <Text>
                    {t('lot_row_spot')}: {Lot || Row || Spot ? `${Lot}/${Row}/${Spot}` : ''}
                </Text>
                <Text>
                    {t('priority')}: {Priority ? t('yes') : t('no')}
                </Text>
                <DetailsActionIcons badges={badges} urls={buildUrls(path, offlineEstimate.ID)} />
                <Button variant="contained" fullWidth color="primary" onClick={openMenu}>
                    {t('actions')}
                </Button>
                <RepairLinesSection>
                    <Title>
                        {t('repair_lines')} {`(${offlineEstimate?.RepairLines.length ?? 0})`}
                    </Title>
                    <OfflineEstimateItemDetails
                        onDetailsClick={onOfflineEstimateLineDetailsClick}
                        detailsUrls={buildDetailsUrls(path, offlineEstimate.ID)}
                    />
                </RepairLinesSection>
            </Container>
            <OfflineEstimateActionsMenu actions={actions} onViewHeaderClick={goToHeader} onClose={closeMenu} open={isMenuOpen} />
        </>
    );
};

export default OfflineEstimateDetails;
