import {put, select, takeLeading, call} from 'redux-saga/effects';

import {UserHistory} from '../../models/History';
import {ReduxAction} from '../../models/ReduxAction';
import request, {ApiResponse} from '../../services/request';
import {showErrorMessages} from '../../utils/showErrorMessages';

import {historyError, historyRequest, historyResponse} from './history.action';
import {HistoryState, PER_PAGE} from './history.reducer';
import {selectHistoryState} from './history.selectors';
import {FETCH_NEXT_HISTORIES} from './history.types';

export function* fetchNextHistoryData() {
    const {take, skip, history}: HistoryState = yield select(selectHistoryState);

    yield put(historyRequest());

    const {data, error}: ApiResponse<UserHistory[]> = yield call(request, {
        url: `/users/history`,
        method: 'get',
        params: {
            skip,
            take,
        },
    });

    if (error) {
        yield put(historyError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield put(
            historyResponse({
                history: [...history, ...data],
                skip: skip + PER_PAGE,
                noMoreData: data.length === 0,
            }),
        );
    }
}

export default function* historyRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_NEXT_HISTORIES, fetchNextHistoryData);
}
