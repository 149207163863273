import styled from 'styled-components';

export const MainHeader = styled.div`
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #fff;
    z-index: 99;
    transition: top 0.4s;
`;

export const MainWrapper = styled.div`
    margin-top: 65px;
    padding-top: 5px;
`;
