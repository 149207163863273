import styled from 'styled-components';

export const Divider = styled.div`
    background-color: grey;
    opacity: 0.5;
    width: 80%;
    height: 1px;
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;
