import {FileInformation} from '../../models/FileInformation';
import {ReduxAction} from '../../models/ReduxAction';

import {FILES_ERROR, FILES_REQUEST, FILES_RESPONSE, CREATE_FILE_RESPONSE, DELETE_FILE_RESPONSE, CLEAR_FILE_STATE} from './file.types';

export interface FilesState {
    error: Error | null;
    loading: boolean;
    files: FileInformation[];
}

const initialState: FilesState = {
    error: null,
    loading: false,
    files: [],
};

export default function fileReducer(state = initialState, action: ReduxAction): FilesState {
    switch (action.type) {
        case FILES_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FILES_RESPONSE:
            return {
                ...state,
                loading: false,
                files: action.payload,
            };
        case FILES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CREATE_FILE_RESPONSE:
            return {
                ...state,
                loading: false,
                files: [...state.files, action.payload],
            };
        case DELETE_FILE_RESPONSE:
            return {
                ...state,
                loading: false,
                files: state.files.filter((item) => item.ID !== action.payload.ID),
            };
        case CLEAR_FILE_STATE:
            return {
                error: null,
                loading: false,
                files: [],
            };
        default:
            return state;
    }
}
