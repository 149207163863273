import {DropDownListComponent, PopupEventArgs} from '@syncfusion/ej2-react-dropdowns';
import {t} from 'i18next';
import React, {ReactElement, useCallback, useState} from 'react';
import {useSelector} from 'react-redux';

import {Company} from '../../models/Company';
import {CompanyDto} from '../../models/dtos/company.dto';
import {SyncfusionSelectEvent} from '../../models/types/SyncfusionSelectEvent';
import {selectCompanyState} from '../../redux/company/company.selectors';
import dropDownFiltering from '../../utils/dropDownFiltering';

import {Container, PaperWrap, ButtonWrap} from './company.styles';

export type SelectCompanyProps = {
    initialValue: number;
    onSubmit: (data: number) => void;
};

/**
 * Admin tool to allow the user to select a company from the list of all companies.
 *
 * @param {SelectCompanyProps} {initialValues, onSubmit}
 * @returns {ReactElement} Component to render.
 */
export const CompanyDropDownList = ({initialValue, onSubmit}: SelectCompanyProps): ReactElement => {
    let {loading, companies} = useSelector(selectCompanyState);
    const [companyID, setCompanyID] = useState(initialValue);
    const isSubmitButtonDisabled = loading || !companies || companies.length === 0 || initialValue === companyID;

    const onChange = (event: SyncfusionSelectEvent<Company>) => {
        return setCompanyID(event.itemData.ID);
    };
    const onSave = () => {
        if (companyID !== null) {
            onSubmit(companyID);
        }
    };

    const onDropDownOpen = useCallback(
        ({popup}: PopupEventArgs) => {
            const input = popup.element.querySelector('input');

            if (input) {
                input.placeholder = t('search');
            }
        },
        [t],
    );

    return (
        <Container>
            <PaperWrap>
                <DropDownListComponent
                    open={onDropDownOpen}
                    placeholder={t('company')}
                    value={2}
                    select={onChange}
                    dataSource={companies as CompanyDto[]}
                    filtering={dropDownFiltering(['CompanyName'], 'contains', [' ', '[', ']', '-'])}
                    allowFiltering
                    floatLabelType="Auto"
                    fields={{text: 'CompanyName', value: 'ID'}}
                    enabled={!loading}
                    index={companies.length === 1 ? 0 : undefined}
                />
                <ButtonWrap size="large" variant="contained" color="primary" onClick={onSave} disabled={isSubmitButtonDisabled} fullWidth>
                    {'Save'}
                </ButtonWrap>
            </PaperWrap>
        </Container>
    );
};
