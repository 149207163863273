import {createSelector} from 'reselect';

import {State} from '../root-reducer';

import {UnitState} from './unit.reducer';

export const selectUnitState = (state: State): UnitState => state.unitReducer;

export const selectUnitTypes = createSelector(selectUnitState, ({unitTypes}) => unitTypes);

export const selectUnitStatus = createSelector(selectUnitState, ({unitStatus}) => unitStatus);
