import {WidgetProps} from '@rjsf/utils';
import {DropDownListComponent, PopupEventArgs} from '@syncfusion/ej2-react-dropdowns';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {RepairSize} from '../../models/RepairSize';
import {RepairSizeDto} from '../../models/dtos/repair-size.dto';
import {RepairLineFields} from '../../models/enumerations/RepairLineFields';
import {SyncfusionSelectEvent} from '../../models/types/SyncfusionSelectEvent';
import {selectJobCodesState} from '../../redux/job-code/job-code.selectors';
import {selectRepairSizesState} from '../../redux/repair-size/repair-size.selectors';
import dropDownFiltering from '../../utils/dropDownFiltering';

const allowedRepairLineFields = [RepairLineFields.SizeList, RepairLineFields.SizeNumeric, RepairLineFields.TireRepair];

export const RepairSizeDropDownInput = ({onChange, formContext, value, disabled}: WidgetProps) => {
    const {JobCodeId, WhyMadeCodeId, ConditionCodeId} = formContext.formData || {};
    const {t} = useTranslation();
    const [localValue, setLocalValue] = useState(value);
    const [options, setOptions] = useState<RepairSizeDto[]>([]);
    const {jobCodes, loading: jobCodesLoading} = useSelector(selectJobCodesState);
    const {repairSizes, loading: repairSizesLoading} = useSelector(selectRepairSizesState);

    const onChangeHandler = useCallback(
        (newValue) => {
            setLocalValue(newValue);
            setTimeout(() => onChange(newValue), 0);
        },
        [onChange],
    );

    useEffect(() => {
        const jobCode = jobCodes.find(({ID}) => JobCodeId === ID);

        if (jobCode && WhyMadeCodeId && ConditionCodeId) {
            const whyMade = jobCode.WhyMade.filter(({WhyMadeCode}) => WhyMadeCodeId === WhyMadeCode.ID);

            if (whyMade?.some(({WhyMadeCodeBaseRepairLineField}) => allowedRepairLineFields.includes(WhyMadeCodeBaseRepairLineField))) {
                const newOptions = repairSizes.filter((item) => item.JobCodeId === jobCode.ID && item.ConditionCodeId === ConditionCodeId);

                setOptions(newOptions);

                if (!newOptions.find(({ID}) => ID === value)) {
                    const newValue = newOptions.length === 1 ? newOptions[0].ID : null;

                    onChangeHandler(newValue);
                }
            }
        }
    }, [ConditionCodeId, JobCodeId, jobCodes, onChangeHandler, repairSizes, WhyMadeCodeId, value]);

    const onSelect = useCallback(
        (event: SyncfusionSelectEvent<RepairSize>) => {
            onChangeHandler(event.itemData.ID);
        },
        [onChangeHandler],
    );

    const onDropDownOpen = useCallback(
        ({popup}: PopupEventArgs) => {
            const input = popup.element.querySelector('input');

            if (input) {
                input.placeholder = t('search');
            }
        },
        [t],
    );

    return (
        <DropDownListComponent
            open={onDropDownOpen}
            placeholder={t('size')}
            value={localValue}
            select={onSelect}
            dataSource={options}
            filtering={dropDownFiltering(['Description'], 'contains')}
            allowFiltering
            floatLabelType="Auto"
            enabled={!repairSizesLoading && !jobCodesLoading && Boolean(options.length) && !disabled}
            fields={{text: 'Size', value: 'ID'}}
        />
    );
};
