// Class to help with catching (TypeScript) unknown errors safely in catch block.

/** Interface like DOM Error object. */
export interface IErrorLike {
    cause: IErrorLike | unknown;
    name: string;
    message?: string;
    stack?: string;
    toString(): string;
}

export interface IErrorOptions {
    cause?: IErrorLike;
    stack?: string;
}

/**
 * Represents an Error-like object that is clonable for passing into React components.
 *
 * @export
 * @class ErrorLike
 * @typedef {ErrorLike}
 * @implements {IErrorLike}
 */
export class ErrorLike implements IErrorLike {
    private _cause: IErrorLike | unknown;
    public name: string;
    public message?: string;
    public stack?: string;

    public get cause() {
        return this._cause;
    }
    public set cause(value) {
        if (value !== undefined && value !== null) {
            if (ErrorLike.IsErrorLike(value)) {
                this._cause = ErrorLike.Copy(value);
            } else {
                const newCause = new ErrorLike();
                newCause.message = String(value);
                newCause.name = String(typeof value);
                this._cause = newCause;
            }
        } else {
            this._cause = undefined;
        }
    }

    constructor(message?: string, options?: IErrorOptions) {
        this.name = 'Error';
        this.message = message;
        this.cause = options?.cause && ErrorLike.Copy(options?.cause);
        this.stack = options?.stack;
        // Object.setPrototypeOf(this, ErrorLike.prototype);
    }

    /**
     * Determine if an object is an Error or ErrorLike object.
     *
     * @param {unknown} e Thrown object to examine.
     * @returns {boolean} True if the type of e is Error or ErrorLike.
     */
    public static IsErrorLike = (e: unknown) => e instanceof Error || e instanceof ErrorLike;

    /**
     * Make a copy of an Error-like object or a reasonable facsimile.
     *
     * @public
     * @static
     * @param {(ErrorLike | Error | unknown)} e Thrown object to convert or copy.
     * @returns {ErrorLike} New copy of an ErrorLike object
     */
    public static Copy(e: ErrorLike | Error | unknown): ErrorLike | undefined {
        if (e === undefined) {
            return undefined;
        }
        return ErrorLike.IsErrorLike(e) ? ErrorLike.FromErrorLike(e as IErrorLike) : ErrorLike.FromUnknown(e);
    }

    /** Copy Error or ErrorLike object into an ErrorLike object. */
    private static FromErrorLike(e: IErrorLike) {
        const copy = new ErrorLike();
        // Copy only the properties defined in this class.
        // Dynamic copying doesn't work right because of prototypes and transpiled object definitions.
        copy.cause = e.cause;
        copy.message = e.message;
        copy.name = e.name;
        copy.stack = e.stack;
        return copy;
    }

    /** Convert unknown object into an ErrorLike object. */
    private static FromUnknown(e: unknown) {
        const copy = new ErrorLike();
        // Populate the new object with reasonable property values.
        copy.message = String(e);
        copy.cause = e;
        copy.name = String(typeof e);
        return copy;
    }
}

/**
 * Override Object.toString() to generate a readable string representation of an ErrorLike object.
 */
ErrorLike.prototype.toString = function (): string {
    'use strict';

    var obj = Object(this);
    if (obj !== this) {
        throw new TypeError();
    }

    var name = this.name;
    name = name === undefined ? 'Error' : String(name);

    var msg = this.message;
    msg = msg === undefined ? '' : String(msg);

    if (name === '') {
        return msg;
    }
    if (msg === '') {
        return name;
    }

    return name + ': ' + msg;
};
