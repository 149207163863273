import {Form} from '@rjsf/mui';
import {RegistryWidgetsType} from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {OfflineEstimateSentStatus} from '../../models/enumerations/OfflineEstimateSentStatus';
import {GeneratePurchaseOrderCheckbox} from '../../pages/work-order-header/generate-purchase-order-checkbox.component';
import {InitialValues} from '../../pages/work-order-header/work-order-header.component';
import {selectSelectedOfflineEstimate} from '../../redux/offline-estimate/offline-estimate.selectors';
import getValidJson from '../../utils/getValidJson';
import {fixSchema, validate} from '../../utils/workOrderHeaderValidation';
import HeaderPageTitle from '../header-page-title/header-page-title.component';
import {HeaderPageMode} from '../header-page-title-controls/header-page-title-controls.types';

import {Container} from './header-page.styles';
import {HeaderPageProps} from './header-page.types';

const customWidgets: RegistryWidgetsType = {
    'generate-purchase-order': GeneratePurchaseOrderCheckbox,
};

const HeaderPage = ({formSchemas, onUpdate, initialValues, workOrderStatus, readOnly}: HeaderPageProps) => {
    const offlineEstimate = useSelector(selectSelectedOfflineEstimate);
    const [mode, setMode] = useState<HeaderPageMode>('read');
    const [formData, setFormData] = useState({});
    const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);
    const [key, setKey] = useState(0);
    const {schema, uiSchema} = fixSchema(
        getValidJson(formSchemas?.Schema),
        getValidJson(formSchemas?.UISchema),
        workOrderStatus,
        initialValues,
        !!offlineEstimate && offlineEstimate?.SentStatus !== OfflineEstimateSentStatus.Live.ID,
    );
    const onSubmitForm = () => {
        if (clickedSubmit) {
            // Copy the form data and remove the read-only fields since they shouldn't be updated.
            let data: Record<string, unknown> = {...formData};
            Object.keys(uiSchema).forEach((key) => {
                if (!formData.hasOwnProperty(key) || uiSchema[key]['ui:readonly']) {
                    delete data[key];
                }
            });
            onUpdate(data);
            setClickedSubmit(false);
            setMode('read');
        }
    };

    useEffect(() => {
        setFormData(initialValues);
    }, [initialValues]);

    // rjsf calls submit on any button click in the form so this is a workaround to only submit when clicking the save button.
    const handleSubmit = useCallback(() => {
        setClickedSubmit(true);
    }, []);

    const handleCancel = useCallback(() => {
        setFormData(initialValues);
        setMode('read');
        setKey(key + 1);
    }, [initialValues]);

    const handleEdit = useCallback(() => {
        setMode('edit');
    }, []);

    const onChange = (formData: InitialValues) => {
        setFormData(formData);
    };

    const updateFieldValue = useCallback((name: string, value: boolean) => {
        setFormData((prevState) => ({...prevState, [name]: value}));
    }, []);

    const isReadMode = mode === 'read';

    return (
        <Container>
            <HeaderPageTitle
                mode={mode}
                readOnly={readOnly}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                onEdit={handleEdit}
                title={formSchemas?.Description}
            />
            <Form
                id="header-form"
                key={key} // Changing the key will make errors go away on cancel.
                schema={schema}
                uiSchema={uiSchema}
                widgets={customWidgets}
                formData={formData}
                formContext={{formData, initialValues, updateFieldValue}}
                noHtml5Validate
                children
                readonly={isReadMode}
                onSubmit={onSubmitForm}
                onChange={({formData}) => onChange(formData)}
                autoComplete={'off'}
                showErrorList={false}
                customValidate={isReadMode ? undefined : validate}
                liveValidate
                validator={validator}
            />
        </Container>
    );
};

export default HeaderPage;
