import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/NotInterested';
import {IconButton} from '@mui/material';
import React from 'react';

import {HeaderPageMode} from './header-page-title-controls.types';

const HeaderPageTitleControls = ({
    mode,
    onSubmit,
    onCancel,
    onEdit,
}: {
    mode: HeaderPageMode;
    onSubmit: () => void;
    onCancel: () => void;
    onEdit: () => void;
}) => {
    if (mode !== 'read') {
        return (
            <div>
                <IconButton color="primary" type="submit" form="header-form" onClick={onSubmit}>
                    <DoneIcon />
                </IconButton>
                <IconButton component="span" onClick={onCancel}>
                    <CancelIcon />
                </IconButton>
            </div>
        );
    } else {
        return (
            <div>
                <IconButton color="primary" onClick={onEdit}>
                    <EditIcon />
                </IconButton>
            </div>
        );
    }
};
export default HeaderPageTitleControls;
