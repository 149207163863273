import {Button, ButtonGroup} from '@mui/material';
import {createElement} from '@syncfusion/ej2-base';
import {DropDownList} from '@syncfusion/ej2-dropdowns';
import {
    Column,
    ColumnDirective,
    ColumnsDirective,
    Filter,
    GridComponent,
    InfiniteScroll,
    Inject,
    PageSettingsModel,
    Predicate,
    QueryCellInfoEventArgs,
    Sort,
} from '@syncfusion/ej2-react-grids';
import {push} from 'connected-react-router';
import {format, isSameDay, subDays} from 'date-fns';
import _omit from 'lodash/omit';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import * as ReactDOM from 'react-dom';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {Fallback} from '../../components/fallback/fallback.component';
import GoOfflineButton from '../../components/go-offline-online-buttons/go-offline-button.component';
import GoOnlineButton from '../../components/go-offline-online-buttons/go-online-button.component';
import {useHeader} from '../../hooks/use-header';
import {useViewportSize} from '../../hooks/use-viewport-size';
import {OfflineEstimate} from '../../models/OfflineEstimate';
import {OfflineEstimateSentStatus} from '../../models/enumerations/OfflineEstimateSentStatus';
import {WorkOrderStatus} from '../../models/enumerations/WorkOrderStatus';
import {selectIsOfflineStatus} from '../../redux/application/application.selectors';
import {isFeatureEnabled} from '../../redux/feature/feature.selectors';
import {enqueueSnackbar} from '../../redux/notistack/notistack.actions';
import {
    copyOfflineEstimate,
    discardOfflineEstimate,
    pollOfflineEstimateTasks,
    queueOfflineEstimates,
    sendAllUnsentOfflineEstimates,
    sendQueuedOfflineEstimates,
    uploadOfflineEstimate,
} from '../../redux/offline-estimate/offline-estimate.actions';
import {selectOfflineEstimateState} from '../../redux/offline-estimate/offline-estimate.selectors';
import {getTimeZoneAbbreviation} from '../../utils/getTimeZoneAbbreviation';
import {getUserFriendlySentStatus} from '../../utils/getUserFriendlySentStatus';

import {
    HeaderTitle,
    NegateButton,
    QuickFilters,
    Results,
    ButtonWrapper,
    ContingencyButtonWrapper,
    PollingButton,
    QuickFilterButton,
} from './offline-estimates.styles';

const pageSettings: PageSettingsModel = {pageSize: 10000};

interface FilteringData {
    action: string;
    columns: Predicate & {properties: any}[];
    currentFilterColumn: Column;
    currentFilteringColumn: string;
    requestType: string;
}

type ReadFilterStatus = {
    column: Column;
    element: HTMLInputElement;
    fltrObj: Filter;
    operator: string;
};

type GridFilter = Record<string, {operator: string; value: unknown}>;

type WriteFilterStatus = {column: Column; target: Element; parent: GridComponent; filteredValue: number};

const OfflineEstimates = () => {
    const dispatch = useDispatch();
    const [gridFilters, setGridFilters] = useState<GridFilter>({});
    const gridRef = useRef<GridComponent>(null);
    const customDropDownRef = useRef<DropDownList>();
    const {width} = useViewportSize();
    const {t} = useTranslation();
    const {estimates: offlineEstimates, uploading, polling} = useSelector(selectOfflineEstimateState);
    const isOfflineMode = useSelector(selectIsOfflineStatus);
    const hasOfflineMode = useSelector(isFeatureEnabled('OfflineMode'));
    const renderMiddleElement = useCallback(() => <HeaderTitle>{t('offline_estimates')}</HeaderTitle>, [t]);
    const sortedOfflineEstimates = useMemo(
        () => [...offlineEstimates.map((offlineEstimate) => ({...offlineEstimate, CreatedDate: new Date(offlineEstimate.CreatedDate)}))].reverse(),
        [offlineEstimates],
    );

    useHeader({
        backToPath: '/',
        middleElement: renderMiddleElement,
    });

    const actionComplete = useCallback(function (state: FilteringData) {
        if (state.requestType === 'filtering' && state.action === 'filter') {
            setGridFilters(
                state.columns.reduce<GridFilter>((acc, predicateOrProperties: any) => {
                    const properties = predicateOrProperties.hasOwnProperty('properties') ? predicateOrProperties.properties : predicateOrProperties;
                    if (acc[properties.field]) {
                        acc[properties.field].value = Array.isArray(acc[properties.field].value)
                            ? [...(acc[properties.field].value as any[]), properties.value]
                            : [acc[properties.field].value, properties.value];
                        return acc;
                    } else {
                        return {...acc, [properties.field]: {operator: properties.operator, value: properties.value}};
                    }
                }, {}),
            );
        }

        if (state.requestType === 'filtering' && state.action === 'clearFilter') {
            setGridFilters((prevState) => _omit(prevState, state.currentFilterColumn.field));
        }
    }, []);

    if (width === undefined) {
        return <Fallback />;
    }

    const destroy = function (this: GridComponent) {
        document.removeEventListener('scroll', this.scrollModule['makeStickyHeader']);
    };

    const enableMobileLayout = width < 1008;

    const onRowClick = ({data}: {data: OfflineEstimate}) => {
        if (data.WorkOrderID) {
            if (isOfflineMode || data.Message != null) {
                // Prioritize displaying the message, if there is one.
                dispatch(
                    enqueueSnackbar(data.Message != null ? data.Message : t('work_order_not_viewable_offline'), {
                        autoHideDuration: 8000,
                        variant: 'error',
                    }),
                );
            } else {
                return dispatch(push(`/estimates/${data.WorkOrderID}`, {menuPrevPath: '/offline-estimates', basePath: '/offline-estimates'}));
            }
        } else {
            return dispatch(push(`/offline-estimates/${data.ID}`));
        }
    };

    const gridTemplate = ({SentStatus, Status, ID}: OfflineEstimate) => {
        if (SentStatus === OfflineEstimateSentStatus.Unsent.ID || SentStatus === OfflineEstimateSentStatus.Error.ID) {
            return (
                <ButtonWrapper>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            dispatch(queueOfflineEstimates([ID]));
                        }}
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth>
                        {SentStatus === OfflineEstimateSentStatus.Error.ID ? t('requeue') : t('queue')}
                    </Button>
                </ButtonWrapper>
            );
        } else if (SentStatus === OfflineEstimateSentStatus.Live.ID && Status !== WorkOrderStatus.WorkRejected.Status) {
            return (
                <ButtonWrapper>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            dispatch(discardOfflineEstimate(ID));
                        }}
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth>
                        {t('remove')}
                    </Button>
                </ButtonWrapper>
            );
        } else if (SentStatus === OfflineEstimateSentStatus.Live.ID && Status === WorkOrderStatus.WorkRejected.Status) {
            return (
                <ButtonWrapper>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            dispatch(copyOfflineEstimate(ID));
                        }}
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth>
                        {t('copy_for_corrections')}
                    </Button>
                </ButtonWrapper>
            );
        } else {
            return <NegateButton />;
        }
    };

    const queryCellInfo = (args: QueryCellInfoEventArgs) => {
        const cell = args.cell as Element;
        const field = (args.column as Column).field;
        const {SentStatus, Status, WorkOrderID, UploadStartDate, CreatedDate} = args.data as OfflineEstimate;

        switch (field) {
            case 'SentStatus': {
                ReactDOM.render(<span>{getUserFriendlySentStatus(SentStatus, Status, UploadStartDate, polling, uploading)}</span>, cell);
                break;
            }
            case 'Status': {
                if (WorkOrderID && Status) {
                    ReactDOM.render(
                        <span>{t(Object.values(WorkOrderStatus).find((status) => status.Status == Status)?.Description ?? t('unknown'))}</span>,
                        cell,
                    );
                } else {
                    cell.setAttribute('style', 'padding: 0;');
                    ReactDOM.render(<span></span>, cell);
                }
                break;
            }
            case 'UploadStartDate': {
                if (UploadStartDate) {
                    ReactDOM.render(<span>{`${format(UploadStartDate, 'M/dd h:mmaaa')} ${getTimeZoneAbbreviation(UploadStartDate)}`}</span>, cell);
                } else {
                    cell.setAttribute('style', 'padding: 0;');
                    ReactDOM.render(<span></span>, cell);
                }
                break;
            }
            case 'CreatedDate': {
                ReactDOM.render(
                    <span>{`${format(new Date(CreatedDate), 'M/dd h:mmaaa')} ${getTimeZoneAbbreviation(new Date(CreatedDate))}`}</span>,
                    cell,
                );
                break;
            }
        }
    };

    const sentStatusLabels = Object.keys(OfflineEstimateSentStatus).reduce<{}[]>((statuses, status) => {
        return [
            ...statuses,
            {
                ID: OfflineEstimateSentStatus[status as keyof typeof OfflineEstimateSentStatus].ID,
                Description: t(OfflineEstimateSentStatus[status as keyof typeof OfflineEstimateSentStatus].Description),
            },
        ];
    }, []);

    const workOrderStatusLabels = Object.keys(WorkOrderStatus).reduce<{}[]>((statuses, status) => {
        return [
            ...statuses,
            {
                Status: WorkOrderStatus[status as keyof typeof WorkOrderStatus].Status,
                Description: t(WorkOrderStatus[status as keyof typeof WorkOrderStatus].Description),
            },
        ];
    }, []);

    const filterSentStatus = {
        ui: {
            create: (args: React.ChangeEvent<HTMLInputElement>) => {
                const flValInput = createElement('input', {className: 'flm-input'});
                args.target.appendChild(flValInput);
                customDropDownRef.current = new DropDownList({
                    dataSource: sentStatusLabels,
                    fields: {text: 'Description', value: 'ID'},
                    placeholder: 'Select a sent status',
                    popupHeight: '200px',
                    value: customDropDownRef.current?.value,
                });
                customDropDownRef.current.appendTo(flValInput);
            },
            read: (args: ReadFilterStatus) => {
                args.fltrObj.filterByColumn(
                    args.column.field,
                    'equal',
                    Number(Object.values(OfflineEstimateSentStatus).find((el) => el.ID === customDropDownRef.current?.value)?.ID),
                );
            },
            write: (args: WriteFilterStatus) => {
                if (customDropDownRef.current) {
                    customDropDownRef.current.value = args.filteredValue;
                }
            },
        },
    };

    const filterStatus = {
        ui: {
            create: (args: React.ChangeEvent<HTMLInputElement>) => {
                const flValInput = createElement('input', {className: 'flm-input'});
                args.target.appendChild(flValInput);
                customDropDownRef.current = new DropDownList({
                    dataSource: workOrderStatusLabels,
                    fields: {text: 'Description', value: 'Status'},
                    placeholder: 'Select a status',
                    popupHeight: '200px',
                    value: customDropDownRef.current?.value,
                });
                customDropDownRef.current.appendTo(flValInput);
            },
            read: (args: ReadFilterStatus) => {
                args.fltrObj.filterByColumn(
                    args.column.field,
                    'equal',
                    Number(Object.values(WorkOrderStatus).find((el) => el.Status === customDropDownRef.current?.value)?.Status),
                );
            },
            write: (args: WriteFilterStatus) => {
                if (customDropDownRef.current) {
                    customDropDownRef.current.value = args.filteredValue;
                }
            },
        },
    };

    return (
        <section>
            <Results>
                <p>
                    {t('total_results')}: {sortedOfflineEstimates.length}
                </p>
                <p>
                    {t('drafts')}:{' '}
                    {sortedOfflineEstimates.filter(({SentStatus}) =>
                        (
                            [
                                OfflineEstimateSentStatus.Unsent.ID,
                                OfflineEstimateSentStatus.Queued.ID,
                                OfflineEstimateSentStatus.Uploading.ID,
                                OfflineEstimateSentStatus.Error.ID,
                            ] as number[]
                        ).includes(SentStatus),
                    )?.length ?? 0}
                </p>
                <p>
                    {t('ready_for_upload')}:{' '}
                    {sortedOfflineEstimates.filter(({SentStatus}) => SentStatus === OfflineEstimateSentStatus.Queued.ID)?.length ?? 0}
                </p>
            </Results>
            <ContingencyButtonWrapper>
                {isOfflineMode ? (
                    <>
                        <GoOnlineButton size={'small'} fullWidth={false} />
                        <Button variant="contained" color="primary" component={Link} size="small" to="/create-estimate">
                            {t('create_estimate')}
                        </Button>
                    </>
                ) : hasOfflineMode ? (
                    <GoOfflineButton size={'small'} fullWidth={false} />
                ) : (
                    ''
                )}
                {uploading ? (
                    <PollingButton size="small" type="submit" variant="contained" color="primary" disabled>
                        {t('uploading')}
                    </PollingButton>
                ) : (
                    ''
                )}
                {polling && offlineEstimates.some(({SentStatus}) => SentStatus === OfflineEstimateSentStatus.Processing.ID) ? (
                    <PollingButton size="small" type="submit" variant="contained" color="primary" disabled>
                        {t('currently_polling')}
                    </PollingButton>
                ) : (
                    ''
                )}
                {offlineEstimates.some(({SentStatus}) => SentStatus === OfflineEstimateSentStatus.Unsent.ID) ? (
                    <Button
                        // Queue all unsent.
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            dispatch(
                                queueOfflineEstimates(
                                    offlineEstimates.filter(({SentStatus}) => SentStatus === OfflineEstimateSentStatus.Unsent.ID).map(({ID}) => ID),
                                ),
                            );
                        }}
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary">
                        {t('queue_all')}
                    </Button>
                ) : (
                    ''
                )}
                {!isOfflineMode && !uploading && offlineEstimates.some(({SentStatus}) => SentStatus === OfflineEstimateSentStatus.Queued.ID) ? (
                    <Button
                        // If uploading isn't happening and there is a queued estimate, allow the user to kick off uploading.
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            dispatch(sendQueuedOfflineEstimates());
                        }}
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary">
                        {t('upload_estimates')}
                    </Button>
                ) : (
                    ''
                )}
                {!polling &&
                !isOfflineMode &&
                offlineEstimates.some(
                    ({WorkOrderID, SentStatus, BackgroundTaskID}) =>
                        WorkOrderID == null && BackgroundTaskID != null && SentStatus === OfflineEstimateSentStatus.Processing.ID,
                ) ? (
                    <Button
                        // If there are any sent with a task ID but polling isn't happening, allow the user to kick it off.
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            dispatch(pollOfflineEstimateTasks(true));
                        }}
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary">
                        {t('start_polling')}
                    </Button>
                ) : (
                    ''
                )}
                {!isOfflineMode &&
                offlineEstimates.some(
                    ({SentStatus, BackgroundTaskID}) => SentStatus === OfflineEstimateSentStatus.Processing.ID && BackgroundTaskID == null,
                ) ? (
                    <Button
                        // If an estimate is in Sent status but it didn't get a background task ID, allow re-sending by re-queueing and uploading.
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            dispatch(
                                queueOfflineEstimates(
                                    offlineEstimates
                                        .filter(
                                            ({SentStatus, BackgroundTaskID}) =>
                                                SentStatus === OfflineEstimateSentStatus.Processing.ID && BackgroundTaskID == null,
                                        )
                                        .map(({ID}) => ID),
                                ),
                            );
                            dispatch(sendAllUnsentOfflineEstimates());
                        }}
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary">
                        {t('resend_all')}
                    </Button>
                ) : (
                    ''
                )}
                {offlineEstimates.some(({SentStatus}) => SentStatus === OfflineEstimateSentStatus.Live.ID) ? (
                    <Button
                        // Clear all live.
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            for (const estimate of offlineEstimates.filter(({SentStatus}) => SentStatus === OfflineEstimateSentStatus.Live.ID)) {
                                dispatch(discardOfflineEstimate(estimate.ID));
                            }
                        }}
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary">
                        {t('clear_all_live')}
                    </Button>
                ) : (
                    ''
                )}
                {!isOfflineMode && offlineEstimates.some(({SentStatus}) => SentStatus === OfflineEstimateSentStatus.Uploading.ID && !uploading) ? (
                    <Button
                        // If uploading isn't happening, allow retrying.
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            dispatch(
                                uploadOfflineEstimate(
                                    offlineEstimates
                                        .filter(({SentStatus}) => SentStatus === OfflineEstimateSentStatus.Uploading.ID)
                                        .map(({ID}) => ID),
                                ),
                            );
                        }}
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary">
                        {t('retry_upload')}
                    </Button>
                ) : (
                    ''
                )}
            </ContingencyButtonWrapper>
            <QuickFilters>
                <p>{t('quick_filters')}:</p>
                <ButtonGroup variant="outlined" size="small">
                    <QuickFilterButton
                        variant={
                            Array.isArray(gridFilters.SentStatus?.value) &&
                            (gridFilters.SentStatus?.value as any[])?.includes(OfflineEstimateSentStatus.Unsent.ID) &&
                            (gridFilters.SentStatus?.value as any[])?.includes(OfflineEstimateSentStatus.Queued.ID)
                                ? 'contained'
                                : 'outlined'
                        }
                        onClick={() => {
                            if (gridRef.current) {
                                gridRef.current.clearFiltering();
                                gridRef.current.dataSource = sortedOfflineEstimates;
                            }
                            if (
                                Array.isArray(gridFilters.SentStatus?.value) &&
                                (gridFilters.SentStatus?.value as any[])?.includes(OfflineEstimateSentStatus.Unsent.ID) &&
                                (gridFilters.SentStatus?.value as any[])?.includes(OfflineEstimateSentStatus.Queued.ID)
                            ) {
                                setGridFilters((prevState) => {
                                    const {SentStatus, ...restFilters} = prevState;
                                    return restFilters;
                                });
                                return;
                            }
                            gridRef.current?.filterByColumn('SentStatus', 'equal', [
                                OfflineEstimateSentStatus.Unsent.ID,
                                OfflineEstimateSentStatus.Queued.ID,
                            ]);

                            // If there are no results, the grid will sometimes show the unfiltered results.
                            // Making the dataSource empty mimics the desired behavior but it requires fixing the dataSource in each onClick.
                            // clearFiltering() seems to be the problematic function and maybe it does something async under the hood
                            //  because without the setTimeout (with a >0 wait time), this still doesn't work.
                            setTimeout(() => {
                                if (gridRef.current && (gridRef.current.getFilteredRecords() as Object[])?.length === 0) {
                                    gridRef.current.dataSource = [];
                                }
                            }, 1);
                        }}
                        size="small">
                        {t('unsent')}
                    </QuickFilterButton>
                    <QuickFilterButton
                        variant={
                            gridFilters.UploadStartDate?.value && isSameDay(new Date(gridFilters.UploadStartDate.value as string), new Date())
                                ? 'contained'
                                : 'outlined'
                        }
                        onClick={() => {
                            if (gridRef.current) {
                                gridRef.current.clearFiltering();
                                gridRef.current.dataSource = sortedOfflineEstimates;
                            }
                            if (
                                Array.isArray(gridFilters.SentStatus?.value) &&
                                (gridFilters.SentStatus?.value as any[])?.includes(OfflineEstimateSentStatus.Unsent.ID) &&
                                (gridFilters.SentStatus?.value as any[])?.includes(OfflineEstimateSentStatus.Queued.ID)
                            ) {
                                setGridFilters((prevState) => {
                                    const {SentStatus, ...restFilters} = prevState;
                                    return restFilters;
                                });
                            }
                            if (
                                gridFilters.SentStatus?.value === OfflineEstimateSentStatus.Live.ID &&
                                gridFilters.UploadStartDate?.value &&
                                isSameDay(new Date(gridFilters.UploadStartDate.value as string), new Date())
                            ) {
                                setGridFilters((prevState) => {
                                    const {UploadStartDate, SentStatus, ...restFilters} = prevState;
                                    return restFilters;
                                });
                                return;
                            }
                            gridRef.current?.filterByColumn('UploadStartDate', 'equal', new Date());
                            gridRef.current?.filterByColumn('SentStatus', 'equal', OfflineEstimateSentStatus.Live.ID);

                            setTimeout(() => {
                                if (gridRef.current && (gridRef.current.getFilteredRecords() as Object[])?.length === 0) {
                                    gridRef.current.dataSource = [];
                                }
                            }, 1);
                        }}
                        size="small">
                        {t('sent_today')}
                    </QuickFilterButton>
                    <QuickFilterButton
                        variant={
                            gridFilters.UploadStartDate?.value &&
                            isSameDay(new Date(gridFilters.UploadStartDate.value as string), subDays(new Date(), 1))
                                ? 'contained'
                                : 'outlined'
                        }
                        onClick={() => {
                            if (gridRef.current) {
                                gridRef.current.clearFiltering();
                                gridRef.current.dataSource = sortedOfflineEstimates;
                            }
                            if (
                                Array.isArray(gridFilters.SentStatus?.value) &&
                                (gridFilters.SentStatus?.value as any[])?.includes(OfflineEstimateSentStatus.Unsent.ID) &&
                                (gridFilters.SentStatus?.value as any[])?.includes(OfflineEstimateSentStatus.Queued.ID)
                            ) {
                                setGridFilters((prevState) => {
                                    const {SentStatus, ...restFilters} = prevState;
                                    return restFilters;
                                });
                            }
                            if (
                                gridFilters.SentStatus?.value === OfflineEstimateSentStatus.Live.ID &&
                                gridFilters.UploadStartDate?.value &&
                                isSameDay(new Date(gridFilters.UploadStartDate.value as string), subDays(new Date(), 1))
                            ) {
                                setGridFilters((prevState) => {
                                    const {UploadStartDate, SentStatus, ...restFilters} = prevState;
                                    return restFilters;
                                });
                                return;
                            }
                            gridRef.current?.filterByColumn('UploadStartDate', 'equal', subDays(new Date(), 1));
                            gridRef.current?.filterByColumn('SentStatus', 'equal', OfflineEstimateSentStatus.Live.ID);

                            setTimeout(() => {
                                if (gridRef.current && (gridRef.current.getFilteredRecords() as Object[])?.length === 0) {
                                    gridRef.current.dataSource = [];
                                }
                            }, 1);
                        }}
                        size="small">
                        {t('sent_1_day')}
                    </QuickFilterButton>
                    <QuickFilterButton
                        variant={
                            gridFilters.UploadStartDate?.value &&
                            isSameDay(new Date(gridFilters.UploadStartDate.value as string), subDays(new Date(), 2))
                                ? 'contained'
                                : 'outlined'
                        }
                        onClick={() => {
                            if (gridRef.current) {
                                gridRef.current.clearFiltering();
                                gridRef.current.dataSource = sortedOfflineEstimates;
                            }
                            if (
                                Array.isArray(gridFilters.SentStatus?.value) &&
                                (gridFilters.SentStatus?.value as any[])?.includes(OfflineEstimateSentStatus.Unsent.ID) &&
                                (gridFilters.SentStatus?.value as any[])?.includes(OfflineEstimateSentStatus.Queued.ID)
                            ) {
                                setGridFilters((prevState) => {
                                    const {SentStatus, ...restFilters} = prevState;
                                    return restFilters;
                                });
                            }
                            if (
                                gridFilters.SentStatus?.value === OfflineEstimateSentStatus.Live.ID &&
                                gridFilters.UploadStartDate?.value &&
                                isSameDay(new Date(gridFilters.UploadStartDate.value as string), subDays(new Date(), 2))
                            ) {
                                setGridFilters((prevState) => {
                                    const {UploadStartDate, SentStatus, ...restFilters} = prevState;
                                    return restFilters;
                                });
                                return;
                            }
                            gridRef.current?.filterByColumn('UploadStartDate', 'equal', subDays(new Date(), 2));
                            gridRef.current?.filterByColumn('SentStatus', 'equal', OfflineEstimateSentStatus.Live.ID);

                            setTimeout(() => {
                                if (gridRef.current && (gridRef.current.getFilteredRecords() as Object[])?.length === 0) {
                                    gridRef.current.dataSource = [];
                                }
                            }, 1);
                        }}
                        size="small">
                        {t('sent_2_days')}
                    </QuickFilterButton>
                    <QuickFilterButton
                        variant={
                            gridFilters.UploadStartDate?.value &&
                            isSameDay(new Date(gridFilters.UploadStartDate.value as string), subDays(new Date(), 3))
                                ? 'contained'
                                : 'outlined'
                        }
                        onClick={() => {
                            if (gridRef.current) {
                                gridRef.current.clearFiltering();
                                gridRef.current.dataSource = sortedOfflineEstimates;
                            }
                            if (
                                Array.isArray(gridFilters.SentStatus?.value) &&
                                (gridFilters.SentStatus?.value as any[])?.includes(OfflineEstimateSentStatus.Unsent.ID) &&
                                (gridFilters.SentStatus?.value as any[])?.includes(OfflineEstimateSentStatus.Queued.ID)
                            ) {
                                setGridFilters((prevState) => {
                                    const {SentStatus, ...restFilters} = prevState;
                                    return restFilters;
                                });
                            }
                            if (
                                gridFilters.SentStatus?.value === OfflineEstimateSentStatus.Live.ID &&
                                gridFilters.UploadStartDate?.value &&
                                isSameDay(new Date(gridFilters.UploadStartDate.value as string), subDays(new Date(), 3))
                            ) {
                                setGridFilters((prevState) => {
                                    const {UploadStartDate, SentStatus, ...restFilters} = prevState;
                                    return restFilters;
                                });
                                return;
                            }
                            gridRef.current?.filterByColumn('UploadStartDate', 'equal', subDays(new Date(), 3));
                            gridRef.current?.filterByColumn('SentStatus', 'equal', OfflineEstimateSentStatus.Live.ID);

                            setTimeout(() => {
                                if (gridRef.current && (gridRef.current.getFilteredRecords() as Object[])?.length === 0) {
                                    gridRef.current.dataSource = [];
                                }
                            }, 1);
                        }}
                        size="small">
                        {t('sent_3_days')}
                    </QuickFilterButton>
                </ButtonGroup>
            </QuickFilters>
            <div className="e-adaptive-demo e-bigger">
                <div className="e-mobile-layout">
                    <div className="e-mobile-content">
                        <GridComponent
                            allowPaging
                            allowSorting
                            ref={gridRef}
                            allowFiltering
                            // @ts-ignore
                            destroy={destroy}
                            enableStickyHeader
                            dataSource={sortedOfflineEstimates}
                            enableInfiniteScrolling
                            rowSelected={onRowClick}
                            pageSettings={pageSettings}
                            queryCellInfo={queryCellInfo}
                            actionComplete={actionComplete}
                            filterSettings={{type: 'Menu'}}
                            enableAdaptiveUI={enableMobileLayout}
                            rowRenderingMode={enableMobileLayout ? 'Vertical' : 'Horizontal'}>
                            <Inject services={[InfiniteScroll, Filter, Sort]} />
                            <ColumnsDirective>
                                <ColumnDirective field="UnitINO" headerText={t('unit')} />
                                <ColumnDirective field="SentStatus" headerText={t('sent_status')} filter={filterSentStatus} />
                                <ColumnDirective field="UploadStartDate" headerText={t('upload_start')} type="date" />
                                <ColumnDirective field="Status" headerText={t('status')} filter={filterStatus} />
                                <ColumnDirective field="CreatedDate" headerText={t('created')} type="date" />
                                <ColumnDirective headerText="" template={gridTemplate} />
                            </ColumnsDirective>
                        </GridComponent>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OfflineEstimates;
