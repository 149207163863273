import React from 'react';

import {MessageDto} from '../../models/dtos/message.dto';
import {formatDate} from '../../utils/formatDate';

import {DatesContainer, MessageItemContainer, MessageText, DateText} from './message-list-item.styles';

const READ_COLOR = '#FFF';
const UNREAD_COLOR = 'rgba(25, 133, 123, 0.1)';

export type MessageItemProps = MessageDto;

export const MessageListItem = ({message, startDate, endDate, isRead}: MessageItemProps) => (
    <MessageItemContainer style={{backgroundColor: isRead ? READ_COLOR : UNREAD_COLOR, cursor: isRead ? 'default' : 'pointer'}}>
        <MessageText>{message}</MessageText>
        <DatesContainer>
            <DateText>Post: {startDate ? formatDate(startDate) : '-'}</DateText>
            <DateText>Exp: {endDate ? formatDate(endDate) : '-'}</DateText>
        </DatesContainer>
    </MessageItemContainer>
);
