import {createSelector} from 'reselect';

import {WorkOrderStatus} from '../../models/enumerations/WorkOrderStatus';
import {State} from '../root-reducer';

import {WorkOrderState} from './work-order.reducer';

export const selectWorkOrderState = (state: State): WorkOrderState => state.workOrderReducer;

export const selectWorkOrders = (stationID: number, companyID: number) =>
    createSelector(selectWorkOrderState, ({workOrders}) =>
        workOrders.filter(
            (item) =>
                item.Status >= WorkOrderStatus.WorkApproved.Status &&
                Object.values(WorkOrderStatus)
                    .filter(({IsMobile}) => IsMobile)
                    .map(({Status}) => Status)
                    .includes(item.Status) &&
                item.Company.ID === companyID &&
                item.RepairLocation.ID === stationID,
        ),
    );

export const selectAssignedWorkOrders = createSelector(selectWorkOrderState, ({workOrders}) =>
    workOrders.filter(({HasAssignedRepairLine}) => !HasAssignedRepairLine),
);

export const selectEstimates = (stationID: number, companyID: number) =>
    createSelector(selectWorkOrderState, ({workOrders}) =>
        workOrders.filter(
            (item) =>
                item.Status < WorkOrderStatus.WorkApproved.Status &&
                Object.values(WorkOrderStatus)
                    .filter(({IsMobile}) => IsMobile)
                    .map(({Status}) => Status)
                    .includes(item.Status) &&
                item.Company.ID === companyID &&
                item.RepairLocation.ID === stationID,
        ),
    );

export const selectWorkOrder = (workOrderID: number) =>
    createSelector(selectWorkOrderState, ({workOrders}) => workOrders.find((workOrder) => workOrder.ID === workOrderID));

export const selectSelectedWorkOrder = createSelector(selectWorkOrderState, ({selectedWorkOrder}) => selectedWorkOrder);
