import {connectRouter} from 'connected-react-router';
import {History} from 'history';
import {combineReducers} from 'redux';

import accessControlReducer, {AccessControlState} from './access-control/access-control.reducer';
import applicationReducer, {ApplicationState} from './application/application.reducer';
import commentReducer, {CommentsState} from './comment/comment.reducer';
import companyReducer, {CompanyState} from './company/company.reducer';
import conditionCodesReducer, {ConditionCodeState} from './condition-code/condition-code.reducer';
import exceptionReasonsReducer, {ExceptionReasonState} from './exception-reason/exception-reason.reducer';
import featuresReducer, {FeatureState} from './feature/feature.reducer';
import fileReducer, {FilesState} from './file/file.reducer';
import formReducer, {FormState} from './form-validation/form.reducer';
import historyReducer, {HistoryState} from './history/history.reducer';
import jobCodesReducer, {JobCodeState} from './job-code/job-code.reducer';
import messageReducer, {MessageState} from './message/message.reducer';
import notistackReducer, {NotistackState} from './notistack/notistack.reducer';
import offlineEstimateReducer, {OfflineEstimateState} from './offline-estimate/offline-estimate.reducer';
import partReducer, {PartState} from './part/part.reducer';
import pricingReducer, {PricingState} from './pricing/pricing.reducer';
import repairLineReducer, {RepairLineState} from './repair-line/repair-line.reducer';
import repairSizesReducer, {RepairSizeState} from './repair-size/repair-size.reducer';
import searchReducer, {SearchState} from './search/search.reducer';
import shopReducer, {ShopState} from './shop/shop.reducer';
import stationReducer, {StationState} from './station/station.reducer';
import unitReducer, {UnitState} from './unit/unit.reducer';
import unitGroupReducer, {UnitGroupState} from './unit-group/unit-group.reducer';
import unitLocationCodesReducer, {UnitLocationCodesState} from './unit-location-code/user-location-code.reducer';
import userReducer, {UserState} from './user/user.reducer';
import whyMadeCodesReducer, {WhyMadeCodesState} from './why-made-code/why-made-code.reducer';
import workOrderReducer, {WorkOrderState} from './work-order/work-order.reducer';

export interface State {
    shopReducer: ShopState;
    userReducer: UserState;
    partReducer: PartState;
    unitReducer: UnitState;
    searchReducer: SearchState;
    historyReducer: HistoryState;
    companyReducer: CompanyState;
    stationReducer: StationState;
    jobCodesReducer: JobCodeState;
    commentReducer: CommentsState;
    featuresReducer: FeatureState;
    fileReducer: FilesState;
    workOrderReducer: WorkOrderState;
    repairLineReducer: RepairLineState;
    repairSizesReducer: RepairSizeState;
    whyMadeCodesReducer: WhyMadeCodesState;
    conditionCodesReducer: ConditionCodeState;
    exceptionReasonsReducer: ExceptionReasonState;
    unitLocationCodesReducer: UnitLocationCodesState;
    messageReducer: MessageState;
    formReducer: FormState;
    notistackReducer: NotistackState;
    accessControlReducer: AccessControlState;
    pricingReducer: PricingState;
    applicationReducer: ApplicationState;
    offlineEstimateReducer: OfflineEstimateState;
    unitGroupReducer: UnitGroupState;
}

const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        shopReducer,
        userReducer,
        partReducer,
        unitReducer,
        stationReducer,
        searchReducer,
        fileReducer,
        historyReducer,
        companyReducer,
        commentReducer,
        workOrderReducer,
        jobCodesReducer,
        featuresReducer,
        repairLineReducer,
        repairSizesReducer,
        whyMadeCodesReducer,
        conditionCodesReducer,
        exceptionReasonsReducer,
        unitLocationCodesReducer,
        messageReducer,
        formReducer,
        notistackReducer,
        accessControlReducer,
        pricingReducer,
        applicationReducer,
        offlineEstimateReducer,
        unitGroupReducer,
    });

export default createRootReducer;
