export enum UnitTypeCodes {
    Unknown,
    Chassis,
    Trailer,
    Container,
    Crane,
    Hostler,
    Lift,
    SwitchEngine,
    AutoLoader,
    Grunt,
    RoadRailer,
    Bogie,
    CouplerMate,
    ChassisHandler,
}
