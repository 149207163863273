import {Typography} from '@mui/material';
import React from 'react';

import {HistoryDto} from '../../models/dtos/history.dto';

import {HistoryItemContainer, HistoryItemText, StyledHistoryItemText} from './history-list-item.styles';

export type HistoryItemProps = HistoryDto;

export const HistoryListItem = ({description, date, unitINO, workOrderNumber, rejectionReason}: HistoryItemProps) => (
    <HistoryItemContainer>
        <HistoryItemText>
            <Typography>{description}</Typography>
            <Typography>{date}</Typography>
        </HistoryItemText>
        {unitINO && workOrderNumber && (
            <StyledHistoryItemText>
                <Typography>{unitINO}</Typography>
                <Typography>{workOrderNumber}</Typography>
            </StyledHistoryItemText>
        )}
        {rejectionReason && <StyledHistoryItemText>{rejectionReason}</StyledHistoryItemText>}
    </HistoryItemContainer>
);
