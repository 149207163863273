import config from '../config';

import {IAuthService, AuthService} from './auth-service';
import CasAuthService, {CasLog} from './cas-auth-service';
import OidcAuthService, {OidcLog} from './oidc-auth-service';

/** Native interface for logger; example: console. */
export interface ILogger {
    debug(...args: unknown[]): void;
    info(...args: unknown[]): void;
    warn(...args: unknown[]): void;
    error(...args: unknown[]): void;
}

/** Log levels enumeration to specify log message severity. */
export enum LogLevels {
    NONE,
    ERROR,
    WARN,
    INFO,
    DEBUG,
}

/** Interface declaring logger management functions. */
interface IAuthLogManager {
    /** Remove logger. */
    reset: () => void;

    /** Set minimum severity level of messages that will be logged. */
    setLevel: (value: LogLevels) => void;

    /** Assign a service to log messages. */
    setLogger: (value: ILogger) => void;
}

/**
 * Type that maps a valid auth service name to its constructor.
 *
 * @typedef {AuthServiceDef}
 */
type AuthServiceDef = {
    /**
     * Get the name of the authentication service.
     *
     * @type {string}
     */
    name: string;

    /**
     * Create an instance of the authentication service.
     *
     * @type {() => AuthService}
     */
    create: () => AuthService;

    logManager: IAuthLogManager; // namespace
};

/**
 * Manage available authentication services and create an instance to use for authentication.
 *
 * @class AuthServiceManager
 * @typedef {AuthServiceManager}
 */
export class AuthServiceManager {
    // Define the list of valid authentication services.
    private knownAuthServices: AuthServiceDef[] = [];

    /** @constructor */
    constructor() {
        this.Initialize();
    }

    /**
     * Define known authentication services.
     *
     * @private
     */
    private Initialize(): void {
        this.knownAuthServices = [
            {
                name: OidcAuthService.AuthServiceName,
                create: () => new OidcAuthService(),
                logManager: OidcLog as unknown as IAuthLogManager, //
            },
            {
                name: CasAuthService.AuthServiceName,
                create: () => new CasAuthService(),
                logManager: CasLog as unknown as IAuthLogManager,
            },
        ];
    }

    /**
     * Find an authentication service definition by name (case-insensitive).
     *
     * @private
     * @param {string} name Official name of the authentication service to find.
     * @returns {AuthServiceDef} Definition of the authentication service to use for creation.
     */
    private FindAuthServiceDef(name: string): AuthServiceDef | undefined {
        name = name.toLowerCase();
        let def = this.knownAuthServices.find((def) => def.name === name);
        return def;
    }

    /**
     * Create an instance of the selected authentication service client.
     *
     * @public
     * @param {string} name Name of the authentication service to create.
     * @param {ILogger} logger A service that can be used for logging (e.g. console) if desired. Default is undefined.
     * @param {LogLevels} logLevel Minimum severity level of messages to log. Default is LogLevels.INFO.
     * @returns {IAuthService} Instance of the named authentication service client; if not found, use the default service.
     * @see {config} authSystem and defaultAuthSystem to set the selected and default authentication systems by name.
     */
    CreateAuthService(name: string, logger?: ILogger, logLevel: LogLevels = LogLevels.DEBUG): IAuthService | undefined {
        let selectedAuthService: IAuthService | undefined;

        // Look up the authentication system by its name.
        let c = this.FindAuthServiceDef(name) || this.FindAuthServiceDef(config.defaultAuthSystem);
        if (!c) {
            throw new Error('No authentication system configured (authSystem was undefined or invalid)');
        }

        // Assign a logger and level to the selected authentication service.
        if (c !== undefined && logger !== undefined) {
            c.logManager.setLogger(logger);
            c.logManager.setLevel(logLevel);
        }

        // Create authentication service instance with the matching name.
        selectedAuthService = c?.create();
        return selectedAuthService;
    }
}
