import {Button} from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const CompanyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const UserContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const RoleButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 24px;
`;

export const RoleButton = styled(Button)<{hasrole: string}>`
    margin: 5px 0;
    color: white;
    background-color: ${(b) => (b.hasrole === 'true' ? 'green' : 'red')};
    &:hover {
        background-color: ${(b) => (b.hasrole === 'true' ? 'green' : 'red')};
    }
`;
