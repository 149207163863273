import {Button, Step, StepButton, StepLabel, Stepper} from '@mui/material';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {useHeader} from '../../hooks/use-header';
import {FMCSADataContext} from '../../providers/fmcsa-data-provider';
import {fetchFmcsaCheck, resetFMCSA} from '../../redux/unit/unit.actions';
import {selectUnitState} from '../../redux/unit/unit.selectors';
import FMCSACheckContainer from '../fmcsa-check/fmcsa-check.container';

import {Container, InputUnitINO, PaperWrap, HeaderTitle} from './fmcsa.styles';

enum STEP {
    UNIT_INPUT,
    FMCSA_CHECK,
}

const FMCSA = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {resetFMCSAFormData} = useContext(FMCSADataContext);
    const [unitINO, setUnitINO] = useState('');
    const location = useLocation<{menuPrevPath?: string}>();
    const {loading, fmcsaCheck} = useSelector(selectUnitState);

    const activeStep = fmcsaCheck ? STEP.FMCSA_CHECK : STEP.UNIT_INPUT;

    const renderMiddleElement = useCallback(() => <HeaderTitle>{t('fmcsa_check')}</HeaderTitle>, [t]);
    const backToPath = useMemo(() => {
        if (activeStep === STEP.FMCSA_CHECK) {
            return () => dispatch(resetFMCSA());
        }

        return location.state?.menuPrevPath || '/';
    }, [location, activeStep]);

    useHeader({
        backToPath,
        middleElement: renderMiddleElement,
    });

    useEffect(() => {
        if (activeStep === STEP.FMCSA_CHECK) {
            setUnitINO('');
        }

        if (activeStep === STEP.UNIT_INPUT) {
            resetFMCSAFormData();
        }
    }, [activeStep]);

    const onFMCSACheck = () => {
        dispatch(fetchFmcsaCheck(unitINO));
    };

    return (
        <Container>
            <PaperWrap>
                <Stepper activeStep={activeStep}>
                    <Step>
                        <StepButton onClick={() => dispatch(resetFMCSA())}>{t('unit_ino')}</StepButton>
                    </Step>
                    <Step>
                        <StepLabel>{t('fmcsa_check')}</StepLabel>
                    </Step>
                </Stepper>
                {activeStep === STEP.UNIT_INPUT && (
                    <>
                        <InputUnitINO
                            id="unitINO"
                            label={t('unit')}
                            type="text"
                            required
                            onChange={(e) => setUnitINO(e.target.value)}
                            variant="standard"
                        />
                        <Button
                            onClick={onFMCSACheck}
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            disabled={!Boolean(unitINO) || loading}>
                            {t('check')}
                        </Button>
                    </>
                )}
                {activeStep === STEP.FMCSA_CHECK && <FMCSACheckContainer />}
            </PaperWrap>
        </Container>
    );
};

export default FMCSA;
