import {differenceInMilliseconds} from 'date-fns';
import {unionBy} from 'lodash';
import {takeLeading, call, put, select} from 'redux-saga/effects';

import config from '../../config';
import {JobCode} from '../../models/JobCode';
import {ReduxAction} from '../../models/ReduxAction';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {formatDate} from '../../utils/formatDate';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {selectIsOfflineStatus} from '../application/application.selectors';
import {selectSelectedShop} from '../shop/shop.selectors';

import {jobCodeError, jobCodeRequest, jobCodesResponse} from './job-code.actions';
import {FETCH_JOB_CODES} from './job-code.types';

const {resourceCacheFallback} = config;

export function* fetchJobCodes({payload: forceFetchAll}: ReduxAction<{forceFetchAll?: boolean}>) {
    const isOfflineMode: boolean = yield select(selectIsOfflineStatus);

    let cachedJobCodes: JobCode[] = [];

    if (isOfflineMode) {
        cachedJobCodes = yield call(IndexedDB.getMany, IndexedDBKeys.JOB_CODES);

        yield put(jobCodesResponse(cachedJobCodes));

        return;
    }

    const {StationID, CompanyID} = yield select(selectSelectedShop);
    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_JOB_CODES_REFRESH_TIME, LocalStorageKeys.LAST_JOB_CODES_UPDATE_TIME);

    if (lastUpdateTime && !forceFetchAll) {
        cachedJobCodes = yield call(IndexedDB.getMany, IndexedDBKeys.JOB_CODES);

        if (differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
            yield put(jobCodesResponse(cachedJobCodes));

            return;
        }
    }
    yield put(jobCodeRequest());

    const requestTime = new Date();

    if (!lastUpdateTime || forceFetchAll) {
        LocalStorage.set(LocalStorageKeys.LAST_JOB_CODES_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<JobCode[]> = yield call(request, {
        url: '/jobcodes',
        method: 'get',
        params: {
            stationID: StationID,
            companyID: CompanyID,
            ...(lastUpdateTime && !forceFetchAll
                ? {
                      includeDeleted: true,
                      lastUpdateTime: formatDate({
                          date: lastUpdateTime,
                          ISOFormat: true,
                      }),
                  }
                : undefined),
        },
        priority: 'high',
    });

    if (error) {
        yield put(jobCodeError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedJobCodes) {
            yield put(jobCodesResponse(cachedJobCodes));
        }
    }

    if (data) {
        const combinedJobCodes = unionBy(data, cachedJobCodes, 'ID')
            .filter((jobCode) => !jobCode.IsDeleted)
            .sort((a, b) => (a.JobCodeNumber.toUpperCase() < b.JobCodeNumber.toUpperCase() ? -1 : 1));

        yield put(jobCodesResponse(combinedJobCodes));

        yield call(IndexedDB.createMany, IndexedDBKeys.JOB_CODES, combinedJobCodes);
        LocalStorage.set(LocalStorageKeys.LAST_JOB_CODES_UPDATE_TIME, requestTime);
    }
}

export default function* jobCodesRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_JOB_CODES, fetchJobCodes);
}
