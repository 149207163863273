import {FormValidation} from '@rjsf/utils';

import {FMCSAFormData} from '../pages/fmcsa-check/fmcsa-check.schema';

export const validate = <T extends Partial<FMCSAFormData>>(formData: T, errors: FormValidation) => {
    if (formData.hasOwnProperty('StickerDate') && formData.StickerDate && new Date(formData.StickerDate) > new Date()) {
        errors.StickerDate?.addError('future dates are not allowed');
    }

    return errors;
};
