import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const ScrollToTop = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        // Scrolling to y=0 doesn't go to the top sometimes. Going very negative gets around that.
        window.scrollTo(0, -1000);
    }, [pathname]);

    return null;
};
