import TextField from '@mui/material/TextField';
import {WidgetProps} from '@rjsf/utils';
import React from 'react';

// The copy of https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/material-ui/src/UpDownWidget/UpDownWidget.tsx
export const UpDownWidget = ({label, value, onChange, autofocus, options, disabled}: WidgetProps) => {
    const _onChange = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        onChange(value);
    };

    const inputProps = {
        min: options.minimum ?? undefined,
    };

    const onBlur = ({target: {value}}: React.FocusEvent<HTMLInputElement>) => {
        // Default the value if the field is empty.
        if (isNaN(+value) || +value === 0) {
            onChange(options?.default ?? options?.minimum ?? 0);
        }
    };

    return (
        <TextField
            disabled={disabled}
            id="standard-number"
            label={label}
            type="number"
            value={!isNaN(value) ? value : ''}
            variant="standard"
            onChange={_onChange}
            onBlur={onBlur}
            autoFocus={autofocus}
            InputProps={{inputProps}}
        />
    );
};
