import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';

import {ScrollToTop} from '../../hooks/scroll-to-top';
import About from '../../pages/about/about.component';
import Approval from '../../pages/approval/approval.component';
import AuthSignInCallback from '../../pages/authentication/auth-sign-in-callback.component';
import AuthSignInError from '../../pages/authentication/auth-sign-in-error.component';
import AuthSignOutCallback from '../../pages/authentication/auth-sign-out-callback.component';
import SignOutPage from '../../pages/authentication/auth-sign-out.component';
import CreateEstimate from '../../pages/create-estimate/create-estimate.component';
import Estimates from '../../pages/estimates/estimates.component';
import History from '../../pages/history/history.component';
import Home from '../../pages/home/home.component';
import MessageList from '../../pages/message-list/message-list.component';
import MyAccount from '../../pages/my-account/my-account.component';
import Search from '../../pages/search/search.component';
import WorkOrders from '../../pages/work-orders/work-orders.component';
import {AccessControlProvider} from '../../providers/access-control-provider';
import {HeaderProvider} from '../../providers/header-provider';
import {ShopProvider} from '../../providers/shop-provider';
import {selectIsOfflineStatus} from '../../redux/application/application.selectors';
import {IndexedDBProvider} from '../indexed-db-provider/indexed-db-provider.component';
import {PrivateRoutes} from '../private-routes/private-routes';
import {ResourceProvider} from '../resource-provider/resource-provider.component';

import {FMCSARoutes} from './fmcsa-routes';
import {OfflineEstimateRoutes} from './offline-estimate-routes.component';
import {WorkOrderRoutes} from './work-order-routes.component';

export const Routes = () => {
    const {t} = useTranslation();
    const isOfflineMode = useSelector(selectIsOfflineStatus);

    return (
        <>
            <ScrollToTop />
            <Switch>
                <Route path="/session-error" component={AuthSignInError} />
                <Route path="/signin-auth" component={AuthSignInCallback} />
                <Route path="/signout-auth" component={AuthSignOutCallback} />
                <Route path="/signout" component={SignOutPage} />
                <PrivateRoutes>
                    <IndexedDBProvider>
                        <ShopProvider>
                            <ResourceProvider>
                                <AccessControlProvider>
                                    <HeaderProvider>
                                        <Switch>
                                            <Route exact path="/" component={Home} />
                                            <Route exact path="/my-account" component={MyAccount} />
                                            <Route exact path="/messages" component={MessageList} />
                                            <Route path="/about" component={About} />
                                            <Route exact path="/create-estimate" component={CreateEstimate} />
                                            <Route path="/history">{!isOfflineMode ? <Route path="/history" component={History} /> : null}</Route>
                                            <Route path="/search">{!isOfflineMode ? <Route path="/search" component={Search} /> : null}</Route>
                                            <Route path="/fmcsa">{!isOfflineMode ? <FMCSARoutes basePath="/fmcsa" /> : null}</Route>
                                            <Route path="/approval">
                                                {!isOfflineMode ? (
                                                    <WorkOrderRoutes basePath="/approval" detailsTitle={t('estimate')} listComponent={Approval} />
                                                ) : null}
                                            </Route>
                                            <Route path="/estimates">
                                                {!isOfflineMode ? (
                                                    <WorkOrderRoutes basePath="/estimates" detailsTitle={t('estimate')} listComponent={Estimates} />
                                                ) : null}
                                            </Route>
                                            <Route path="/work-orders">
                                                {!isOfflineMode ? (
                                                    <WorkOrderRoutes
                                                        basePath="/work-orders"
                                                        detailsTitle={t('work_order')}
                                                        listComponent={WorkOrders}
                                                    />
                                                ) : null}
                                            </Route>
                                            <Route path="/offline-estimates">
                                                <OfflineEstimateRoutes />
                                            </Route>
                                            <Route path="*" render={() => <Redirect to="/" push />} />
                                        </Switch>
                                    </HeaderProvider>
                                </AccessControlProvider>
                            </ResourceProvider>
                        </ShopProvider>
                    </IndexedDBProvider>
                </PrivateRoutes>
                <Route path="*" render={() => <Redirect to="/" push />} />
            </Switch>
        </>
    );
};
