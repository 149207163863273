import _last from 'lodash/last';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import {FileInformation} from '../../models/FileInformation';
import {ReduxAction} from '../../models/ReduxAction';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import request, {ApiResponse} from '../../services/request';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {updateWorkOrderInStoreAndIndexedDB} from '../../utils/updateWorkOrderInStoreAndIndexedDB';
import {fetchWorkOrder} from '../work-order/work-order.actions';
import {WorkOrderState} from '../work-order/work-order.reducer';
import {selectWorkOrderState} from '../work-order/work-order.selectors';

import {createFileResponse, deleteFileResponse, filesError, filesRequest, filesResponse} from './file.actions';
import {CREATE_FILE, CreateFile, DELETE_FILE, FETCH_FILES, FetchFiles} from './file.types';

export function* fetchFiles({payload}: ReduxAction<FetchFiles>) {
    yield put(filesRequest());

    const {data, error}: ApiResponse<FileInformation[]> = yield call(request, {
        url: '/files',
        method: 'get',
        params: {
            associatedObjectID: payload.associateObjectID,
            associatedTypeID: payload.associatedTypeID,
        },
    });

    if (error) {
        yield put(filesError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        const cachedFiles: FileInformation[] = yield call(IndexedDB.getMany, IndexedDBKeys.FILES);
        // Get ids of files that are not in cachedFiles
        const ids = data.map((file) => file.ID).filter((id) => !cachedFiles.map((file) => file.ID).includes(id));

        const responses: ApiResponse<string>[] = yield all(
            ids.map((ID) =>
                call(request, {
                    url: `/files/download/${ID}`,
                    method: 'get',
                    responseType: 'blob',
                }),
            ),
        );

        const newFiles: FileInformation[] = [];

        // Merge File object with Blob
        responses.forEach((response) => {
            const ID = +_last(response.request?.responseURL.split('/'))!;
            const file = data.find((file) => file.ID === ID);

            if (file && response.data) {
                newFiles.push({
                    ...file,
                    src: response.data,
                });
            }
        });

        yield put(filesResponse([...cachedFiles, ...newFiles].filter((file) => data.map((d) => d.ID).includes(file.ID))));

        yield call(IndexedDB.createMany, IndexedDBKeys.FILES, [...cachedFiles, ...newFiles]);
    }
}

function* createFile({payload}: ReduxAction<CreateFile>) {
    yield put(filesRequest());

    const {data, error}: ApiResponse<FileInformation> = yield call(request, {
        data: payload.file,
        url: '/files',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            type: 'formData',
        },
        params: {
            type: payload.type,
            associatedObjectID: payload.associateObjectID,
            associatedTypeID: payload.associatedTypeID,
        },
    });

    if (error) {
        yield all([put(filesError(error.message)), showErrorMessages(error.messages)]);
    }

    if (data) {
        yield put(
            createFileResponse({
                ...data,
                URL: String(payload.src),
            }),
        );

        if (
            payload.workOrderID &&
            (payload.associatedTypeID === AssociatedTypes.WorkOrder || payload.associatedTypeID === AssociatedTypes.WorkOrderRepairLine)
        ) {
            const {workOrders}: WorkOrderState = yield select(selectWorkOrderState);

            const workOrder = workOrders.find(({ID}) => ID === payload.workOrderID);

            if (workOrder) {
                yield updateWorkOrderInStoreAndIndexedDB({...workOrder, FileList: [...workOrder.FileList, data]});
                yield put(fetchWorkOrder({workOrderID: payload.workOrderID}));
            }
        }
    }
}

function* deleteFile({payload}: ReduxAction) {
    yield put(filesRequest());

    const {data, error}: ApiResponse<FileInformation> = yield call(request, {
        url: `/files/${payload.fileID}`,
        method: 'delete',
    });

    if (error) {
        yield all([put(filesError(error.message)), showErrorMessages(error.messages)]);
    } else if (data) {
        yield all([put(deleteFileResponse(data)), call(IndexedDB.deleteOne, IndexedDBKeys.FILES, data.ID)]);

        if (payload.associatedTypeID === AssociatedTypes.WorkOrder || payload.associatedTypeID === AssociatedTypes.WorkOrderRepairLine) {
            const {workOrders}: WorkOrderState = yield select(selectWorkOrderState);

            const workOrder = workOrders.find(({ID}) => ID === payload.workOrderID);

            if (workOrder) {
                yield updateWorkOrderInStoreAndIndexedDB({...workOrder, FileList: workOrder.FileList.filter((file) => file.ID !== data.ID)});
                yield put(fetchWorkOrder({workOrderID: payload.workOrderID}));
            }
        }
    }
}

export default function* fileRootSaga() {
    yield takeLatest<ReduxAction>(FETCH_FILES, fetchFiles);
    yield takeLatest<ReduxAction>(DELETE_FILE, deleteFile);
    yield takeLatest<ReduxAction>(CREATE_FILE, createFile);
}
