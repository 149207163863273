import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {Badge, Button, IconButton} from '@mui/material';
import {Form} from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import React, {useContext, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {FMCSADataContext} from '../../providers/fmcsa-data-provider';
import {validate} from '../../utils/fmcsaCheckValidation';

import {FMCSAFormData, getFMCSASchema, uiSchema} from './fmcsa-check.schema';
import {ButtonWrap, Container, IconButtonWrapper, Loader, PaperWrap} from './fmcsa-check.styles';

export type FMCSACheckProps = {
    fmcsaLoading: boolean;
    filesLoading: boolean;
    filesCount: number;
    onSubmit: (data: FMCSAFormData) => void;
};

const FMCSACheck = ({fmcsaLoading, filesLoading, filesCount, onSubmit}: FMCSACheckProps) => {
    const {t} = useTranslation();
    const {formData, setFormData} = useContext(FMCSADataContext);

    const schema = useMemo(getFMCSASchema, [t]);

    return (
        <Container>
            <PaperWrap>
                <IconButtonWrapper>
                    <IconButton size="large" title={t('files')} to="fmcsa/files" component={Link} disabled={fmcsaLoading || filesLoading}>
                        <Badge overlap="rectangular" badgeContent={filesCount} showZero color="primary">
                            <InsertDriveFileIcon />
                        </Badge>
                    </IconButton>
                </IconButtonWrapper>
                <Form
                    schema={schema}
                    uiSchema={uiSchema}
                    formData={formData}
                    onSubmit={({formData}) => onSubmit(formData)}
                    onChange={({formData}) => setFormData(formData)}
                    showErrorList={false}
                    validator={validator}
                    customValidate={validate}>
                    <ButtonWrap>
                        <Button size="large" variant="contained" color="primary" type="submit" disabled={fmcsaLoading} fullWidth>
                            {fmcsaLoading ? <Loader size={26} /> : t('submit')}
                        </Button>
                    </ButtonWrap>
                </Form>
            </PaperWrap>
        </Container>
    );
};

export default FMCSACheck;
