import styled from 'styled-components';

import barcodeIcon from '../../images/barcode.svg';

export const Title = styled.h3`
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.primary.main};
    width: 90%;
    margin: 15px auto;
    padding-bottom: 10px;
`;

export const GridWrapper = styled.div`
    .e-gridcontent {
        .e-content {
            .e-table {
                background-color: #fcfafa;
            }
        }
    }

    .e-custom-icons.e-barcode::before {
        font-size: 0.875rem;
        content: url('${barcodeIcon}');
    }
`;
