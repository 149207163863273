import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;

    button {
        background-color: #19857b;
        margin: 10px 20px;
    }
`;

export const AccordionContainer = styled.div`
    display: flex;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
    justify-content: center;
`;

export const AccordionComponent = styled(Accordion)`
    width: 80vw;
    max-width: 800px;
`;

export const AccordionSummaryComponent = styled(AccordionSummary)`
    .MuiAccordionSummary-content {
        display: flex;
        align-items: center;
    }
`;

export const AccordionDetailsComponent = styled(AccordionDetails)`
    overflow: scroll;
`;
