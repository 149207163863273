import {Comment} from '../models/Comment';
import {OfflineEstimateComment} from '../models/OfflineEstimateComment';
import {CommentDto} from '../models/dtos/comment-dto';

import {formatDate} from './formatDate';
import {getUserNameOrDefault} from './getUserNameOrDefault';

type CommentType = Comment | OfflineEstimateComment;

const getFormattedCommentTimeStamp = ({TimeStamp, ModifiedDate}: CommentType) =>
    TimeStamp
        ? formatDate({date: TimeStamp, includeTime: true}) +
          (ModifiedDate ? ` (Last Edited ${formatDate({date: ModifiedDate, includeTime: true})})` : '')
        : '';

export const convertCommentToCommentDto = <T extends Comment | OfflineEstimateComment>(comment: T): CommentDto<T> => ({
    ...comment,
    TimeStampDate: new Date(comment.TimeStamp),
    UserName: getUserNameOrDefault(comment.User),
    FormattedTimeStamp: getFormattedCommentTimeStamp(comment),
});

export const convertCommentsToCommentDtos = (comments: CommentType[]) => comments.map(convertCommentToCommentDto);
