import {t} from 'i18next';

import {OfflineEstimateSentStatus} from '../models/enumerations/OfflineEstimateSentStatus';
import {WorkOrderStatus} from '../models/enumerations/WorkOrderStatus';

export const getUserFriendlySentStatus = (
    sentStatus: number,
    workOrderStatus: number | undefined,
    uploadStartDate: Date | undefined,
    isPolling: boolean,
    isUploading: boolean,
) => {
    let sentStatusDescription = t(Object.values(OfflineEstimateSentStatus).find((status) => status.ID == sentStatus)?.Description ?? t('unknown'));
    if (workOrderStatus) {
        if (workOrderStatus === WorkOrderStatus.Open.Status) {
            sentStatusDescription = t('open_with_errors');
        } else if (
            [
                WorkOrderStatus.SubmittedForApproval.Status,
                WorkOrderStatus.PendingEscalation.Status,
                WorkOrderStatus.WorkApproved.Status,
                WorkOrderStatus.RepairsDone.Status,
                WorkOrderStatus.PartiallyApproved.Status,
                WorkOrderStatus.Approved.Status,
            ].includes(workOrderStatus)
        ) {
            sentStatusDescription = t('estimate_accepted');
        }
    } else if (uploadStartDate && sentStatus === OfflineEstimateSentStatus.Unsent.ID) {
        sentStatusDescription = t('upload_failed');
    } else if (!isUploading && sentStatus === OfflineEstimateSentStatus.Uploading.ID) {
        sentStatusDescription = t('upload_stalled');
    } else if (!isPolling && sentStatus === OfflineEstimateSentStatus.Processing.ID) {
        sentStatusDescription = t('polling_stalled');
    }

    return sentStatusDescription;
};
