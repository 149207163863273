import {
    ColumnDirective,
    ColumnsDirective,
    DetailRow,
    Edit,
    GridComponent,
    GridModel,
    InfiniteScroll,
    Inject,
    Toolbar,
    Filter,
    Sort,
    ToolbarItems,
    DialogEditEventArgs,
    QueryCellInfoEventArgs,
    Grid,
} from '@syncfusion/ej2-react-grids';
import {debounce} from 'lodash';
import React, {useContext, useMemo, useRef} from 'react';
import * as ReactDOM from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';

import {Comment} from '../../models/Comment';
import {WorkOrderRepairLine} from '../../models/WorkOrderRepairLine';
import {CommentDto} from '../../models/dtos/comment-dto';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {WorkOrderRepairLineStatus} from '../../models/enumerations/WorkOrderRepairLineStatus';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {createRepairLineComment, deleteRepairLineComment, updateRepairLineComment} from '../../redux/comment/comment.actions';
import {selectRepairLineComments} from '../../redux/comment/comment.selectors';
import {convertCommentsToCommentDtos} from '../../utils/convertCommentToCommentDto';

import {Title} from './repair-line-comments-list.style';

interface RepairLineCommentsListProps {
    repairLines?: WorkOrderRepairLine[];
    title: string;
    scrollToRepairLineID: number | null;
}

type ChangesRepairLineComments = {
    type: string;
    action: string;
    requestType: string;
    data: Comment;
    row: HTMLElement;
};

const toolbarOptions: ToolbarItems[] = ['Add', 'Edit', 'Delete'];

const RepairLineCommentsList = ({repairLines = [], title, scrollToRepairLineID: repairLineID}: RepairLineCommentsListProps) => {
    const dispatch = useDispatch();
    const {workOrder, readOnly} = useContext(WorkOrderDataContext);

    const gridRef = useRef<GridComponent>(null);
    const rowRef = useRef<null | HTMLElement>();

    const scrollToRow = debounce(() => {
        rowRef.current?.scrollIntoView();
    }, 500);

    const repairLineComments = useSelector(selectRepairLineComments);
    const comments: Comment[] = Object.values(repairLineComments).flat();

    const dataBound = function (this: GridComponent) {
        // This try/catch need to avoid Syncfusion error
        try {
            this.detailRowModule.expandAll();

            if (rowRef.current) {
                let index = rowRef.current.getAttribute('aria-rowindex');
                if (index) {
                    gridRef.current?.selectRow(parseInt(index, 10));
                }
            }
        } catch {}
    };

    const rowDataBound = function (args: ChangesRepairLineComments) {
        if (args.data.ID == repairLineID) {
            rowRef.current = args.row;
        }
    };

    const actionComplete = function (args: ChangesRepairLineComments) {
        if (args.requestType === 'refresh' && rowRef.current) {
            scrollToRow();
        }
    };

    const queryCellInfo = (args: QueryCellInfoEventArgs) => {
        const {UnitLocationCodeDescription} = args.data as WorkOrderRepairLine;

        if (args.column?.field === 'UnitLocationCodeDescription') {
            ReactDOM.render(<span>{UnitLocationCodeDescription ? UnitLocationCodeDescription : 'Unknown'}</span>, args.cell as Element);
        }
    };

    const childQueryCellInfo = (args: QueryCellInfoEventArgs) => {
        const {FormattedTimeStamp} = args.data as CommentDto;

        if (args.column?.field === 'TimeStampDate') {
            ReactDOM.render(<span>{FormattedTimeStamp}</span>, args.cell as Element);
        }
    };

    const childGridOptions = useMemo<GridModel>(
        () => ({
            dataSource: convertCommentsToCommentDtos(comments),
            enableAdaptiveUI: true,
            rowRenderingMode: 'Vertical',
            queryString: 'AssociatedID',
            allowSorting: true,
            allowFiltering: true,
            filterSettings: {
                type: 'Menu',
            },
            queryCellInfo: childQueryCellInfo,
            load: function (this: Grid) {
                const parentRowData = this.parentDetails.parentRowData as {ID: string};
                this.parentDetails.parentKeyFieldValue = parentRowData.ID;
            },
            created: function (this: Grid) {
                const repairLineID = Number(this.parentDetails.parentKeyFieldValue);
                const repairLine = repairLines.find((item) => item.ID === repairLineID);

                const isStatusRejected = repairLine?.Status === WorkOrderRepairLineStatus.Rejected;

                if (isStatusRejected) {
                    const gridId = this.toolbarModule['gridID'];

                    setTimeout(() => {
                        this.toolbarModule.enableItems([`${gridId}_add`, `${gridId}_edit`, `${gridId}_delete`], false);
                    }, 0);
                }
            },
            actionComplete: (args: DialogEditEventArgs) => {
                if (args.requestType === 'add' || args.requestType === 'beginEdit') {
                    if (args?.dialog?.position?.Y) {
                        args.dialog.position.Y = 'top';
                    }
                }
            },
            actionBegin: function (this: Grid, state: ChangesRepairLineComments) {
                if (state.requestType === 'delete') {
                    const repairLineID = Number(this.parentDetails.parentKeyFieldValue);

                    return Array.isArray(state.data)
                        ? state.data.forEach((item: Comment) => dispatch(deleteRepairLineComment(item.ID, repairLineID, workOrder.ID)))
                        : dispatch(deleteRepairLineComment(state.data.ID, repairLineID, workOrder.ID));
                }

                if (
                    state.action === 'add' &&
                    state.requestType === 'save' &&
                    this.parentDetails &&
                    !Number.isNaN(Number(this.parentDetails.parentKeyFieldValue))
                ) {
                    return dispatch(
                        createRepairLineComment({
                            workOrderID: workOrder.ID,
                            associateObjectID: Number(this.parentDetails.parentKeyFieldValue),
                            associatedTypeID: AssociatedTypes.WorkOrderRepairLine,
                            comment: state.data,
                        }),
                    );
                }

                if (
                    state.action === 'edit' &&
                    state.requestType === 'save' &&
                    this.parentDetails &&
                    !Number.isNaN(Number(this.parentDetails.parentKeyFieldValue))
                ) {
                    return dispatch(
                        updateRepairLineComment({
                            associateObjectID: Number(this.parentDetails?.parentKeyFieldValue),
                            commentID: state.data.ID,
                            comment: state.data,
                        }),
                    );
                }
            },
            editSettings: {
                allowEditing: true,
                allowAdding: true,
                allowDeleting: true,
                mode: 'Dialog',
            },
            allowTextWrap: true,
            toolbar: !readOnly ? toolbarOptions : [],
            columns: [
                {
                    field: 'UserName',
                    headerText: 'User',
                    width: 'auto',
                    allowEditing: false,
                },
                {
                    type: 'date',
                    field: 'TimeStampDate',
                    headerText: 'Date',
                    allowEditing: false,
                },
                {field: 'Message', headerText: 'Message', validationRules: {required: true}},
            ],
        }),
        [comments, readOnly],
    );

    return (
        <div className="e-bigger repairline-comments">
            <Title>{title}</Title>
            <GridComponent
                dataBound={dataBound}
                rowDataBound={rowDataBound}
                childGrid={childGridOptions}
                allowPaging
                ref={gridRef}
                queryCellInfo={queryCellInfo}
                actionComplete={actionComplete}
                enableAdaptiveUI
                dataSource={repairLines}
                enableInfiniteScrolling
                rowRenderingMode="Vertical">
                <ColumnsDirective>
                    <ColumnDirective field="JobCodeNumber" headerText="Code" />
                    <ColumnDirective field="JobCodeDescription" headerText="Desc" />
                    <ColumnDirective field="ConditionCodeDescription" headerText="Repair" />
                    <ColumnDirective field="UnitLocationCodeDescription" headerText="Loc" />
                    <ColumnDirective field="RepairQuantity" headerText="Qty" />
                </ColumnsDirective>
                <Inject services={[Edit, Filter, Sort, Toolbar, DetailRow, InfiniteScroll]} />
            </GridComponent>
        </div>
    );
};

export default RepairLineCommentsList;
