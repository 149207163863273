import Quagga, {QuaggaJSCodeReader, QuaggaJSConfigObject, QuaggaJSResultCallbackFunction, QuaggaJSResultObject_CodeResult} from '@ericblade/quagga2';
import {RefObject, useCallback, useEffect} from 'react';

const getMedian = (arr: number[]) => {
    arr.sort((a, b) => a - b);
    const half = Math.floor(arr.length / 2);
    if (arr.length % 2 === 1) {
        return arr[half];
    }
    return (arr[half - 1] + arr[half]) / 2;
};

const getMedianOfCodeErrors = (decodedCodes: QuaggaJSResultObject_CodeResult['decodedCodes']) => {
    const errors = decodedCodes.filter((x) => x.error !== undefined).map((x) => x.error);

    return getMedian(errors as number[]);
};

const defaultConstraints: MediaTrackConstraints = {
    width: window.innerWidth,
    height: window.innerHeight,
};

const defaultLocatorSettings: QuaggaJSConfigObject['locator'] = {
    patchSize: 'medium',
    halfSample: true,
};

const defaultDecoders: QuaggaJSCodeReader[] = [
    'code_128_reader',
    'codabar_reader',
    'code_93_reader',
    'code_39_reader',
    'code_39_vin_reader',
    'ean_reader',
    'ean_5_reader',
    'ean_2_reader',
    'ean_8_reader',
];

export type BarcodeScannerProps = {
    onDetected: (unpause: () => void, code: string) => void;
    scannerRef: RefObject<HTMLDivElement>;
    onScannerReady?: () => void;
    cameraId?: string;
    facingMode?: string;
    constraints?: MediaTrackConstraints;
    locator?: QuaggaJSConfigObject['locator'];
    numOfWorkers?: number;
    decoders?: QuaggaJSCodeReader[];
    locate?: boolean;
};

export const BarcodeScanner = ({
    onDetected,
    scannerRef,
    onScannerReady,
    cameraId,
    facingMode,
    constraints = defaultConstraints,
    locator = defaultLocatorSettings,
    numOfWorkers = navigator.hardwareConcurrency || 0,
    decoders = defaultDecoders,
    locate = true,
}: BarcodeScannerProps) => {
    const errorCheck: QuaggaJSResultCallbackFunction = useCallback(
        (result) => {
            if (!onDetected) {
                return;
            }
            const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
            // if Quagga is at least 90% certain that it read correctly, then accept the code.
            if (err < 0.1 && result.codeResult.code) {
                Quagga.pause();
                onDetected(Quagga.start, result.codeResult.code);
            }
        },
        [onDetected],
    );

    useEffect(() => {
        if (scannerRef.current) {
            Quagga.init(
                {
                    inputStream: {
                        type: 'LiveStream',
                        constraints: {
                            ...constraints,
                            ...(cameraId && {deviceId: cameraId}),
                            ...(!cameraId && {facingMode}),
                        },
                        target: scannerRef.current,
                    },
                    locator,
                    numOfWorkers,
                    decoder: {readers: decoders},
                    locate,
                },
                (err) => {
                    if (err) {
                        return console.log('Error starting Quagga:', err);
                    }
                    if (scannerRef && scannerRef.current) {
                        Quagga.start();
                        if (onScannerReady) {
                            onScannerReady();
                        }
                    }
                },
            );
            Quagga.onDetected(errorCheck);

            return () => {
                Quagga.offDetected(errorCheck);
                Quagga.stop();
            };
        }
    }, [cameraId, onDetected, onScannerReady, scannerRef, errorCheck, constraints, locator, decoders, locate, facingMode, numOfWorkers]);

    return null;
};
