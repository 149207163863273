import React, {useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {useHeader} from '../../hooks/use-header';
import {Part} from '../../models/Part';
import {EquipmentGroups} from '../../models/enumerations/EquipmentGroups';
import {OfflineEstimateDataContext} from '../../providers/offline-estimate-data-provider';
import {enqueueSnackbar} from '../../redux/notistack/notistack.actions';
import {createOfflineEstimatePart, updateOfflineEstimatePart} from '../../redux/offline-estimate/offline-estimate.actions';
import {selectPartState} from '../../redux/part/part.selectors';

import {PartForm, PartFormData} from './part-form.component';
import {HeaderTitle} from './part-form.styles';

export type OfflinePartFormContainerProps = {
    offlineEstimateLineID: number;
    partNumber: string | undefined;
    onClose: () => void;
};

const OfflinePartFormContainer = ({offlineEstimateLineID, partNumber, onClose}: OfflinePartFormContainerProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {offlineEstimate} = useContext(OfflineEstimateDataContext);
    const {parts} = useSelector(selectPartState);

    const repairLine = offlineEstimate.RepairLines.find((item) => item.ID === offlineEstimateLineID);
    const part = repairLine?.Parts.find(({PartNumber}) => PartNumber === partNumber);
    const partsOptions: Part[] = parts.filter(
        ({AllowablePartCombinations, EquipmentGroupIDPriced}) =>
            AllowablePartCombinations.some(
                ({JobCodeID, ConditionCodeID}) => JobCodeID === repairLine?.JobCodeId && ConditionCodeID === repairLine?.ConditionCodeId,
            ) &&
            Object.entries(EquipmentGroupIDPriced).filter(
                (p) => p[0] === (offlineEstimate?.EquipmentGroupID?.toString() ?? EquipmentGroups.Unknown.ID.toString()) && p[1],
            ),
    );
    const initialValues: PartFormData | undefined =
        part || partsOptions?.length === 1
            ? {
                  repairLinePartID: part?.OfflineEstimateLinePartID,
                  partID: part?.ID ?? partsOptions[0].ID,
                  partNumber: part?.PartNumber ?? partsOptions[0].PartNumber,
                  manufacturer: part?.Manufacturer ?? partsOptions[0].Manufacturer,
                  partDescription: part?.PartDescription ?? partsOptions[0].PartDescription,
                  quantity: part?.Quantity ?? 1,
              }
            : undefined;

    const Header = useCallback(() => <HeaderTitle>{t(part?.PartNumber ? 'update_part' : 'create_part')}</HeaderTitle>, [part?.PartNumber, t]);

    useHeader({
        backToPath: onClose,
        middleElement: Header,
        setStateParam: true,
    });

    const onSubmit = useCallback(
        (data: PartFormData) => {
            if (!data.partID || data.partID < 0) {
                dispatch(
                    enqueueSnackbar(t('part_required'), {
                        autoHideDuration: 3000,
                        variant: 'error',
                    }),
                );
                return;
            } else if (
                offlineEstimate.RepairLines.find((rl) => rl.ID === offlineEstimateLineID)?.Parts.some(
                    (p) => p.OfflineEstimateLinePartID !== data.repairLinePartID && p.ID === data.partID,
                )
            ) {
                dispatch(
                    enqueueSnackbar(t('duplicate_part'), {
                        autoHideDuration: 3000,
                        variant: 'error',
                    }),
                );
                return;
            }

            dispatch(
                data.repairLinePartID
                    ? updateOfflineEstimatePart({
                          offlineEstimateID: offlineEstimate.ID,
                          offlineEstimateLineID,
                          offlineEstimateLinePartID: data.repairLinePartID,
                          part: {
                              ID: data.partID,
                              PartNumber: data.partNumber,
                              Manufacturer: data.manufacturer,
                              PartDescription: data.partDescription,
                              Quantity: data.quantity,
                          },
                      })
                    : createOfflineEstimatePart({
                          offlineEstimateID: offlineEstimate.ID,
                          offlineEstimateLineID,
                          part: {
                              ID: data.partID,
                              PartNumber: data.partNumber,
                              Quantity: data.quantity,
                              Manufacturer: data.manufacturer,
                              PartDescription: data.partDescription,
                          },
                      }),
            );

            onClose();
        },
        [dispatch, onClose, offlineEstimateLineID],
    );

    return <PartForm key={`${offlineEstimateLineID}-${part?.ID}`} onSubmit={onSubmit} partsOptions={partsOptions} initialValues={initialValues} />;
};

export default OfflinePartFormContainer;
