import {CircularProgress} from '@mui/material';
import styled from 'styled-components';

export const PaperWrap = styled.div`
    height: auto;
    width: 100%;
    padding: 24px;

    h2 {
        text-align: center;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    .e-valid-input {
        font-size: 16px;
    }

    span.Mui-checked #root_priority + svg {
        color: #19857b;
    }

    button {
        margin-top: 15px;
    }
`;

export const HeaderTitle = styled.h2`
    color: ${({theme}) => theme.palette.primary.main};
`;

export const Loader = styled(CircularProgress)`
    color: #ffffff;
`;
