import {differenceInMilliseconds} from 'date-fns';
import {unionBy} from 'lodash';
import {takeLeading, call, put, select} from 'redux-saga/effects';

import config from '../../config';
import {ConditionCode} from '../../models/ConditionCode';
import {ReduxAction} from '../../models/ReduxAction';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {formatDate} from '../../utils/formatDate';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {selectIsOfflineStatus} from '../application/application.selectors';

import {conditionCodeError, conditionCodeRequest, conditionCodesResponse} from './condition-code.actions';
import {FETCH_CONDITION_CODES} from './condition-code.types';

const {resourceCacheFallback} = config;

export function* fetchConditionCodes() {
    const isOfflineMode: boolean = yield select(selectIsOfflineStatus);

    let cachedConditionCodes: ConditionCode[] = [];

    if (isOfflineMode) {
        cachedConditionCodes = yield call(IndexedDB.getMany, IndexedDBKeys.CONDITION_CODES);

        yield put(conditionCodesResponse(cachedConditionCodes));

        return;
    }

    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_CONDITION_CODES_REFRESH_TIME, LocalStorageKeys.LAST_CONDITION_CODES_UPDATE_TIME);

    if (lastUpdateTime) {
        cachedConditionCodes = yield call(IndexedDB.getMany, IndexedDBKeys.CONDITION_CODES);

        if (differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
            yield put(conditionCodesResponse(cachedConditionCodes));

            return;
        }
    }
    yield put(conditionCodeRequest());

    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_CONDITION_CODES_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<ConditionCode[]> = yield call(request, {
        url: '/conditioncodes',
        method: 'get',
        params: lastUpdateTime
            ? {
                  includeDeleted: true,
                  lastUpdateTime: formatDate({
                      date: lastUpdateTime,
                      ISOFormat: true,
                  }),
              }
            : undefined,
    });

    if (error) {
        yield put(conditionCodeError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedConditionCodes) {
            yield put(conditionCodesResponse(cachedConditionCodes));
        }
    }

    if (data) {
        const combinedConditionCodes = unionBy(data, cachedConditionCodes, 'ID').filter((conditionCode) => !conditionCode.IsDeleted);

        yield put(conditionCodesResponse(combinedConditionCodes));

        yield call(IndexedDB.createMany, IndexedDBKeys.CONDITION_CODES, combinedConditionCodes);
        LocalStorage.set(LocalStorageKeys.LAST_CONDITION_CODES_UPDATE_TIME, requestTime);
    }
}

export default function* conditionCodesRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_CONDITION_CODES, fetchConditionCodes);
}
