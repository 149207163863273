import {useState, useEffect} from 'react';

export type ViewportSize = {
    width?: number;
    height?: number;
};

export const useViewportSize = () => {
    const [size, setSize] = useState<ViewportSize>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const handleResize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return size;
};
