import {FormValidation} from '@rjsf/utils';
import {t} from 'i18next';

import {WorkOrderRepairLine} from '../models/WorkOrderRepairLine';
import {WorkOrderRepairLineStatus} from '../models/enumerations/WorkOrderRepairLineStatus';

export const validate = <T extends Partial<WorkOrderRepairLine>>(formData: T, errors: FormValidation, rls: any[]) => {
    // Offline repair lines don't have an ID or IsDeleted properties. Check IsDeleted when possible and use the URL to get the ID.
    const rlID = formData.hasOwnProperty('ID')
        ? formData.ID
        : +window.location.pathname.substring(window.location.pathname.indexOf('line/') + 5, window.location.pathname.length).split('/')[0];

    if (formData.hasOwnProperty('JobCodeId') && (!formData.JobCodeId || formData.JobCodeId < 0)) {
        errors.JobCodeId?.addError('is a required property');
    } else if (
        rls
            .filter(
                (rl) =>
                    rlID !== rl.ID &&
                    rl.Status !== WorkOrderRepairLineStatus.Rejected &&
                    (!rl.hasOwnProperty('IsDeleted') || !(rl as WorkOrderRepairLine).IsDeleted),
            )
            .some(({JobCodeId, UnitLocationCodeId}) => formData.JobCodeId === JobCodeId && formData.UnitLocationCodeId === UnitLocationCodeId)
    ) {
        errors.JobCodeId?.addError(t('concurrent_record_error'));
    }

    return errors;
};
