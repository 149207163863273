import styled from 'styled-components';

export const DatesContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
`;

export const MessageItemContainer = styled.div`
    border-bottom: 1px solid #d3d3d3;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
`;

export const MessageText = styled.span`
    font-size: 20px;
    font-weight: 500;
    margin-left: 5px;
`;

export const DateText = styled.span`
    font-size: 12px;
    font-weight: 500;
`;
