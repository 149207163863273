import {Button} from '@mui/material';
import {useSnackbar, SnackbarKey} from 'notistack';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {removeSnackbar} from '../redux/notistack/notistack.actions';
import {selectNotifications} from '../redux/notistack/notistack.selectors';

let displayed: SnackbarKey[] = [];

const useNotistack = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(selectNotifications);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const storeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed.filter((key) => id !== key)];
    };

    React.useEffect(() => {
        notifications.forEach(({key, message, options = {}, dismissed = false}) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) {
                return;
            }

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                ...options,
                action: (key) => (
                    <Button color="inherit" onClick={() => closeSnackbar(key)}>
                        dismiss
                    </Button>
                ),
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeSnackbar(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useNotistack;
