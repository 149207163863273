import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: white;
    z-index: 300;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 90px 24px;
`;
