import React, {useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Redirect, useLocation} from 'react-router-dom';

import {Fallback} from '../../components/fallback/fallback.component';
import {AuthContext, IAuthResult} from '../../providers/auth-provider';
import {unsetCompany} from '../../redux/company/company.actions';
import {unsetShop} from '../../redux/shop/shop.actions';
import {updateUser} from '../../redux/user/user.actions';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import {ErrorLike} from '../../utils/errorLike';

export type SelectSelectedUserData = {
    username: string | null;
    pgt: string;
};

/**
 * Authentication Sign-In completion callback page. Authentication sign-in is launched from a call to
 * startAuthentication in {@link PrivateRoutes}. We know the user is not authenticated until
 * completeAuthentication is finished. Then it redirects to the page where sign-in was launched.
 *
 * @returns {*}
 */
const AuthSignInCallback = () => {
    const {completeAuthentication, removeUser} = useContext(AuthContext);
    const [authResult, setAuthResult] = useState({} as IAuthResult);
    const location = useLocation();
    const dispatch = useDispatch();
    LocalStorage.remove(LocalStorageKeys.SELECTED_SHOP_ID);
    dispatch(unsetShop());
    dispatch(unsetCompany());

    console.debug('>>> authsignincallback rendering');

    /** Function to store the currently logged-in user when authentication is complete. */
    const onAuthenticationDone = ({username, pgt}: SelectSelectedUserData) => {
        if (username) {
            dispatch(updateUser(username, pgt));
        }
    };

    useEffect(() => {
        console.debug('authsignincallback completing authentication');
        // On success, completeAuthentication will return to the address that triggered authentication. Remove session
        // information if processing fails. Also clear the currently logged-in user.
        // WARNING! the promise rejection must be caught in a catch clause or block;
        // otherwise, the error will abort the application.
        completeAuthentication().catch((e: unknown) => {
            const result: IAuthResult = {authError: ErrorLike.Copy(e)};
            // Remove session information and trigger user notification if processing is incomplete.
            removeUser();
            onAuthenticationDone({username: null, pgt: ''});
            setAuthResult(result);
        });
    }, [completeAuthentication, removeUser]);

    // If authentication failed, notify the user.
    if (authResult.authError) {
        // Redirect with an authentication error and save the current location in the browser history.
        // The authentication result is passed in the to.state property but ends up in props.location.state.
        console.debug('authsignincallback redirect to sessionerror');
        return <Redirect to={{pathname: '/session-error', state: {authResult: authResult, from: location}}} push={true} />;
    }

    return <Fallback />;
};

export default AuthSignInCallback;
