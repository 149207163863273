import {Part} from '../../models/Part';
import {ReduxAction} from '../../models/ReduxAction';

import {
    PARTS_ERROR,
    PARTS_REQUEST,
    PARTS_RESPONSE,
    CREATE_REPAIR_LINE_PART_RESPONSE,
    UPDATE_REPAIR_LINE_PART_RESPONSE,
    DELETE_REPAIR_LINE_PART_RESPONSE,
} from './part.types';

export interface PartState {
    error: Error | null;
    loading: boolean;
    parts: Part[];
}

const initialState: PartState = {
    error: null,
    loading: false,
    parts: [],
};

export default function partReducer(state = initialState, action: ReduxAction): PartState {
    switch (action.type) {
        case PARTS_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case PARTS_RESPONSE:
            return {
                ...state,
                loading: false,
                parts: action.payload.parts,
            };
        case PARTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case CREATE_REPAIR_LINE_PART_RESPONSE:
        case UPDATE_REPAIR_LINE_PART_RESPONSE:
        case DELETE_REPAIR_LINE_PART_RESPONSE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}
