import {differenceInMilliseconds} from 'date-fns';
import {unionBy} from 'lodash';
import {takeLeading, call, put, select} from 'redux-saga/effects';

import config from '../../config';
import {ReduxAction} from '../../models/ReduxAction';
import {UnitLocationCode} from '../../models/UnitLocationCode';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {formatDate} from '../../utils/formatDate';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {selectIsOfflineStatus} from '../application/application.selectors';

import {unitLocationCodeError, unitLocationCodeRequest, unitLocationCodesResponse} from './user-location-code.actions';
import {FETCH_UNIT_LOCATION_CODES} from './user-location-code.types';

const {resourceCacheFallback} = config;

export function* fetchUnitLocationCodes() {
    const isOfflineMode: boolean = yield select(selectIsOfflineStatus);

    let cachedUnitLocationCodes: UnitLocationCode[] = [];

    if (isOfflineMode) {
        cachedUnitLocationCodes = yield call(IndexedDB.getMany, IndexedDBKeys.UNIT_LOCATION_CODES);

        yield put(unitLocationCodesResponse(cachedUnitLocationCodes));

        return;
    }

    const lastUpdateTime = getLastUpdateTime(
        LocalStorageKeys.LAST_UNIT_LOCATION_CODES_REFRESH_TIME,
        LocalStorageKeys.LAST_UNIT_LOCATION_CODES_UPDATE_TIME,
    );

    if (lastUpdateTime) {
        cachedUnitLocationCodes = yield call(IndexedDB.getMany, IndexedDBKeys.UNIT_LOCATION_CODES);

        if (differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
            yield put(unitLocationCodesResponse(cachedUnitLocationCodes));

            return;
        }
    }

    yield put(unitLocationCodeRequest());

    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_UNIT_LOCATION_CODES_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<UnitLocationCode[]> = yield call(request, {
        url: '/unitlocationcodes',
        method: 'get',
        params: lastUpdateTime
            ? {
                  includeDeleted: true,
                  lastUpdateTime: formatDate({
                      date: lastUpdateTime,
                      ISOFormat: true,
                  }),
              }
            : undefined,
    });

    if (error) {
        yield put(unitLocationCodeError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedUnitLocationCodes) {
            yield put(unitLocationCodesResponse(cachedUnitLocationCodes));
        }
    }

    if (data) {
        const combinedUnitLocationCodes = unionBy(data, cachedUnitLocationCodes, 'ID').filter((unitLocationCode) => !unitLocationCode.IsDeleted);

        yield put(unitLocationCodesResponse(combinedUnitLocationCodes));

        yield call(IndexedDB.createMany, IndexedDBKeys.UNIT_LOCATION_CODES, combinedUnitLocationCodes);
        LocalStorage.set(LocalStorageKeys.LAST_UNIT_LOCATION_CODES_UPDATE_TIME, requestTime);
    }
}

export default function* unitLocationCodesRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_UNIT_LOCATION_CODES, fetchUnitLocationCodes);
}
