import {push} from 'connected-react-router';
import {differenceInMilliseconds} from 'date-fns';
import {t} from 'i18next';
import {all, call, delay, fork, put, select, takeLatest} from 'redux-saga/effects';

import config from '../../config';
import {CompanySummary} from '../../models/CompanySummary';
import {OfflineEstimate} from '../../models/OfflineEstimate';
import {OfflineEstimateComment} from '../../models/OfflineEstimateComment';
import {OfflineEstimateFile} from '../../models/OfflineEstimateFile';
import {OfflineEstimateLine} from '../../models/OfflineEstimateLine';
import {OfflineEstimatePart} from '../../models/OfflineEstimatePart';
import {OfflineTaskResult} from '../../models/OfflineTaskResult';
import {ReduxAction} from '../../models/ReduxAction';
import {ShopPricing} from '../../models/ShopPricing';
import {StationCompanyRelation} from '../../models/StationCompanyRelation';
import {StationSummary} from '../../models/StationSummary';
import {UnitDetail} from '../../models/UnitDetail';
import {UnitGroup} from '../../models/UnitGroup';
import {WorkOrder} from '../../models/WorkOrder';
import {CreateOfflineEstimateDto} from '../../models/dtos/create-offline-estimate.dto';
import {FetchOfflineEstimateDto} from '../../models/dtos/fetch-offline-estimate.dto';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {EquipmentGroups} from '../../models/enumerations/EquipmentGroups';
import {OfflineEstimateSentStatus} from '../../models/enumerations/OfflineEstimateSentStatus';
import {WorkOrderRepairLineStatus} from '../../models/enumerations/WorkOrderRepairLineStatus';
import {WorkOrderStatus} from '../../models/enumerations/WorkOrderStatus';
import {ApiResponse} from '../../services/api-request';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import request from '../../services/request';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {splitUnitINO} from '../../utils/splitUnitINO';
import {selectIsOfflineStatus} from '../application/application.selectors';
import {enqueueSnackbar} from '../notistack/notistack.actions';
import {selectSelectedShop, selectShops} from '../shop/shop.selectors';
import {createWorkOrderResponse} from '../work-order/work-order.actions';

import {
    offlineEstimatesResponse,
    pollError,
    pollFinish,
    pollResponse,
    pollStart,
    putOfflineEstimate,
    removeOfflineEstimate,
    selectOfflineEstimate,
    uploadError,
    uploadFinish,
    uploadResponse,
    uploadStart,
} from './offline-estimate.actions';
import {selectOfflineEstimateState} from './offline-estimate.selectors';
import {
    FETCH_OFFLINE_ESTIMATES,
    CREATE_OFFLINE_ESTIMATE,
    CREATE_OFFLINE_ESTIMATE_COMMENT,
    CREATE_OFFLINE_ESTIMATE_FILE,
    CREATE_OFFLINE_ESTIMATE_LINE,
    CREATE_OFFLINE_ESTIMATE_PART,
    CreateOfflineEstimateCommentDto,
    CreateOfflineEstimateFileDto,
    CreateOfflineEstimateLineDto,
    CreateOfflineEstimatePartDto,
    DELETE_OFFLINE_ESTIMATE_COMMENT,
    DELETE_OFFLINE_ESTIMATE_FILE,
    DELETE_OFFLINE_ESTIMATE_LINE,
    DELETE_OFFLINE_ESTIMATE_PART,
    DeleteOfflineEstimateCommentDto,
    DeleteOfflineEstimateFileDto,
    DISCARD_OFFLINE_ESTIMATE,
    FETCH_OFFLINE_ESTIMATE,
    QUEUE_OFFLINE_ESTIMATES,
    SEND_ALL_UNSENT_OFFLINE_ESTIMATES,
    SEND_QUEUED_OFFLINE_ESTIMATES,
    UPDATE_OFFLINE_ESTIMATE,
    UPDATE_OFFLINE_ESTIMATE_COMMENT,
    UPDATE_OFFLINE_ESTIMATE_LINE,
    UPDATE_OFFLINE_ESTIMATE_PART,
    UpdateOfflineEstimateCommentDto,
    UpdateOfflineEstimateLineDto,
    UpdateOfflineEstimatePartDto,
    DEQUEUE_OFFLINE_ESTIMATE,
    POLL_OFFLINE_ESTIMATE_TASKS,
    COPY_OFFLINE_ESTIMATE,
    UPLOAD_OFFLINE_ESTIMATES,
    CLEAN_UP_OFFLINE_DATA,
} from './offline-estimate.types';

const {offlineCutOff} = config;

export function* fetchOfflineEstimates() {
    const offlineEstimates: OfflineEstimate[] = yield call(IndexedDB.getMany, IndexedDBKeys.OFFLINE_ESTIMATES);
    const shop: StationCompanyRelation = yield select(selectSelectedShop);

    yield put(
        offlineEstimatesResponse(offlineEstimates.filter(({StationID, CompanyID}) => shop.StationID === StationID && shop.CompanyID === CompanyID)),
    );
}

function* cleanUpOfflineData() {
    const shop: StationCompanyRelation = yield select(selectSelectedShop);
    const offlineEstimates: OfflineEstimate[] = yield call(IndexedDB.getMany, IndexedDBKeys.OFFLINE_ESTIMATES);
    const cleanedUpEstimates = offlineEstimates.filter(
        ({CreatedDate}) => differenceInMilliseconds(new Date(), new Date(CreatedDate)) <= offlineCutOff,
    );

    yield put(
        offlineEstimatesResponse(cleanedUpEstimates.filter(({StationID, CompanyID}) => shop.StationID === StationID && shop.CompanyID === CompanyID)),
    );
    yield call(IndexedDB.createMany, IndexedDBKeys.OFFLINE_ESTIMATES, cleanedUpEstimates);
}

function* createOfflineEstimate({payload}: ReduxAction<CreateOfflineEstimateDto>) {
    const unitGroups: UnitGroup[] = yield call(IndexedDB.getMany, IndexedDBKeys.UNIT_GROUPS);
    const splitUnit = splitUnitINO(payload.unitINO.toUpperCase());
    let equipmentGroupID: number | undefined = undefined;

    if (splitUnit?.length === 2) {
        const unitGroup = unitGroups.find((g) =>
            g.Details.filter((d) => d.Initial == splitUnit[0]).find((d) =>
                d.Ranges.some((r) => r.Lower <= +splitUnit[1] && +splitUnit[1] <= r.Upper),
            ),
        );

        if (unitGroup) {
            equipmentGroupID = unitGroup.EquipmentGroupID;
        }
    }

    const estimate: OfflineEstimate = {
        ID: Date.now(),
        UnitINO: payload.unitINO.toUpperCase(),
        EquipmentGroupID: equipmentGroupID,
        ElectiveInspectionType: payload.electiveInspectionType,
        CompanyID: payload.companyID,
        StationID: payload.stationID,
        Priority: payload.priority,
        CreatedDate: new Date().toISOString(),
        SentStatus: OfflineEstimateSentStatus.Unsent.ID,
        Status: undefined,
        Files: [],
        Comments: [],
        RepairLines: [],
        GeneratePO: false,
        Lot: '',
        Mate: '',
        ReferenceNumber: '',
        Row: '',
        Spot: '',
        BackgroundTaskID: undefined,
        WorkOrderID: undefined,
        Message: undefined,
        UploadStartDate: undefined,
    };

    yield call(IndexedDB.createOne, IndexedDBKeys.OFFLINE_ESTIMATES, estimate);

    yield call(fetchOfflineEstimates);

    if (payload.redirect) {
        yield put(push(`/offline-estimates/${estimate.ID}`));
    }
}

function* copyOfflineEstimate({payload}: ReduxAction<{offlineEstimateID: number}>) {
    const estimate: OfflineEstimate = yield call(IndexedDB.getOne, IndexedDBKeys.OFFLINE_ESTIMATES, payload.offlineEstimateID);

    // Call of the actions to make a copy.
    yield createOfflineEstimate({
        type: CREATE_OFFLINE_ESTIMATE,
        payload: {
            unitINO: estimate.UnitINO,
            electiveInspectionType: estimate.ElectiveInspectionType,
            stationID: estimate.StationID,
            companyID: estimate.CompanyID,
            priority: estimate.Priority,
            redirect: false,
        },
    });

    // Since the ID is milliseconds since epoch and it was just created, it should have the largest ID.
    const offlineEstimates: OfflineEstimate[] = yield call(IndexedDB.getMany, IndexedDBKeys.OFFLINE_ESTIMATES);
    const copyID = Math.max(...offlineEstimates.map(({ID}: OfflineEstimate) => ID));

    // Get the current date and increment it each time it is used.
    let newID = +new Date();

    // Reset some fields and override all of the IDs
    estimate.ID = copyID;
    estimate.SentStatus = OfflineEstimateSentStatus.Unsent.ID;
    estimate.Status = undefined;
    estimate.BackgroundTaskID = undefined;
    estimate.WorkOrderID = undefined;
    estimate.UploadStartDate = undefined;
    estimate.CreatedDate = new Date();
    estimate.RepairLines = estimate.RepairLines.map((e) => ({...e, ID: newID++}));
    estimate.Files = estimate.Files.map((f) => ({...f, ID: newID++}));
    estimate.RepairLines.forEach((rl) => (rl.Files = rl.Files.map((f) => ({...f, ID: newID++}))));
    estimate.Comments = estimate.Comments.map((c) => ({...c, ID: newID++}));
    estimate.RepairLines.forEach((rl) => (rl.Comments = rl.Comments.map((c) => ({...c, ID: newID++}))));
    estimate.RepairLines.forEach((rl) => rl.Parts.map((p) => ({...p, OfflineEstimateLinePartID: newID++})));

    // This will update all properties in the partial object so this should do everything.
    yield updateOfflineEstimate({type: UPDATE_OFFLINE_ESTIMATE, payload: {ID: estimate.ID, data: estimate}});
}

function* updateOfflineEstimate({payload}: ReduxAction<{ID: number; data: Partial<OfflineEstimate>}>) {
    const offlineEstimate: OfflineEstimate = yield call(IndexedDB.getOne, IndexedDBKeys.OFFLINE_ESTIMATES, payload.ID);

    if (!offlineEstimate) {
        return;
    }

    const updatedOfflineEstimate: OfflineEstimate = {
        ...offlineEstimate,
        ...payload.data,
        UnitINO: payload.data.UnitINO?.toUpperCase() ?? '',
    };

    if (offlineEstimate.UnitINO !== updatedOfflineEstimate.UnitINO) {
        // Check if the new unit is in a different unit group. If it is, update the estimate and repricing the lines.
        const unitGroups: UnitGroup[] = yield call(IndexedDB.getMany, IndexedDBKeys.UNIT_GROUPS);
        const splitUnit = splitUnitINO(updatedOfflineEstimate.UnitINO.toUpperCase());
        let equipmentGroupID: number | undefined = undefined;

        if (splitUnit?.length === 2) {
            const unitGroup = unitGroups.find((g) =>
                g.Details.filter((d) => d.Initial == splitUnit[0]).find((d) =>
                    d.Ranges.some((r) => r.Lower <= +splitUnit[1] && +splitUnit[1] <= r.Upper),
                ),
            );

            equipmentGroupID = unitGroup ? unitGroup.EquipmentGroupID : undefined;
        }

        if (equipmentGroupID !== offlineEstimate.EquipmentGroupID) {
            updatedOfflineEstimate.EquipmentGroupID = equipmentGroupID;
            const shopPricing: ShopPricing[] = yield call(IndexedDB.getMany, IndexedDBKeys.PRICING);
            // There should only ever be 1 shop pricing stored.
            if (
                shopPricing?.length === 1 &&
                shopPricing[0].CompanyID === updatedOfflineEstimate.CompanyID &&
                shopPricing[0].StationID === updatedOfflineEstimate.StationID
            ) {
                for (const repairLine of updatedOfflineEstimate.RepairLines) {
                    const matches = shopPricing[0].Pricing.filter(
                        (p) =>
                            p.JobCodeID === repairLine.JobCodeId &&
                            (p.ConditionCodeID === repairLine.ConditionCodeId || p.ConditionCodeID < 1) &&
                            (equipmentGroupID == null || p.EquipmentGroupIDs.includes(equipmentGroupID)),
                    );

                    if (matches?.length > 0) {
                        // Multiple matches might be found if the unit wasn't found in the unit group so the equipment group is not known.
                        // Take the largest match so it will either be correct or cause an error.
                        repairLine.LaborHours = Math.max(...matches.map((m) => m.MaxHoursPerRepairLine));
                    }
                }
            }
        }
    }

    yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, updatedOfflineEstimate);
    yield put(putOfflineEstimate(updatedOfflineEstimate));
}

function* sendAllUnsentOfflineEstimates() {
    const offlineEstimates: OfflineEstimate[] = yield call(IndexedDB.getMany, IndexedDBKeys.OFFLINE_ESTIMATES);
    const unsentEstimates = offlineEstimates.filter(({SentStatus: status}) => status === OfflineEstimateSentStatus.Unsent.ID);

    yield all(
        unsentEstimates.map((estimate) =>
            call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, {...estimate, SentStatus: OfflineEstimateSentStatus.Queued.ID}),
        ),
    );

    yield call(fetchOfflineEstimates);
    yield call(sendQueuedOfflineEstimates);
}

function* uploadOfflineEstimates({payload}: ReduxAction<{offlineEstimateIDs: number[]}>) {
    const {offlineEstimateIDs} = payload;
    const offlineEstimates: OfflineEstimate[] = yield call(IndexedDB.getMany, IndexedDBKeys.OFFLINE_ESTIMATES);
    const toUpload = offlineEstimates.filter(({ID}) => offlineEstimateIDs.includes(ID));
    let successCount = 0;
    let failCount = 0;

    for (const estimate of toUpload) {
        // Clone the object to avoid updating the original by reference.
        const copy = window.structuredClone(estimate);

        // Convert the files to base64 strings before sending.
        for (const file of copy?.Files) {
            file.Data = btoa(file.Data);
        }
        for (const rl of copy?.RepairLines) {
            for (const file of rl?.Files) {
                file.Data = btoa(file.Data);
            }
        }

        estimate.SentStatus = OfflineEstimateSentStatus.Uploading.ID;
        estimate.UploadStartDate = new Date();
        yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, estimate);
        yield put(uploadStart(estimate.ID, estimate.UploadStartDate));
        const {data, error}: ApiResponse<number> = yield call(request, {
            data: copy,
            url: '/offlineworkorders',
            method: 'post',
            priority: 'high',
            timeout: 300000, // 5 minute timeout
            retriesCount: 1,
        });

        if (data != null) {
            // The result is the ID of the background task processing the work order. Keep track of it so we can poll the API until it finishes.
            estimate.BackgroundTaskID = data;
            estimate.SentStatus = OfflineEstimateSentStatus.Processing.ID;
            yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, estimate);
            yield put(uploadResponse(estimate.ID, estimate.BackgroundTaskID));

            const {polling} = yield select(selectOfflineEstimateState);
            if (!polling) {
                // Use fork so the call is non-blocking.
                yield fork(pollOfflineEstimateTasks, {payload: false});
            }
            successCount = successCount + 1;
        }

        if (error) {
            estimate.BackgroundTaskID = undefined;
            estimate.SentStatus = OfflineEstimateSentStatus.Unsent.ID;
            yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, estimate);
            yield put(uploadError(estimate.ID));
            yield showErrorMessages([t('estimate_failed_upload', {unitINO: estimate.UnitINO})]);
            failCount = failCount + 1;
        }
    }

    yield put(uploadFinish());
    if (failCount === 0) {
        yield put(
            enqueueSnackbar(t('offline_upload_complete'), {
                variant: 'success',
                persist: false,
            }),
        );
    } else if (successCount === 0) {
        yield put(
            enqueueSnackbar(t('offline_upload_all_fail'), {
                variant: 'error',
                persist: false,
            }),
        );
    } else {
        yield put(
            enqueueSnackbar(t('offline_upload_partial_success'), {
                variant: 'warning',
                persist: false,
            }),
        );
    }
}

function* queueOfflineEstimates({payload}: ReduxAction<{offlineEstimateIDs: number[]}>) {
    const {offlineEstimateIDs} = payload;
    const offlineEstimates: OfflineEstimate[] = yield call(IndexedDB.getMany, IndexedDBKeys.OFFLINE_ESTIMATES);

    for (const offlineEstimateID of offlineEstimateIDs) {
        const offlineEstimate = offlineEstimates.find(({ID}) => ID === offlineEstimateID);

        if (offlineEstimate) {
            const updateOfflineEstimate: OfflineEstimate = {...offlineEstimate, SentStatus: OfflineEstimateSentStatus.Queued.ID};

            yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, updateOfflineEstimate);
            yield put(putOfflineEstimate(updateOfflineEstimate));
        }
    }
}

function* dequeueOfflineEstimate({payload}: ReduxAction<{offlineEstimateID: number}>) {
    const {offlineEstimateID} = payload;

    const offlineEstimate: OfflineEstimate = yield call(IndexedDB.getOne, IndexedDBKeys.OFFLINE_ESTIMATES, offlineEstimateID);
    const updateOfflineEstimate: OfflineEstimate = {...offlineEstimate, SentStatus: OfflineEstimateSentStatus.Unsent.ID};

    yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, updateOfflineEstimate);

    yield put(putOfflineEstimate(updateOfflineEstimate));
}

function* discardOfflineEstimate({payload}: ReduxAction<{offlineEstimateID: number}>) {
    const {offlineEstimateID} = payload;

    yield call(IndexedDB.deleteOne, IndexedDBKeys.OFFLINE_ESTIMATES, offlineEstimateID);

    yield put(removeOfflineEstimate(offlineEstimateID));
}

export function* fetchOfflineEstimate({payload}: ReduxAction<FetchOfflineEstimateDto>) {
    const offlineEstimate: OfflineEstimate = yield call(IndexedDB.getOne, IndexedDBKeys.OFFLINE_ESTIMATES, payload.offlineEstimateID);

    if (offlineEstimate) {
        yield put(selectOfflineEstimate(offlineEstimate));

        payload.onFinish();
    }
}

export function* createOfflineEstimateLine({payload}: ReduxAction<CreateOfflineEstimateLineDto>) {
    const offlineEstimate: OfflineEstimate = yield call(IndexedDB.getOne, IndexedDBKeys.OFFLINE_ESTIMATES, payload.offlineEstimateID);

    if (offlineEstimate) {
        const offlineEstimateLine: OfflineEstimateLine = {
            ID: Date.now(),
            JobCodeId: payload.offlineEstimateLine.JobCodeId,
            WhyMadeCodeId: payload.offlineEstimateLine.WhyMadeCodeId,
            ConditionCodeId: payload.offlineEstimateLine.ConditionCodeId,
            UnitLocationCodeId: payload.offlineEstimateLine.UnitLocationCodeId,
            RepairQuantity: payload.offlineEstimateLine.RepairQuantity,
            LaborHours: payload.offlineEstimateLine.LaborHours,
            Overtime1: payload.offlineEstimateLine.Overtime1,
            Overtime2: payload.offlineEstimateLine.Overtime2,
            Overtime3: payload.offlineEstimateLine.Overtime3,
            MaterialSubtotal: payload.offlineEstimateLine.MaterialSubtotal,
            Status: WorkOrderRepairLineStatus.Created,
            Parts: [],
            Files: [],
            Comments: [],
        };

        const updatedOfflineEstimate: OfflineEstimate = {...offlineEstimate, RepairLines: [...offlineEstimate.RepairLines, offlineEstimateLine]};

        yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, updatedOfflineEstimate);

        yield put(putOfflineEstimate(updatedOfflineEstimate));
    }
}

export function* updateOfflineEstimateLine({payload}: ReduxAction<UpdateOfflineEstimateLineDto>) {
    const offlineEstimate: OfflineEstimate = yield call(IndexedDB.getOne, IndexedDBKeys.OFFLINE_ESTIMATES, payload.offlineEstimateID);

    if (offlineEstimate) {
        const updatedOfflineEstimate: OfflineEstimate = {
            ...offlineEstimate,
            RepairLines: offlineEstimate.RepairLines.map((item) =>
                item.ID === payload.offlineEstimateLineID ? {...item, ...payload.offlineEstimateLine} : item,
            ),
        };

        yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, updatedOfflineEstimate);

        yield put(putOfflineEstimate(updatedOfflineEstimate));
    }
}

export function* deleteOfflineEstimateLine({payload}: ReduxAction<{offlineEstimateID: number; offlineEstimateLineID: number}>) {
    const offlineEstimate: OfflineEstimate = yield call(IndexedDB.getOne, IndexedDBKeys.OFFLINE_ESTIMATES, payload.offlineEstimateID);

    if (offlineEstimate) {
        const updatedOfflineEstimate: OfflineEstimate = {
            ...offlineEstimate,
            RepairLines: offlineEstimate.RepairLines.filter((item) => item.ID !== payload.offlineEstimateLineID),
        };

        yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, updatedOfflineEstimate);

        yield put(putOfflineEstimate(updatedOfflineEstimate));
    }
}

export function* createOfflineEstimateFile({payload}: ReduxAction<CreateOfflineEstimateFileDto>) {
    const offlineEstimate: OfflineEstimate = yield call(IndexedDB.getOne, IndexedDBKeys.OFFLINE_ESTIMATES, payload.offlineEstimateID);

    if (!offlineEstimate) {
        return;
    }

    const newFile: OfflineEstimateFile = {
        ID: Date.now(),
        FileType: payload.fileType,
        FileName: payload.fileName,
        DocumentTypeCode: payload.type,
        Data: payload.src!.toString(),
    };

    let updatedOfflineEstimate: OfflineEstimate = {
        ...offlineEstimate,
    };

    if (payload.associatedTypeID === AssociatedTypes.WorkOrderRepairLine) {
        updatedOfflineEstimate.RepairLines = updatedOfflineEstimate.RepairLines.map((item) =>
            item.ID === payload.associateObjectID ? {...item, Files: [...item.Files, newFile]} : item,
        );
    } else {
        updatedOfflineEstimate.Files = [...offlineEstimate.Files, newFile];
    }

    yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, updatedOfflineEstimate);

    yield put(putOfflineEstimate(updatedOfflineEstimate));
}

export function* deleteOfflineEstimateFile({payload}: ReduxAction<DeleteOfflineEstimateFileDto>) {
    const offlineEstimate: OfflineEstimate = yield call(IndexedDB.getOne, IndexedDBKeys.OFFLINE_ESTIMATES, payload.offlineEstimateID);

    if (!offlineEstimate) {
        return;
    }

    let updatedOfflineEstimate: OfflineEstimate = {
        ...offlineEstimate,
    };

    if (payload.associatedTypeID === AssociatedTypes.WorkOrderRepairLine) {
        updatedOfflineEstimate.RepairLines = updatedOfflineEstimate.RepairLines.map((item) =>
            item.ID === payload.associateObjectID ? {...item, Files: item.Files.filter((file) => file.ID !== payload.fileID)} : item,
        );
    } else {
        updatedOfflineEstimate.Files = offlineEstimate.Files.filter((file) => file.ID !== payload.fileID);
    }

    yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, updatedOfflineEstimate);

    yield put(putOfflineEstimate(updatedOfflineEstimate));
}

export function* createOfflineEstimatePart({payload}: ReduxAction<CreateOfflineEstimatePartDto>) {
    const offlineEstimate: OfflineEstimate = yield call(IndexedDB.getOne, IndexedDBKeys.OFFLINE_ESTIMATES, payload.offlineEstimateID);

    if (!offlineEstimate) {
        return;
    }

    const newPart: OfflineEstimatePart = {
        ...payload.part,
        OfflineEstimateLinePartID: Date.now(),
    };

    const updatedOfflineEstimate: OfflineEstimate = {
        ...offlineEstimate,
        RepairLines: offlineEstimate.RepairLines.map((item) =>
            item.ID === payload.offlineEstimateLineID ? {...item, Parts: [...item.Parts, newPart]} : item,
        ),
    };

    yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, updatedOfflineEstimate);

    yield put(putOfflineEstimate(updatedOfflineEstimate));
}

export function* updateOfflineEstimatePart({payload}: ReduxAction<UpdateOfflineEstimatePartDto>) {
    const offlineEstimate: OfflineEstimate = yield call(IndexedDB.getOne, IndexedDBKeys.OFFLINE_ESTIMATES, payload.offlineEstimateID);

    if (!offlineEstimate) {
        return;
    }

    const updatedOfflineEstimate: OfflineEstimate = {
        ...offlineEstimate,
        RepairLines: offlineEstimate.RepairLines.map((item) =>
            item.ID === payload.offlineEstimateLineID
                ? {
                      ...item,
                      Parts: item.Parts.map((part) =>
                          part.OfflineEstimateLinePartID === payload.offlineEstimateLinePartID ? {...part, ...payload.part} : part,
                      ),
                  }
                : item,
        ),
    };

    yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, updatedOfflineEstimate);

    yield put(putOfflineEstimate(updatedOfflineEstimate));
}

export function* deleteOfflineEstimatePart({payload}: ReduxAction<{offlineEstimateID: number; offlineEstimateLineID: number; partID: number}>) {
    const offlineEstimate: OfflineEstimate = yield call(IndexedDB.getOne, IndexedDBKeys.OFFLINE_ESTIMATES, payload.offlineEstimateID);

    if (!offlineEstimate) {
        return;
    }

    const updatedOfflineEstimate: OfflineEstimate = {
        ...offlineEstimate,
        RepairLines: offlineEstimate.RepairLines.map((item) =>
            item.ID === payload.offlineEstimateLineID
                ? {...item, Parts: item.Parts.filter((part) => part.OfflineEstimateLinePartID !== payload.partID)}
                : item,
        ),
    };

    yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, updatedOfflineEstimate);

    yield put(putOfflineEstimate(updatedOfflineEstimate));
}

export function* createOfflineEstimateComment({payload}: ReduxAction<CreateOfflineEstimateCommentDto>) {
    const offlineEstimate: OfflineEstimate = yield call(IndexedDB.getOne, IndexedDBKeys.OFFLINE_ESTIMATES, payload.offlineEstimateID);

    if (!offlineEstimate) {
        return;
    }

    const newComment: OfflineEstimateComment = {
        ...payload.comment,
        ID: Date.now(),
    };

    let updatedOfflineEstimate: OfflineEstimate = {
        ...offlineEstimate,
    };

    if (payload.associatedTypeID === AssociatedTypes.WorkOrderRepairLine) {
        updatedOfflineEstimate.RepairLines = updatedOfflineEstimate.RepairLines.map((item) =>
            item.ID === payload.associateObjectID ? {...item, Comments: [...item.Comments, newComment]} : item,
        );
    } else {
        updatedOfflineEstimate.Comments = [...updatedOfflineEstimate.Comments, newComment];
    }

    yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, updatedOfflineEstimate);

    yield put(putOfflineEstimate(updatedOfflineEstimate));
}

export function* updateOfflineEstimateComment({payload}: ReduxAction<UpdateOfflineEstimateCommentDto>) {
    const offlineEstimate: OfflineEstimate = yield call(IndexedDB.getOne, IndexedDBKeys.OFFLINE_ESTIMATES, payload.offlineEstimateID);

    if (!offlineEstimate) {
        return;
    }

    let updatedOfflineEstimate: OfflineEstimate = {
        ...offlineEstimate,
    };

    if (payload.associatedTypeID === AssociatedTypes.WorkOrderRepairLine) {
        updatedOfflineEstimate.RepairLines = updatedOfflineEstimate.RepairLines.map((item) =>
            item.ID === payload.associateObjectID
                ? {
                      ...item,
                      Comments: item.Comments.map((comment) =>
                          comment.ID === payload.commentID ? {...comment, ...payload.comment, ModifiedDate: new Date().toISOString()} : comment,
                      ),
                  }
                : item,
        );
    } else {
        updatedOfflineEstimate.Comments = updatedOfflineEstimate.Comments.map((comment) =>
            comment.ID === payload.commentID ? {...comment, ...payload.comment, ModifiedDate: new Date().toISOString()} : comment,
        );
    }

    yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, updatedOfflineEstimate);

    yield put(putOfflineEstimate(updatedOfflineEstimate));
}

export function* deleteOfflineEstimateComment({payload}: ReduxAction<DeleteOfflineEstimateCommentDto>) {
    const offlineEstimate: OfflineEstimate = yield call(IndexedDB.getOne, IndexedDBKeys.OFFLINE_ESTIMATES, payload.offlineEstimateID);

    if (!offlineEstimate) {
        return;
    }

    let updatedOfflineEstimate: OfflineEstimate = {
        ...offlineEstimate,
    };

    if (payload.associatedTypeID === AssociatedTypes.WorkOrderRepairLine) {
        updatedOfflineEstimate.RepairLines = updatedOfflineEstimate.RepairLines.map((item) =>
            item.ID === payload.associateObjectID ? {...item, Comments: item.Comments.filter((comment) => comment.ID !== payload.commentID)} : item,
        );
    } else {
        updatedOfflineEstimate.Comments = updatedOfflineEstimate.Comments.filter((comment) => comment.ID !== payload.commentID);
    }

    yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, updatedOfflineEstimate);

    yield put(putOfflineEstimate(updatedOfflineEstimate));
}

export function* sendQueuedOfflineEstimates() {
    const isOfflineMode: boolean = yield select(selectIsOfflineStatus);

    if (isOfflineMode) {
        return;
    }

    const offlineEstimates: OfflineEstimate[] = yield call(IndexedDB.getMany, IndexedDBKeys.OFFLINE_ESTIMATES);

    const queuedOfflineEstimates = offlineEstimates.filter(({SentStatus}) => SentStatus === OfflineEstimateSentStatus.Queued.ID);

    if (!queuedOfflineEstimates.length) {
        return;
    } else {
        yield uploadOfflineEstimates({type: UPLOAD_OFFLINE_ESTIMATES, payload: {offlineEstimateIDs: queuedOfflineEstimates.map(({ID}) => ID)}});
    }
}

export function* pollOfflineEstimateTasks({payload: startImmediately}: ReduxAction<{startImmediately?: boolean}> | {payload: false}) {
    const isOfflineMode: boolean = yield select(selectIsOfflineStatus);

    if (isOfflineMode) {
        return;
    }

    yield put(pollStart());

    let offlineEstimates: OfflineEstimate[] = yield call(IndexedDB.getMany, IndexedDBKeys.OFFLINE_ESTIMATES);
    let processing = offlineEstimates.filter(({BackgroundTaskID}) => BackgroundTaskID !== undefined);
    let retryTracker = processing?.map(({BackgroundTaskID}) => ({BackgroundTaskID, RunningRetryCount: 0, FinishedRetryCount: 0}));

    // Wait a few seconds to allow the background process to start running.
    if (!startImmediately) {
        yield delay(5000);
    } else if (processing?.length && processing[0].BackgroundTaskID !== undefined) {
        // In this use case (Check Processing button), pollStart() doesn't trigger a re-render of the component for some reason. This triggers one.
        yield put(pollResponse(processing[0].ID, undefined, undefined, OfflineEstimateSentStatus.Processing.ID));
        yield put(uploadResponse(processing[0].ID, processing[0].BackgroundTaskID));
    }
    while (processing && processing.length > 0) {
        for (const estimate of processing) {
            const {data, error}: ApiResponse<OfflineTaskResult> = yield call(request, {
                url: `/tasks/progress?taskID=${Number(estimate.BackgroundTaskID)}`,
                method: 'get',
                timeout: 30000, // 30 second timeout
            });

            if (error || (data && data.IsDead) || (data && !data.IsDead && !data.IsRunning && data.Result == null)) {
                // If the background task hasn't started, retry a few times before giving up.
                if (
                    data &&
                    !data.IsDead &&
                    !data.IsRunning &&
                    data.Result == null &&
                    retryTracker.find((r) => r.BackgroundTaskID === estimate.BackgroundTaskID && r.RunningRetryCount < 4)
                ) {
                    retryTracker = retryTracker.map((r) =>
                        r.BackgroundTaskID === estimate.BackgroundTaskID ? {...r, RunningRetryCount: r.RunningRetryCount + 1} : r,
                    );
                    continue;
                }
                estimate.BackgroundTaskID = undefined;
                estimate.SentStatus = OfflineEstimateSentStatus.Error.ID;
                yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, estimate);
                yield put(pollError(estimate.ID));

                // Check the cache and see if there is a work order for the same unit to tailor the error.
                const liveEstimates: WorkOrder[] = yield call(IndexedDB.getMany, IndexedDBKeys.WORK_ORDERS);
                if (
                    liveEstimates.find(
                        ({UnitDetails, Status}) => Status <= WorkOrderStatus.WorkApproved.Status && UnitDetails?.UnitINO == estimate.UnitINO,
                    )
                ) {
                    yield showErrorMessages([t('estimate_poll_fail_match', {unitINO: estimate.UnitINO})]);
                } else {
                    yield showErrorMessages([t('estimate_poll_fail_no_match', {unitINO: estimate.UnitINO})]);
                }
            }
            if (data && data.IsRunning) {
                continue;
            }
            if (data && data.Result) {
                // Not sure how/why but sometimes the task finishes and no work order is returned.
                // Retry fetching the results and if nothing is found, set the offline estimate's SentStatus to Error.
                if (
                    (!data.Result.Data || (data.Result.Data.ID <= 0 && (data.Result.ErrorMessages?.length ?? 0) === 0)) &&
                    retryTracker.find((r) => r.BackgroundTaskID === estimate.BackgroundTaskID && r.FinishedRetryCount < 4)
                ) {
                    retryTracker = retryTracker.map((r) =>
                        r.BackgroundTaskID === estimate.BackgroundTaskID ? {...r, FinishedRetryCount: r.FinishedRetryCount + 1} : r,
                    );
                    continue;
                } else if (!data.Result.Data || data.Result.Data.ID <= 0) {
                    // Prepend the first error saying an estimate was not created.
                    if (data.Result.ErrorMessages?.length) {
                        data.Result.ErrorMessages[0].Message =
                            t('estimate_not_created', {unitINO: estimate.UnitINO}) + ': ' + data.Result.ErrorMessages[0].Message;
                        yield showErrorMessages(data.Result.ErrorMessages.map(({Message}) => Message));
                    }
                    estimate.BackgroundTaskID = undefined;
                    estimate.SentStatus = OfflineEstimateSentStatus.Error.ID;
                    yield put(pollError(estimate.ID));
                    yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, estimate);

                    // If there are any errors returned, then those are displayed instead of this error about the background task not being found.
                    if ((data.Result.ErrorMessages?.length ?? 0) === 0) {
                        // Check the cache and see if there is a work order for the same unit to tailor the error.
                        const liveEstimates: WorkOrder[] = yield call(IndexedDB.getMany, IndexedDBKeys.WORK_ORDERS);
                        if (
                            liveEstimates.find(
                                ({UnitDetails, Status}) => Status <= WorkOrderStatus.WorkApproved.Status && UnitDetails?.UnitINO == estimate.UnitINO,
                            )
                        ) {
                            yield showErrorMessages([t('estimate_poll_fail_match', {unitINO: estimate.UnitINO})]);
                        } else {
                            yield showErrorMessages([t('estimate_poll_fail_no_match', {unitINO: estimate.UnitINO})]);
                        }
                    }
                } else {
                    estimate.BackgroundTaskID = undefined;
                    estimate.WorkOrderID = data.Result.Data.ID;
                    estimate.Status = data.Result.Data.Status;
                    estimate.SentStatus = OfflineEstimateSentStatus.Live.ID;
                    yield call(IndexedDB.updateOne, IndexedDBKeys.OFFLINE_ESTIMATES, estimate);
                    yield put(pollResponse(estimate.ID, data.Result.Data.ID, data.Result.Data.Status, estimate.SentStatus));

                    if (estimate.Status !== WorkOrderStatus.WorkRejected.Status) {
                        // This imperfect mapping will get replaced on the next work order poll.
                        const shops: StationCompanyRelation[] = yield select(selectShops);
                        const mappedWorkOrder: WorkOrder = {
                            ...estimate,
                            ID: estimate.WorkOrderID,
                            Status: estimate.Status,
                            ShopCodeID:
                                shops?.find(({StationID, CompanyID}) => StationID === estimate.StationID && CompanyID === estimate.CompanyID)?.ID ??
                                -1,
                            Company: {ID: estimate.CompanyID} as unknown as CompanySummary,
                            ModifiedDate: '',
                            WorkOrderNumber: '',
                            RepairDate: '',
                            RepairLocation: {ID: estimate.StationID} as unknown as StationSummary,
                            UnitDetails: {UnitINO: estimate.UnitINO} as unknown as UnitDetail,
                            FHWADueDate: '',
                            BITDueDate: '',
                            PurchaseOrderNumber: '',
                            WorkOrderRepairLines: [],
                            DeclinationReasons: [],
                            FileList: [],
                            SpecialInstructionAuthorizations: [],
                            DwellDays: 0,
                            HasAssignedRepairLine: false,
                            RefurbishedDate: '',
                            EquipmentGroups: estimate.EquipmentGroupID ?? EquipmentGroups.Unknown.ID,
                            IsDeleted: false,
                            CommentsCount: 0,
                        };
                        yield put(createWorkOrderResponse(mappedWorkOrder));

                        // Check if the estimate is already in the DB before adding it.
                        const wo: WorkOrder = yield call(IndexedDB.getOne, IndexedDBKeys.WORK_ORDERS, estimate.WorkOrderID);
                        if (wo == undefined) {
                            yield call(IndexedDB.createOne, IndexedDBKeys.WORK_ORDERS, mappedWorkOrder);
                        }
                    }
                }
            }
        }
        offlineEstimates = yield call(IndexedDB.getMany, IndexedDBKeys.OFFLINE_ESTIMATES);
        processing = offlineEstimates.filter(({BackgroundTaskID}) => BackgroundTaskID !== undefined);
        if (processing && processing.length > 0) {
            yield delay(5000);
        }
    }

    yield put(pollFinish());
    yield put(
        enqueueSnackbar(t('offline_processing_complete'), {
            variant: 'success',
            persist: false,
        }),
    );
}

export default function* offlineEstimateRootSaga() {
    yield takeLatest<ReduxAction>(FETCH_OFFLINE_ESTIMATES, fetchOfflineEstimates);
    yield takeLatest<ReduxAction>(CLEAN_UP_OFFLINE_DATA, cleanUpOfflineData);
    yield takeLatest<ReduxAction>(CREATE_OFFLINE_ESTIMATE, createOfflineEstimate);
    yield takeLatest<ReduxAction>(COPY_OFFLINE_ESTIMATE, copyOfflineEstimate);
    yield takeLatest<ReduxAction>(UPDATE_OFFLINE_ESTIMATE, updateOfflineEstimate);
    yield takeLatest<ReduxAction>(SEND_ALL_UNSENT_OFFLINE_ESTIMATES, sendAllUnsentOfflineEstimates);
    yield takeLatest<ReduxAction>(UPLOAD_OFFLINE_ESTIMATES, uploadOfflineEstimates);
    yield takeLatest<ReduxAction>(QUEUE_OFFLINE_ESTIMATES, queueOfflineEstimates);
    yield takeLatest<ReduxAction>(DEQUEUE_OFFLINE_ESTIMATE, dequeueOfflineEstimate);
    yield takeLatest<ReduxAction>(DISCARD_OFFLINE_ESTIMATE, discardOfflineEstimate);
    yield takeLatest<ReduxAction>(FETCH_OFFLINE_ESTIMATE, fetchOfflineEstimate);
    yield takeLatest<ReduxAction>(CREATE_OFFLINE_ESTIMATE_LINE, createOfflineEstimateLine);
    yield takeLatest<ReduxAction>(UPDATE_OFFLINE_ESTIMATE_LINE, updateOfflineEstimateLine);
    yield takeLatest<ReduxAction>(DELETE_OFFLINE_ESTIMATE_LINE, deleteOfflineEstimateLine);
    yield takeLatest<ReduxAction>(CREATE_OFFLINE_ESTIMATE_FILE, createOfflineEstimateFile);
    yield takeLatest<ReduxAction>(DELETE_OFFLINE_ESTIMATE_FILE, deleteOfflineEstimateFile);
    yield takeLatest<ReduxAction>(CREATE_OFFLINE_ESTIMATE_PART, createOfflineEstimatePart);
    yield takeLatest<ReduxAction>(UPDATE_OFFLINE_ESTIMATE_PART, updateOfflineEstimatePart);
    yield takeLatest<ReduxAction>(DELETE_OFFLINE_ESTIMATE_PART, deleteOfflineEstimatePart);
    yield takeLatest<ReduxAction>(CREATE_OFFLINE_ESTIMATE_COMMENT, createOfflineEstimateComment);
    yield takeLatest<ReduxAction>(UPDATE_OFFLINE_ESTIMATE_COMMENT, updateOfflineEstimateComment);
    yield takeLatest<ReduxAction>(DELETE_OFFLINE_ESTIMATE_COMMENT, deleteOfflineEstimateComment);
    yield takeLatest<ReduxAction>(SEND_QUEUED_OFFLINE_ESTIMATES, sendQueuedOfflineEstimates);
    yield takeLatest<ReduxAction>(POLL_OFFLINE_ESTIMATE_TASKS, pollOfflineEstimateTasks);
}
