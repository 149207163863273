import {differenceInMilliseconds} from 'date-fns';
import {unionBy} from 'lodash';
import {takeLeading, call, put} from 'redux-saga/effects';

import config from '../../config';
import {Company} from '../../models/Company';
import {CompanyType} from '../../models/CompanyType';
import {ReduxAction} from '../../models/ReduxAction';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {formatDate} from '../../utils/formatDate';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';

import {companiesRequest, companyError, companiesResponse, companiesTypesResponse} from './company.actions';
import {FETCH_COMPANIES, FETCH_COMPANIES_TYPES, SELECT_COMPANY} from './company.types';

const {resourceCacheFallback} = config;

export function* fetchCompaniesTypes() {
    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_COMPANIES_TYPES_REFRESH_TIME, LocalStorageKeys.LAST_COMPANIES_TYPES_UPDATE_TIME);

    let cachedCompaniesTypes: CompanyType[] = [];

    if (lastUpdateTime) {
        cachedCompaniesTypes = yield call(IndexedDB.getMany, IndexedDBKeys.COMPANIES_TYPES);

        if (differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
            yield put(companiesTypesResponse(cachedCompaniesTypes));

            return;
        }
    }
    yield put(companiesRequest());

    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_COMPANIES_TYPES_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<CompanyType[]> = yield call(request, {
        url: '/companies/types',
        method: 'get',
        params: lastUpdateTime
            ? {
                  includeDeleted: true,
                  lastUpdateTime: formatDate({
                      date: lastUpdateTime,
                      ISOFormat: true,
                  }),
              }
            : undefined,
    });

    if (error) {
        yield put(companyError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedCompaniesTypes) {
            yield put(companiesTypesResponse(cachedCompaniesTypes));
        }
    }

    if (data) {
        const combinedCompanyTypes = unionBy(data, cachedCompaniesTypes, 'ID').filter((companyType) => !companyType.IsDeleted);

        yield put(companiesTypesResponse(combinedCompanyTypes));

        yield call(IndexedDB.createMany, IndexedDBKeys.COMPANIES_TYPES, combinedCompanyTypes);
        LocalStorage.set(LocalStorageKeys.LAST_COMPANIES_TYPES_UPDATE_TIME, requestTime);
    }
}

export function* fetchCompanies() {
    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_COMPANIES_REFRESH_TIME, LocalStorageKeys.LAST_COMPANIES_UPDATE_TIME);

    let cachedCompanies: Company[] = [];

    if (lastUpdateTime) {
        cachedCompanies = yield call(IndexedDB.getMany, IndexedDBKeys.COMPANIES);

        if (differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
            yield put(companiesResponse(cachedCompanies));

            return;
        }
    }
    yield put(companiesRequest());

    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_COMPANIES_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<Company[]> = yield call(request, {
        url: '/companies',
        method: 'get',
        params: lastUpdateTime
            ? {
                  includeDeleted: true,
                  lastUpdateTime: formatDate({
                      date: lastUpdateTime,
                      ISOFormat: true,
                  }),
              }
            : undefined,
    });

    if (error) {
        yield put(companyError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedCompanies) {
            yield put(companiesResponse(cachedCompanies));
        }
    }

    if (data) {
        // unionBy takes the left array's object when the uniqueness check matches.
        const combinedCompanies = unionBy(data, cachedCompanies, 'ID').filter((company) => !company.IsDeleted);

        yield put(companiesResponse(combinedCompanies));

        yield call(IndexedDB.createMany, IndexedDBKeys.COMPANIES, combinedCompanies);
        LocalStorage.set(LocalStorageKeys.LAST_COMPANIES_UPDATE_TIME, requestTime);
    }
}

export function* selectCompany({payload}: ReduxAction<{company: Company}>) {
    LocalStorage.set(LocalStorageKeys.SELECTED_COMPANY_ID, payload.company.ID);
}

export default function* companiesRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_COMPANIES, fetchCompanies);
    yield takeLeading<ReduxAction>(FETCH_COMPANIES_TYPES, fetchCompaniesTypes);
    yield takeLeading<ReduxAction>(SELECT_COMPANY, selectCompany);
}
