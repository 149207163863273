import React, {useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';

import DetailsTitle from '../../components/details-title/details-title.component';
import {useHeader} from '../../hooks/use-header';
import {OfflineEstimateDataContext} from '../../providers/offline-estimate-data-provider';

import OfflineEstimateDetails from './offline-estimate-details.component';

export type OfflineEstimateDetailsProps = {
    backToPath: string;
};

const OfflineEstimateDetailsContainer = ({backToPath}: OfflineEstimateDetailsProps) => {
    const {push} = useHistory();
    const {t} = useTranslation();
    const {offlineEstimate} = useContext(OfflineEstimateDataContext);
    const location = useLocation<{menuPrevPath?: string}>();

    const onHeaderClick = useCallback(() => {
        push(`${backToPath}/${offlineEstimate.ID}/header`);
    }, [offlineEstimate.ID, push]);

    const TitleTop = useCallback(() => <DetailsTitle onClick={onHeaderClick} unitINO={offlineEstimate.UnitINO} />, [onHeaderClick, offlineEstimate]);

    useHeader({
        backToPath: location.state?.menuPrevPath || backToPath,
        middleElement: TitleTop,
    });

    return <OfflineEstimateDetails path={backToPath} title={t('offline_estimate')} />;
};

export default OfflineEstimateDetailsContainer;
