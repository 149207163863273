import {differenceInMilliseconds} from 'date-fns';
import {unionBy} from 'lodash';
import {takeLatest, call, put, takeLeading, select} from 'redux-saga/effects';

import config from '../../config';
import {ReduxAction} from '../../models/ReduxAction';
import {User} from '../../models/User';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {formatDate} from '../../utils/formatDate';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {selectSelectedShop} from '../shop/shop.selectors';

import {setUser, userError, userRequest, usersResponse} from './user.actions';
import {FETCH_USERS, LOGOUT_USER, SELECT_USER} from './user.types';

const {resourceCacheFallback} = config;

/** Execute request to fetch the list of Users from the database and update local UserState. */
export function* fetchUsers() {
    const {StationID, CompanyID} = yield select(selectSelectedShop);

    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_USERS_REFRESH_TIME, LocalStorageKeys.LAST_USERS_UPDATE_TIME);

    let cachedUsers: User[] = [];

    if (lastUpdateTime) {
        cachedUsers = yield call(IndexedDB.getMany, IndexedDBKeys.USERS);

        if (differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
            yield put(usersResponse(cachedUsers));

            return;
        }
    }

    yield put(userRequest());

    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_USERS_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<User[]> = yield call(request, {
        url: '/users',
        method: 'get',
        params: {
            stationID: StationID,
            companyID: CompanyID,
            ...(lastUpdateTime && {
                includeDeleted: true,
                lastUpdateTime: formatDate({
                    date: lastUpdateTime,
                    ISOFormat: true,
                }),
            }),
        },
        priority: 'high',
    });

    if (error) {
        yield put(userError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedUsers) {
            yield put(usersResponse(cachedUsers));
        }
    }

    if (data) {
        const combinedUsers = unionBy(data, cachedUsers, 'ID').filter((user) => !user.IsDeleted);

        yield put(usersResponse(combinedUsers));

        yield call(IndexedDB.createMany, IndexedDBKeys.USERS, combinedUsers);
        LocalStorage.set(LocalStorageKeys.LAST_USERS_UPDATE_TIME, requestTime);
    }
}

export function* selectUser(action: ReduxAction) {
    const {data, error}: ApiResponse<User> = yield call(request, {
        url: `/users/${encodeURIComponent(action.payload.username)}/`,
        method: 'get',
    });

    if (error) {
        yield put(userError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        LocalStorage.set(LocalStorageKeys.SELECTED_USER, data);
        yield put(setUser(data, action.payload.pgt));
    }
}

export function* logoutUser(action: ReduxAction) {
    yield put(userRequest());

    yield call(request, {
        url: '/users/logout',
        method: 'post',
    });

    if (action.payload.callback) {
        action.payload.callback();
    }
}

export default function* usersRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_USERS, fetchUsers);
    yield takeLatest<ReduxAction>(SELECT_USER, selectUser);
    yield takeLatest<ReduxAction>(LOGOUT_USER, logoutUser);
}
