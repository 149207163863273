import {createSelector} from 'reselect';

import {State} from '../root-reducer';

import {CommentsState} from './comment.reducer';

export const selectCommentState = (state: State): CommentsState => state.commentReducer;

export const selectWorkOrderComments = createSelector(selectCommentState, ({workOrderComments}) => workOrderComments);

export const selectRepairLineComments = createSelector(selectCommentState, ({repairLineComments}) => repairLineComments);
