import {Station} from '../../models/Station';

import {STATIONS_REQUEST, STATIONS_ERROR, STATIONS_RESPONSE, FETCH_STATIONS} from './station.types';

export const stationsRequest = () => ({
    type: STATIONS_REQUEST,
});

export const stationsError = (error: Error | unknown) => ({
    type: STATIONS_ERROR,
    payload: error,
});

export const stationsResponse = (data: Station[]) => ({
    type: STATIONS_RESPONSE,
    payload: data,
});

export const fetchStations = () => ({
    type: FETCH_STATIONS,
});
