import {t} from 'i18next';
import React from 'react';

import Logo from '../../components/logo/logo.component';

import {Wrapper, Message} from './initial-auth-failure.styles';

const InitialAuthFailure = () => (
    <Wrapper>
        <Logo />
        <Message>
            <h1>{t('unable_to_reach_server')}</h1>
            <h2>{t('try_again_later')}</h2>
        </Message>
    </Wrapper>
);

export default InitialAuthFailure;
