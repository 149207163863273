import {AccessControl} from '../models/AccessControl';
import {Company} from '../models/Company';
import {StationCompanyRelation} from '../models/StationCompanyRelation';
import {User} from '../models/User';
import {ApplicationMode} from '../redux/application/application.types';

export enum LocalStorageKeys {
    REDIRECT_URI = 'redirect_uri',
    LAST_SHOPS_UPDATE_TIME = 'last_shops_update_time',
    LAST_USERS_UPDATE_TIME = 'last_users_update_time',
    LAST_PARTS_UPDATE_TIME = 'last_parts_update_time',
    LAST_FEATURES_UPDATE_TIME = 'last_features_update_time',
    LAST_STATIONS_UPDATE_TIME = 'last_estimates_update_time',
    LAST_ESTIMATES_UPDATE_TIME = 'last_estimates_update_time',
    LAST_JOB_CODES_UPDATE_TIME = 'last_job_codes_update_time',
    LAST_COMPANIES_UPDATE_TIME = 'last_companies_update_time',
    LAST_REPAIR_SIZES_UPDATE_TIME = 'last_repair_sizes_update_time',
    LAST_WORK_ORDERS_UPDATE_TIME = 'last_work_orders_update_time',
    LAST_UNITS_TYPES_UPDATE_TIME = 'last_units_types_update_time',
    LAST_UNITS_STATUS_UPDATE_TIME = 'last_units_status_update_time',
    LAST_COMPANIES_TYPES_UPDATE_TIME = 'last_companies_update_time',
    LAST_WHY_MADE_CODES_UPDATE_TIME = 'last_why_made_codes_update_time',
    LAST_CONDITION_CODES_UPDATE_TIME = 'last_condition_codes_update_time',
    LAST_EXCEPTION_REASONS_UPDATE_TIME = 'last_exception_reasons_update_time',
    LAST_UNIT_LOCATION_CODES_UPDATE_TIME = 'last_unit_location_codes_update_time',
    LAST_MESSAGES_UPDATE_TIME = 'last_messages_refresh_time',
    LAST_FORMS_UPDATE_TIME = 'last_forms_refresh_time',
    LAST_UNIT_GROUPS_UPDATE_TIME = 'last_unit_groups_update_time',
    LAST_PRICING_UPDATE_TIME = 'last_pricing_update_time',
    LAST_SHOPS_REFRESH_TIME = 'last_shops_refresh_time',
    LAST_USERS_REFRESH_TIME = 'last_users_refresh_time',
    LAST_PARTS_REFRESH_TIME = 'last_parts_refresh_time',
    LAST_FEATURES_REFRESH_TIME = 'last_features_refresh_time',
    LAST_STATIONS_REFRESH_TIME = 'last_estimates_refresh_time',
    LAST_ESTIMATES_REFRESH_TIME = 'last_estimates_refresh_time',
    LAST_JOB_CODES_REFRESH_TIME = 'last_job_codes_refresh_time',
    LAST_COMPANIES_REFRESH_TIME = 'last_companies_refresh_time',
    LAST_REPAIR_SIZES_REFRESH_TIME = 'last_repair_sizes_refresh_time',
    LAST_WORK_ORDERS_REFRESH_TIME = 'last_work_orders_refresh_time',
    LAST_UNITS_TYPES_REFRESH_TIME = 'last_units_types_refresh_time',
    LAST_UNITS_STATUS_REFRESH_TIME = 'last_units_status_refresh_time',
    LAST_COMPANIES_TYPES_REFRESH_TIME = 'last_companies_refresh_time',
    LAST_WHY_MADE_CODES_REFRESH_TIME = 'last_why_made_codes_refresh_time',
    LAST_CONDITION_CODES_REFRESH_TIME = 'last_condition_codes_refresh_time',
    LAST_EXCEPTION_REASONS_REFRESH_TIME = 'last_exception_reasons_refresh_time',
    LAST_UNIT_LOCATION_CODES_REFRESH_TIME = 'last_unit_location_codes_refresh_time',
    LAST_MESSAGES_REFRESH_TIME = 'last_messages_refresh_time',
    LAST_FORMS_REFRESH_TIME = 'last_forms_refresh_time',
    LAST_ACCESS_CONTROL_REFRESH_TIME = 'last_access_control_refresh_time',
    LAST_UNIT_GROUPS_REFRESH_TIME = 'last_unit_groups_refresh_time',
    LAST_PRICING_REFRESH_TIME = 'last_pricing_refresh_time',
    SELECTED_SHOP_ID = 'selected_shop_id',
    SELECTED_COMPANY_ID = 'selected_company_id',
    ACCESS_CONTROL = 'access_control',
    RESET_SEARCH_FILTER = 'reset_search_filter',
    LAST_SELECTED_SHOP = 'last_selected_shop',
    SELECTED_USER = 'selected_user',
    PROXY_GRANTING_TICKET = 'proxy_granting_ticket',
    RETRY_TIMESTAMP = 'retry_timestamp',
    READ_MESSAGES = 'read_messages',
    APPLICATION_MODE = 'application_mode',
    OFFLINE_MODE_START_DATE = 'offline_mode_start_date',
}

interface KeyValueMap {
    readonly [LocalStorageKeys.REDIRECT_URI]: string;
    readonly [LocalStorageKeys.SELECTED_SHOP_ID]: StationCompanyRelation['ID'];
    readonly [LocalStorageKeys.SELECTED_COMPANY_ID]: Company['ID'];
    readonly [LocalStorageKeys.ACCESS_CONTROL]: AccessControl;
    readonly [LocalStorageKeys.SELECTED_USER]: User;
    readonly [LocalStorageKeys.PROXY_GRANTING_TICKET]: string;
    readonly [LocalStorageKeys.RETRY_TIMESTAMP]: Date;

    readonly [LocalStorageKeys.LAST_SHOPS_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_USERS_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_PARTS_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_FEATURES_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_STATIONS_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_ESTIMATES_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_COMPANIES_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_JOB_CODES_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_REPAIR_SIZES_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_WORK_ORDERS_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_UNITS_TYPES_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_UNITS_STATUS_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_WHY_MADE_CODES_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_COMPANIES_TYPES_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_CONDITION_CODES_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_EXCEPTION_REASONS_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_UNIT_LOCATION_CODES_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_MESSAGES_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_FORMS_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_UNIT_GROUPS_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_PRICING_UPDATE_TIME]: Date;
    readonly [LocalStorageKeys.LAST_SHOPS_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_USERS_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_PARTS_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_FEATURES_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_STATIONS_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_ESTIMATES_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_COMPANIES_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_JOB_CODES_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_REPAIR_SIZES_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_WORK_ORDERS_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_UNITS_TYPES_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_UNITS_STATUS_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_WHY_MADE_CODES_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_COMPANIES_TYPES_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_CONDITION_CODES_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_EXCEPTION_REASONS_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_UNIT_LOCATION_CODES_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_MESSAGES_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_FORMS_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_ACCESS_CONTROL_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_UNIT_GROUPS_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.LAST_PRICING_REFRESH_TIME]: Date;
    readonly [LocalStorageKeys.RESET_SEARCH_FILTER]: Boolean;
    readonly [LocalStorageKeys.LAST_SELECTED_SHOP]: number;
    readonly [LocalStorageKeys.READ_MESSAGES]: number[];
    readonly [LocalStorageKeys.APPLICATION_MODE]: ApplicationMode;
    readonly [LocalStorageKeys.OFFLINE_MODE_START_DATE]: Date;
}

class LocalStorage {
    get<T extends LocalStorageKeys, R extends KeyValueMap[T]>(key: T): R | null {
        const value = localStorage.getItem(key);

        return value ? LocalStorage.parseValue(key, value) : null;
    }

    set<T extends LocalStorageKeys, R extends KeyValueMap[T]>(key: T, value: R) {
        localStorage.setItem(key, LocalStorage.stringifyValue(key, value));
    }

    remove<T extends LocalStorageKeys>(key: T) {
        localStorage.removeItem(key);
    }

    private static stringifyValue<T extends LocalStorageKeys, R extends KeyValueMap[T]>(key: T, value: R): string {
        switch (key) {
            case LocalStorageKeys.ACCESS_CONTROL:
            case LocalStorageKeys.READ_MESSAGES:
            case LocalStorageKeys.SELECTED_USER:
                return JSON.stringify(value);
            case LocalStorageKeys.LAST_SHOPS_UPDATE_TIME:
            case LocalStorageKeys.LAST_USERS_UPDATE_TIME:
            case LocalStorageKeys.LAST_PARTS_UPDATE_TIME:
            case LocalStorageKeys.LAST_FEATURES_UPDATE_TIME:
            case LocalStorageKeys.LAST_STATIONS_UPDATE_TIME:
            case LocalStorageKeys.LAST_ESTIMATES_UPDATE_TIME:
            case LocalStorageKeys.LAST_JOB_CODES_UPDATE_TIME:
            case LocalStorageKeys.LAST_COMPANIES_UPDATE_TIME:
            case LocalStorageKeys.LAST_WORK_ORDERS_UPDATE_TIME:
            case LocalStorageKeys.LAST_UNITS_TYPES_UPDATE_TIME:
            case LocalStorageKeys.LAST_UNITS_STATUS_UPDATE_TIME:
            case LocalStorageKeys.LAST_REPAIR_SIZES_UPDATE_TIME:
            case LocalStorageKeys.LAST_CONDITION_CODES_UPDATE_TIME:
            case LocalStorageKeys.LAST_COMPANIES_TYPES_UPDATE_TIME:
            case LocalStorageKeys.LAST_WHY_MADE_CODES_UPDATE_TIME:
            case LocalStorageKeys.LAST_EXCEPTION_REASONS_UPDATE_TIME:
            case LocalStorageKeys.LAST_UNIT_LOCATION_CODES_UPDATE_TIME:
            case LocalStorageKeys.LAST_MESSAGES_UPDATE_TIME:
            case LocalStorageKeys.LAST_FORMS_UPDATE_TIME:
            case LocalStorageKeys.LAST_UNIT_GROUPS_UPDATE_TIME:
            case LocalStorageKeys.LAST_PRICING_UPDATE_TIME:
            case LocalStorageKeys.LAST_SHOPS_REFRESH_TIME:
            case LocalStorageKeys.LAST_USERS_REFRESH_TIME:
            case LocalStorageKeys.LAST_PARTS_REFRESH_TIME:
            case LocalStorageKeys.LAST_FEATURES_REFRESH_TIME:
            case LocalStorageKeys.LAST_STATIONS_REFRESH_TIME:
            case LocalStorageKeys.LAST_ESTIMATES_REFRESH_TIME:
            case LocalStorageKeys.LAST_JOB_CODES_REFRESH_TIME:
            case LocalStorageKeys.LAST_COMPANIES_REFRESH_TIME:
            case LocalStorageKeys.LAST_WORK_ORDERS_REFRESH_TIME:
            case LocalStorageKeys.LAST_UNITS_TYPES_REFRESH_TIME:
            case LocalStorageKeys.LAST_UNITS_STATUS_REFRESH_TIME:
            case LocalStorageKeys.LAST_REPAIR_SIZES_REFRESH_TIME:
            case LocalStorageKeys.LAST_CONDITION_CODES_REFRESH_TIME:
            case LocalStorageKeys.LAST_COMPANIES_TYPES_REFRESH_TIME:
            case LocalStorageKeys.LAST_WHY_MADE_CODES_REFRESH_TIME:
            case LocalStorageKeys.LAST_EXCEPTION_REASONS_REFRESH_TIME:
            case LocalStorageKeys.LAST_UNIT_LOCATION_CODES_REFRESH_TIME:
            case LocalStorageKeys.LAST_MESSAGES_REFRESH_TIME:
            case LocalStorageKeys.LAST_FORMS_REFRESH_TIME:
            case LocalStorageKeys.LAST_ACCESS_CONTROL_REFRESH_TIME:
            case LocalStorageKeys.LAST_UNIT_GROUPS_REFRESH_TIME:
            case LocalStorageKeys.LAST_PRICING_REFRESH_TIME:
            case LocalStorageKeys.OFFLINE_MODE_START_DATE:
            default:
                return value.toString();
        }
    }

    private static parseValue<T extends LocalStorageKeys, R extends KeyValueMap[T]>(key: LocalStorageKeys, value: string): R | null {
        switch (key) {
            case LocalStorageKeys.ACCESS_CONTROL:
            case LocalStorageKeys.READ_MESSAGES:
            case LocalStorageKeys.SELECTED_USER:
                return JSON.parse(value);
            case LocalStorageKeys.LAST_SHOPS_UPDATE_TIME:
            case LocalStorageKeys.LAST_USERS_UPDATE_TIME:
            case LocalStorageKeys.LAST_PARTS_UPDATE_TIME:
            case LocalStorageKeys.LAST_FEATURES_UPDATE_TIME:
            case LocalStorageKeys.LAST_STATIONS_UPDATE_TIME:
            case LocalStorageKeys.LAST_ESTIMATES_UPDATE_TIME:
            case LocalStorageKeys.LAST_COMPANIES_UPDATE_TIME:
            case LocalStorageKeys.LAST_JOB_CODES_UPDATE_TIME:
            case LocalStorageKeys.LAST_WORK_ORDERS_UPDATE_TIME:
            case LocalStorageKeys.LAST_UNITS_TYPES_UPDATE_TIME:
            case LocalStorageKeys.LAST_UNITS_STATUS_UPDATE_TIME:
            case LocalStorageKeys.LAST_REPAIR_SIZES_UPDATE_TIME:
            case LocalStorageKeys.LAST_COMPANIES_TYPES_UPDATE_TIME:
            case LocalStorageKeys.LAST_WHY_MADE_CODES_UPDATE_TIME:
            case LocalStorageKeys.LAST_CONDITION_CODES_UPDATE_TIME:
            case LocalStorageKeys.LAST_EXCEPTION_REASONS_UPDATE_TIME:
            case LocalStorageKeys.LAST_UNIT_LOCATION_CODES_UPDATE_TIME:
            case LocalStorageKeys.LAST_MESSAGES_UPDATE_TIME:
            case LocalStorageKeys.LAST_FORMS_UPDATE_TIME:
            case LocalStorageKeys.LAST_UNIT_GROUPS_UPDATE_TIME:
            case LocalStorageKeys.LAST_PRICING_UPDATE_TIME:
            case LocalStorageKeys.LAST_SHOPS_REFRESH_TIME:
            case LocalStorageKeys.LAST_USERS_REFRESH_TIME:
            case LocalStorageKeys.LAST_PARTS_REFRESH_TIME:
            case LocalStorageKeys.LAST_FEATURES_REFRESH_TIME:
            case LocalStorageKeys.LAST_STATIONS_REFRESH_TIME:
            case LocalStorageKeys.LAST_ESTIMATES_REFRESH_TIME:
            case LocalStorageKeys.LAST_COMPANIES_REFRESH_TIME:
            case LocalStorageKeys.LAST_JOB_CODES_REFRESH_TIME:
            case LocalStorageKeys.LAST_UNITS_TYPES_REFRESH_TIME:
            case LocalStorageKeys.LAST_UNITS_STATUS_REFRESH_TIME:
            case LocalStorageKeys.LAST_REPAIR_SIZES_REFRESH_TIME:
            case LocalStorageKeys.LAST_COMPANIES_TYPES_REFRESH_TIME:
            case LocalStorageKeys.LAST_WHY_MADE_CODES_REFRESH_TIME:
            case LocalStorageKeys.LAST_CONDITION_CODES_REFRESH_TIME:
            case LocalStorageKeys.LAST_EXCEPTION_REASONS_REFRESH_TIME:
            case LocalStorageKeys.LAST_UNIT_LOCATION_CODES_REFRESH_TIME:
            case LocalStorageKeys.LAST_MESSAGES_REFRESH_TIME:
            case LocalStorageKeys.LAST_ACCESS_CONTROL_REFRESH_TIME:
            case LocalStorageKeys.LAST_UNIT_GROUPS_REFRESH_TIME:
            case LocalStorageKeys.LAST_PRICING_REFRESH_TIME:
            case LocalStorageKeys.RETRY_TIMESTAMP:
            case LocalStorageKeys.OFFLINE_MODE_START_DATE:
                return LocalStorage.parseDate(key, value) as R;
            case LocalStorageKeys.REDIRECT_URI:
            case LocalStorageKeys.PROXY_GRANTING_TICKET:
                return value as R;
            case LocalStorageKeys.SELECTED_SHOP_ID:
            case LocalStorageKeys.SELECTED_COMPANY_ID:
                return LocalStorage.parseNumber(key, value) as R;
            case LocalStorageKeys.RESET_SEARCH_FILTER:
            case LocalStorageKeys.LAST_SELECTED_SHOP:
                return LocalStorage.parseNumber(key, value) as R;
            case LocalStorageKeys.APPLICATION_MODE:
                return LocalStorage.parseApplicationMode(value) as R;
            default:
                return null;
        }
    }

    private static parseDate(key: LocalStorageKeys, value: string) {
        const date = new Date(value);

        if (!isNaN(date.getTime())) {
            return date;
        }

        localStorage.removeItem(key);

        return null;
    }

    private static parseNumber(key: LocalStorageKeys, value: string) {
        const parsedValue = parseFloat(value);

        if (!isNaN(parsedValue)) {
            return parsedValue;
        }

        localStorage.removeItem(key);

        return null;
    }

    private static parseApplicationMode(value: string) {
        if (value !== 'online' && value !== 'offline') {
            return null;
        }

        return value;
    }
}

export default new LocalStorage();
