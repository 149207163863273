import styled from 'styled-components';

export const HistoryItemContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;

    &:after {
        content: '';
        background: rgba(0, 0, 0, 0.35);
        position: absolute;
        bottom: 0;
        left: 2.5%;
        height: 1px;
        width: 95%;
    }
`;

export const HistoryItemText = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const StyledHistoryItemText = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 10px;
`;
