import React, {ReactElement} from 'react';
import {useSelector} from 'react-redux';

import config from '../../config';
import {selectIsOfflineStatus} from '../../redux/application/application.selectors';

import {PrivateRoutesOfflineMode} from './private-routes-offline-mode';
import {PrivateRoutesOnlineMode} from './private-routes-online-mode';

export type PrivateRoutesProps = {
    children: ReactElement;
};

const {noAuth} = config;

export const PrivateRoutes = ({children}: PrivateRoutesProps): ReactElement => {
    const isOfflineMode = useSelector(selectIsOfflineStatus);

    if (noAuth || isOfflineMode) {
        return <PrivateRoutesOfflineMode>{children}</PrivateRoutesOfflineMode>;
    } else {
        return <PrivateRoutesOnlineMode>{children}</PrivateRoutesOnlineMode>;
    }
};
