import React, {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import OfflineCommentsGridListComponent from '../../components/comments-grid-list/offline-comments-grid-list.component';
import DetailsTitle from '../../components/details-title/details-title.component';
import OfflineEstimateLineCommentsList from '../../components/repair-line-comments-list/offline-repair-line-comments-list.component';
import {useHeader} from '../../hooks/use-header';
import {useQueryParams} from '../../hooks/use-query-params';
import {OfflineEstimateDataContext} from '../../providers/offline-estimate-data-provider';

export type OfflineEstimateCommentsProps = {
    backToPath?: string;
};

const OfflineEstimateComments = ({backToPath}: OfflineEstimateCommentsProps) => {
    const {push} = useHistory();
    const queryParams = useQueryParams();
    const {offlineEstimate} = useContext(OfflineEstimateDataContext);

    const onHeaderClick = useCallback(() => {
        push(`${backToPath}/${offlineEstimate.ID}/header`, {menuPrevPath: location.pathname});
    }, [backToPath, offlineEstimate, push]);

    const TitleTop = useCallback(() => <DetailsTitle onClick={onHeaderClick} unitINO={offlineEstimate?.UnitINO} />, [onHeaderClick, offlineEstimate]);

    useHeader({
        backToPath: `${backToPath}/${offlineEstimate.ID}`,
        middleElement: TitleTop,
    });

    const offlineEstimateLineID = +(queryParams.get('lineID') || '');
    const scrollToRepairLineID = location.hash ? +location.hash.replace('#', '') : null;

    const filteredRepairLines = useMemo(() => {
        return offlineEstimateLineID ? offlineEstimate.RepairLines.filter(({ID}) => ID === offlineEstimateLineID) : offlineEstimate.RepairLines;
    }, [offlineEstimateLineID, offlineEstimate]);

    return (
        <>
            <OfflineCommentsGridListComponent payload={offlineEstimate.Comments} title="Offline Estimate comments" />
            <OfflineEstimateLineCommentsList
                offlineEstimateLines={filteredRepairLines}
                scrollToRepairLineID={scrollToRepairLineID}
                title="Offline Estimate line comments"
            />
        </>
    );
};

export default OfflineEstimateComments;
