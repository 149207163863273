import {differenceInMilliseconds} from 'date-fns';
import {unionBy} from 'lodash';
import {takeLeading, call, put, select} from 'redux-saga/effects';

import config from '../../config';
import {ReduxAction} from '../../models/ReduxAction';
import {WhyMadeCode} from '../../models/WhyMadeCode';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {formatDate} from '../../utils/formatDate';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {selectIsOfflineStatus} from '../application/application.selectors';

import {whyMadeCodeRequest, whyMadeCodeError, whyMadeCodesResponse} from './why-made-code.actions';
import {FETCH_WHY_MADE_CODES} from './why-made-code.types';

const {resourceCacheFallback} = config;

export function* fetchWhyMadeCodes() {
    const isOfflineMode: boolean = yield select(selectIsOfflineStatus);

    let cachedWhyMadeCodes: WhyMadeCode[] = [];

    if (isOfflineMode) {
        cachedWhyMadeCodes = yield call(IndexedDB.getMany, IndexedDBKeys.WHY_MADE_CODES);

        yield put(whyMadeCodesResponse(cachedWhyMadeCodes));

        return;
    }

    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_WHY_MADE_CODES_REFRESH_TIME, LocalStorageKeys.LAST_WHY_MADE_CODES_UPDATE_TIME);

    if (lastUpdateTime) {
        cachedWhyMadeCodes = yield call(IndexedDB.getMany, IndexedDBKeys.WHY_MADE_CODES);

        if (differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
            yield put(whyMadeCodesResponse(cachedWhyMadeCodes));

            return;
        }
    }

    yield put(whyMadeCodeRequest());

    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_WHY_MADE_CODES_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<WhyMadeCode[]> = yield call(request, {
        url: '/whymadecodes',
        method: 'get',
        params: lastUpdateTime
            ? {
                  includeDeleted: true,
                  lastUpdateTime: formatDate({
                      date: lastUpdateTime,
                      ISOFormat: true,
                  }),
              }
            : undefined,
    });

    if (error) {
        yield put(whyMadeCodeError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedWhyMadeCodes) {
            yield put(whyMadeCodesResponse(cachedWhyMadeCodes));
        }
    }

    if (data) {
        const combinedWhyMadeCodes = unionBy(data, cachedWhyMadeCodes, 'ID').filter((whyMadeCode) => !whyMadeCode.IsDeleted);

        yield put(whyMadeCodesResponse(combinedWhyMadeCodes));

        yield call(IndexedDB.createMany, IndexedDBKeys.WHY_MADE_CODES, combinedWhyMadeCodes);
        LocalStorage.set(LocalStorageKeys.LAST_WHY_MADE_CODES_UPDATE_TIME, requestTime);
    }
}

export default function* whyMadeCodesRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_WHY_MADE_CODES, fetchWhyMadeCodes);
}
