import React, {ReactElement, useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, useLocation} from 'react-router-dom';

import {AuthContext, IAuthResult, IAuthState} from '../../providers/auth-provider';
import {updateUser} from '../../redux/user/user.actions';
import {selectUser, selectUserState} from '../../redux/user/user.selectors';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import {ErrorLike} from '../../utils/errorLike';
import {Fallback} from '../fallback/fallback.component';
import FullPageFallback from '../full-page-fallback/full-page-fallback.component';
import InitialAuthFailure from '../initial-auth-failure/initial-auth-failure.component';

export type SelectSelectedUserData = {
    username?: string | null;
};

export type PrivateRoutesProps = {
    children: ReactElement;
};

export const PrivateRoutesOnlineMode = ({children}: PrivateRoutesProps): ReactElement => {
    const {isAuthenticated, startAuthentication, removeUser, getUser} = useContext(AuthContext);
    const [authState, setAuthState] = useState({} as IAuthState);
    const [authResult, setAuthResult] = useState({} as IAuthResult);
    const location = useLocation();
    const selectedUser = useSelector(selectUser);
    const {initialFetching} = useSelector(selectUserState);

    // Fetch user list to map username to the currently logged-in User.
    const dispatch = useDispatch();

    // This gives us an indicator to tell if the component is mounted or not.
    const isMounted = useRef(false);
    useEffect(() => {
        console.debug('privateroutes was mounted');
        isMounted.current = true;
        return () => {
            isMounted.current = false;
            console.debug('privateroutes was unmounted');
        };
    }, []);

    useEffect(() => {
        console.debug('privateroutes initializing');
        // Initialize authentication state on first render.
        // Use the isAuthenticated promise to determine if the user is authenticated.
        isAuthenticated()
            .then((status) => {
                // Update final authentication status of user.
                setAuthState((prevState) => ({...prevState, isAuthenticated: status}));
            })
            .catch(() => {
                // Flag authentication status as completely initialized and ready to use.
                setAuthState((prevState) => ({...prevState, isInitialized: true}));
            });
    }, [isAuthenticated]);

    useEffect(() => {
        console.debug('privateroutes authenticated');
        // Initialize authentication state on first render.
        // Use the isAuthenticated promise to determine if the user is authenticated.
        getUser()
            .then((user) => {
                // Save the current user information in local storage.
                if (user?.profile?.sub && user?.profile?.sub.toUpperCase() !== selectedUser?.UserName?.toUpperCase() && user?.proxy_granting_ticket) {
                    isAuthenticated().then((status) => {
                        if (status) {
                            LocalStorage.set(LocalStorageKeys.PROXY_GRANTING_TICKET, user!.proxy_granting_ticket!);
                            dispatch(updateUser(user!.profile!.sub!, user!.proxy_granting_ticket!));
                        }
                    });
                }
                setAuthState((prevState) => ({...prevState, username: user?.profile?.sub}));
                console.debug('privateroutes find username=%s', user?.profile?.sub);
            })
            .finally(() => {
                // Flag authentication status as completely initialized and ready to use.
                setAuthState((prevState) => ({...prevState, isInitialized: true}));
            });
    }, [getUser]);

    useEffect(() => {
        console.debug('privateroutes authstate=%o', authState);
        if (!authState.isInitialized) {
            // Wait until authentication has been initialized before calling its functions.
            return;
        }

        // If user is not authenticated, launch the authentication procedure.
        if (authState.isAuthenticated === false) {
            // Redirect to the authentication server. Typically, the authentication server will redirect back to the
            // sign-in result page and AuthCallback captures the result when startAuthentication succeeds.
            // Successful authentication cannot be determined until completeAuthentication is finished, which
            // stores session information and contains data that indicates if the user is authenticated.
            // If the authentication procedure is started, we won't return here until it's been completed.
            // WARNING! the promise rejection must be caught in a catch clause or block;
            // otherwise, the error will abort the application.
            startAuthentication().catch((e: unknown) => {
                const result: IAuthResult = {authError: ErrorLike.Copy(e)};
                // Remove session information and trigger user notification if processing is incomplete.
                removeUser();
                console.debug('privateroutes find username=%s', null);
                setAuthResult(result);
            });
        }
    }, [authState.isInitialized, authState.isAuthenticated, startAuthentication]);

    // If authentication failed, notify the user.
    if (authResult.authError) {
        // Redirect to AuthSignInError and save the current location to come back to. Our state object containing the
        // current location and authentication result are passed in to.state but ends up in props.location.state.
        console.debug('privateroutes redirect to sessionerror');
        return <Redirect to={{pathname: '/session-error', state: {authResult: authResult, fromUrl: location}}} push={true} />;
    }

    if (!authState.isInitialized) {
        console.debug('privateroutes not initialized');
        // Hide private routes until authentication is finished and the user object is stored.
        return <Fallback />;
    }

    if (!selectedUser && initialFetching) {
        return <FullPageFallback />;
    }

    if (!selectedUser && authState.isInitialized) {
        // If server unavailable and user not found.
        return <InitialAuthFailure />;
    }

    console.debug('isInitialized=%d, isAuthenticated=%d, selectedUser=%o', authState.isInitialized, authState.isAuthenticated, selectedUser);
    console.debug('privateroutes render children');
    return children;
};
