import {takeLatest, call, put, select} from 'redux-saga/effects';

import {ReduxAction} from '../../models/ReduxAction';
import {RejectionReason} from '../../models/RejectionReason';
import {WorkOrderRepairLine} from '../../models/WorkOrderRepairLine';
import {AddDeclinationReasonDto} from '../../models/dtos/add-declination-reason.dto';
import {CreateRepairLineDto} from '../../models/dtos/create-repair-line.dto';
import {RemoveDeclinationReasonDto} from '../../models/dtos/remove-declination-reason.dto';
import {UpdateRepairLineDto} from '../../models/dtos/update-repair-line.dto';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import request, {ApiResponse} from '../../services/request';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {fetchWorkOrder} from '../work-order/work-order.actions';
import {WorkOrderState} from '../work-order/work-order.reducer';
import {selectWorkOrderState} from '../work-order/work-order.selectors';

import {
    repairLineError,
    repairLineRequest,
    repairLinesResponse,
    createRepairLineResponse,
    deleteRepairLineResponse,
    updateRepairLineResponse,
    removeRepairLineDeclinationReasonResponse,
    fetchRepairLines,
    addRepairLineDeclinationReasonResponse,
} from './repair-line.actions';
import {
    UPDATE_REPAIR_LINE,
    CREATE_REPAIR_LINE,
    DELETE_REPAIR_LINE,
    FETCH_REPAIR_LINES,
    ADD_REPAIR_LINE_DECLINATION_REASON,
    REMOVE_REPAIR_LINE_DECLINATION_REASON,
} from './repair-line.types';

export function* fetchRepairLinesSaga({payload}: ReduxAction<{workOrderID: number}>) {
    yield put(repairLineRequest());

    const {data, error}: ApiResponse<WorkOrderRepairLine[]> = yield call(request, {
        url: '/repairlines',
        method: 'get',
        params: {
            workOrderID: payload.workOrderID,
        },
    });

    if (error) {
        yield put(repairLineError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield put(repairLinesResponse(payload.workOrderID, data));
    }
}

function* createRepairLine({payload}: ReduxAction<CreateRepairLineDto>) {
    yield put(repairLineRequest());

    const {data, error}: ApiResponse<WorkOrderRepairLine> = yield call(request, {
        data: payload.repairLine,
        url: '/repairlines',
        method: 'post',
        params: {
            workOrderID: payload.workOrderID,
        },
    });

    if (error) {
        yield put(repairLineError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield put(createRepairLineResponse(payload.workOrderID, data));
        yield put(fetchWorkOrder({workOrderID: payload.workOrderID}));
    }
}

function* updateRepairLine({payload}: ReduxAction<UpdateRepairLineDto>) {
    yield put(repairLineRequest());

    const {data, error}: ApiResponse<WorkOrderRepairLine> = yield call(request, {
        url: `/repairlines/${payload.repairLineID}`,
        method: 'put',
        data: payload.repairLine,
    });

    if (error) {
        yield put(repairLineError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield put(updateRepairLineResponse(payload.workOrderID, payload.repairLineID, data));
        yield put(fetchWorkOrder({workOrderID: payload.workOrderID}));
    }
}

function* deleteRepairLine({payload}: ReduxAction<{repairLineID: number; workOrderID: number}>) {
    yield put(repairLineRequest());

    const {error}: ApiResponse = yield call(request, {
        url: `/repairlines/${payload.repairLineID}`,
        method: 'delete',
    });

    if (error) {
        yield put(repairLineError(error.message));
        yield showErrorMessages(error.messages);
    } else {
        yield put(deleteRepairLineResponse(payload.workOrderID, payload.repairLineID));
        yield put(fetchWorkOrder({workOrderID: payload.workOrderID}));
    }
}

function* addRepairLineDeclinationReason({payload}: ReduxAction<AddDeclinationReasonDto>) {
    yield put(repairLineRequest());

    const {error}: ApiResponse<RejectionReason> = yield call(request, {
        url: '/exceptionreasons',
        method: 'post',
        data: payload.rejectionReason,
        params: {
            associatedObjectID: payload.ID,
            associatedTypeID: AssociatedTypes.WorkOrderRepairLine,
        },
    });

    if (error) {
        yield put(repairLineError(error.message));
        yield showErrorMessages(error.messages);
    } else {
        yield put(addRepairLineDeclinationReasonResponse());

        const {workOrders}: WorkOrderState = yield select(selectWorkOrderState);
        const workOrder = workOrders.find(({WorkOrderRepairLines}) => WorkOrderRepairLines.some(({ID}) => ID === payload.ID));

        if (workOrder) {
            yield put(fetchWorkOrder({workOrderID: workOrder.ID}));
            yield put(fetchRepairLines(workOrder.ID));
        }
    }
}

function* removeRepairLineDeclinationReason({payload}: ReduxAction<RemoveDeclinationReasonDto>) {
    yield put(repairLineRequest());

    const {error}: ApiResponse<RejectionReason> = yield call(request, {
        url: `/exceptionreasons/${payload.errorMessageID}/${AssociatedTypes.WorkOrderRepairLine}`,
        method: 'delete',
    });

    if (error) {
        yield put(repairLineError(error.message));
        yield showErrorMessages(error.messages);
    } else {
        yield put(removeRepairLineDeclinationReasonResponse(payload));
    }
}

export default function* repairLineRootSaga() {
    yield takeLatest<ReduxAction>(FETCH_REPAIR_LINES, fetchRepairLinesSaga);
    yield takeLatest<ReduxAction>(UPDATE_REPAIR_LINE, updateRepairLine);
    yield takeLatest<ReduxAction>(DELETE_REPAIR_LINE, deleteRepairLine);
    yield takeLatest<ReduxAction>(CREATE_REPAIR_LINE, createRepairLine);
    yield takeLatest<ReduxAction>(ADD_REPAIR_LINE_DECLINATION_REASON, addRepairLineDeclinationReason);
    yield takeLatest<ReduxAction>(REMOVE_REPAIR_LINE_DECLINATION_REASON, removeRepairLineDeclinationReason);
}
