import {ReduxAction} from '../../models/ReduxAction';
import {UnitLocationCode} from '../../models/UnitLocationCode';

import {UNIT_LOCATION_CODE_ERROR, UNIT_LOCATION_CODE_REQUEST, UNIT_LOCATION_CODES_RESPONSE} from './user-location-code.types';

export interface UnitLocationCodesState {
    error: Error | null;
    loading: boolean;
    unitLocationCodes: UnitLocationCode[];
}

const initialState: UnitLocationCodesState = {
    error: null,
    loading: false,
    unitLocationCodes: [],
};

export default function unitLocationCodesReducer(state = initialState, action: ReduxAction): UnitLocationCodesState {
    switch (action.type) {
        case UNIT_LOCATION_CODE_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case UNIT_LOCATION_CODES_RESPONSE:
            return {
                ...state,
                loading: false,
                unitLocationCodes: action.payload,
            };
        case UNIT_LOCATION_CODE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
