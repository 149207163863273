export const WORK_ORDER_NDF = 'WORK_ORDER_NDF';
export const FETCH_WORK_ORDER = 'FETCH_WORK_ORDER';
export const FETCH_WORK_ORDERS = 'FETCH_WORK_ORDERS';
export const UPDATE_WORK_ORDER = 'UPDATE_WORK_ORDER';
export const DELETE_WORK_ORDER = 'DELETE_WORK_ORDER';
export const WORK_ORDERS_REQUEST = 'WORK_ORDERS_REQUEST';
export const WORK_ORDER_REQUEST = 'WORK_ORDER_REQUEST';
export const WORK_ORDER_RESPONSE = 'WORK_ORDER_RESPONSE';
export const WORK_ORDERS_RESPONSE = 'WORK_ORDERS_RESPONSE';
export const WORK_ORDERS_ERROR = 'WORK_ORDERS_ERROR';
export const CREATE_WORK_ORDER = 'CREATE_WORK_ORDER';
export const REJECTION_WORK_ORDER = 'REJECTION_WORK_ORDER';
export const WORK_ORDER_COMPLETIONS = 'WORK_ORDER_COMPLETIONS';
export const CREATE_WORK_ORDER_RESPONSE = 'CREATE_WORK_ORDER_RESPONSE';
export const WORK_ORDER_ASSIGNMENTS = 'WORK_ORDER_ASSIGNMENTS';
export const UPDATE_STATUS_WORK_ORDER = 'UPDATE_STATUS_WORK_ORDER';
export const UPDATE_WORK_ORDER_RESPONSE = 'UPDATE_WORK_ORDER_RESPONSE';
export const DELETE_WORK_ORDER_RESPONSE = 'DELETE_WORK_ORDER_RESPONSE';
export const ADD_WORK_ORDER_DECLINATION_REASON = 'ADD_WORK_ORDER_DECLINATION_REASON';
export const ADD_WORK_ORDER_DECLINATION_REASON_RESPONSE = 'ADD_WORK_ORDER_DECLINATION_REASON_RESPONSE';
export const REMOVE_WORK_ORDER_DECLINATION_REASON = 'REMOVE_WORK_ORDER_DECLINATION_REASON';
export const REMOVE_WORK_ORDER_DECLINATION_REASON_RESPONSE = 'REMOVE_WORK_ORDER_DECLINATION_REASON_RESPONSE';
export const REASSIGN_WORK_ORDER_SHOP = 'REASSIGN_WORK_ORDER_SHOP';
export const UNSELECT_WORK_ORDER = 'UNSELECT_WORK_ORDER';
