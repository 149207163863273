import TextField from '@mui/material/TextField';
import {WidgetProps} from '@rjsf/utils';
import React, {useEffect} from 'react';

import {WorkOrderRepairLine} from '../../models/WorkOrderRepairLine';

// The copy of https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/material-ui/src/UpDownWidget/UpDownWidget.tsx
export const StraightTimeDisplay = ({label, value, onChange, options, formContext}: WidgetProps) => {
    const {LaborHours, Overtime1, Overtime2, Overtime3}: Partial<WorkOrderRepairLine> = formContext?.formData ?? {};
    value = (LaborHours ?? 0) - (Overtime1 ?? 0) - (Overtime2 ?? 0) - (Overtime3 ?? 0);
    useEffect(() => {
        const total = (LaborHours ?? 0) - (Overtime1 ?? 0) - (Overtime2 ?? 0) - (Overtime3 ?? 0);
        setTimeout(() => {
            onChange(total);
        }, 100); // Fix miss calculation, probably caused by firing same event on another component
    }, [Overtime1, Overtime2, Overtime3, onChange, LaborHours]);

    return (
        <TextField
            id="straight-time-number"
            defaultValue={options.default}
            label={label}
            type="number"
            value={(value ?? 0).toFixed(2)}
            variant="standard"
            InputProps={{readOnly: true, disableUnderline: true}}
            disabled={true}
        />
    );
};
