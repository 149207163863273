import {call, put, select, takeLeading} from 'redux-saga/effects';

import config from '../../config';
import {AccessControl} from '../../models/AccessControl';
import {ReduxAction} from '../../models/ReduxAction';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {selectIsOfflineStatus} from '../application/application.selectors';

import {accessControlError, accessControlRequest, accessControlResponse} from './access-control.actions';
import {FETCH_ACCESS_CONTROL} from './access-control.types';

const {resourceCacheFallback} = config;

export function* fetchAccessControl() {
    const isOfflineMode: boolean = yield select(selectIsOfflineStatus);

    if (isOfflineMode) {
        const cachedAccessControl = LocalStorage.get(LocalStorageKeys.ACCESS_CONTROL);
        yield put(accessControlResponse(cachedAccessControl));
        return;
    }

    const lastRefresh = Number(LocalStorage.get(LocalStorageKeys.LAST_ACCESS_CONTROL_REFRESH_TIME));

    if (Date.now() < lastRefresh + resourceCacheFallback) {
        const cachedAccessControl = LocalStorage.get(LocalStorageKeys.ACCESS_CONTROL);
        yield put(accessControlResponse(cachedAccessControl));
        return;
    }

    yield put(accessControlRequest());

    const requestTime = new Date();
    const {data, error}: ApiResponse<AccessControl> = yield call(request, {
        url: '/accesscontrol',
        method: 'get',
    });

    if (error) {
        yield put(accessControlError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield put(accessControlResponse(data));
        LocalStorage.set(LocalStorageKeys.ACCESS_CONTROL, data);
        LocalStorage.set(LocalStorageKeys.LAST_ACCESS_CONTROL_REFRESH_TIME, requestTime);
    }
}

export default function* accessControlRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_ACCESS_CONTROL, fetchAccessControl);
}
