export const EquipmentGroups = {
    Unknown: {
        ID: 0,
        Description: 'Unknown',
    },
    MarineChassis: {
        ID: 1,
        Description: 'Marine Chassis',
    },
    ChassisTransporter: {
        ID: 2,
        Description: 'Chassis Transporter',
    },
    Gensets: {
        ID: 3,
        Description: 'Gensets',
    },
    DomesticChassis: {
        ID: 4,
        Description: 'Domestic Chassis',
    },
} as const;
