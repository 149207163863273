import React, {Component, ReactElement} from 'react';

import {Header} from '../components/header/header.component';

import {MainHeader, MainWrapper} from './header-provider.style';

export interface IHeaderContext {
    backToPath: string | React.Dispatch<React.SetStateAction<boolean>> | (() => void) | null;
    middleElement: (() => ReactElement | ReactElement[] | null) | null;
    updateHeaderData: (data: HeaderProviderState) => void;
    setStateParam?: boolean | null;
    basePath?: string | null;
}

export const HeaderContext = React.createContext<IHeaderContext>({
    backToPath: null,
    middleElement: null,
    updateHeaderData: () => {},
    setStateParam: null,
    basePath: null,
});

export type HeaderProviderProps = {
    children: ReactElement | (ReactElement | null)[];
};

export type HeaderProviderState = {
    backToPath: string | React.Dispatch<React.SetStateAction<boolean>> | (() => void) | null;
    visible?: boolean;
    previousScrollPosition?: number;
    middleElement: (() => ReactElement | ReactElement[] | null) | null;
    setStateParam?: boolean | null;
    basePath?: string | null;
};

export class HeaderProvider extends Component<HeaderProviderProps, HeaderProviderState> {
    constructor(props: HeaderProviderProps) {
        super(props);

        this.handleScroll = this.handleScroll.bind(this);

        this.state = {
            backToPath: null,
            middleElement: null,
            visible: true,
            previousScrollPosition: 0,
            setStateParam: null,
            basePath: null,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', () => requestAnimationFrame(this.handleScroll));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const {visible} = this.state;
        // Make the header visible if the user just scrolled up or they are near the top.
        // If the full height of the page is only a little bigger than the window viewport, don't mess with the header.
        const scrollPosition = window.pageYOffset;
        if (document.body.offsetHeight - window.innerHeight > 200 || !visible) {
            this.setState((prevState) => ({
                ...prevState,
                visible:
                    document.body.offsetHeight - window.innerHeight < 200 ||
                    (prevState.previousScrollPosition ?? 0) > scrollPosition ||
                    scrollPosition < 75,
                previousScrollPosition: scrollPosition,
            }));
        }
    };

    updateHeaderData = ({backToPath, middleElement, setStateParam, basePath}: HeaderProviderState) => {
        this.setState({
            backToPath,
            middleElement,
            setStateParam,
            basePath,
        });
    };

    getProviderValues = (): IHeaderContext => {
        const {backToPath, middleElement, setStateParam, basePath} = this.state;

        return {
            backToPath,
            middleElement,
            updateHeaderData: this.updateHeaderData,
            setStateParam,
            basePath,
        };
    };

    render() {
        const {visible} = this.state;
        const {children} = this.props;

        return (
            <HeaderContext.Provider value={this.getProviderValues()}>
                <MainHeader style={{top: visible ? '0' : '-65px'}}>
                    <Header />
                </MainHeader>
                <MainWrapper>{children}</MainWrapper>
            </HeaderContext.Provider>
        );
    }
}
