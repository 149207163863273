export const ElectiveInspectionTypes = {
    PreRepair: {
        ID: 1,
        Description: 'pre_repair',
    },
    PostRepair: {
        ID: 2,
        Description: 'post_repair',
    },
    PreTrip: {
        ID: 3,
        Description: 'pre_trip',
    },
    PreRepairRoadability: {
        ID: 4,
        Description: 'pre_repair_roadability',
    },
    PostRepairRoadability: {
        ID: 5,
        Description: 'post_repair_roadability',
    },
} as const;
