import TextField from '@mui/material/TextField';
import {WidgetProps} from '@rjsf/utils';
import {t} from 'i18next';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {WorkOrderRepairLine} from '../../models/WorkOrderRepairLine';
import {pricingError} from '../../redux/pricing/pricing.actions';
import {selectRepairLinePricing} from '../../redux/pricing/pricing.selectors';

// The copy of https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/material-ui/src/UpDownWidget/UpDownWidget.tsx
export const LaborHoursInput = ({label, value, onChange, autofocus, options, formContext, disabled}: WidgetProps) => {
    const dispatch = useDispatch();
    const pricing = useSelector(selectRepairLinePricing);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const {RepairQuantity, LaborHours, Overtime1, Overtime2, Overtime3}: Partial<WorkOrderRepairLine> = formContext?.formData ?? {};
    const currentSum = (Overtime1 ?? 0) + (Overtime2 ?? 0) + (Overtime3 ?? 0);
    const max = pricing ? pricing.MaxHoursPerRepairLine * (RepairQuantity ?? 1) : 0;

    if (LaborHours !== undefined && currentSum > LaborHours && !error) {
        setError(true);
        setErrorMessage(t('overtime_error'));
        dispatch(pricingError(true));
    } else if ((LaborHours === undefined || currentSum <= LaborHours) && error) {
        setError(false);
        setErrorMessage('');
        dispatch(pricingError(false));
    }

    const inputProps = {
        min: 0,
        step: '.1',
    };

    const _onChange = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        onChange(value);
    };

    const onBlur = ({target: {value}}: React.FocusEvent<HTMLInputElement>) => {
        // Default the value if the field is empty.
        if (value === null || value === undefined || value === '') {
            onChange(max);
        }

        // Set to min or max if the value is above or below respectively.
        if (value !== null && value !== undefined) {
            if (options?.minimum !== undefined && +value < +options!.minimum!) {
                onChange(options.minimum);
            }
        }
    };

    useEffect(() => {
        if (pricing && LaborHours === null && Overtime1 === null && Overtime2 === null && Overtime3 === null) {
            // Floor the value at 2 decimal places in case rounding/fixing rounds up above the max.
            onChange(((RepairQuantity || 1) * pricing.MaxHoursPerRepairLine).floor());
        }
    }, [pricing, RepairQuantity, Overtime1, Overtime2, Overtime3, onChange, LaborHours]);

    return (
        <TextField
            disabled={disabled}
            id="standard-number"
            defaultValue={options.default}
            label={label}
            type="number"
            value={value ?? ''}
            variant="standard"
            onChange={_onChange}
            onBlur={onBlur}
            autoFocus={autofocus}
            InputProps={{inputProps}}
            error={error}
            helperText={errorMessage}
        />
    );
};
