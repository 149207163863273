export const parsePermissionRuleValue = (value: string) => {
    if (value === 'true') {
        return true;
    }

    if (value === 'false') {
        return false;
    }

    return new Function('entity,user', `return ${value}`);
};
