import {t} from 'i18next';

import {OfflineEstimate} from '../models/OfflineEstimate';
import {ReduxAction} from '../models/ReduxAction';
import {OfflineEstimateSentStatus} from '../models/enumerations/OfflineEstimateSentStatus';
import {dequeueOfflineEstimate, discardOfflineEstimate, queueOfflineEstimates} from '../redux/offline-estimate/offline-estimate.actions';
import {isOfflineEstimateReadOnly} from '../utils/isOfflineEstimateReadOnly';

enum Group {
    Group_1 = 1,
    Group_2 = 2,
}

export type OfflineEstimateAction = {
    label: string;
    group: Group;
    title?: string;
    submitButtonLabel?: string;
    isStatusRejected?: boolean;
    color: 'red' | 'green';
    action?: (data?: unknown) => ReduxAction;
};

const useNextOfflineEstimateActions = (offlineEstimate: OfflineEstimate): OfflineEstimateAction[] => {
    const actions: OfflineEstimateAction[] = [];

    if (offlineEstimate.SentStatus === OfflineEstimateSentStatus.Unsent.ID) {
        actions.push({
            label: t('queue'),
            color: 'green',
            group: Group.Group_1,
            action: queueOfflineEstimates.bind(null, [offlineEstimate.ID]),
        });
    } else if (offlineEstimate.SentStatus === OfflineEstimateSentStatus.Queued.ID) {
        actions.push({
            label: t('dequeue'),
            color: 'red',
            group: Group.Group_2,
            action: dequeueOfflineEstimate.bind(null, offlineEstimate.ID),
        });
    }

    if (!isOfflineEstimateReadOnly(offlineEstimate.SentStatus)) {
        actions.push({
            label: t('discard'),
            color: 'red',
            group: Group.Group_2,
            isStatusRejected: true,
            title: t('are_you_sure_discard_estimate'),
            submitButtonLabel: t('yes'),
            action: discardOfflineEstimate.bind(null, offlineEstimate.ID),
        });
    }

    return actions;
};

export default useNextOfflineEstimateActions;
