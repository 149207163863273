import {ConditionCode} from '../../models/ConditionCode';

import {FETCH_CONDITION_CODES, CONDITION_CODE_ERROR, CONDITION_CODE_REQUEST, CONDITION_CODES_RESPONSE} from './condition-code.types';

export const conditionCodeRequest = () => ({
    type: CONDITION_CODE_REQUEST,
});

export const conditionCodeError = (error: Error | unknown) => ({
    type: CONDITION_CODE_ERROR,
    payload: error,
});

export const conditionCodesResponse = (data: ConditionCode[]) => ({
    type: CONDITION_CODES_RESPONSE,
    payload: data,
});

export const fetchConditionCodes = () => ({
    type: FETCH_CONDITION_CODES,
});
