import {Badge, CircularProgress, Drawer, List, ListItemButton} from '@mui/material';
import {push} from 'connected-react-router';
import {t} from 'i18next';
import React, {ReactElement, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {AuthContext, IAuthState} from '../../providers/auth-provider';
import {selectIsOfflineStatus} from '../../redux/application/application.selectors';
import {selectNewMessagesCount} from '../../redux/message/message.selectors';
import {selectUserState} from '../../redux/user/user.selectors';

import {ProgressContainer, StyledListItemText} from './navigation-menu.styles';

export type NavigationMenuProps = {
    open: boolean;
    onClose: () => void;
};

/**
 * Main application menu.
 *
 * @param {NavigationMenuProps} {open, onClose}
 * @returns {ReactElement}
 */
export const NavigationMenu = ({open, onClose}: NavigationMenuProps): ReactElement => {
    const dispatch = useDispatch();
    const {loading} = useSelector(selectUserState);
    const isOfflineMode = useSelector(selectIsOfflineStatus);
    const {isAuthenticated, logout} = useContext(AuthContext);
    const [authState, setAuthState] = useState({} as IAuthState);

    useEffect(() => {
        // Initialize authentication state on first render.
        // Use the isAuthenticated promise to determine if the user is authenticated.
        isAuthenticated()
            .then((status) => {
                // Update final authentication status of user.
                setAuthState((prevState) => ({...prevState, isAuthenticated: status}));
            })
            .finally(() => {
                // Flag authentication status as completely initialized and ready to use.
                setAuthState((prevState) => ({...prevState, isInitialized: true}));
            });
    }, [isAuthenticated]);

    const onNavItemClick = useCallback(
        (path: string) => () => {
            dispatch(push(path, {menuPrevPath: location.pathname}));
            onClose();
        },
        [dispatch, onClose],
    );
    const messagesCount = useSelector(selectNewMessagesCount);

    return (
        <Drawer anchor="right" open={open} onClose={onClose} PaperProps={{style: {justifyContent: 'space-between', minWidth: '140px'}}}>
            {!isOfflineMode ? (
                <>
                    <List>
                        <ListItemButton onClick={onNavItemClick('/')}>
                            <StyledListItemText primary={t('home')} />
                        </ListItemButton>
                        <ListItemButton onClick={onNavItemClick('/my-account')}>
                            <StyledListItemText primary={t('my_account')} />
                        </ListItemButton>
                        <ListItemButton onClick={onNavItemClick('/approval')}>
                            <StyledListItemText primary={t('approval')} />
                        </ListItemButton>
                        <ListItemButton onClick={onNavItemClick('/messages')}>
                            <StyledListItemText primary={t('messages')} />
                            <Badge overlap="rectangular" badgeContent={messagesCount} max={9} color="error" />
                        </ListItemButton>
                        <ListItemButton onClick={onNavItemClick('/history')}>
                            <StyledListItemText primary={t('history')} />
                        </ListItemButton>
                        <ListItemButton onClick={onNavItemClick('/about')}>
                            <StyledListItemText primary={t('about')} />
                        </ListItemButton>
                    </List>
                    {loading ? (
                        <ProgressContainer>
                            <CircularProgress size={25} color="primary" />
                        </ProgressContainer>
                    ) : (
                        <List>
                            {authState.isAuthenticated && (
                                <ListItemButton onClick={logout}>
                                    <StyledListItemText primary={t('sign_out')} />
                                </ListItemButton>
                            )}
                        </List>
                    )}
                </>
            ) : (
                <>
                    <List>
                        <ListItemButton onClick={onNavItemClick('/')}>
                            <StyledListItemText primary={t('home')} />
                        </ListItemButton>
                        <ListItemButton onClick={onNavItemClick('/my-account')}>
                            <StyledListItemText primary={t('my_account')} />
                        </ListItemButton>
                        <ListItemButton onClick={onNavItemClick('/messages')}>
                            <StyledListItemText primary={t('messages')} />
                            <Badge overlap="rectangular" badgeContent={messagesCount} max={9} color="error" />
                        </ListItemButton>
                        <ListItemButton onClick={onNavItemClick('/about')}>
                            <StyledListItemText primary={t('about')} />
                        </ListItemButton>
                    </List>
                </>
            )}
        </Drawer>
    );
};
