import {ReduxAction} from '../../models/ReduxAction';

import {ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR, NotificationItem} from './notistack.types';

export interface NotistackState {
    notifications: NotificationItem[];
}

const initialState: NotistackState = {
    notifications: [],
};

export default function notistackReducer(state = initialState, action: ReduxAction): NotistackState {
    switch (action.type) {
        case ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [...state.notifications, action.payload],
            };
        case CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map((notification) =>
                    action.payload.dismissAll || notification.key === action.payload.key ? {...notification, dismissed: true} : {...notification},
                ),
            };
        case REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter((notification) => notification.key !== action.payload.key),
            };
        default:
            return state;
    }
}
