import {push} from 'connected-react-router';
import {t} from 'i18next';
import {put, select, takeEvery, takeLatest} from 'redux-saga/effects';

import config from '../../config';
import {ReduxAction} from '../../models/ReduxAction';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import {fetchConditionCodes} from '../condition-code/condition-code.actions';
import {CONDITION_CODE_ERROR, CONDITION_CODES_RESPONSE} from '../condition-code/condition-code.types';
import {getForms} from '../form-validation/form.actions';
import {FORM_ERROR, FORMS_RESPONSE, FormName} from '../form-validation/form.types';
import {fetchJobCodes} from '../job-code/job-code.actions';
import {JOB_CODE_ERROR, JOB_CODES_RESPONSE} from '../job-code/job-code.types';
import {enqueueSnackbar} from '../notistack/notistack.actions';
import {sendQueuedOfflineEstimates} from '../offline-estimate/offline-estimate.actions';
import {fetchParts} from '../part/part.actions';
import {PARTS_ERROR, PARTS_RESPONSE} from '../part/part.types';
import {fetchShopPricing} from '../pricing/pricing.actions';
import {SHOP_PRICING_ERROR, SHOP_PRICING_RESPONSE} from '../pricing/pricing.types';
import {fetchUnitGroups} from '../unit-group/unit-group.actions';
import {UNIT_GROUP_ERROR, UNIT_GROUP_RESPONSE} from '../unit-group/unit-group.types';
import {fetchUnitLocationCodes} from '../unit-location-code/user-location-code.actions';
import {UNIT_LOCATION_CODE_ERROR, UNIT_LOCATION_CODES_RESPONSE} from '../unit-location-code/user-location-code.types';
import {fetchWhyMadeCodes} from '../why-made-code/why-made-code.actions';
import {WHY_MADE_CODE_ERROR, WHY_MADE_CODES_RESPONSE} from '../why-made-code/why-made-code.types';

import {setApplicationMode, setModeLoading} from './application.actions';
import {ApplicationState} from './application.reducer';
import {selectApplicationState, selectGlobalLoading} from './application.selectors';
import {ApplicationMode, CHECK_OFFLINE_DURATION, GO_OFFLINE, GO_ONLINE} from './application.types';

const {offlineReminderInterval} = config;

export function* goOfflineFetchResourcesWatcher() {
    const globalLoading: boolean = yield select(selectGlobalLoading);
    const applicationState: ApplicationState = yield select(selectApplicationState);

    if (applicationState.modeLoading && !globalLoading) {
        yield put(setApplicationMode(ApplicationMode.OFFLINE));
        LocalStorage.set(LocalStorageKeys.APPLICATION_MODE, ApplicationMode.OFFLINE);

        yield put(setModeLoading(false));
        if (`/offline-estimates` !== window.location.pathname) {
            yield put(push(`/offline-estimates`));
        }
    }
}

export function* goOffline() {
    yield put(setModeLoading(true));

    yield put(fetchJobCodes());
    yield put(fetchShopPricing());
    yield put(fetchWhyMadeCodes());
    yield put(fetchConditionCodes());
    yield put(fetchUnitLocationCodes());
    yield put(fetchParts());
    yield put(getForms([FormName.WorkOrderHeader, FormName.RepairLine]));
    yield put(fetchUnitGroups());

    LocalStorage.set(LocalStorageKeys.OFFLINE_MODE_START_DATE, new Date());
}

export function* goOnline({payload}: ReduxAction<{isCancel: boolean}>) {
    if (!payload?.isCancel && `/offline-estimates` !== window.location.pathname) {
        yield put(push(`/offline-estimates`));
    }

    yield put(setModeLoading(true));
    yield put(setApplicationMode(ApplicationMode.ONLINE));
    LocalStorage.set(LocalStorageKeys.APPLICATION_MODE, ApplicationMode.ONLINE);

    yield put(setModeLoading(false));
    yield put(sendQueuedOfflineEstimates());
}

export function* checkOfflineDuration() {
    const startDate = LocalStorage.get(LocalStorageKeys.OFFLINE_MODE_START_DATE);

    if (startDate == null || +startDate < +new Date() - offlineReminderInterval) {
        yield put(
            enqueueSnackbar(t('offline_duration_reminder'), {
                variant: 'error',
                persist: true,
            }),
        );
        LocalStorage.set(LocalStorageKeys.OFFLINE_MODE_START_DATE, new Date());
    }
}

export default function* applicationRootSaga() {
    yield takeLatest<ReduxAction>(GO_OFFLINE, goOffline);
    yield takeLatest<ReduxAction>(GO_ONLINE, goOnline);
    yield takeLatest<ReduxAction>(CHECK_OFFLINE_DURATION, checkOfflineDuration);
    yield takeEvery<ReduxAction>(
        [
            JOB_CODES_RESPONSE,
            JOB_CODE_ERROR,
            WHY_MADE_CODES_RESPONSE,
            WHY_MADE_CODE_ERROR,
            CONDITION_CODES_RESPONSE,
            CONDITION_CODE_ERROR,
            UNIT_LOCATION_CODES_RESPONSE,
            UNIT_LOCATION_CODE_ERROR,
            PARTS_RESPONSE,
            PARTS_ERROR,
            FORMS_RESPONSE,
            FORM_ERROR,
            UNIT_GROUP_ERROR,
            UNIT_GROUP_RESPONSE,
            SHOP_PRICING_ERROR,
            SHOP_PRICING_RESPONSE,
        ],
        goOfflineFetchResourcesWatcher,
    );
}
