import React, {useCallback, useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';

import DetailsTitle from '../../components/details-title/details-title.component';
import {useHeader} from '../../hooks/use-header';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {clearFiles} from '../../redux/file/file.actions';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import {getTotalSpecialInstructionsInfo} from '../../utils/getSpecialInstructionsInfo';

import WorkOrderDetails from './work-order-details.component';

export type WorkOrderDetailsProps = {
    title: string;
    backToPath: string;
};

const WorkOrderDetailsContainer = ({title, backToPath}: WorkOrderDetailsProps) => {
    const {push} = useHistory();
    const dispatch = useDispatch();
    const location = useLocation<{menuPrevPath?: string; basePath?: string}>();
    const {workOrder, repairLines} = useContext(WorkOrderDataContext);

    useEffect(() => {
        LocalStorage.set(LocalStorageKeys.RESET_SEARCH_FILTER, true); // reset search filter
        // Clear file state on unmount so previous files aren't displayed while the new ones are loaded.
        return () => {
            dispatch(clearFiles());
        };
    }, [dispatch]);

    const {UnitDetails: unitDetails, WorkOrderNumber: workOrderNumber, SpecialInstructionAuthorizations} = workOrder;

    const onHeaderClick = useCallback(() => {
        push(`${backToPath}/${workOrder.ID}/header`);
    }, [workOrder.ID, push]);

    const TitleTop = useCallback(
        () => <DetailsTitle onClick={onHeaderClick} unitINO={unitDetails.UnitINO} workOrderNumber={workOrderNumber} />,
        [onHeaderClick, unitDetails, workOrderNumber],
    );

    useHeader({
        backToPath: location.state?.menuPrevPath || backToPath,
        middleElement: TitleTop,
        basePath: location.state?.basePath,
    });

    const partsCount = repairLines.reduce((partialSum, {Parts}) => partialSum + Parts?.length ?? 0, 0);

    const {authorizedSpecialInstructionsCount, unauthorizedSpecialInstructionsCount} = getTotalSpecialInstructionsInfo(
        SpecialInstructionAuthorizations || [],
        repairLines.map((item) => item.SpecialInstructionAuthorizations).flat(),
    );

    return (
        <WorkOrderDetails
            path={backToPath}
            title={title}
            badge={{
                parts: partsCount,
                files: workOrder.FileList.length,
                comments: workOrder.CommentsCount,
                authorizedSpecialInstructions: authorizedSpecialInstructionsCount,
                unauthorizedSpecialInstructions: unauthorizedSpecialInstructionsCount,
            }}
        />
    );
};

export default WorkOrderDetailsContainer;
