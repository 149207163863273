import Dialog from '@mui/material/Dialog';
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Filter,
    Inject,
    Sort,
    GridModel,
    Toolbar,
    DetailRow,
    Edit,
    InfiniteScroll,
    Grid,
} from '@syncfusion/ej2-react-grids';
import {t} from 'i18next';
import React, {useCallback, useContext, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {OfflineEstimateLine} from '../../models/OfflineEstimateLine';
import {OfflineEstimatePart} from '../../models/OfflineEstimatePart';
import {HeaderProvider} from '../../providers/header-provider';
import {OfflineEstimateDataContext} from '../../providers/offline-estimate-data-provider';
import {selectConditionCodes} from '../../redux/condition-code/condition-code.selectors';
import {selectJobCodes} from '../../redux/job-code/job-code.selectors';
import {enqueueSnackbar} from '../../redux/notistack/notistack.actions';
import {createOfflineEstimatePart, deleteOfflineEstimatePart, updateOfflineEstimatePart} from '../../redux/offline-estimate/offline-estimate.actions';
import {selectPartState} from '../../redux/part/part.selectors';
import {selectUnitLocationCodes} from '../../redux/unit-location-code/user-location-code.selectors';
import {selectWhyMadeCodes} from '../../redux/why-made-code/why-made-code.selectors';
import {convertOfflineEstimateLinesToOfflineEstimateLineDataGridDtos} from '../../utils/convertOfflineEstimateLineToOfflineEstimateLineDataGridDto';
import {isOfflineEstimateReadOnly} from '../../utils/isOfflineEstimateReadOnly';
import {BarcodeScanner} from '../barcode-scanner/barcode-scanner.container';
import OfflinePartFormContainer from '../part-form/offline-part-form.container';

import {Title, GridWrapper} from './offline-estimate-parts-grid.styles';

type OfflineEstimatePartsGridProps = {
    repairLines: (OfflineEstimateLine & {repairLineID: number})[];
    parts: (OfflineEstimatePart & {repairLineID: number})[];
};

const OfflineEstimatePartsGrid = ({repairLines = [], parts = []}: OfflineEstimatePartsGridProps) => {
    const dispatch = useDispatch();
    const {offlineEstimate} = useContext(OfflineEstimateDataContext);
    const isReadOnly = isOfflineEstimateReadOnly(offlineEstimate?.SentStatus);
    const [barcodeLoading, setBarcodeLoading] = useState(false);
    const [barcodeScannerRepairLineID, setBarcodeScannerRepairLineID] = useState<number | null>(null);
    const {parts: partsRegistry} = useSelector(selectPartState);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedRepairLineId, setSelectedRepairLineId] = useState<number>();
    const [selectedPartNumber, setSelectedPartNumber] = useState();
    const jobCodes = useSelector(selectJobCodes);
    const whyMadeCodes = useSelector(selectWhyMadeCodes);
    const conditionCodes = useSelector(selectConditionCodes);
    const unitLocationCodes = useSelector(selectUnitLocationCodes);

    const onPartFormClose = useCallback(() => {
        setIsFormOpen(false);
        setSelectedRepairLineId(undefined);
        setSelectedPartNumber(undefined);
    }, []);

    const toolbarOptions = ['Add', {prefixIcon: 'e-custom-icons e-barcode', id: 'Scanner'}, 'Edit', 'Delete'];

    const childGridOptions: GridModel = useMemo(
        () => ({
            columns: [
                {
                    field: 'PartNumber',
                    headerText: 'Part Number',
                    validationRules: {required: true},
                },
                {
                    field: 'PartDescription',
                    headerText: 'Description',
                    validationRules: {required: false},
                },
                {field: 'Quantity', headerText: 'Quantity', validationRules: {required: true, number: true}},
            ],
            dataSource: parts,
            enableAdaptiveUI: true,
            rowRenderingMode: 'Vertical',
            queryString: 'repairLineID',
            allowTextWrap: true,
            editSettings: isReadOnly
                ? undefined
                : {
                      mode: 'Dialog',
                      allowEditing: true,
                      allowAdding: true,
                      allowDeleting: true,
                  },
            toolbar: isReadOnly ? [] : toolbarOptions,
            actionBegin: function (this: Grid, state) {
                if (state.name === 'actionBegin' && state.requestType === 'beginEdit') {
                    state.cancel = true;
                    setIsFormOpen(true);
                    setSelectedRepairLineId(state.rowData.repairLineID);
                    setSelectedPartNumber(state.rowData.PartNumber);
                }

                if (state.requestType === 'delete' && this.parentDetails) {
                    const part: OfflineEstimatePart = state.data[0];
                    const offlineEstimateLineID = Number(this.parentDetails.parentKeyFieldValue);

                    state.cancel = true;
                    dispatch(deleteOfflineEstimatePart(offlineEstimate.ID, offlineEstimateLineID, part.OfflineEstimateLinePartID));
                }

                if (state.requestType === 'add' && this.parentDetails && !Number.isNaN(Number(this.parentDetails.parentKeyFieldValue))) {
                    const offlineEstimateLineID = Number(this.parentDetails.parentKeyFieldValue);
                    state.cancel = true;
                    setIsFormOpen(true);
                    setSelectedRepairLineId(offlineEstimateLineID);
                }
            },
            toolbarClick: function (this: Grid, args) {
                const repairLineID = this.toolbarModule['parent'].properties.parentDetails.parentKeyFieldValue;

                if (args.item.properties.id === 'Scanner') {
                    setBarcodeScannerRepairLineID(repairLineID);
                }
            },
        }),
        [parts, offlineEstimate],
    );

    const dataBound = function (this: GridComponent) {
        // This try/catch need to avoid Syncfusion error
        try {
            this.detailRowModule.expandAll();
        } catch {}
    };

    const onScanned = useCallback(
        (unpause: () => void, code: string) => {
            setBarcodeLoading(true);

            const repairLine = repairLines.find(({ID}) => ID === barcodeScannerRepairLineID);
            const allowableParts = partsRegistry.filter(({AllowablePartCombinations, EquipmentGroupIDPriced}) =>
                AllowablePartCombinations.some(
                    ({JobCodeID, ConditionCodeID}) => repairLine?.JobCodeId === JobCodeID && repairLine?.ConditionCodeId === ConditionCodeID,
                ),
            );

            // allowable parts list should be searched for a matching part number
            let foundPart = allowableParts.find(({PartNumber}) => PartNumber === code);

            // if none are found, the allowable parts' alternative part numbers should be searched for a match
            if (!foundPart) {
                foundPart = allowableParts.find(({AlternativePartNumbers}) => AlternativePartNumbers.includes(code));
            }

            const onFinish = () => {
                // the delay for the next scan
                setBarcodeLoading(false);
                setTimeout(() => {
                    unpause();
                }, 3000);
            };

            if (foundPart) {
                const partInRepairLine = repairLine?.Parts.find(({PartNumber}) => PartNumber === code);
                // If the same part is scanned multiple times, the quantity of this part should be incremented by 1
                if (partInRepairLine) {
                    dispatch(
                        updateOfflineEstimatePart({
                            offlineEstimateID: offlineEstimate.ID,
                            offlineEstimateLinePartID: partInRepairLine.OfflineEstimateLinePartID,
                            offlineEstimateLineID: barcodeScannerRepairLineID!,
                            part: {
                                ID: foundPart.ID,
                                PartNumber: code,
                                PartDescription: foundPart.PartDescription,
                                Quantity: partInRepairLine.Quantity + 1,
                            },
                        }),
                    );
                } else {
                    dispatch(
                        createOfflineEstimatePart({
                            offlineEstimateID: offlineEstimate.ID,
                            offlineEstimateLineID: barcodeScannerRepairLineID!,
                            part: {
                                ID: foundPart.ID,
                                PartNumber: code,
                                PartDescription: foundPart.PartDescription,
                                Quantity: 1,
                                Manufacturer: foundPart.Manufacturer,
                            },
                        }),
                    );
                }

                onFinish();
            } else {
                const jobCode = jobCodes.find((jobCode) => jobCode.ID === repairLine?.JobCodeId);
                const errorMessage = `${code} is not allowed on ${t('job_code')} ${jobCode?.JobCodeNumber}`;

                dispatch(
                    enqueueSnackbar(errorMessage, {
                        autoHideDuration: 3000,
                        variant: 'error',
                    }),
                );
                onFinish();
            }
        },
        [barcodeScannerRepairLineID, dispatch, partsRegistry, repairLines, offlineEstimate],
    );

    const onBarcodeScannerClose = useCallback(() => {
        setBarcodeScannerRepairLineID(null);
    }, []);

    const gridData = useMemo(
        () => convertOfflineEstimateLinesToOfflineEstimateLineDataGridDtos(repairLines, jobCodes, whyMadeCodes, conditionCodes, unitLocationCodes),
        [repairLines, jobCodes, whyMadeCodes, conditionCodes, unitLocationCodes],
    );

    return (
        <>
            <BarcodeScanner
                isLoading={barcodeLoading}
                isOpen={Boolean(barcodeScannerRepairLineID)}
                onClose={onBarcodeScannerClose}
                onScanned={onScanned}
            />
            {isFormOpen && selectedRepairLineId && (
                <Dialog fullScreen open={true}>
                    <HeaderProvider>
                        <OfflinePartFormContainer
                            offlineEstimateLineID={selectedRepairLineId}
                            partNumber={selectedPartNumber}
                            onClose={onPartFormClose}
                        />
                    </HeaderProvider>
                </Dialog>
            )}
            <GridWrapper hidden={isFormOpen} className="e-bigger parts-container">
                <Title>Parts</Title>
                <GridComponent
                    dataBound={dataBound}
                    childGrid={childGridOptions}
                    allowPaging
                    allowSorting
                    allowFiltering
                    enableAdaptiveUI
                    dataSource={gridData}
                    enableInfiniteScrolling
                    rowRenderingMode="Vertical"
                    filterSettings={{type: 'Menu'}}>
                    <ColumnsDirective>
                        <ColumnDirective field="JobCodeNumber" headerText="Code" />
                        <ColumnDirective field="JobCodeDescription" headerText="Desc" />
                        <ColumnDirective field="ConditionCodeDescription" headerText="Repair" />
                        <ColumnDirective field="UnitLocationCodeDescription" headerText="Loc" />
                        <ColumnDirective field="RepairQuantity" headerText="Qty" />
                    </ColumnsDirective>
                    <Inject services={[InfiniteScroll, Filter, Sort, DetailRow, Toolbar, Edit]} />
                </GridComponent>
            </GridWrapper>
        </>
    );
};

export default OfflineEstimatePartsGrid;
