import {SpecialInstructionAuthorization} from '../models/SpecialInstructionAuthorization';

export const getSpecialInstructionsInfo = (specialInstructions: SpecialInstructionAuthorization[]) => {
    const notDeletedSpecialInstructions = specialInstructions.filter((si) => si !== null && !si?.IsDeleted);
    const authorizedSpecialInstructionsCount = notDeletedSpecialInstructions.filter((si) => si?.AuthorizationDate).length;
    const unauthorizedSpecialInstructionsCount = notDeletedSpecialInstructions.length - authorizedSpecialInstructionsCount;

    return {
        authorizedSpecialInstructionsCount: authorizedSpecialInstructionsCount,
        unauthorizedSpecialInstructionsCount: unauthorizedSpecialInstructionsCount,
    };
};

export const getTotalSpecialInstructionsInfo = (
    woSpecialInstructions: SpecialInstructionAuthorization[],
    rlSpecialInstructions: SpecialInstructionAuthorization[],
) => {
    const {
        authorizedSpecialInstructionsCount: woAuthorizedSpecialInstructionsCount,
        unauthorizedSpecialInstructionsCount: wounauthorizedSpecialInstructionsCount,
    } = getSpecialInstructionsInfo(woSpecialInstructions);

    const {
        authorizedSpecialInstructionsCount: rlAuthorizedSpecialInstructionsCount,
        unauthorizedSpecialInstructionsCount: rlunauthorizedSpecialInstructionsCount,
    } = getSpecialInstructionsInfo(rlSpecialInstructions);

    return {
        authorizedSpecialInstructionsCount: woAuthorizedSpecialInstructionsCount + rlAuthorizedSpecialInstructionsCount,
        unauthorizedSpecialInstructionsCount: wounauthorizedSpecialInstructionsCount + rlunauthorizedSpecialInstructionsCount,
    };
};
