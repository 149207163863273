import {JobCode} from '../../models/JobCode';
import {ReduxAction} from '../../models/ReduxAction';

import {JOB_CODE_ERROR, JOB_CODE_REQUEST, JOB_CODES_RESPONSE} from './job-code.types';

export interface JobCodeState {
    error: Error | null;
    loading: boolean;
    jobCodes: JobCode[];
}

const initialState: JobCodeState = {
    error: null,
    loading: false,
    jobCodes: [],
};

export default function jobCodesReducer(state = initialState, action: ReduxAction): JobCodeState {
    switch (action.type) {
        case JOB_CODE_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case JOB_CODES_RESPONSE:
            return {
                ...state,
                loading: false,
                jobCodes: action.payload,
            };
        case JOB_CODE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
