import {ItemModel} from '@syncfusion/ej2-navigations';
import {ClickEventArgs} from '@syncfusion/ej2-navigations/src/toolbar/toolbar';
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    InfiniteScroll,
    EditSettingsModel,
    Filter,
    Inject,
    Sort,
    Edit,
    Toolbar,
    Column,
    QueryCellInfoEventArgs,
} from '@syncfusion/ej2-react-grids';
import {push} from 'connected-react-router';
import {t} from 'i18next';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';
import React, {memo, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useAbac} from 'react-abac';
import * as ReactDOM from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';

import {WorkOrderRepairLine} from '../../models/WorkOrderRepairLine';
import {WorkOrderRepairLineDataGridDto} from '../../models/dtos/work-order-repair-line-data-grid-dto';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {Permission} from '../../models/enumerations/Permission';
import {WorkOrderRepairLineStatus} from '../../models/enumerations/WorkOrderRepairLineStatus';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {fetchComments} from '../../redux/comment/comment.action';
import {selectRepairLineComments} from '../../redux/comment/comment.selectors';
import {fetchRepairLinePricing} from '../../redux/pricing/pricing.actions';
import {deleteRepairLine, addRepairLineDeclinationReason, removeRepairLineDeclinationReason} from '../../redux/repair-line/repair-line.actions';
import {selectRepairLineState} from '../../redux/repair-line/repair-line.selectors';
import {selectWhyMadeCodesState} from '../../redux/why-made-code/why-made-code.selectors';
import {selectWorkOrderState} from '../../redux/work-order/work-order.selectors';
import {convertRepairLinesToRepairLineDataGridDtos} from '../../utils/convertRepairLineToRepairLineDataGridDto';
import AuditMessages from '../audit-messages/audit-messages';
import DeclinationReasonsTemplate from '../declination-reasons/declination-reasons-template';
import RejectWorkOrderDialog from '../reject-work-order-dialog/reject-work-order-dialog.component';

import {Counter, ButtonDetails} from './repair-item-details.styles';

export type RepairItemDetailsProps = {
    onLineDetailsClick: (line: WorkOrderRepairLine) => void;
    detailsUrls: {
        create: string;
        update: string;
        files: string;
        parts: string;
        comments: string;
    };
};

const RepairItemDetails = ({onLineDetailsClick, detailsUrls}: RepairItemDetailsProps) => {
    const dispatch = useDispatch();
    const repairLineComments = useSelector(selectRepairLineComments);
    const {loading: repairLineLoading} = useSelector(selectRepairLineState);
    const {loading: workOrderLoading} = useSelector(selectWorkOrderState);
    const {whyMadeCodes} = useSelector(selectWhyMadeCodesState);
    const {workOrder, readOnly, repairLines} = useContext(WorkOrderDataContext);
    const [selectedRepairLine, setSelectedRepairLine] = useState<WorkOrderRepairLine | null>();
    const {userHasPermissions} = useAbac();
    const workOrderRepairLines = useMemo(
        () => convertRepairLinesToRepairLineDataGridDtos(repairLines, repairLineComments, whyMadeCodes),
        [repairLines, repairLineComments, whyMadeCodes],
    );

    useEffect(() => {
        if (selectedRepairLine) {
            const repairLine = repairLines.find((item) => item.ID === selectedRepairLine.ID);

            setSelectedRepairLine(repairLine);
        }
    }, [repairLines]);

    const onRemoveDeclinationReasonClick = useCallback(
        (errorMessage, itemID) => {
            dispatch(
                removeRepairLineDeclinationReason({
                    ID: itemID,
                    workOrderID: workOrder.ID,
                    errorMessageID: errorMessage.ID,
                }),
            );
        },
        [dispatch, workOrder],
    );

    const handleCancel = useCallback(() => {
        setSelectedRepairLine(null);
    }, []);

    const onRowSelected = ({data}: {data: WorkOrderRepairLineDataGridDto}) => {
        const buttons = document.querySelectorAll('.e-toolbar-item[title=Delete], .e-toolbar-item[title=Reject], .e-toolbar-item[title=Update]');

        if (data.Status === WorkOrderRepairLineStatus.Rejected) {
            buttons.forEach((button) => button.classList.add('e-overlay'));
        } else {
            buttons.forEach((button) => button.classList.remove('e-overlay'));
        }
    };
    const onRowDeselected = () => {
        document
            .querySelectorAll('.e-toolbar-item[title=Delete], .e-toolbar-item[title=Reject], .e-toolbar-item[title=Update]')
            .forEach((button) => button.classList.add('e-overlay'));
    };

    useEffect(() => {
        repairLines.forEach((line) => {
            dispatch(
                fetchComments({
                    associateObjectID: line.ID,
                    associatedTypeID: AssociatedTypes.WorkOrderRepairLine,
                }),
            );
        });
    }, [dispatch, repairLines]);

    const onDetailsClick = useCallback(
        (repairLine: WorkOrderRepairLine) => () => {
            onLineDetailsClick(repairLine);
        },
        [onLineDetailsClick],
    );

    const clickHandler = function (this: GridComponent, args: ClickEventArgs) {
        const properties = this.properties as {selectedRowIndex: number; dataSource: WorkOrderRepairLineDataGridDto[]};

        const repairLine = properties.dataSource[properties.selectedRowIndex];

        if (args.item.id === 'Create') {
            dispatch(push(detailsUrls.create));
        }

        if (args.item.id === 'Update') {
            dispatch(push(`${detailsUrls.update}/${repairLine.ID}`));
            dispatch(
                fetchRepairLinePricing({
                    companyID: workOrder.Company.ID,
                    stationID: workOrder.RepairLocation.ID,
                    equipmentGroupID: workOrder.EquipmentGroups,
                    jobCodeID: repairLine.JobCodeId,
                    conditionCodeID: repairLine.ConditionCodeId,
                    repairSizeID: repairLine.RepairSizeId,
                    suppressError: false,
                }),
            );
        }

        if (args.item.id === 'Reject') {
            setSelectedRepairLine(repairLine);
        }

        if (args.item.id === 'Delete') {
            // @ts-ignore
            dispatch(deleteRepairLine(repairLine.ID, workOrder.ID));
        }
    };

    const editSettings: EditSettingsModel = {
        allowDeleting: true,
        mode: 'Dialog',
    };

    let toolbarOptions: ItemModel[] = [
        {
            tooltipText: 'Delete',
            prefixIcon: 'e-delete-1',
            id: 'Delete',
            align: 'Right',
            disabled: true,
        },
        {
            tooltipText: 'Reject',
            prefixIcon: 'e-thumbs-down-1',
            id: 'Reject',
            align: 'Right',
            disabled: true,
        },
        {
            tooltipText: 'Update',
            prefixIcon: 'e-edit-5',
            id: 'Update',
            align: 'Right',
            disabled: true,
        },
        {
            tooltipText: 'Create',
            prefixIcon: 'e-plus-small',
            id: 'Create',
            align: 'Right',
        },
    ];

    const actionBegin: GridComponent['actionBegin'] = useCallback(function (state) {
        if (state.requestType === 'filterbeforeopen' && state.columnName === 'Status') {
            state.filterModel.sBox.firstChild.setAttribute('hidden', '');
        }
    }, []);

    // https://www.syncfusion.com/forums/175268/get-error-when-enablestickyheader
    const destroy = function (this: GridComponent) {
        document.removeEventListener('scroll', this.scrollModule['makeStickyHeader']);
    };

    const queryCellInfo = (args: QueryCellInfoEventArgs) => {
        const cell = args.cell as Element;
        const field = (args.column as Column).field;
        const data = args.data as WorkOrderRepairLineDataGridDto;

        switch (field) {
            case 'Status':
                ReactDOM.render(<span>{t(WorkOrderRepairLineStatus[data.Status])}</span>, cell);
                break;
            case 'FilesCount':
                ReactDOM.render(
                    <Counter onClick={() => dispatch(push(`${detailsUrls.files}?repairLineID=${data.ID}`))}>{data.FilesCount}</Counter>,
                    cell,
                );
                break;
            case 'CommentsCount':
                ReactDOM.render(<Counter onClick={() => dispatch(push(`${detailsUrls.comments}#${data.ID}`))}>{data.CommentsCount}</Counter>, cell);
                break;
            case 'PartsCount':
                ReactDOM.render(
                    <Counter onClick={() => dispatch(push(`${detailsUrls.parts}?repairLineID=${data.ID}`))}>{data.PartsCount}</Counter>,
                    cell,
                );
                break;
            case 'DetailsField':
                ReactDOM.render(<ButtonDetails onClick={onDetailsClick(data)}>{t('details')}</ButtonDetails>, cell);
                break;
            case 'DeclinationReasonsField':
                if (_isEmpty(data.DeclinationReasons)) {
                    ReactDOM.render(<div />, cell);
                } else {
                    const isRemoveDeclinationReasonsAllowed = !readOnly && userHasPermissions(Permission.WORK_ORDER_REJECTED);

                    ReactDOM.render(
                        <DeclinationReasonsTemplate
                            itemID={data.ID}
                            loading={repairLineLoading || workOrderLoading}
                            declinationReasons={_uniqBy(data.DeclinationReasons, 'ID')}
                            onRemoveClick={isRemoveDeclinationReasonsAllowed ? onRemoveDeclinationReasonClick : undefined}
                        />,
                        cell,
                    );
                }
                break;
            case 'AuditMessagesField':
                ReactDOM.render(_isEmpty(data.AuditMessages) ? <div /> : <AuditMessages auditMessages={data.AuditMessages} />, cell);
                break;
        }
    };

    return (
        <>
            <div className="e-adaptive-demo e-bigger" key="repair-item-details-key">
                <div className="e-mobile-layout">
                    <div className="e-mobile-content">
                        <GridComponent
                            selectionSettings={{type: 'Single'}}
                            rowSelected={onRowSelected}
                            rowDeselected={onRowDeselected}
                            allowPaging
                            allowSorting
                            className="repairLine-grid"
                            allowSelection
                            allowFiltering
                            enableAdaptiveUI
                            queryCellInfo={queryCellInfo}
                            actionBegin={actionBegin}
                            toolbarClick={clickHandler}
                            editSettings={editSettings}
                            toolbar={!readOnly ? toolbarOptions : []}
                            dataSource={workOrderRepairLines}
                            rowRenderingMode="Vertical"
                            // @ts-ignore
                            destroy={destroy}
                            enableStickyHeader
                            enableInfiniteScrolling
                            filterSettings={{type: 'Menu'}}>
                            <ColumnsDirective>
                                <ColumnDirective field="JobCodeNumber" headerText={t('code')} />
                                <ColumnDirective field="JobCodeDescription" headerText={t('description')} />
                                <ColumnDirective field="UnitLocationCodeDescription" headerText={t('location')} />
                                <ColumnDirective field="Defect" headerText={t('defect')} />
                                <ColumnDirective field="ConditionCodeDescription" headerText={t('repair')} />
                                <ColumnDirective field="RepairQuantity" headerText={t('quantity')} />
                                <ColumnDirective
                                    headerText={t('status')}
                                    field="Status"
                                    filter={{type: 'CheckBox'}}
                                    filterItemTemplate={({Status}: WorkOrderRepairLine) => t(WorkOrderRepairLineStatus[Status])}
                                />
                                <ColumnDirective headerText={t('files')} field="FilesCount" />
                                <ColumnDirective headerText={t('comments')} field="CommentsCount" />
                                <ColumnDirective headerText={t('parts')} field="PartsCount" />
                                <ColumnDirective field="DetailsField" headerText="" />
                                <ColumnDirective
                                    field="DeclinationReasonsField"
                                    headerText=""
                                    customAttributes={{class: 'remove-column-header-padding'}}
                                />
                                <ColumnDirective
                                    field="AuditMessagesField"
                                    headerText=""
                                    customAttributes={{class: 'remove-column-header-padding'}}
                                />
                            </ColumnsDirective>
                            <Inject services={[InfiniteScroll, Filter, Sort, Toolbar, Edit]} />
                        </GridComponent>
                    </div>
                </div>
            </div>
            {selectedRepairLine && (
                <RejectWorkOrderDialog
                    loading={repairLineLoading}
                    onReject={() => {}}
                    onCancel={handleCancel}
                    itemName={t('repair_line')}
                    itemType={AssociatedTypes.WorkOrderRepairLine}
                    itemID={selectedRepairLine.ID}
                    open={Boolean(selectedRepairLine)}
                    declinationReasons={selectedRepairLine.DeclinationReasons}
                    addDeclinationReasonAction={addRepairLineDeclinationReason}
                    removeDeclinationReasonAction={removeRepairLineDeclinationReason}
                />
            )}
        </>
    );
};

export const buildDetailsUrls = (path: string, workOrderID: number): RepairItemDetailsProps['detailsUrls'] => ({
    create: `${path}/${workOrderID}/line`,
    update: `${path}/${workOrderID}/line`,
    files: `${path}/${workOrderID}/files`,
    parts: `${path}/${workOrderID}/parts`,
    comments: `${path}/${workOrderID}/comments`,
});

export default memo(RepairItemDetails);
