import {AccessControl} from '../../models/AccessControl';
import {ReduxAction} from '../../models/ReduxAction';

import {ACCESS_CONTROL_REQUEST, ACCESS_CONTROL_ERROR, ACCESS_CONTROL_RESPONSE} from './access-control.types';

export interface AccessControlState {
    error: Error | null;
    initLoading: boolean;
    accessControl: AccessControl | null;
}

const initialState: AccessControlState = {
    error: null,
    initLoading: true,
    accessControl: null,
};

export default function accessControlReducer(state = initialState, action: ReduxAction): AccessControlState {
    switch (action.type) {
        case ACCESS_CONTROL_REQUEST:
            return {
                ...state,
                error: null,
            };
        case ACCESS_CONTROL_RESPONSE:
            return {
                ...state,
                initLoading: false,
                accessControl: action.payload,
            };
        case ACCESS_CONTROL_ERROR:
            return {
                ...state,
                initLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
