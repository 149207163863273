import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {StyledEngineProvider} from '@mui/styled-engine-sc';
import {ConnectedRouter} from 'connected-react-router';
import {SnackbarProvider} from 'notistack';
import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {Provider} from 'react-redux';
import {ThemeProvider as StyledThemeProvider} from 'styled-components';

import {AuthProviderWrapper} from './components/auth-provider-wrapper/auth-provider-wrapper';
import {Routes} from './components/routes/routes.component';
import {useNetworkStatus} from './hooks/use-network-status';
import ErrorBoundaryPage from './pages/error-boundary-page/error-bundary-page.component';
import NotificationsProvider from './providers/notifications-provider';
import store, {history} from './redux/store';
import theme from './theme';

export const App = () => {
    const {NetworkStatusIndicator} = useNetworkStatus();

    return (
        <ErrorBoundary FallbackComponent={ErrorBoundaryPage}>
            <NetworkStatusIndicator />
            <Provider store={store}>
                <AuthProviderWrapper>
                    <StyledEngineProvider injectFirst>
                        <StyledThemeProvider theme={theme}>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <ConnectedRouter history={history}>
                                    <SnackbarProvider maxSnack={3}>
                                        <NotificationsProvider>
                                            <Routes />
                                        </NotificationsProvider>
                                    </SnackbarProvider>
                                </ConnectedRouter>
                            </ThemeProvider>
                        </StyledThemeProvider>
                    </StyledEngineProvider>
                </AuthProviderWrapper>
            </Provider>
        </ErrorBoundary>
    );
};
