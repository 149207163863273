import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 1em 1em;

    span.Mui-checked #root_Priority + svg {
        color: #19857b;
    }
`;
