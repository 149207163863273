import {
    DataSourceChangedEventArgs,
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    InfiniteScroll,
    Filter,
    Inject,
    Sort,
    EditSettingsModel,
    ToolbarItems,
    Edit,
    Toolbar,
    QueryCellInfoEventArgs,
} from '@syncfusion/ej2-react-grids';
import React, {useCallback, useMemo, useContext} from 'react';
import * as ReactDOM from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';

import {Comment} from '../../models/Comment';
import {User} from '../../models/User';
import {CommentDto} from '../../models/dtos/comment-dto';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {createComment, deleteComment, updateComment} from '../../redux/comment/comment.actions';
import {selectUser} from '../../redux/user/user.selectors';
import {convertCommentsToCommentDtos} from '../../utils/convertCommentToCommentDto';
import {formatDate} from '../../utils/formatDate';

import {Title} from './comments-grid-list.style';

type CommentsGridListComponentProps = {
    payload?: Comment[];
    title?: string;
};

const messageColumnRules = {required: true};
const toolbarOptions: ToolbarItems[] = ['Add', 'Edit', 'Delete'];
const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: 'Dialog',
};

function CommentsGridListComponent({payload = [], title = ''}: CommentsGridListComponentProps) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const {workOrder, readOnly} = useContext(WorkOrderDataContext);
    const comments = useMemo(() => convertCommentsToCommentDtos(payload), [payload]);

    const dataManager = useCallback(
        function (state: DataSourceChangedEventArgs) {
            // Set the fields that aren't automatically set so the comment looks correct while the save request finishes.
            // The time is going to be off but at least its not "undefined" or missing entirely.
            state.data = {
                ...state.data,
                FormattedTimeStamp: formatDate({date: new Date(), includeTime: true}),
                UserName: user?.FirstName && user?.LastName ? `${user.FirstName} ${user.LastName}` : user?.UserName ? user.UserName : '',
            };

            if (state.requestType === 'delete') {
                dispatch(deleteComment((state.data as User[])[0].ID, workOrder.ID));
            } else if (state.action === 'add' && state.requestType === 'save') {
                dispatch(
                    createComment({
                        associateObjectID: workOrder.ID,
                        associatedTypeID: AssociatedTypes.WorkOrder,
                        comment: state.data as Comment,
                    }),
                );

                state.endEdit && state.endEdit();
            } else if (state.action === 'edit' && state.requestType === 'save') {
                dispatch(
                    updateComment({
                        associateObjectID: workOrder.ID,
                        commentID: (state.data as Comment).ID,
                        comment: state.data as Comment,
                    }),
                );
            }
        },
        [dispatch, workOrder.ID],
    );

    const queryCellInfo = (args: QueryCellInfoEventArgs) => {
        const {FormattedTimeStamp} = args.data as CommentDto;

        if (args.column?.field === 'TimeStampDate') {
            ReactDOM.render(<span>{FormattedTimeStamp}</span>, args.cell as Element);
        }
    };

    return (
        <div className="e-adaptive-demo e-bigger" key={payload?.length}>
            <div className="e-mobile-layout">
                <div className="e-mobile-content">
                    <Title>{title}</Title>
                    <GridComponent
                        editSettings={editSettings}
                        toolbar={!readOnly ? toolbarOptions : []}
                        allowPaging
                        allowSorting
                        allowFiltering
                        enableAdaptiveUI
                        dataSource={comments}
                        enableInfiniteScrolling
                        allowTextWrap
                        actionBegin={dataManager}
                        rowRenderingMode="Vertical"
                        queryCellInfo={queryCellInfo}
                        filterSettings={{type: 'Menu'}}>
                        <ColumnsDirective>
                            <ColumnDirective field="UserName" headerText="User" width="auto" allowEditing={false} />
                            <ColumnDirective
                                //type="date"
                                field="TimeStampDate"
                                headerText="Date"
                                allowEditing={false}
                            />
                            <ColumnDirective validationRules={messageColumnRules} field="Message" headerText="Message" />
                        </ColumnsDirective>
                        <Inject services={[InfiniteScroll, Filter, Sort, Edit, Toolbar]} />
                    </GridComponent>
                </div>
            </div>
        </div>
    );
}

export default CommentsGridListComponent;
