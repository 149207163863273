import {push} from 'connected-react-router';
import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import HeaderPage from '../../components/header-page/header-page.component';
import {useUnitHeader} from '../../hooks/use-unit-header';
import {EquipmentGroups} from '../../models/enumerations/EquipmentGroups';
import {WorkOrderStatus} from '../../models/enumerations/WorkOrderStatus';
import {OfflineEstimateDataContext} from '../../providers/offline-estimate-data-provider';
import {getForm} from '../../redux/form-validation/form.actions';
import {selectForm} from '../../redux/form-validation/form.selectors';
import {FormName} from '../../redux/form-validation/form.types';
import {updateOfflineEstimate} from '../../redux/offline-estimate/offline-estimate.actions';
import {isOfflineEstimateReadOnly} from '../../utils/isOfflineEstimateReadOnly';

export type OfflineEstimateHeaderProps = {
    backToPath?: string;
};

export interface OfflineEstimateInitialValues {
    ElectiveInspectionType: number;
    Mate: string;
    Lot: string;
    ReferenceNumber: string;
    Row: string;
    Spot: string;
    Priority: boolean;
    GeneratePO: boolean;
    EquipmentGroups: string;
}

const OfflineEstimateHeader = ({backToPath}: OfflineEstimateHeaderProps) => {
    const dispatch = useDispatch();
    const {offlineEstimate} = useContext(OfflineEstimateDataContext);
    const isReadOnly = isOfflineEstimateReadOnly(offlineEstimate?.SentStatus);
    const form = useSelector(selectForm(FormName.WorkOrderHeader));
    const location = useLocation<{menuPrevPath?: string}>();

    useEffect(() => {
        if (offlineEstimate.ID) {
            dispatch(getForm(FormName.WorkOrderHeader));
        } else {
            dispatch(push('/offline-estimates'));
        }
    }, [dispatch, offlineEstimate.ID]);

    useUnitHeader({
        unitNumber: offlineEstimate?.UnitINO,
        redirectToPath: location.state?.menuPrevPath || `${backToPath}/${offlineEstimate.ID}`,
        backToPath: location.state?.menuPrevPath || `${backToPath}/${offlineEstimate.ID}`,
    });

    const onUpdateWorkOrder = useCallback(
        (data: Record<string, unknown>) => {
            dispatch(updateOfflineEstimate(offlineEstimate.ID, data));
        },
        [dispatch, offlineEstimate],
    );

    const initialValues = useMemo<OfflineEstimateInitialValues>(
        () => ({
            UnitINO: offlineEstimate.UnitINO,
            Mate: offlineEstimate.Mate,
            Lot: offlineEstimate.Lot,
            Row: offlineEstimate.Row,
            Spot: offlineEstimate.Spot,
            Priority: offlineEstimate.Priority,
            GeneratePO: offlineEstimate.GeneratePO,
            ElectiveInspectionType: offlineEstimate.ElectiveInspectionType,
            ReferenceNumber: offlineEstimate.ReferenceNumber,
            EquipmentGroups:
                Object.values(EquipmentGroups).find((e) => e.ID === offlineEstimate.EquipmentGroupID)?.Description ??
                EquipmentGroups.Unknown.Description,
        }),
        [offlineEstimate],
    );

    return (
        <HeaderPage
            workOrderStatus={WorkOrderStatus.Created.Status}
            formSchemas={form}
            onUpdate={onUpdateWorkOrder}
            initialValues={initialValues}
            readOnly={isReadOnly}
        />
    );
};

export default OfflineEstimateHeader;
