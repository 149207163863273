import Button from '@mui/material/Button';
import {t} from 'i18next';
import React, {useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {useHeader} from '../../hooks/use-header';

import {Container, HeaderTitle, Error, PaperWrap} from './authentication.styles';

/**
 * {@link AuthSignInError} page that display an authentication error.
 *
 * @returns {*}
 */

type LocationState = {
    authResult: {
        authError: {
            message: string;
        };
    };
    fromUrl: {
        hash: string;
        pathname: string;
        search: string;
    };
};

const AuthSignInError = () => {
    const history = useHistory();
    const location = useLocation<LocationState>();
    const {fromUrl, authResult} = location.state;
    const error = authResult?.authError?.message ? `The error was '${authResult!.authError!.message.toString()}'` : 'An unexpected error occurred.';

    const renderMiddleElement = useCallback(() => <HeaderTitle>{t('auth_sign_in_error')}</HeaderTitle>, [t]);

    useHeader({
        backToPath: '/',
        middleElement: renderMiddleElement,
    });

    return (
        <Container>
            <PaperWrap>
                {error && (
                    <Error>
                        <p>{t('authentication_failed')}</p>
                        <p>{error}</p>
                    </Error>
                )}
                <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={false}
                    fullWidth
                    onClick={() => history.push(fromUrl?.pathname || '/')}>
                    Return to {fromUrl?.pathname ?? 'Home'}
                </Button>
            </PaperWrap>
        </Container>
    );
};

export default AuthSignInError;
