export enum AssociatedTypes {
    None,
    Invoice,
    WorkOrder,
    WorkOrderRepairLine,
    PurchaseOrder,
    WorkOrderRebill,
    Unit,
    WorkUnit,
    RoadabilityDefect,
    Inspection,
    J1,
    J2,
    J9,
    J2Detail,
    InventoryUnitActivity,
    WorkOrderStatusChangeLog,
    Citation,
    J2Header,
    MobileVersion,
    ServiceAuthorization,
    InventoryUnitHold,
    InventoryUnitJ1Relation,
    InventoryUnitRoadabilityDefectRelation,
    RebillInvoiceGroup,
    UnitInspectionForm,
    UnitInspectionFormAnswer,
    Estimate,
    EstimateLine,
    EquipmentCharacteristicMaintenance,
    Defect,
    InventoryUnitDefectRelation,
    EditReason,
    DverHeader,
    DverDetail,
    Part,
    JobCode,
    Station,
    Company,
    StationCompanyRelation,
    RepairLinePart,
    FMCSACheck,
}
