import {ColumnDirective, ColumnsDirective, GridComponent, Filter, Inject, Sort} from '@syncfusion/ej2-react-grids';
import React, {useMemo} from 'react';

import {SpecialInstructionAuthorization} from '../../models/SpecialInstructionAuthorization';
import {convertSpecialInstructionsToSpecialInstructionDtos} from '../../utils/convertSpecialInstructionToSpecialInstructionDto';

import {Title} from './special-instructions-grid-list.styles';

type SpecialInstructionsGridListComponentProps = {
    payload?: SpecialInstructionAuthorization[];
    title?: string;
};

function SpecialInstructionsGridListComponent({payload = [], title = ''}: SpecialInstructionsGridListComponentProps) {
    const specialInstructions = useMemo(() => convertSpecialInstructionsToSpecialInstructionDtos(payload), [payload]);

    return (
        <div className="e-adaptive-demo e-bigger" key={payload?.length}>
            <div className="e-mobile-layout">
                <div className="e-mobile-content">
                    <Title>{title}</Title>
                    <GridComponent
                        allowPaging
                        allowSorting
                        allowFiltering
                        enableAdaptiveUI
                        dataSource={specialInstructions}
                        allowTextWrap
                        rowRenderingMode="Vertical"
                        filterSettings={{type: 'Menu'}}>
                        <ColumnsDirective>
                            <ColumnDirective field="SpecialInstruction.Description" headerText="Special Instruction" width="auto" />
                            <ColumnDirective field="AuthorizedBy" headerText="Authorized By" />
                            <ColumnDirective field="SpecialInstruction.MinimumAuthorizationLevel" headerText="Authorization Level" />
                        </ColumnsDirective>
                        <Inject services={[Filter, Sort]} />
                    </GridComponent>
                </div>
            </div>
        </div>
    );
}

export default SpecialInstructionsGridListComponent;
