import {UnitLocationCode} from '../../models/UnitLocationCode';

import {
    FETCH_UNIT_LOCATION_CODES,
    UNIT_LOCATION_CODE_ERROR,
    UNIT_LOCATION_CODE_REQUEST,
    UNIT_LOCATION_CODES_RESPONSE,
} from './user-location-code.types';

export const unitLocationCodeRequest = () => ({
    type: UNIT_LOCATION_CODE_REQUEST,
});

export const unitLocationCodeError = (error: Error | unknown) => ({
    type: UNIT_LOCATION_CODE_ERROR,
    payload: error,
});

export const unitLocationCodesResponse = (data: UnitLocationCode[]) => ({
    type: UNIT_LOCATION_CODES_RESPONSE,
    payload: data,
});

export const fetchUnitLocationCodes = () => ({
    type: FETCH_UNIT_LOCATION_CODES,
});
