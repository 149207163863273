/**
 * Returns an array of strings where index 0 is the initial and index 1 is the number. Returns an empty array if it could not be split.
 */
export const splitUnitINO = (unitINO: string): string[] => {
    const inoSplit = new RegExp('^([A-Z]{1,4})([0-9]{1,6})$');
    if (inoSplit.test(unitINO)) {
        const result = inoSplit.exec(unitINO);
        if (result?.length) {
            return [result[1], result[2]];
        }
    }

    return [];
};
