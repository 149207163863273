import {createSelector} from 'reselect';

import {State} from '../root-reducer';

import {FormState} from './form.reducer';
import {FormName} from './form.types';

export const selectFormState = (state: State): FormState => state.formReducer;
export const selectFormsState = (state: State): FormState => state.formReducer;

export const selectForm = (name: FormName) => {
    return createSelector(selectFormState, ({forms}) => forms[name]);
};

export const selectForms = () => {
    return createSelector(selectFormsState, ({forms}) => forms);
};
