export const FETCH_REPAIR_LINE_PRICING = 'FETCH_REPAIR_LINE_PRICING';
export const REPAIR_LINE_PRICING_RESPONSE = 'REPAIR_LINE_PRICING_RESPONSE';
export const PRICING_ERROR = 'PRICING_ERROR';
export const CLEAR_REPAIR_LINE_PRICING = 'CLEAR_REPAIR_LINE_PRICING';
export const FETCH_SHOP_PRICING = 'FETCH_SHOP_PRICING';
export const SHOP_PRICING_RESPONSE = 'SHOP_PRICING_RESPONSE';
export const SHOP_PRICING_ERROR = 'SHOP_PRICING_ERROR';
export const PRICING_REQUEST = 'PRICING_REQUEST';

export type FetchRepairLinePricing = {
    companyID: number;
    stationID: number;
    jobCodeID: number;
    conditionCodeID: number;
    repairSizeID?: number;
    equipmentGroupID?: number;
    repairDate?: string;
    suppressError?: boolean;
};
