import {differenceInMilliseconds} from 'date-fns';
import {unionBy} from 'lodash';
import {takeLeading, call, put} from 'redux-saga/effects';

import config from '../../config';
import {ExceptionReason} from '../../models/ExceptionReason';
import {ReduxAction} from '../../models/ReduxAction';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {formatDate} from '../../utils/formatDate';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';

import {exceptionReasonError, exceptionReasonRequest, exceptionReasonsResponse} from './exception-reason.actions';
import {FETCH_EXCEPTION_REASONS} from './exception-reason.types';

const {resourceCacheFallback} = config;

export function* fetchExceptionReasons() {
    const lastUpdateTime = getLastUpdateTime(
        LocalStorageKeys.LAST_EXCEPTION_REASONS_REFRESH_TIME,
        LocalStorageKeys.LAST_EXCEPTION_REASONS_UPDATE_TIME,
    );

    let cachedExceptionReasons: ExceptionReason[] = [];

    if (lastUpdateTime) {
        cachedExceptionReasons = yield call(IndexedDB.getMany, IndexedDBKeys.EXCEPTION_REASONS);

        if (differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
            yield put(exceptionReasonsResponse(cachedExceptionReasons));

            return;
        }
    }
    yield put(exceptionReasonRequest());

    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_EXCEPTION_REASONS_REFRESH_TIME, requestTime);
    }

    const {data, error}: ApiResponse<ExceptionReason[]> = yield call(request, {
        url: '/exceptionreasons',
        method: 'get',
        params: lastUpdateTime
            ? {
                  includeDeleted: true,
                  lastUpdateTime: formatDate({
                      date: lastUpdateTime,
                      ISOFormat: true,
                  }),
              }
            : undefined,
    });

    if (error) {
        yield put(exceptionReasonError(error.message));
        yield showErrorMessages(error.messages);

        if (cachedExceptionReasons) {
            yield put(exceptionReasonsResponse(cachedExceptionReasons));
        }
    }

    if (data) {
        const combinedExceptionReasons = unionBy(data, cachedExceptionReasons, 'ID').filter((exceptionReason) => !exceptionReason.IsDeleted);

        yield put(exceptionReasonsResponse(combinedExceptionReasons));

        yield call(IndexedDB.createMany, IndexedDBKeys.EXCEPTION_REASONS, combinedExceptionReasons);
        LocalStorage.set(LocalStorageKeys.LAST_EXCEPTION_REASONS_UPDATE_TIME, requestTime);
    }
}

export default function* exceptionReasonsRootSaga() {
    yield takeLeading<ReduxAction>(FETCH_EXCEPTION_REASONS, fetchExceptionReasons);
}
