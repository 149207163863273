export {};
declare global {
    interface String {
        floor(decimalPlaces?: number): string;
    }

    interface Number {
        floor(decimalPlaces?: number): number;
    }
}

/**
 * @param decimalPlaces - Optional - Defaults to 2. Number of decimal places to keep.
 * @returns Floored number.
 */

Number.prototype.floor = function (decimalPlaces?: number): number {
    return +this.toString().replace(new RegExp(`\\.(\\d{${decimalPlaces ?? 2}})\\d+`, 'g'), decimalPlaces === 0 ? '$1' : '.$1');
};

/**
 * @param decimalPlaces - Optional - Defaults to 2. Number of decimal places to keep.
 * @returns String representation of the floored number.
 */
String.prototype.floor = function (decimalPlaces?: number): string {
    return this.replace(new RegExp(`\\.(\\d{${decimalPlaces ?? 2}})\\d+`, 'g'), decimalPlaces === 0 ? '$1' : '.$1');
};
