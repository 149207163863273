import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    color: ${({theme}) => theme.palette.primary.main};
    border-bottom: 1px solid;
    margin: 0.5em 1em;
`;

export const Title = styled.h2`
    grid-column-start: 2;
    font-weight: normal;
    margin: 0;
    text-transform: uppercase;
`;
