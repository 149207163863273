import {Button, CircularProgress} from '@mui/material';
import styled from 'styled-components';

import theme from '../../theme';

export const Loader = styled(CircularProgress)`
    color: #ffffff;
`;

export const Reset = styled(Button)`
    margin: 25px 0;
    background-color: ${theme.palette.info.main};
    color: #ffffff;
    &:hover {
        background-color: ${theme.palette.info.dark};
    }
`;
