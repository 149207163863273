import {DependencyList, EffectCallback, useEffect, useRef} from 'react';

export const useDidUpdate = (effect: EffectCallback, deps?: DependencyList) => {
    const mounted = useRef(false);

    useEffect(() => {
        if (!mounted.current) {
            // componentDidMount logic
            mounted.current = true;
        } else {
            // componentDidUpdate logic
            return effect();
        }
    }, deps);
};
