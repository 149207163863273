import {all, put} from 'redux-saga/effects';

import {enqueueSnackbar} from '../redux/notistack/notistack.actions';

export function* showErrorMessages(messages: string[]) {
    yield all(
        messages.map((message) =>
            put(
                enqueueSnackbar(message, {
                    variant: 'error',
                }),
            ),
        ),
    );
}
