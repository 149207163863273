import React, {useCallback} from 'react';

import UnitHeaderTitle from '../components/unit-header-title/unit-header-title.component';

import {useHeader} from './use-header';

export const useUnitHeader = ({
    unitNumber,
    workOrderNumber,
    redirectToPath,
    backToPath,
    basePath,
}: {
    unitNumber?: string;
    workOrderNumber?: string;
    redirectToPath?: string;
    backToPath?: string;
    basePath?: string;
}) => {
    const title = useCallback(
        () => <UnitHeaderTitle unitNumber={unitNumber} workOrderNumber={workOrderNumber} redirectToPath={redirectToPath} />,
        [redirectToPath, unitNumber, workOrderNumber],
    );

    useHeader({
        backToPath: backToPath,
        middleElement: title,
        basePath: basePath,
    });
};
