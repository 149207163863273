import {ReduxAction} from '../../models/ReduxAction';
import {Station} from '../../models/Station';

import {STATIONS_ERROR, STATIONS_REQUEST, STATIONS_RESPONSE} from './station.types';

export interface StationState {
    error: Error | null;
    loading: boolean;
    stations: Station[];
}

const initialState: StationState = {
    error: null,
    loading: false,
    stations: [],
};

export default function stationReducer(state = initialState, action: ReduxAction): StationState {
    switch (action.type) {
        case STATIONS_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case STATIONS_RESPONSE:
            return {
                ...state,
                loading: false,
                stations: action.payload,
            };
        case STATIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
