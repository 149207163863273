import {FMCSA} from '../../models/FMCSA';
import {ReduxAction} from '../../models/ReduxAction';
import {UnitStatus} from '../../models/UnitStatus';
import {UnitType} from '../../models/UnitType';

import {FMCSA_CHECK_RESPONSE, UNITS_ERROR, UNITS_REQUEST, UNITS_STATUS_RESPONSE, UNITS_TYPES_RESPONSE, RESET_FMCSA} from './unit.types';

export interface UnitState {
    error: Error | null;
    loading: boolean;
    fmcsaCheck: FMCSA | null;
    unitTypes: UnitType[];
    unitStatus: UnitStatus[];
}

const initialState: UnitState = {
    error: null,
    loading: false,
    fmcsaCheck: null,
    unitTypes: [],
    unitStatus: [],
};

export default function unitReducer(state = initialState, action: ReduxAction): UnitState {
    switch (action.type) {
        case UNITS_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case UNITS_TYPES_RESPONSE:
            return {
                ...state,
                loading: false,
                unitTypes: action.payload,
            };
        case UNITS_STATUS_RESPONSE:
            return {
                ...state,
                loading: false,
                unitStatus: action.payload,
            };
        case UNITS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case FMCSA_CHECK_RESPONSE:
            return {
                ...state,
                loading: false,
                fmcsaCheck: action.payload,
            };
        case RESET_FMCSA:
            return {
                ...state,
                loading: false,
                fmcsaCheck: null,
            };
        default:
            return state;
    }
}
