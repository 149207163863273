import {AccessControl} from '../../models/AccessControl';

import {ACCESS_CONTROL_REQUEST, ACCESS_CONTROL_RESPONSE, FETCH_ACCESS_CONTROL, ACCESS_CONTROL_ERROR} from './access-control.types';

export const accessControlRequest = () => ({
    type: ACCESS_CONTROL_REQUEST,
});

export const accessControlResponse = (data: AccessControl | null) => ({
    type: ACCESS_CONTROL_RESPONSE,
    payload: data,
});

export const fetchAccessControl = () => ({
    type: FETCH_ACCESS_CONTROL,
});

export const accessControlError = (error: Error | unknown) => ({
    type: ACCESS_CONTROL_ERROR,
    payload: error,
});
