import {Comment} from '../../models/Comment';
import {ReduxAction} from '../../models/ReduxAction';

import {
    COMMENTS_ERROR,
    COMMENTS_REQUEST,
    COMMENTS_RESPONSE,
    CREATE_COMMENT_RESPONSE,
    DELETE_COMMENT_RESPONSE,
    UPDATE_COMMENT_RESPONSE,
    REPAIR_LINE_COMMENTS_RESPONSE,
    CREATE_REPAIR_LINE_COMMENT_RESPONSE,
    UPDATE_REPAIR_LINE_COMMENT_RESPONSE,
    DELETE_REPAIR_LINE_COMMENT_RESPONSE,
} from './comment.types';

export interface CommentsState {
    error: Error | null;
    loading: boolean;
    workOrderComments: Comment[];
    repairLineComments: Record<Comment['ID'], Comment[]>;
}

const initialState: CommentsState = {
    error: null,
    loading: false,
    workOrderComments: [],
    repairLineComments: {},
};

export default function commentReducer(state = initialState, action: ReduxAction): CommentsState {
    switch (action.type) {
        case COMMENTS_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case COMMENTS_RESPONSE:
            return {
                ...state,
                loading: false,
                workOrderComments: action.payload,
            };
        case COMMENTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case CREATE_COMMENT_RESPONSE:
            return {
                ...state,
                loading: false,
                workOrderComments: [action.payload, ...state.workOrderComments].filter((item) => item.ID),
            };
        case UPDATE_COMMENT_RESPONSE:
            return {
                ...state,
                loading: false,
                workOrderComments: state.workOrderComments.map((item) => (item.ID === action.payload.ID ? action.payload : item)),
            };
        case DELETE_COMMENT_RESPONSE:
            return {
                ...state,
                loading: false,
                workOrderComments: state.workOrderComments.filter((item) => item.ID !== action.payload),
            };
        case REPAIR_LINE_COMMENTS_RESPONSE:
            return {
                ...state,
                loading: false,
                repairLineComments: {
                    ...state.repairLineComments,
                    [action.payload.repairLineID]: action.payload.comments,
                },
            };
        case CREATE_REPAIR_LINE_COMMENT_RESPONSE:
            return {
                ...state,
                loading: false,
                repairLineComments: {
                    ...state.repairLineComments,
                    [action.payload.repairLineID]: [action.payload.comment, ...state.repairLineComments[action.payload.repairLineID]].filter(
                        (item) => item.ID,
                    ),
                },
            };
        case UPDATE_REPAIR_LINE_COMMENT_RESPONSE:
            return {
                ...state,
                loading: false,
                repairLineComments: {
                    ...state.repairLineComments,
                    [action.payload.repairLineID]: state.repairLineComments[action.payload.repairLineID].map((item) =>
                        item.ID === action.payload.comment.ID ? action.payload.comment : item,
                    ),
                },
            };
        case DELETE_REPAIR_LINE_COMMENT_RESPONSE:
            return {
                ...state,
                loading: false,
                repairLineComments: {
                    ...state.repairLineComments,
                    [action.payload.repairLineID]: state.repairLineComments[action.payload.repairLineID].filter(
                        (item) => item.ID !== action.payload.commentID,
                    ),
                },
            };
        default:
            return state;
    }
}
