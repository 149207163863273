import React, {FC} from 'react';

import {LinkWrap, Title, UnitNumber, WorkOrderNumber} from './unit-header-title.styles';

const UnitHeaderTitle: FC<{
    unitNumber?: string;
    workOrderNumber?: string;
    redirectToPath?: string;
}> = ({unitNumber, workOrderNumber, redirectToPath}) => {
    const headerBody = (
        <Title>
            <UnitNumber>{unitNumber}</UnitNumber>
            {workOrderNumber && <WorkOrderNumber>{workOrderNumber}</WorkOrderNumber>}
        </Title>
    );

    if (redirectToPath) {
        return <LinkWrap to={redirectToPath}>{headerBody}</LinkWrap>;
    }

    return headerBody;
};

export default UnitHeaderTitle;
