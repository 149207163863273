import React, {Component, ReactElement} from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';

import config from '../config';
import {logoutUser} from '../redux/user/user.actions';
import {IAuthService} from '../services/auth-service';
import {AuthServiceManager, ILogger, LogLevels} from '../services/auth-service-manager';
import {ErrorLike} from '../utils/errorLike';

/**
 * Authentication structure used to create a React context. Equivalent to IAuthService.
 *
 * @export
 * @interface IAuthContext
 * @typedef {IAuthContext}
 * @extends {IAuthService}
 */
export interface IAuthContext extends IAuthService {}

/**
 * Authentication status summary.
 *
 * @export
 * @interface IAuthState
 * @typedef {IAuthState}
 */
export interface IAuthState {
    isAuthenticated?: boolean;
    isInitialized?: boolean;
    username?: string | null;
}

/**
 * Authentication summary for authentication error results.
 *
 * @export
 * @interface IAuthResult
 * @typedef {IAuthResult}
 */
export interface IAuthResult {
    /** Exception or other error. Must be serializable (so, not Error!). */
    authError?: ErrorLike;
}

/**
 * Authentication error data that can be passed in {@link Redirect} state.
 *
 * @export
 * @interface AuthErrorState
 * @typedef {AuthErrorState}
 */
export interface AuthErrorState {
    /** History location that was redirected to the error page. */
    fromUrl?: Location;
    /** Authentication result containing an error message or exception that was thrown. */
    authResult?: IAuthResult;
}

/**
 * Create an authentication context for the application with a dummy default service.
 *
 * @type {React.Context<IAuthContext>}
 */

export type AuthProviderProps = {
    children: ReactElement | ReactElement[];
    logoutUser: (callback?: () => void) => Action;
};

export const AuthContext = React.createContext<IAuthContext>({
    authServiceName: 'undefined',
    isAuthenticated: () => Promise.resolve(false),
    getClaims: () => Promise.resolve(null),
    getAuthorizationHeaderValue: () => Promise.resolve(null),
    startAuthentication: () => Promise.resolve(),
    completeAuthentication: () => Promise.resolve(),
    logout: () => undefined,
    getUser: () => Promise.resolve(null),
    removeUser: () => Promise.resolve(),
    getUsername: () => Promise.resolve(null),
});

// Create a context component populated with the selected authentication service object.
class AuthProviderComponent extends Component<AuthProviderProps> {
    // Get the instance of the configured authentication service.
    private authServiceManager = new AuthServiceManager();
    private authLogger: ILogger | undefined = undefined; // define a service for logging (e.g. console) if desired.
    private authLogLevel: LogLevels | undefined;
    private authServiceProvider: IAuthService | undefined;

    constructor(props: AuthProviderProps) {
        super(props);
        this.authLogger = console;
        this.authLogLevel = LogLevels.DEBUG;
        this.authServiceProvider = this.authServiceManager.CreateAuthService(config.authSystem, this.authLogger, this.authLogLevel);
    }

    logout = () => {
        this.props.logoutUser(this.authServiceProvider?.logout);
    };

    render() {
        const {children} = this.props;

        // We cannot continue without an authentication client.
        if (this.authServiceProvider === undefined) {
            throw new Error('No valid authentication client was defined');
        }

        // Return the AuthProvider component populated with the auth service object.
        return <AuthContext.Provider value={{...this.authServiceProvider, logout: this.logout}}>{children}</AuthContext.Provider>;
    }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        logoutUser: (callback?: () => void) => dispatch(logoutUser(callback)),
    };
};

export const AuthProvider = connect(null, mapDispatchToProps)(AuthProviderComponent);
