import {ReactElement} from 'react';

import useNotistack from '../hooks/use-notistack';

export type NotificationsProviderProps = {
    children: ReactElement;
};

const NotificationsProvider = ({children}: NotificationsProviderProps) => {
    useNotistack();

    return children;
};

export default NotificationsProvider;
