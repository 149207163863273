import {Button} from '@mui/material';
import {AutoCompleteComponent, DropDownListComponent} from '@syncfusion/ej2-react-dropdowns';
import {NumericTextBoxComponent} from '@syncfusion/ej2-react-inputs';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {Part} from '../../models/Part';
import {PartDto} from '../../models/dtos/part.dto';
import {isFeatureEnabled} from '../../redux/feature/feature.selectors';

import {PaperWrap, Container} from './part-form.styles';

export type PartFormData = {
    repairLinePartID: number | undefined;
    partID: number | undefined;
    partNumber: string;
    manufacturer: string;
    partDescription: string;
    quantity: number;
};

export type PartFormProps = {
    initialValues?: PartFormData;
    partsOptions: PartDto[];
    onSubmit: (data: PartFormData) => void;
    disableSubmit?: boolean;
};

export type SelectPartNumber = {
    itemData: Part;
};

type QuantityParts = {
    value: number;
};

export const PartForm = ({initialValues, onSubmit, partsOptions, disableSubmit}: PartFormProps) => {
    const {t} = useTranslation();
    const isDCLIFeatureEnabled = useSelector(isFeatureEnabled('DCLI'));
    const [partID, setPartID] = useState(initialValues?.partID ?? -1);
    const [partNumber, setPartNumber] = useState(initialValues?.partNumber || '');
    const [manufacturer, setManufacturer] = useState(initialValues?.manufacturer || '');
    const [partDescription, setPartDescription] = useState(initialValues?.partDescription || '');
    const [quantity, setQuantity] = useState(initialValues?.quantity || 1);

    const partTemplate = ({PartNumber, PartDescription, Manufacturer}: Part) => {
        return <div>{`[${PartNumber}] [${Manufacturer}] ${PartDescription}`}</div>;
    };

    const submit = useCallback(() => {
        onSubmit({
            repairLinePartID: initialValues?.repairLinePartID,
            partID: partID,
            partNumber: partNumber,
            manufacturer: manufacturer,
            partDescription: partDescription,
            quantity: quantity,
        });
    }, [onSubmit, initialValues?.repairLinePartID, partID, partNumber, partDescription, quantity]);

    return (
        <Container>
            <PaperWrap>
                {isDCLIFeatureEnabled ? (
                    <DropDownListComponent
                        placeholder={'Part Number'}
                        value={initialValues?.partID ?? -1}
                        select={({itemData}: SelectPartNumber) => {
                            setPartID(itemData.ID);
                            setPartNumber(itemData.PartNumber);
                            setManufacturer(itemData.Manufacturer);
                            setPartDescription(itemData.PartDescription);
                        }}
                        dataSource={partsOptions}
                        floatLabelType="Auto"
                        fields={{text: 'PartNumber', value: 'ID'}}
                        itemTemplate={partTemplate}
                        valueTemplate={partTemplate}
                    />
                ) : (
                    <AutoCompleteComponent
                        id="PartNumber"
                        placeholder="Part Number"
                        type="text"
                        value={initialValues?.partNumber ?? ''}
                        dataSource={partsOptions}
                        select={({itemData}: SelectPartNumber) => {
                            setPartID(itemData.ID);
                            setPartNumber(itemData.PartNumber);
                            setPartDescription(itemData.PartDescription);
                        }}
                        fields={{text: 'PartNumber', value: 'PartNumber'}}
                        floatLabelType="Auto"
                    />
                )}
                <NumericTextBoxComponent
                    value={initialValues?.quantity ?? 1}
                    id="Quantity"
                    placeholder="Quantity"
                    onChange={({value}: QuantityParts) => setQuantity(value)}
                    decimals={0}
                    min={1}
                    format={'n0'}
                />
                <Button disabled={disableSubmit} fullWidth size="large" onClick={submit} color="primary" variant="contained">
                    {t('save')}
                </Button>
            </PaperWrap>
        </Container>
    );
};
