import {ReduxAction} from '../../models/ReduxAction';
import {RepairSize} from '../../models/RepairSize';

import {REPAIR_SIZE_ERROR, REPAIR_SIZE_REQUEST, REPAIR_SIZES_RESPONSE} from './repair-size.types';

export interface RepairSizeState {
    error: Error | null;
    loading: boolean;
    repairSizes: RepairSize[];
}

const initialState: RepairSizeState = {
    error: null,
    loading: false,
    repairSizes: [],
};

export default function repairSizesReducer(state = initialState, action: ReduxAction): RepairSizeState {
    switch (action.type) {
        case REPAIR_SIZE_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case REPAIR_SIZES_RESPONSE:
            return {
                ...state,
                loading: false,
                repairSizes: action.payload,
            };
        case REPAIR_SIZE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
