import {OfflineEstimateComment} from '../../models/OfflineEstimateComment';
import {OfflineEstimateLine} from '../../models/OfflineEstimateLine';
import {OfflineEstimatePart} from '../../models/OfflineEstimatePart';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {DocumentTypeCodes} from '../../models/enumerations/DocumentTypeCodes';

export const FETCH_OFFLINE_ESTIMATES = 'FETCH_OFFLINE_ESTIMATES';
export const CLEAN_UP_OFFLINE_DATA = 'CLEAN_UP_OFFLINE_DATA';
export const OFFLINE_ESTIMATES_RESPONSE = 'OFFLINE_WORK_ORDER_RESPONSE';
export const CREATE_OFFLINE_ESTIMATE = 'CREATE_OFFLINE_ESTIMATE';
export const UPDATE_OFFLINE_ESTIMATE = 'UPDATE_OFFLINE_ESTIMATE';
export const SEND_ALL_UNSENT_OFFLINE_ESTIMATES = 'SEND_ALL_UNSENT_OFFLINE_ESTIMATES';
export const UPLOAD_OFFLINE_ESTIMATES = 'UPLOAD_OFFLINE_ESTIMATES';
export const QUEUE_OFFLINE_ESTIMATES = 'QUEUE_OFFLINE_ESTIMATES';
export const DEQUEUE_OFFLINE_ESTIMATE = 'DEQUEUE_OFFLINE_ESTIMATE';
export const PUT_OFFLINE_ESTIMATE = 'PUT_OFFLINE_ESTIMATE';
export const DISCARD_OFFLINE_ESTIMATE = 'DISCARD_OFFLINE_ESTIMATE';
export const REMOVE_OFFLINE_ESTIMATE = 'REMOVE_OFFLINE_ESTIMATE';
export const COPY_OFFLINE_ESTIMATE = 'COPY_OFFLINE_ESTIMATE';
export const FETCH_OFFLINE_ESTIMATE = 'FETCH_OFFLINE_ESTIMATE';
export const SELECT_OFFLINE_ESTIMATE = 'SELECT_OFFLINE_ESTIMATE';
export const UNSELECT_OFFLINE_ESTIMATE = 'UNSELECT_OFFLINE_ESTIMATE';
export const CREATE_OFFLINE_ESTIMATE_FILE = 'CREATE_OFFLINE_ESTIMATE_FILE';
export const DELETE_OFFLINE_ESTIMATE_FILE = 'DELETE_OFFLINE_ESTIMATE_FILE';
export const CREATE_OFFLINE_ESTIMATE_LINE = 'CREATE_OFFLINE_ESTIMATE_LINE';
export const UPDATE_OFFLINE_ESTIMATE_LINE = 'UPDATE_OFFLINE_ESTIMATE_LINE';
export const DELETE_OFFLINE_ESTIMATE_LINE = 'DELETE_OFFLINE_ESTIMATE_LINE';
export const CREATE_OFFLINE_ESTIMATE_PART = 'CREATE_OFFLINE_ESTIMATE_PART';
export const UPDATE_OFFLINE_ESTIMATE_PART = 'UPDATE_OFFLINE_ESTIMATE_PART';
export const DELETE_OFFLINE_ESTIMATE_PART = 'DELETE_OFFLINE_ESTIMATE_PART';
export const CREATE_OFFLINE_ESTIMATE_COMMENT = 'CREATE_OFFLINE_ESTIMATE_COMMENT';
export const UPDATE_OFFLINE_ESTIMATE_COMMENT = 'UPDATE_OFFLINE_ESTIMATE_COMMENT';
export const DELETE_OFFLINE_ESTIMATE_COMMENT = 'DELETE_OFFLINE_ESTIMATE_COMMENT';
export const SEND_QUEUED_OFFLINE_ESTIMATES = 'SEND_QUEUED_OFFLINE_ESTIMATES';
export const POLL_OFFLINE_ESTIMATE_TASKS = 'poll_offline_estimate_tasks';
export const UPLOAD_START = 'upload_start';
export const UPLOAD_FINISH = 'upload_finish';
export const UPLOAD_ERROR = 'upload_error';
export const POLL_ERROR = 'poll_error';
export const UPLOAD_RESPONSE = 'upload_response';
export const POLL_RESPONSE = 'poll_response';
export const POLL_START = 'poll_start';
export const POLL_FINISH = 'poll_finish';

export type CreateOfflineEstimateFileDto = {
    offlineEstimateID: number;
    associateObjectID?: number;
    associatedTypeID: AssociatedTypes.WorkOrder | AssociatedTypes.WorkOrderRepairLine;
    fileName: string;
    fileType: string;
    src: string | ArrayBuffer | null;
    type: DocumentTypeCodes;
};

export type DeleteOfflineEstimateFileDto = {
    fileID: number;
    associateObjectID?: number;
    associatedTypeID: AssociatedTypes.WorkOrder | AssociatedTypes.WorkOrderRepairLine;
    offlineEstimateID: number;
};

type OfflineEstimateLineField =
    | 'JobCodeId'
    | 'WhyMadeCodeId'
    | 'ConditionCodeId'
    | 'UnitLocationCodeId'
    | 'RepairQuantity'
    | 'LaborHours'
    | 'Overtime1'
    | 'Overtime2'
    | 'Overtime3'
    | 'MaterialSubtotal';
export type CreateOfflineEstimateLineDto = {
    offlineEstimateID: number;
    offlineEstimateLine: Pick<OfflineEstimateLine, OfflineEstimateLineField>;
};

export type UpdateOfflineEstimateLineDto = {
    offlineEstimateID: number;
    offlineEstimateLineID: number;
    offlineEstimateLine: Partial<Pick<OfflineEstimateLine, OfflineEstimateLineField>>;
};

export type CreateOfflineEstimatePartDto = {
    offlineEstimateID: number;
    offlineEstimateLineID: number;
    part: Omit<OfflineEstimatePart, 'OfflineEstimateLinePartID'>;
};

export type UpdateOfflineEstimatePartDto = {
    offlineEstimateID: number;
    offlineEstimateLineID: number;
    offlineEstimateLinePartID: number;
    part: Partial<OfflineEstimatePart>;
};

export type CreateOfflineEstimateCommentDto = {
    offlineEstimateID: number;
    comment: Omit<OfflineEstimateComment, 'ID'>;
    associateObjectID?: number;
    associatedTypeID: AssociatedTypes.WorkOrder | AssociatedTypes.WorkOrderRepairLine;
};

export type UpdateOfflineEstimateCommentDto = {
    offlineEstimateID: number;
    commentID: number;
    comment: Partial<Omit<OfflineEstimateComment, 'ID'>>;
    associateObjectID?: number;
    associatedTypeID: AssociatedTypes.WorkOrder | AssociatedTypes.WorkOrderRepairLine;
};

export type DeleteOfflineEstimateCommentDto = {
    offlineEstimateID: number;
    commentID: number;
    associateObjectID?: number;
    associatedTypeID: AssociatedTypes.WorkOrder | AssociatedTypes.WorkOrderRepairLine;
};
