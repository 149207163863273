import styled from 'styled-components';
export const Title = styled.h3`
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.primary.main};
    width: 90%;
    margin: 15px auto;
    padding-bottom: 10px;
`;
