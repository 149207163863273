import {Pricing} from '../../models/Pricing';
import {ReduxAction} from '../../models/ReduxAction';

import {CLEAR_REPAIR_LINE_PRICING, PRICING_ERROR, PRICING_REQUEST, REPAIR_LINE_PRICING_RESPONSE, SHOP_PRICING_RESPONSE} from './pricing.types';

export interface PricingState {
    error: boolean;
    loading: boolean;
    repairLinePricing: Pricing | null;
}

const initialState: PricingState = {
    error: false,
    loading: false,
    repairLinePricing: null,
};

export default function pricingReducer(state = initialState, action: ReduxAction): PricingState {
    switch (action.type) {
        case PRICING_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REPAIR_LINE_PRICING_RESPONSE:
            return {
                ...state,
                loading: false,
                error: false,
                repairLinePricing: action.payload,
            };
        case SHOP_PRICING_RESPONSE:
            return {
                ...state,
                loading: false,
            };
        case CLEAR_REPAIR_LINE_PRICING:
            return {
                ...state,
                loading: false,
                repairLinePricing: null,
            };
        case PRICING_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
