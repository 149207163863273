import React, {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import DetailsTitle from '../../components/details-title/details-title.component';
import OfflineEstimatePartsGrid from '../../components/offline-estimate-parts-grid/offline-estimate-parts-grid.component';
import {useHeader} from '../../hooks/use-header';
import {useQueryParams} from '../../hooks/use-query-params';
import {OfflineEstimateDataContext} from '../../providers/offline-estimate-data-provider';

export type OfflineEstimatePartsProps = {
    backToPath?: string;
};

const OfflineEstimatePartsContainer = ({backToPath}: OfflineEstimatePartsProps) => {
    const {push} = useHistory();
    const queryParams = useQueryParams();
    const {offlineEstimate} = useContext(OfflineEstimateDataContext);

    const onHeaderClick = useCallback(() => {
        push(`${backToPath}/${offlineEstimate.ID}/header`, {menuPrevPath: location.pathname});
    }, [offlineEstimate, push]);

    const TitleTop = useCallback(() => {
        return <DetailsTitle onClick={onHeaderClick} unitINO={offlineEstimate.UnitINO} />;
    }, [offlineEstimate]);

    useHeader({
        backToPath: `${backToPath}/${offlineEstimate.ID}`,
        middleElement: TitleTop,
    });

    const offlineEstimateLineID = +(queryParams.get('lineID') || '');

    const {offlineEstimateLinesWithRepairLineID, parts} = useMemo(() => {
        const {RepairLines: repairLines} = offlineEstimate;
        const offlineEstimateRepairLines = offlineEstimateLineID ? repairLines.filter(({ID}) => ID === offlineEstimateLineID) : repairLines;

        const offlineEstimateLinesWithRepairLineID = offlineEstimateRepairLines.map((repairLine) => ({
            ...repairLine,
            repairLineID: repairLine.ID,
        }));

        const parts = offlineEstimateRepairLines
            .map((repairLine) =>
                repairLine.Parts.map((part) => ({
                    ...part,
                    repairLineID: repairLine.ID,
                })),
            )
            .flat();

        return {offlineEstimateLinesWithRepairLineID, parts};
    }, [offlineEstimateLineID, offlineEstimate]);

    return <OfflineEstimatePartsGrid repairLines={offlineEstimateLinesWithRepairLineID} parts={parts} />;
};

export default OfflineEstimatePartsContainer;
