import React from 'react';
import {Route, Switch} from 'react-router-dom';

import FMCSA from '../../pages/fmcsa/fmcsa.component';
import FMCSAFilePage from '../../pages/fmcsa-file-page/fmcsa-file-page.component';
import {FMCSADataProvider} from '../../providers/fmcsa-data-provider';

export type FMCSARoutesProps = {
    basePath: string;
};

export const FMCSARoutes = ({basePath}: FMCSARoutesProps) => (
    <Switch>
        <Route path={basePath}>
            <FMCSADataProvider>
                <Switch>
                    <Route exact path={basePath} render={() => <FMCSA />} />
                    <Route exact path={`${basePath}/files`} render={() => <FMCSAFilePage />} />
                </Switch>
            </FMCSADataProvider>
        </Route>
    </Switch>
);
