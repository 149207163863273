import {
    ColumnDirective,
    ColumnsDirective,
    DataSourceChangedEventArgs,
    Edit,
    EditSettingsModel,
    Filter,
    GridComponent,
    InfiniteScroll,
    Inject,
    QueryCellInfoEventArgs,
    Sort,
    Toolbar,
    ToolbarItems,
} from '@syncfusion/ej2-react-grids';
import React, {useCallback, useContext, useMemo} from 'react';
import * as ReactDOM from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';

import {OfflineEstimateComment} from '../../models/OfflineEstimateComment';
import {CommentDto} from '../../models/dtos/comment-dto';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {OfflineEstimateDataContext} from '../../providers/offline-estimate-data-provider';
import {
    createOfflineEstimateComment,
    updateOfflineEstimateComment,
    deleteOfflineEstimateComment,
} from '../../redux/offline-estimate/offline-estimate.actions';
import {selectUser} from '../../redux/user/user.selectors';
import {convertCommentsToCommentDtos} from '../../utils/convertCommentToCommentDto';
import {formatDate} from '../../utils/formatDate';
import {getUserNameOrDefault} from '../../utils/getUserNameOrDefault';
import {isOfflineEstimateReadOnly} from '../../utils/isOfflineEstimateReadOnly';

import {Title} from './comments-grid-list.style';

type OfflineCommentsGridListComponentProps = {
    payload?: OfflineEstimateComment[];
    title?: string;
};

const messageColumnRules = {required: true};
const toolbarOptions: ToolbarItems[] = ['Add', 'Edit', 'Delete'];
const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: 'Dialog',
};

function OfflineCommentsGridListComponent({payload = [], title = ''}: OfflineCommentsGridListComponentProps) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const {offlineEstimate} = useContext(OfflineEstimateDataContext);
    const isReadOnly = isOfflineEstimateReadOnly(offlineEstimate?.SentStatus);
    const comments = useMemo(() => convertCommentsToCommentDtos(payload), [payload]);

    const dataManager = useCallback(
        function (state: DataSourceChangedEventArgs) {
            // Set the fields that aren't automatically set so the comment looks correct while the save request finishes.
            // The time is going to be off but at least its not "undefined" or missing entirely.
            state.data = {
                ...state.data,
                TimeStamp: new Date(),
                FormattedTimeStamp: formatDate({date: new Date(), includeTime: true}),
                UserName: user?.FirstName && user?.LastName ? `${user.FirstName} ${user.LastName}` : user?.UserName ? user.UserName : '',
            };

            if (state.requestType === 'delete') {
                dispatch(
                    deleteOfflineEstimateComment({
                        offlineEstimateID: offlineEstimate.ID,
                        associatedTypeID: AssociatedTypes.WorkOrder,
                        commentID: (state.data as OfflineEstimateComment[])[0].ID,
                    }),
                );
            } else if (user && state.action === 'add' && state.requestType === 'save') {
                const {Message, TimeStamp} = state.data as OfflineEstimateComment;

                const comment: Omit<OfflineEstimateComment, 'ID'> = {
                    Message,
                    TimeStamp,
                    User: {
                        ID: user.ID,
                        FirstName: user.FirstName,
                        LastName: user.LastName,
                        UserName: user.UserName,
                    },
                };

                dispatch(
                    createOfflineEstimateComment({
                        offlineEstimateID: offlineEstimate.ID,
                        associatedTypeID: AssociatedTypes.WorkOrder,
                        comment,
                    }),
                );

                state.endEdit && state.endEdit();
            } else if (state.action === 'edit' && state.requestType === 'save') {
                const {ID, Message} = state.data as OfflineEstimateComment;

                dispatch(
                    updateOfflineEstimateComment({
                        offlineEstimateID: offlineEstimate.ID,
                        associatedTypeID: AssociatedTypes.WorkOrder,
                        commentID: ID,
                        comment: {
                            Message,
                        },
                    }),
                );
            }
        },
        [dispatch, offlineEstimate.ID],
    );

    const queryCellInfo = (args: QueryCellInfoEventArgs) => {
        const {FormattedTimeStamp} = args.data as CommentDto;

        if (args.column?.field === 'TimeStampDate') {
            ReactDOM.render(<span>{FormattedTimeStamp}</span>, args.cell as Element);
        }
    };

    return (
        <div className="e-adaptive-demo e-bigger" key={payload?.length}>
            <div className="e-mobile-layout">
                <div className="e-mobile-content">
                    <Title>{title}</Title>
                    <GridComponent
                        editSettings={isReadOnly ? undefined : editSettings}
                        toolbar={isReadOnly ? [] : toolbarOptions}
                        allowPaging
                        allowSorting
                        allowFiltering
                        enableAdaptiveUI
                        dataSource={comments}
                        enableInfiniteScrolling
                        allowTextWrap
                        actionBegin={dataManager}
                        rowRenderingMode="Vertical"
                        queryCellInfo={queryCellInfo}
                        filterSettings={{type: 'Menu'}}>
                        <ColumnsDirective>
                            <ColumnDirective
                                field="UserName"
                                headerText="User"
                                width="auto"
                                allowEditing={false}
                                defaultValue={user ? getUserNameOrDefault(user) : 'Unknown'}
                            />
                            <ColumnDirective
                                //type="date"
                                field="TimeStampDate"
                                headerText="Date"
                                allowEditing={false}
                                defaultValue="Now"
                            />
                            <ColumnDirective validationRules={messageColumnRules} field="Message" headerText="Message" />
                        </ColumnsDirective>
                        <Inject services={[InfiniteScroll, Filter, Sort, Edit, Toolbar]} />
                    </GridComponent>
                </div>
            </div>
        </div>
    );
}

export default OfflineCommentsGridListComponent;
